import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AvailabilityApi from '../apis/Availability';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';

interface Props { }

const ConfirmAvailability: React.FC<Props> = ({  }) => {

  const [status, setStatus] = useState <"loading" | "loaded" | "errored"> ("loading")

  useEffect(() => {
    confirm();
  }, [])

  const confirm = async function() {
    setStatus("loading");
    let token = window.location.href.split("/confirm-availability/")[1];
    if (token) {
      AvailabilityApi
        .confirm(token)
        .then(data => {
          setStatus("loaded")
        }).catch(data => {
          setStatus("errored")
        })
    } else {
      setStatus("errored")
    }
  }

  return (
    <IonPage data-page="verify-email">
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="verify-email-wrapper" fullscreen>
        <div>
          <div>
            { (status === "loaded") &&
              <IonIcon 
                icon={checkmarkCircle}
                style={{
                  fontSize: '3.6em',
                  color: '#50D890'
                }}
              />
            }
            { (status === "errored") &&
              <IonIcon
                icon={closeCircle}
                style={{
                  fontSize: '3.6em',
                  color: '#FB5B5A'
                }}
              />
            }
            <h1
              style={{
                margin: '2px 0 10px 0'
              }}
            >
              { status === "loading" ? "Confirming Availability" : status === "loaded" ? "Availability Confirmed" : "Failed to confirm availability" }
            </h1>
            { (status === "errored") &&
              <button 
                style={{ margin: '12px 0 0 0' }} 
                className="main" 
                onClick={() => {
                  confirm();
                }}
              >
                Try Again
              </button>
            }
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ConfirmAvailability;