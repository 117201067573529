export const updateResolvedEvents = (data: Object) => {
    return {
        type: "PUSH_RESOLVED_EVENTS",
        data: data
    }
}

export const clearResolvedEvents = () => {
    return {
        type: "CLEAR_RESOLVED_EVENTS"
    }
}