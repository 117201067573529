import { IonAlert, IonBackButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { CircularProgress } from '@material-ui/core';
import { folderOpen } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import UserApi from '../apis/User';
import Configuration from '../Configuration';
import { AllFilesResponse, FileType, FileUserDetail, UserFileWitUserDetails, UserFolder } from '../types/User';
import DocumentViewer from './Components/Files/DocumentViewer';
import FileListItem from './Components/Files/FileListItem';
import Select from 'react-select';
import OnboardingGuide from './Components/OnboardingGuide';
import Utilities from '../Utilities';
import InternalTracker from '../InternalTracker';
import BusinessImg from '../images/business.png';
import { getCacheValue, setCacheValue } from '../stores/Cache';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const NoUserSelectedFilter = {
  organisationId: "",
  organisationName: "All Organisations",
}

interface Props {
  match?: Object,
}

interface UserDetail {
  name: string;
  userId: string;
}

let GlobalselectedOrgId = null;

const Files: React.FC<Props> = ({ match }) => {
  
  const [activeTab, setActiveTab] = useState <"own" | "received"> ("received");
  const [files, setFiles] = useState<UserFileWitUserDetails[]> ([]);
  const [folders, setFolders] = useState<UserFolder[]> ([]);
  const [users, setUsers] = useState<FileUserDetail[]> ([]);
  const [orgs, setOrgs] = useState<FileUserDetail[]> ([]);
  const [ownUserId, setOwnUserId] = useState<string> ("");
  const [loading, setLoading] = useState <string> (null)
  const [searchKeywords, setSearchKeywords] = useState <string> ("");
  const [tabTitle, setTabTitle] = useState <string> ("Files");
  const [openFileId, setOpenFileId] = useState <number> (null);
  const [selectedUserId, setSelectedUserId] = useState <string> (null);
  const [selectedOrgId, setSelectedOrgId] = useState <string> (null);
  const [selectedFolderId, setSelectedFolderId] = useState <string> (null);
  const [noAccessFileId, setNoAccessFileId] = useState <number> (null);
  const [noAccessFolderId, setNoAccessFolderId] = useState <number> (null);
  const [noAccessFileFolderName, setNoAccessFileFolderName] = useState <string> (null);
  const [openedFileType, setOpenedFileType] = useState <FileType> (null);
  const [openedFileName, setOpenedFileName] = useState <string> (null);
  const [openedFileUri, setOpenedFileUri] = useState <string> (null);
  const [openedFileId, setOpenedFileId] = useState <number> (null);
  
  const contentRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    (window as any).onSignalRFileShared = function (fileId: number) {
      loadFiles(true);
    }

    loadFiles();

    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "{}") : null;
    if (user && user.id) {
      setOwnUserId(user.id)
    }

    loadCache();

    history.listen(e => {
      if (e.pathname.startsWith("/files")) {
        loadFiles();
      }
    })
  }, [])

  const loadCache = async () => {
    const files = await getCacheValue("files");
    const folders = await getCacheValue("folders");
    if (files & folders) {
      setFiles(files);
      setFolders(folders);
    }
  }

  const loadFiles = async (silent?: boolean) => {
    if (!silent) {
      setLoading("Loading files...")
    }
    const files: AllFilesResponse | void = await UserApi.getAllFiles().catch(e => {
        (window as any).toast("Failed to load files", "error")
    });
    if (files) {
      // @ts-ignore
      let allFiles = /*(files.ownFiles.concat(*/files.receivedFiles/*))*/ as UserFileWitUserDetails[];
      for (let i = 0; i < allFiles.length; i++) {
        const contactDetails = files.userDetails.find(ud => allFiles[i].userId === ud.userId);
        allFiles[i].userName = contactDetails?.name;
        allFiles[i].userReported = contactDetails?.reported;
        const org = files.userDetails.find(ud => allFiles[i].userId === ud.userId);
        if (org) {
          allFiles[i].organisationId = org.organisationId;
          allFiles[i].organisationName = org.organisationName;
        }
      }

      setCacheValue("files", allFiles);

      if (openFileId || window.location.href.includes("/file/")) {
        let openFileIdToOpen = openFileId || parseInt(window.location.href.split("/file/")[1])
        const file = allFiles.find(f => f.id === openFileIdToOpen)
        if (file && file.accessLink) {
          window.open(file.accessLink)
        }
      }

      files.receivedFolders = files.receivedFolders.sort(Utilities.dynamicSort("name"));

      let orgs = [];
      setFiles(allFiles);
      // @ts-ignore
      const folders = files.receivedFolders.concat(files.ownFolders).concat({ id: 0, name: "Uncategorized Files" })
      setFolders(folders);
      setCacheValue("folders", folders);
      const users = files.userDetails.map(ud => {
        if (ud.organisationId && !orgs.find(o => o.organisationId === ud.organisationId)) {
          orgs.push({
            organisationId: ud.organisationId,
            organisationName: ud.organisationName
          })
        }
        return ud
      })
      setUsers(users);
      setOrgs(orgs);

      // @ts-ignore
      const entityTypeFromUrl = match?.params?.type;
      // @ts-ignore
      const idFromUrl = match?.params?.id;

      if (entityTypeFromUrl === "file") {
        const file = allFiles.find(f => f.id === parseInt(idFromUrl))
        if (file && file.accessLink) {
          window.open(file.accessLink)
        }
      } else if (entityTypeFromUrl === "user") {
        setSelectedUserId(idFromUrl);
      }
      
      setTimeout(() => {
        if (GlobalselectedOrgId && files.receivedFiles.filter(file => shouldRenderFile(file, "", null, GlobalselectedOrgId, null, users)).length === 0) {
            (window as any).toast("No files shared by this organization", "info");
            setSelectedOrgId(null)
            setSelectedUserId(null);
            GlobalselectedOrgId = null;
            setTabTitle("Files shared with you");
        }
      }, 1000)
    }
    setLoading(null);
  }

  useEffect(() => {
    // @ts-ignore
    const entity = match?.params?.entity;
    // @ts-ignore
    const id = match?.params?.id;

    switch (entity) {
      case "user":
        setSelectedUserId(id);
        if (users.find(u => u.userId === id)) {
          setTabTitle("Files from " + users.find(u => u.userId === id)?.name);
        }
        break;
      case "folder":
        setSelectedFolderId(id);
        break;
      case "file":
        setOpenFileId(id);
        break;
      case "org":
        GlobalselectedOrgId = id;
        setSelectedOrgId(id);
        if (orgs.find(o => o.organisationId === id)) {
          setTabTitle("Files from " + orgs.find(o => o.organisationId === id)?.organisationName);
        }
        break;
      default:
        setTabTitle("Your Contacts' Files");
        break;
    }

    // @ts-ignore
  }, [match.params.id, orgs, users])

  const shouldRenderFile = function(file, searchKeywords, selectedUserId, selectedOrgId, folderId, usersOverride?) {
    let usersToUse = ((!users || users.length === 0) && usersOverride) ? usersOverride : users;
    
    if (!file.moderatorApproved) {
      return false;
    }
    
    if (searchKeywords && file.fileName.toLowerCase().indexOf(searchKeywords) === -1) {
      return false;
    }

    if (selectedUserId && file.userId !== selectedUserId) {
      return false;
    }

    if (selectedOrgId && usersToUse.find(u => u.userId === file.userId)?.organisationId !== selectedOrgId) {
      return false;
    }

    if (! (file.folderId === folderId || ((folderId === 0 || folderId === null) && (!file.folderId || file.folderId === 0))) ) {
      return false;
    }

    return true;
  }

  return (
    <IonPage data-page="files" ref={contentRef}>
      <IonHeader mode='md'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{tabTitle}</IonTitle>
          <div className="nonblocking-loading-wrapper" style={(loading) ? { display: 'flex' } : { display: 'none' }}>
            <CircularProgress />
            Loading
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent id="files-wrapper">
        <div className='filters'>
          <Select                 
            formatOptionLabel={( { organisationName, organisationId }) => (
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 4 }} >
                    <img
                      style={{ width: 24, height: 24, marginRight: 2, borderRadius: '100%' }} 
                      src={ (organisationId) ? (UserApi.getOrgPicture(organisationId)) : BusinessImg}
                    />
                    <p style={{ margin: 0, paddingLeft: 10, color: 'black' }}>{organisationName}</p>
                </div>
            )}
            id='file-owner-select'
            isSearchable={true}
            value={selectedOrgId ? orgs.find(o => o.organisationId === selectedOrgId) : NoUserSelectedFilter}
            onChange={(option) => {
              // @ts-ignore
              const organisationId = option!.organisationId;
              // @ts-ignore
              const organisationName = option!.organisationName;
              InternalTracker.trackEvent("Files Filter", {
                type: "org",
                id: organisationId,
                name: organisationName
              })
              setSelectedOrgId(organisationId);
              GlobalselectedOrgId = organisationId;
              // @ts-ignore
              if (organisationId) setTabTitle("Files from " + orgs.find(o => o.organisationName === option!.organisationName)?.organisationName);
            }}
            defaultValue={NoUserSelectedFilter}
            controlShouldRenderValue={true}
            inputId="file-owner-select"
            placeholder="Filter by User"
            options={[NoUserSelectedFilter].concat(orgs)}
            menuShouldScrollIntoView={false}
          />
          <IonSearchbar 
            placeholder={"Search in files"} 
            debounce={600} 
            mode="ios"
            onKeyUp={e => {
              setSearchKeywords((e.target as HTMLInputElement).value.toLowerCase())
            }}
            onIonInput={e => {
              InternalTracker.trackEvent("Filter Filter", {
                type: "text",
                text: (e.target as HTMLIonSearchbarElement).value.toLowerCase()
              })
            }}
          ></IonSearchbar>
        </div>
        { (true || selectedUserId || selectedOrgId || searchKeywords) &&
          <div className="folders">
            { folders.map(folder => {
              const shouldRenderFolder = files.find(file => shouldRenderFile(file, searchKeywords, selectedUserId, selectedOrgId, folder.id));
              if (!shouldRenderFolder) return null;
              return (
                <div className='folder group scrollable-group'>
                  <div className="head">
                    <IonIcon icon={folderOpen} />
                    {folder.name}
                  </div>
                  <div className='files'>
                  { files.map((file, fileI) => {
                    if (shouldRenderFile(file, searchKeywords, selectedUserId, selectedOrgId, folder.id)) {
                      return (
                        <FileListItem
                          file={file}
                          fileI={fileI}
                          onClick={() => {
                            Utilities.onDrawerShow();

                            if (activeTab === "received") {
                              if (!file.virusScanned) {
                                (window as any).toast("This file has not been virus scanned yet. Please try again later", "error");
                                return;
                              }
                              if (!file.accessLink) {
                                setNoAccessFileId(file.id);
                                setNoAccessFileFolderName(file.fileName)
                                setNoAccessFolderId(file.folderId || 0)
                              } else {

                                InternalTracker.trackEvent("File Open", {
                                  id: file.id,
                                  name: file.fileName,
                                  type: file.fileType,
                                })

                                if (
                                  ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(file.fileFormat || "") !== -1 ||
                                  file.fileType === FileType.VideoLink || 
                                  file.fileType === FileType.Link
                                ) {
                                  setOpenedFileUri(file.accessLink);
                                  setOpenedFileId(file.id);
                                  setOpenedFileName(file.fileName || "");
                                  setOpenedFileType(file.fileType || null);                                                              
                                } else {
                                  window.open(file.accessLink)
                                }
                              }
                            } else {
                              // alert("TODO manage from here, add, reorder, edit, delete...")
                            }
                          }}
                          showAccess={false}
                          showCategory={true}
                          showAVScan={true}
                          showDescription={true}
                          showOwner={/*activeTab === "received"*/ !selectedUserId && !selectedOrgId}
                        />
                      )
                    }
                    return null;
                  }) }
                  </div>
                </div>
              )
            }) }
          </div>
        }
        { (openedFileUri) &&
            <DocumentViewer
                uri={openedFileUri}
                id={openedFileId}
                type={openedFileType}
                name={openedFileName}
                close={() => {
                    setOpenedFileUri(undefined);
                }}
                report={() => {
                  if (window.confirm("Are you sure you want to report this file?")) {
                    UserApi.reportFile(openedFileId).then(() => {
                      (window as any).toast("File reported successfully", "success");
                    }).catch((err) => {
                      (window as any).toast("Error reporting file", "error");
                    })
                  }
                }}
            />
        }
        { (selectedUserId || selectedOrgId) &&
          <IonFab vertical="bottom" horizontal="start" slot="fixed" style={{ width: "calc(100% - 20px)", display: "flex", justifyContent: "center" }}>
            <IonFabButton 
              onClick={() => {
                setSelectedOrgId(null)
                GlobalselectedOrgId = null;
                setSelectedUserId(null)
                setTabTitle("Your Contacts' Files");
              }}
              style={{
                "width": 200,
                "--border-radius": '52px'
              }}
            >
              Show Files from Everyone
            </IonFabButton>
          </IonFab>
        }
        { (!selectedUserId && !selectedOrgId && localStorage.getItem("FeatureFiles")) &&
          <IonFab vertical="bottom" horizontal="start" slot="fixed" style={{ width: "calc(100% - 20px)", display: "flex", justifyContent: "center" }}>
            <IonFabButton 
              onClick={() => {
                localStorage.setItem("userGuideOpenSection", "files");
                window.location.href = "/calendar";
              }}
              style={{
                "width": 200,
                "--border-radius": '52px'
              }}
            >
              Add or see your content
            </IonFabButton>
          </IonFab>
        }
        <IonAlert
          isOpen={noAccessFileId !== null}
          onDidDismiss={() => { 
            setNoAccessFileId(null) 
            setNoAccessFolderId(null)
          }}
          header={"You don't have access to the'" + noAccessFileFolderName + "' file"}
          buttons={[
            {
              text: 'Close',
              handler: () => {  }
            },
            {
              text: 'Request Access to File',
              handler: () => {
                InternalTracker.trackEvent("File Request Access", {
                  id: noAccessFileId,
                })
                UserApi.requestFileAccess(noAccessFileId || 0).then(() => {
                  (window as any).toast("Access request sent successfully", "success");
                }).catch((err) => {
                  (window as any).toast("Error sending access request", "error");
                })
              }
            }
          ].concat(noAccessFolderId ? [{
            text: 'Request Access to Folder',
            handler: () => {
              InternalTracker.trackEvent("Folder Request Access", {
                id: noAccessFileId,
              })
              UserApi.requestFolderAccess(noAccessFolderId || 0).then(() => {
                (window as any).toast("Access request sent successfully", "success");
              }).catch((err) => {
                (window as any).toast("Error sending access request", "error");
              })
            }
          }] : [])}
        />
        <OnboardingGuide
          section="files"
        />
      </IonContent>
    </IonPage>
  );
};

export default Files;
