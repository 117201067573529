
import React, { useEffect, useState } from 'react';
import Configuration from '../../Configuration';
import { RatingRecord } from '../../types/Offer';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonList, IonLoading, IonModal, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { checkmarkOutline, close, closeOutline, timeSharp } from 'ionicons/icons';
import UserApi from '../../apis/User';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

export enum ReportTypeId {
  Contact = 1,
  Chat = 2,
  Offer = 3
}

const REPORT_REASONS = {
  1: [
    { id: 0, text: "Select Reason" },
    // { id: 6, text: "This person is unknown by me" },
    // { id: 7, text: "I don't work with this agency" },
    { id: 1, text: "Image unsuitable" },
    { id: 2, text: "Headline unsuitable" },
    // { id: 3, text: "Qualifications unsuitable" },
    // { id: 4, text: "Skills unsuitable" },
    { id: 5, text: "Name unsuitable" },
    // { id: 8, text: "My record's doesn't match their contact details" },
    { id: 9, text: "Other" }
  ],
  2: [
    { id: 0, text: "Select Reason" },
    { id: 10, text: "Unsuitable Message" },
    { id: 11, text: "Other" }
  ],
  3: [
    { id: 0, text: "Select Reason" },
    { id: 12, text: "Unsuitable Offer" },
    { id: 13, text: "Other" }
  ]
}

interface ReportModalProps {
    onClose: () => void;
    open: boolean;
    id: string;
    type: ReportTypeId;
    isUserId?: boolean;
    reportedEntityId?: string;
}

interface Toast {
  message: string;
  icon: string;
  color: string;
  position?: "top" | "bottom" | "middle" | undefined
}

const ReportModal: React.FC<ReportModalProps> = ({ id, type, open, onClose, isUserId }) => {

    const [reasonType, setReasonType] = useState <string> ("Select Reason");
    const [extraNotes, setExtraNotes] = useState <string> ("");
    const [manuallyEdited, setManuallyEdited] = useState <boolean> (false);
    const [loading, setLoading] = useState <boolean> (false);
    const [toast, setToast] = useState <Toast> (null);

    useEffect( () => {
      
    }, [])
    
    const reportReasons = REPORT_REASONS[type];

    const report = async (reasonType: string, extraNotes: string) => {
      setLoading(true);
      // await reportUser(id, reasonType, extraNotes);
      let error = null;
      const res = await UserApi.report(type, REPORT_REASONS[type].find(t => t.text === reasonType).id, extraNotes, isUserId ? null : id, isUserId ? id : null).catch(e => {
        // (window as any).toast("Error reporting user", "error")
        setToast({ message: "Error Sending Report", icon: closeOutline, color: "danger" })
        error = e;
      });
      if (!error) {
        // (window as any).toast("User reported", "success")
        setToast({ message: "Report Sent", icon: checkmarkOutline, color: "success" })
      }
      setLoading(false);
      onClose();
    }

    return (
      <IonModal
        isOpen={open}
        canDismiss={!open}
        onDidDismiss={() => { 
            onClose()
        }}
        data-modal="report"
        mode="ios"
      >
        <IonHeader mode="md">
          <IonToolbar>
            <IonTitle>Report {type === ReportTypeId.Contact ? "Contact" : type === ReportTypeId.Chat ? "Chat Thead" : type === ReportTypeId.Offer ? "Offer" : "" }</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                onClose()
              }}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonSelect
                label={"Select Reason"}
                onIonChange={ async (e) => { 
                  // if (!manuallyEdited || reasonType === "") {
                  //   setExtraNotes(reasonType === "Other" ? "" : reasonType)
                  // }
                  setReasonType(e.detail.value)
                }}
                value={reasonType || ""}
            >
                { reportReasons.map((reason, i) => {
                    return (<IonSelectOption key={reason.id} value={reason.text}>{reason.text}</IonSelectOption>)
                }) }
            </IonSelect>
              <IonTextarea
                rows={4}
                placeholder="Add more comments" 
                value={extraNotes || ""} 
                onIonInput={(e) => { 
                  setExtraNotes(e.detail.value || "")
                  setManuallyEdited(true)
                }}
              />
            <IonButton
              expand="block"
              onClick={() => {
                if (reasonType === "Select Reason") {
                  setToast({ message: "Please select a reason", icon: closeOutline, color: "danger" })
                  return;
                }
                report(reasonType, extraNotes);
              }}
            >Confirm Report</IonButton>
          </IonList>
        </IonContent>
        <IonLoading isOpen={loading} message={"Loading"} />
        <IonToast isOpen={toast !== null} position={toast ? (toast.position || "top") : "top"} message={toast ? toast.message : ""} duration={4000} icon={toast ? toast.icon : undefined} color={toast ? toast.color : undefined} onDidDismiss={() => { setToast(null) }} />
      </IonModal>
    );
    
};

export default ReportModal;