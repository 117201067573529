import InternalAPI from './InternalAPI';
import versionName from '../versionName';

class NotificationAPI {

    /**
     * Returns all outstanding notifications
     * @returns outstanding notifications
     */
    static async get(): Promise<Object> { return InternalAPI.request("GET", "/publicapi/notification/outstanding/detailed?app-version=" + versionName + "&", null, null, null, null, true, null, null, true); }

    /**
     * Returns all outstanding notifications from the new api
     * @returns outstanding notifications
     */
     static async getNew(): Promise<Object> { return InternalAPI.request("GET", "/notification/outstanding?", null, null, null, true, true, null, null, true); }

    /**
     * Searches in notifications
     * @returns notifications matches
     */
    static async search(keywords: string, start: string, end: string, types: string[]): Promise<Object> { return InternalAPI.request("GET", "/publicapi/notification/outstanding/detailed?app-version=" + versionName + "&keywords=" + keywords + "&start-date=" + start + "T00:00:00.000Z&end-date=" + end + "T23:59:59.000Z&" + (types.length !== 0 ? (types.join("=true&") + "=true") : ""), null, null, null, null, true); }

    /**
     * Returns all outstanding notifications
     * @returns outstanding notifications
     */
    static async getDirect(): Promise<Object> { return InternalAPI.request("GET", "/publicapi/events/pending?", null, null, null, null, true); }

    /**
     * Accepts the share invite
     * @returns Accept confirmation
     */
    static async acceptContactInvite(id: string): Promise<Object> { return InternalAPI.request("GET", "/publicapi/invitations/accept/" + id + "?"); }

    /**
     * Rejects the share invite
     * @returns Reject confirmation
     */
    static async rejectContactInvite(id: string): Promise<Object> { return InternalAPI.request("GET", "/publicapi/invitations/reject/" + id + "?"); }

    /**
     * Acknowledges the notification
     */
    static async acknowledgeNotification(notificationId: string, deviceId: string, tapped?: boolean): Promise<null> { 
        return InternalAPI.request("PUT", "/notifications/push/" + (notificationId || 'undefined') + "/acknowledge?deviceId=" + (deviceId) + "&tapped=" + tapped + "", null, null, null, true); 
    }
}

export default NotificationAPI;
