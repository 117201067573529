import { IonList, IonLabel, IonInput, IonItem, IonModal, IonAlert, IonActionSheet, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonSearchbar, IonTitle, IonToast, IonToolbar, IonButton, IonSegment, IonSegmentButton } from '@ionic/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addCircle, closeCircle, arrowBackOutline, share, informationCircle, location, briefcase, caretDownCircle, close, chevronBackCircle, personAdd, checkmarkDone, chevronForwardCircle, search, mail, listOutline, helpBuoy, people, business, closeSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import ContactAPI from '../apis/ContactAPI';
import AvailabilityApi from '../apis/Availability';
import ContactsHero from '../images/contacts-hero.png';
import { refreshOnboardingGuide } from '../state/actions/OnboardingGuide';
import store from '../state/store';
import { Contact, ContactSearchType, ContactType } from '../types/Contact';
import Utilities from '../Utilities';
import ContactListItem from './Components/Contacts/ContactListItem';
import OnboardingGuide from './Components/OnboardingGuide';
import InternalTracker from '../InternalTracker';
import Configuration from '../Configuration';
import { Plugins } from '@capacitor/core';
import ProfileProgress from './Components/ProfileProgress';
import { ChatService } from '../services/ChatService';
import RatingModal from './Components/Rating/RatingModal';
import { getDummyRatingRating } from '../types/Offer';
import UserAPI from '../apis/User';
import Confetti from 'react-confetti'
import { Share } from '@capacitor/share';
import { Tooltip } from '@material-ui/core';
import { createGesture } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props { }

let globalContactSearchText = "";

interface ReferredContact {
  id: number;
  name: string;
  date: string;
  // status: ReferralStatus;
}

const ReferralsAdd: React.FC<Props> = ({  }) => {
  
  const [step, setStep] = useState <number> (1);
  const [referredPartyType, setReferredPartyType] = useState <string> ("");
  const [referralRecipientType, setReferralRecipientType] = useState <string> ("");
  const [previousStep, setPreviousStep] = useState <number> (0);
  const [blockingLoading, setBlockingLoading] = useState <string> (null)
  const [outcome, setOutcome] = useState <boolean | null> (null)

  const [email, setEmail] = useState <string> ("");
  const [nameFirst, setNameFirst] = useState <string> ("");
  const [nameLast, setNameLast] = useState <string> ("");
  const [orgName, setOrgName] = useState <string> ("");
  const [userId, setUserId] = useState <string> ("");
  const [skills, setSkills] = useState <string> ("");
  const [location, setLocation] = useState <string> ("");

  const [email2, setEmail2] = useState <string> ("");
  const [nameFirst2, setNameFirst2] = useState <string> ("");
  const [nameLast2, setNameLast2] = useState <string> ("");
  const [orgName2, setOrgName2] = useState <string> ("");
  const [userId2, setUserId2] = useState <string> ("");

  const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false);
  const [confirmDifferentUserType, setConfirmDifferentUserType] = useState <{
    actual: string;
    selected: string;
  }> (null);
  const [confetti, setConfetti] = useState <boolean> (false);
  const [missingFields, setMissingFields] = useState <string[]> ([]);

  const [workerTooltipShowing, setWorkerTooltipShowing] = useState <boolean> (false);
  const [hirerToolTipShowing, setHirerToolTipShowing] = useState <boolean> (false);
  const [agencyToolTipShowing, setAgencyToolTipShowing] = useState <boolean> (false);

  const contentRef = useRef(null);
  const history = useHistory();

  const submit = async (
    step: number,
    nameFirst: string,
    nameLast: string,
    nameFirst2: string,
    nameLast2: string,
    email: string,
    email2: string,
    userId: string,
    userId2: string,
    skills: string,
    orgName: string,
    orgName2: string,
    location: string,
    referredPartyType: string,
    referralRecipientType: string,
    overrideTypeMismatch?: boolean
  ) => {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
    setHirerToolTipShowing(false)
    setAgencyToolTipShowing(false)
    setWorkerTooltipShowing(false)
    
    if (step === 20) {
      // once we switch to server side recommendations
    } else if (step === 33) {
      setBlockingLoading("Sending Invitation");
      let referred: any = null;
      let referredTo: any = null;

      if ( // correct order, no need to swap
        (referredPartyType === "worker" && (referralRecipientType === "hirer" || referralRecipientType === "agency")) ||
        (referredPartyType === "hirer" && referralRecipientType === "agency") 
      ) {
        referred = {
          firstName: nameFirst,
          lastName: nameLast,
          email: email,
          userId: userId,
          notes: skills,
          location: location,
          orgName: orgName,
          type: referredPartyType === "worker" ? 1 : referredPartyType === "hirer" ? 2 : 3
        }
        referredTo = {
          firstName: nameFirst2,
          lastName: nameLast2,
          email: email2,
          userId: userId2,
          orgName: orgName2,
          type: referralRecipientType === "hirer" ? 2 : 3
        }
      } else {
        referred = {
          firstName: nameFirst2,
          lastName: nameLast2,
          email: email2,
          userId: userId2,
          notes: skills,
          location: location,
          orgName: orgName2,
          type: referralRecipientType === "worker" ? 1 : referralRecipientType === "hirer" ? 2 : 3
        }
        referredTo = {
          firstName: nameFirst,
          lastName: nameLast,
          email: email,
          userId: userId,
          orgName: orgName,
          type: referredPartyType === "worker" ? 1 : referredPartyType === "hirer" ? 2 : 3
        }
      }

      console.log("REFERRED: ", referred, "REFERRED TO: ", referredTo);

      ContactAPI.sendReferral(referred, referredTo).then((res: any) => {
        localStorage.removeItem("referral-state");
        setBlockingLoading(null);
        setOutcome(true);
        setConfetti(true);
        setStep(100);
        InternalTracker.trackEvent("Referral Sent", {
          referredType: referred.type,
          referredToType: referredTo.type
        });
      }).catch((err: any) => {
        localStorage.removeItem("referral-state");
        console.log(err);
        setBlockingLoading(null);
        setOutcome(false)
        setPreviousStep(step);
        InternalTracker.trackEvent("Referral Failed to Send", {
          referredType: referred.type,
          referredToType: referredTo.type
        });
      })
        
    } else if (step === 30) {
      if (email && !Utilities.isEmail(email)) {
        (window as any).toast("Please enter a valid email address", "error"); return;
      } else if ((nameFirst && nameLast && email) || userId) {
        if (user.id === userId || user.email === email) {
          (window as any).toast("You cannot refer yourself", "error"); return;
        }

        setBlockingLoading("Loading...")
        let userTypes = null;
        if (userId) {
          userTypes = await UserAPI.getUserTypeByUserId(userId).catch(e => {  });
        } else if (email) {
          userTypes = await UserAPI.getUserTypeByUserEmail(email).catch(e => {  });
        }
        if (!userTypes) {
          (window as any).toast("Failed to validate user type, please try later", "error"); setBlockingLoading(null); return;
        }
        if (!userTypes.nonExistent) {
          if (referredPartyType === "worker" && !userTypes.worker) {
            (window as any).toast("The selected user is not a worker", "error"); setBlockingLoading(null); return;
          } else if (referredPartyType === "hirer" && !userTypes.hirer) {
            (window as any).toast("The selected user is not a hirer", "error"); setBlockingLoading(null); return;
          } else if (referredPartyType === "agency" && !userTypes.agency) {
            (window as any).toast("The selected user is not an agency", "error"); setBlockingLoading(null); return;
          }
        }
        setBlockingLoading(null);

        if (referredPartyType === "worker" && !location && !userId) {
          (window as any).toast("Please enter the location of your contact", "error"); 
        } else {
          setStep(31);
        }
      } else {
        (window as any).toast("Please enter a name and email address, or select a contact from the contact list", "error"); 
      }
    } else if (step === 32) {
      if (email2 && !Utilities.isEmail(email2)) {
        (window as any).toast("Please enter a valid email address", "error"); 
      } else if ((nameFirst2 && nameLast2 && email2) || userId2) {
        if (user.id === userId2 || user.email === email2) {
          (window as any).toast("You cannot refer yourself", "error");  return;
        }

        setBlockingLoading("Loading...")
        let userTypes = null;
        if (userId2) {
          userTypes = await UserAPI.getUserTypeByUserId(userId2).catch(e => {  });;
        } else if (email2) {
          userTypes = await UserAPI.getUserTypeByUserEmail(email2).catch(e => {  });;
        }
        if (!userTypes) {
          (window as any).toast("Failed to validate user type, please try later", "error"); setBlockingLoading(null); return;
        }
        if (!userTypes.nonExistent) {
          if (referralRecipientType === "worker" && !userTypes.worker) {
            (window as any).toast("The selected user is not a worker", "error"); setBlockingLoading(null); return;
          } else if (referralRecipientType === "hirer" && !userTypes.hirer) {
            (window as any).toast("The selected user is not a hirer", "error"); setBlockingLoading(null); return;
          } else if (referralRecipientType === "agency" && !userTypes.agency) {
            (window as any).toast("The selected user is not an agency", "error"); setBlockingLoading(null); return;
          }
        }
        setBlockingLoading(null);

        if (referralRecipientType === "worker" && !location && !userId2) {
          (window as any).toast("Please enter the location of your contact", "error");
        } else {
          setStep(33);
        }
      } else {
        (window as any).toast("Please enter a name and email address, or select a contact from the contact list", "error");
      }
    }
  }

  const resetState = () => {
    setNameFirst("");
    setNameLast("");
    setUserId("");
    setEmail("");
    setOrgName("");
    setNameFirst2("");
    setNameLast2("");
    setUserId2("");
    setEmail2("");
    setOrgName2("");
    setReferredPartyType("");
    setReferralRecipientType("");
    setSkills("");
    setLocation("");
  }

  useEffect( () => {
    setStep(1);
    history.listen(async (e) => {
      if (e.pathname === "/referrals/add")
        reloadStateFromStorage();
    })

    reloadStateFromStorage();

    // Prevent backscrolls disrupting managed steps
    let gesture = createGesture({ el: document.getElementById('referral-add-ion-page'), threshold: 0, gestureName: 'my-gesture', gesturePriority: 40.5, onMove: ev => { } });
    gesture.enable(true);

    if ((window as any).os !== "web") {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setTimeout(() => {
          setKeyboardOpen(true)
        })
      });
  
      Keyboard.addListener('keyboardWillHide', () => {
        setTimeout(() => {
          setKeyboardOpen(false)
        })
      });
    }
  }, [])

  const reloadStateFromStorage = () => {
    if (localStorage.getItem("referral-state")) {
      const lastState = JSON.parse(localStorage.getItem("referral-state"));
      const lastContactSelected = localStorage.getItem("referral-contact") ? JSON.parse(localStorage.getItem("referral-contact")) : null;

      if (lastState.step === 30 || lastState.step === 32) {
        setNameFirst(lastState.nameFirst);
        setNameLast(lastState.nameLast);
        setUserId(lastState.userId);
        setEmail(lastState.email);
        setOrgName(lastState.orgName);
        setNameFirst2(lastState.nameFirst2);
        setNameLast2(lastState.nameLast2);
        setUserId2(lastState.userId2);
        setEmail2(lastState.email2);
        setOrgName2(lastState.orgName2);
        setReferredPartyType(lastState.referredPartyType);
        setReferralRecipientType(lastState.referralRecipientType);

        if (lastContactSelected) {
          if (lastState.step === 30) {
            setNameFirst(lastContactSelected.firstName);
            setNameLast(lastContactSelected.lastName);
            setEmail("");
            setUserId(lastContactSelected.userId);
            if (lastContactSelected.organisationName) {
              setOrgName(lastContactSelected.organisationName);
            } else {
              setOrgName("");
            }
          } else {
            setNameFirst2(lastContactSelected.firstName);
            setNameLast2(lastContactSelected.lastName);
            setEmail2("");
            setUserId2(lastContactSelected.userId);
            if (lastContactSelected.organisationName) {
              setOrgName2(lastContactSelected.organisationName);
            } else {
              setOrgName2("");
            }
          }
          localStorage.removeItem("referral-contact");
        }
        setStep(lastState.step);
      } else {
        resetState();
      }
    } else {
      resetState();
    } 
  }

  const resetInputs = (resetSecondContact: boolean = false) => {
    if (resetSecondContact) {
      setNameFirst2("");
      setNameLast2("");
      setEmail2("");
      setOrgName2("");
      setUserId2("");
    } else {
      setNameFirst("");
      setNameLast("");
      setEmail("");
      setOrgName("");
      setUserId("");
    }
  }

  const recommendDialogue = (toType: string) => {
    const ownTypeId = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).userType : 0;
    const fromType = ownTypeId === 1 || ownTypeId === 0 ? "worker" : ownTypeId === 2 ? "hirer" : "agency";

    const subject = 
      (fromType === "worker" && toType === "worker") ? "Use Updatedge to share your availability like me" :
      (fromType === "worker" && toType === "hirer") ? "Use Updatedge to view my availability, chat and send me offers" :
      (fromType === "worker" && toType === "agency") ? "Use Updatedge to view my availability, chat and send me offers" :
      (fromType === "hirer" && toType === "worker") ? "Use Updatedge to share your availability with us" :
      (fromType === "hirer" && toType === "hirer") ? "Use Updatedge to see your contacts’ availability, chat and send offers" :
      (fromType === "hirer" && toType === "agency") ? "Use Updatedge to see your contact’s availability, chat and send offers" :
      (fromType === "agency" && toType === "worker") ? "Use Updatedge to share your availability with us" :
      (fromType === "agency" && toType === "hirer") ? "Use Updatedge to see your contacts’ availability, chat and send offers" :
      (fromType === "agency" && toType === "agency") ? "Use Updatedge to see your contacts’ availability, chat and send offers" : "";

    let body =
      (fromType === "worker" && toType === "worker") ? "I'm using Updatedge to share my availability, receive offers and chat directly with my hirers and agencies. Add your hirers and agencies too by using the mobile app or sign up here: https://my.updatedge.com." :
      (fromType === "worker" && toType === "hirer") ? "You can see all your contacts’ availability 24/7 (including mine), offer work, book contacts in, and let any mutual agency know. Let me know what you think - You can sign in here to use a free account: https://portal.updatedge.com." :
      (fromType === "worker" && toType === "agency") ? "You can see your contacts’ availability 24/7 (including mine), offer work and book contacts on behalf of hirers. Let me know what you think - You can sign in here to use a free account: https://portal.updatedge.com." :
      (fromType === "hirer" && toType === "worker") ? "We’re using it to see our workers’ availability 24/7, offer work and book them. Add us, your other hirers and agencies too by using the mobile app or sign up here: https://my.updatedge.com." :
      (fromType === "hirer" && toType === "hirer") ? "We’re using it to see our contacts’ availability 24/7, offer work, book contacts in, and let any mutual agency know. You can too - let me know what you think - You can sign in here to use a free account: https://portal.updatedge.com." :
      (fromType === "hirer" && toType === "agency") ? "We want to see the availability of all the agency staff you provide to us too, so we have greater choice. We are recommending the Updatedge mobile app to them, to share their availability with us and would appreciate your support. To find out more please go to https://portal.updatedge.com." :
      (fromType === "agency" && toType === "worker") ? "We’re using it to see our contacts’ availability 24/7, offer work and book them. Add us, your other agencies and hirers too by using the mobile app or sign up here: https://my.updatedge.com." :
      (fromType === "agency" && toType === "hirer") ? "We’re using it see our contacts’ availability 24/7, offer work, book contacts in, and let any mutual agency know. You can too - let me know what you think - You can sign in here to use a free account: https://portal.updatedge.com." :
      (fromType === "agency" && toType === "agency") ? "You can too – see your contacts’ availability 24/7, offer work, book contacts in, and let any mutual agency know. You can too - let me know what you think - You can sign in here to use a free account: https://portal.updatedge.com." : "";

    body += " Contact the helpful Updatedge team if there are questions: support@updatedge.com."

    if ((window as any).os === "ios" || (window as any).os === "android") {
      Share.share({
        title: "Updatedge",
        text: body,
        url: body.indexOf("https://my.updatedge.com") !== -1 ? "https://my.updatedge.com" : "https://portal.updatedge.com",
      }).catch(e => {});
    } else {
      window.open(`mailto:?subject=${subject}&body=${body}`)
    }
  }

  useEffect( () => {
    if (step === 30 || step === 32) {
      setTimeout(() => {
        const firstInputElement = document.querySelector('ion-input') as HTMLIonInputElement
        if (firstInputElement) {
          firstInputElement.setFocus();
        }
      }, 100)
      setHirerToolTipShowing(false)
      setAgencyToolTipShowing(false)
      setWorkerTooltipShowing(false)
    }
  }, [step])

  const userIds = localStorage.getItem("userIds") ? JSON.parse(localStorage.getItem("userIds")) : null;

  const EMAIL_INPUT_DOM = <IonItem>
    <IonInput
      placeholder="Email" 
      id="email"
      value={email} 
      onIonInput={(e) => { 
        setEmail((e.target as HTMLIonInputElement).value.toString());
      }}
    />
  </IonItem>

  const NAME_INPUT_DOM = 
    <div className='half-inputs'>
      <IonItem>
        <IonInput
          placeholder="First Name" 
          id="name"
          value={nameFirst} 
          onIonInput={(e) => { 
            setNameFirst(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
          }}
        />
      </IonItem>
      <IonItem>
        <IonInput
          placeholder="Last Name" 
          id="name"
          value={nameLast} 
          onIonInput={(e) => { 
            setNameLast(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
          }}
        />
      </IonItem>
    </div>

  const SKILLS_INPUT_DOM = <IonItem>
    <IonInput
      placeholder="Skills / Specialism" 
      id="skills"
      value={skills} 
      onIonInput={(e) => { 
        setSkills(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
      }}
    />
  </IonItem>

  const LOCATION_INPUT_DOM = <IonItem>
    <IonInput
      placeholder="Town / City" 
      id="location"
      value={location} 
      onIonInput={(e) => { 
        setLocation(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
      }}
    />
  </IonItem>

  const ORG_NAME_DOM = <IonItem>
    <IonInput
      placeholder="Organisation Name" 
      id="name"
      value={orgName} 
      onIonInput={(e) => { 
        setOrgName(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
      }}
    />
  </IonItem>

  const EMAIL_INPUT_DOM2 = <IonItem>
    <IonInput
      placeholder="Email" 
      id="email"
      value={email2} 
      onIonInput={(e) => { 
        setEmail2(((e.target as HTMLIonInputElement).value.toString().trim()));
      }}
    />
  </IonItem>

  const NAME_INPUT_DOM2 = 
  <div className='half-inputs'>
    <IonItem>
      <IonInput
        placeholder="First Name" 
        id="name"
        value={nameFirst2} 
        onIonInput={(e) => { 
          setNameFirst2(Utilities.capitalizeFirstLetter(((e.target as HTMLIonInputElement).value.toString())));
        }}
      />
    </IonItem>
    <IonItem>
      <IonInput
        placeholder="Last Name" 
        id="name"
        value={nameLast2} 
        onIonInput={(e) => { 
          setNameLast2(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
        }}
      />
    </IonItem>
  </div>

  const ORG_NAME_DOM2 = <IonItem>
    {/* <IonLabel position="stacked">Organisation Name</IonLabel> */}
    <IonInput
      placeholder="Organisation Name" 
      id="name"
      value={orgName2} 
      onIonInput={(e) => { 
        setOrgName2(Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString()));
      }}
    />
  </IonItem>

  const SUBMIT_BTN = <button
      className="main"
      onClick={() => {
        submit(step, nameFirst, nameLast, nameFirst2, nameLast2, email, email2, userId, userId2, skills, orgName, orgName2, location, referredPartyType, referralRecipientType);
      }}
      style={{
        marginTop: 15
      }}
  >
    Next Step
  </button>

  const CONTACT_LIST_BTN = <button
    className="main contact-list-btn"
    onClick={() => {
      localStorage.setItem("referral-state", JSON.stringify({
        step: step,
        referredPartyType: referredPartyType,
        referralRecipientType: referralRecipientType,
        email: email,
        nameFirst: nameFirst,
        nameLast: nameLast,
        orgName: orgName,
        userId: userId,
        email2: email2,
        nameFirst2: nameFirst2,
        nameLast2: nameLast2,
        orgName2: orgName2,
        userId2: userId2,
        selectingUserType: step === 30 ? referredPartyType : referralRecipientType
      }));
      InternalTracker.trackEvent("Selecting Referrals from List Inited");
      history.push("/selectreferral?scrollTo=" + (step === 30 ? (referredPartyType === 'hirer' ? 'hirers' : 'agencies') : referralRecipientType === 'hirer' ? 'hirers' : 'agencies'));
    }}
    style={{
      margin: '10px -20px 0 -20px',
      width: 'calc(100% + 40px)',
      backgroundColor: "#ededed",
      color: "black",
      borderRadius: 0,
      fontWeight: 500,
      marginTop: keyboardOpen ? 4 : undefined
    }}
  >
   <span>Select from contact list</span>
   { (userIds && userIds.length) ?
    <div className='logos'>
      { userIds.slice(0, 5).map((userId: string) => {
        return (
          <img src={UserAPI.getProfilePicture(userId)} />
        )
      }) }
    </div>
    : null }
  </button>

  const REMOVE_CONTACT_BTN = <button
    className="main delete"
    onClick={() => {
      InternalTracker.trackEvent("Reset Referral Contact");
      setNameFirst("");
      setNameLast("");
      setEmail("");
      setOrgName("");
      setUserId("");
    }}
  >
    <span>Change Contact / Enter Manually</span>
  </button>

  const REMOVE_CONTACT_2_BTN = <button
    className="main delete"
    onClick={() => {
      InternalTracker.trackEvent("Reset Referral Contact");
      setNameFirst2("");
      setNameLast2("");
      setEmail2("");
      setOrgName2("");
      setUserId2("");
    }}
  >
    <span>Change Contact / Enter Manually</span>
  </button>

  const WORKER_TOOLTIP =
    <Tooltip
      title="A worker contact you recommend to share their availability to receive work offers from business hirers, or agencies"
      open={workerTooltipShowing}
      placement='left'
    >
      <IonIcon className='tooltip' onClick={(e) => {
        e.stopPropagation()
        setHirerToolTipShowing(false)
        setAgencyToolTipShowing(false)
        setWorkerTooltipShowing(!workerTooltipShowing)
      }} icon={informationCircle} />
    </Tooltip>

  const HIRER_TOOLTIP =
    <Tooltip
      title="An organisation that hires temporary or part-time workers"
      open={hirerToolTipShowing}
      placement='left'
    >
      <IonIcon className='tooltip' onClick={(e) => {
        e.stopPropagation()
        setHirerToolTipShowing(!hirerToolTipShowing)
        setAgencyToolTipShowing(false)
        setWorkerTooltipShowing(false)
      }} icon={informationCircle} />
    </Tooltip>

    const AGENCY_TOOLTIP =
    <Tooltip
      title="An organisation that represents workers placing them with hirers"
      open={agencyToolTipShowing}
      placement='left'
    >
      <IonIcon className='tooltip' onClick={(e) => {
        e.stopPropagation()
        setHirerToolTipShowing(false)
        setAgencyToolTipShowing(!agencyToolTipShowing)
        setWorkerTooltipShowing(false)
      }} icon={informationCircle} />
    </Tooltip>


  const runningFromIframe = window.self !== window.top;

  return (
    <IonPage data-page="referrals-add" ref={contentRef} id="referral-add-ion-page">
      <IonHeader mode='md'>
        <IonToolbar style={{
          '--border-width': '0px',
          height: keyboardOpen ? 0 : undefined,
          overflow: keyboardOpen ? 'hidden' : undefined
        }}>
          <IonButtons slot="start">
            <IonButton onClick={() => {
                resetState();
                setStep(1)
                history.push("/referrals/list");
              }}>
              <IonIcon icon={listOutline} />
              <IonLabel>View Past Referrals</IonLabel>
            </IonButton>
          </IonButtons>
            <IonButtons slot="end">
            { (step !== 100 && !(runningFromIframe && step === 1)) &&
              <IonButton onClick={() => {
                  resetState();
                  setStep(1);
                  localStorage.removeItem("referral-state")
                  setPreviousStep(0);
                  InternalTracker.trackEvent("Discarded Referral Creation");
                  if (!runningFromIframe) {
                    history.push("/contacts");
                  }
                }}>
                <IonIcon icon={close} />
                <IonLabel>Discard</IonLabel>
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="referrals-add-wrapper">
        <div 
          className='content'
          style={ runningFromIframe ? { alignItems: 'center' } : { } }
        >
          { keyboardOpen &&
            <button id='hide-keyboard'>
              <IonIcon icon={close} />
              <span>Hide Keyboard</span>
            </button>
          }
          { (step === 1) &&
            <section data-section="1">
              <h2>Who are you referring?</h2>
              <div className='selector'>
                <div onClick={() => {
                  if (referredPartyType !== "worker") {
                    resetInputs();
                  }
                  setReferredPartyType("worker");
                  setStep(30);
                  InternalTracker.trackEvent("Referral Type Selected", {
                    userType: "worker",
                    type: "referred"
                  });
                }}>
                  <IonIcon className='icon' icon={briefcase} />
                  A Worker
                  {WORKER_TOOLTIP}
                </div>
                <div onClick={() => {
                  if (referredPartyType !== "hirer") {
                    resetInputs();
                    InternalTracker.trackEvent("Referral Type Selected", {
                      userType: "hirer",
                      type: "referred"
                    });
                  }
                  setReferredPartyType("hirer");
                  setStep(30);
                }}>
                  <IonIcon className='icon' icon={business} />
                  A Hirer
                  {HIRER_TOOLTIP}
                </div>
                <div onClick={() => {
                  if (referredPartyType !== "agency") {
                    resetInputs();
                  }
                  InternalTracker.trackEvent("Referral Type Selected", {
                    userType: "agency",
                    type: "referred"
                  });
                  setReferredPartyType("agency");
                  setStep(30);
                }}>
                  <IonIcon className='icon' icon={people} />
                  An Agency
                  {AGENCY_TOOLTIP}
                </div>
              </div>
              <div className='or'>or</div>
              <div className='selector'>
                <div onClick={() => {
                  setStep(20)
                  InternalTracker.trackEvent("Recommend Updatedge Pressed");
                }}>Recommend Updatedge</div>
              </div>
              <div className='selector'>
                <div onClick={() => {
                  history.push("/referrals/list");
                }}>View Past Referrals</div>
              </div>
            </section>
          }
          { (step === 20) &&
            <section data-section="20">
              <h3>
                <IonIcon icon={arrowBackOutline} onClick={() => {
                  setStep(1)
                }} />
                Who are you recommending Updatedge to?
              </h3>
              <div className='form'>
                <div className='selector'>
                  <div onClick={() => {
                    recommendDialogue("worker");
                    InternalTracker.trackEvent("Recommend Updatedge Pressed", {
                      type: "worker"
                    });
                  }}>
                    <IonIcon className='icon' icon={briefcase} />
                    A Worker
                  </div>
                  <div onClick={() => {
                    recommendDialogue("hirer");
                    InternalTracker.trackEvent("Recommend Updatedge Pressed", {
                      type: "hirer"
                    });
                  }}>
                    <IonIcon className='icon' icon={business} />
                    A Hirer
                  </div>
                  <div onClick={() => {
                    recommendDialogue("agency");
                    InternalTracker.trackEvent("Recommend Updatedge Pressed", {
                      type: "agency"
                    });
                  }}>
                    <IonIcon className='icon' icon={people} />
                    An Agency
                  </div>
                </div>
              </div>
            </section>
          }
          { (step === 30) &&
            <section data-section="30">
              <h3>
                <IonIcon icon={arrowBackOutline} onClick={() => {
                  setStep(1);
                  resetState();
                  localStorage.removeItem("referral-state");
                  localStorage.removeItem("referral-contact");
                }} />
                Enter the details of the <mark className='highlight-animation'>{referredPartyType}</mark> you want to refer.
              </h3>
              <div className='form'>
                { (userId) ?
                  <React.Fragment>
                    <div className='contact-selected-wrapper'>
                      <ContactListItem 
                        everFirst={false} 
                        list="OTHER"
                        hideOrgImg={true}
                        onEdit={(name) => { }}
                        hideOptions={true}
                        hideRatings={true}
                        contact={{
                          id: userId,
                          userId: userId,
                          name: nameFirst + " " + nameLast
                        } as Contact} 
                      />
                      {REMOVE_CONTACT_BTN}
                    </div>
                    {referredPartyType === "worker" && SKILLS_INPUT_DOM}
                    {referredPartyType === "worker" && LOCATION_INPUT_DOM}
                  </React.Fragment>
                  :
                  <React.Fragment>
                    { (referredPartyType !== "worker") &&
                      <React.Fragment>
                        {CONTACT_LIST_BTN}
                        <div style={{ marginBottom: keyboardOpen ? 2 : 8, marginTop: keyboardOpen ? 2 : 8 }} className='or'>or</div>
                      </React.Fragment>
                    }
                    {EMAIL_INPUT_DOM}
                    {NAME_INPUT_DOM}
                    {referredPartyType !== "worker" && ORG_NAME_DOM}
                    {referredPartyType === "worker" && SKILLS_INPUT_DOM}
                    {referredPartyType === "worker" && LOCATION_INPUT_DOM}
                    { (referredPartyType === "worker") &&
                      <React.Fragment>
                        <div style={{ marginTop: keyboardOpen ? 2 : 8 }} className='or'>or</div>
                        {CONTACT_LIST_BTN}
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
                {SUBMIT_BTN}
              </div>
            </section>
          }
          { (step === 31) &&
            <section data-section="31">
              <h3>
                <IonIcon icon={arrowBackOutline} onClick={() => {
                  setStep(30)
                }} />
                Who would you like to refer to?
              </h3>
              <div className='form'>
                <div className='selector'>
                  { referredPartyType !== "worker" &&
                    <div onClick={() => {
                      if (referralRecipientType !== "worker") {
                        resetInputs(true);
                      }
                      setReferralRecipientType("worker")
                      setStep(32)
                      InternalTracker.trackEvent("Referral Type Selected", {
                        userType: "worker",
                        type: "referredTo"
                      });
                    }}>
                      <IonIcon icon={briefcase} />
                      A Worker
                      {WORKER_TOOLTIP}
                    </div>
                  }
                  { referredPartyType !== "hirer" &&
                    <div onClick={() => {
                      if (referralRecipientType !== "hirer") {
                        resetInputs(true);
                      }
                      InternalTracker.trackEvent("Referral Type Selected", {
                        userType: "hirer",
                        type: "referredTo"
                      });
                      setReferralRecipientType("hirer")
                      setStep(32)
                    }}>
                      <IonIcon icon={business} />
                      A Hirer
                      {HIRER_TOOLTIP}
                    </div>
                  }
                  { referredPartyType !== "agency" &&
                    <div onClick={() => {
                      if (referralRecipientType !== "agency") {
                        resetInputs(true);
                      }
                      InternalTracker.trackEvent("Referral Type Selected", {
                        userType: "agency",
                        type: "referredTo"
                      });
                      setReferralRecipientType("agency")
                      setStep(32)
                    }}>
                      <IonIcon icon={people} />
                      An Agency
                      {AGENCY_TOOLTIP}
                    </div>
                  }
                </div>
              </div>
            </section>
          }
          { (step === 32) &&
            <section data-section="32">
              <h3>
                <IonIcon icon={arrowBackOutline} onClick={() => {
                  setStep(31);
                }} />
                Enter the details of the <mark className='highlight-animation'>{referralRecipientType}</mark> to whom you are referring {nameFirst}
              </h3>
              <div className='form'>
                { (userId2) ?
                  <React.Fragment>
                    <div className='contact-selected-wrapper'>
                      <ContactListItem 
                        everFirst={false} 
                        list="OTHER"
                        hideOrgImg={true}
                        onEdit={(name) => { }}
                        hideOptions={true}
                        hideRatings={true}
                        contact={{
                          id: userId2,
                          userId: userId2,
                          name: nameFirst2 + " " + nameLast2
                        } as Contact} 
                      />
                      {REMOVE_CONTACT_2_BTN}
                    </div>
                    {referralRecipientType === "worker" && SKILLS_INPUT_DOM}
                    {referralRecipientType === "worker" && LOCATION_INPUT_DOM}
                  </React.Fragment>
                  :
                  <React.Fragment>
                    { (referralRecipientType !== "worker") &&
                      <React.Fragment>
                        {CONTACT_LIST_BTN}
                        <div style={{ marginBottom: keyboardOpen ? 2 : 8, marginTop: keyboardOpen ? 2 : 8 }} className='or'>or</div>
                      </React.Fragment>
                    }
                    {EMAIL_INPUT_DOM2}
                    {NAME_INPUT_DOM2}
                    {referralRecipientType !== "worker" && ORG_NAME_DOM2}
                    {referralRecipientType === "worker" && SKILLS_INPUT_DOM}
                    {referralRecipientType === "worker" && LOCATION_INPUT_DOM}
                    { (referralRecipientType === "worker") &&
                      <React.Fragment>
                        <div style={{ marginTop: keyboardOpen ? 2 : 8 }} className='or'>or</div>
                        {CONTACT_LIST_BTN}
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
                {SUBMIT_BTN}
              </div>
            </section>
          }
          { (step === 33) &&
            <section 
              data-section="33"
              style={{
                alignSelf: "center",
              }}
            >
              <h3 style={{
                display: "block",
                textAlign: "center",
              }}>
                Confirm Details
              </h3>
              { referralRecipientType === "worker" ?
                <p>We will let <mark className='highlight-animation'>{nameFirst}</mark> at <mark className='highlight-animation'>{orgName}</mark> know that you referred <mark className='highlight-animation'>{nameFirst2}</mark>, who will also receive a notification</p> :
                <p>We will let <mark className='highlight-animation'>{nameFirst2}</mark> at <mark className='highlight-animation'>{orgName2}</mark> know that you referred <mark className='highlight-animation'>{nameFirst}</mark>, who will also receive a notification</p>
              }
              { (
                  (referredPartyType === "worker" && referralRecipientType === "agency") || 
                  (referredPartyType === "agency" && referralRecipientType === "worker")
                ) &&
                  <p>Updatedge doesn't handle payments for referrals, please contact <mark className='highlight-animation'>{nameFirst2}</mark> directly for details.</p>
              }
              <div className='options'>
                <button className='main' onClick={() => {
                  setStep(1)
                }}>
                  Change Details
                </button>
                <button className='main' onClick={() => {
                  submit(step, nameFirst, nameLast, nameFirst2, nameLast2, email, email2, userId, userId2, skills, orgName, orgName2, location, referredPartyType, referralRecipientType);
                }}>
                  Send Invite
                </button>
              </div>
            </section>
          }
          { (step === 100) &&
            <section 
              data-section="100" 
              data-outcome={outcome}
              style={{
                alignSelf: "center",
              }}
            >
              <h2 style={{
                display: "block",
                textAlign: "center",
              }}>
                {outcome ? "All Done" : "Something went wrong" }
              </h2>
              <div className='options'>
                { outcome ?
                  <button className='main' onClick={() => {
                    resetState();
                    setStep(1)
                  }}>
                    Refer Another
                  </button>
                  :
                  <button className='main' onClick={() => {
                    setStep(previousStep)
                  }}>
                    Try Again
                  </button>
                }
                <button className='main' onClick={() => {
                  resetState();
                  setStep(1);
                  history.push("/referrals/list")
                }}>
                  View Referrals
                </button>
              </div>
            </section>
          }
        </div>
        <IonLoading
          isOpen={blockingLoading != null}
          onDidDismiss={() => setBlockingLoading(null)}
          message={blockingLoading}
          duration={12000}
        />
        <IonAlert
          isOpen={confirmDifferentUserType !== null}
          onDidDismiss={() => setConfirmDifferentUserType(null)}
          header={confirmDifferentUserType ? ("The selected user has already identified themselves as " + confirmDifferentUserType.actual + ". Are you sure you want to refer them as a " + confirmDifferentUserType.selected + "?") : ""}
          buttons={[
            {
              text: ('Yes, refer them as ' + (confirmDifferentUserType ? confirmDifferentUserType.selected : "")),
              handler: () => {
                submit(step, nameFirst, nameLast, nameFirst2, nameLast2, email, email2, userId, userId2, skills, orgName, orgName2, location, referredPartyType, referralRecipientType, true);
              },
            },
            {
              text: 'Select Different / Change Type',
              handler: () => {
                setConfirmDifferentUserType(null);
              },
            }
          ]}
        />
      </IonContent>
      { confetti &&
          <Confetti
              width={document.documentElement.clientWidth}
              recycle={false}
              numberOfPieces={350}
              onConfettiComplete={() => {
                  setConfetti(false)
              }}
              height={document.documentElement.clientHeight}
          />
      }
    </IonPage>
  );
};

export default ReferralsAdd;
