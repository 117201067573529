import { IonTextarea, IonAlert, IonBackButton, IonButtons, IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar, IonDatetime } from '@ionic/react';
import { caretDownCircle, checkmarkCircleOutline, warningOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import SectorsAPI from '../apis/Sectors';
import UserAPI from '../apis/User';
import Configuration from '../Configuration';
import '../styles/Components/Settings/ProfileSettings.scss';
import '../styles/TabHeader.scss';
import { User, UserDTO } from '../types/User';
import { UserEmail } from '../types/UserEmail';
import Utilities from '../Utilities';
// TODONOW TRACKING
import '../styles/Components/TagManager.scss';
import '../styles/TimelineClearup.scss';
import { Plugins } from '@capacitor/core';
import { getAllEvents, saveEvent } from '../stores/Event';
import { Keyboard } from '@capacitor/keyboard';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  
}

interface Tag {
  id: string,
  text: string,
  value: string
}

const ProfileSettings: React.FC<Props> = ({ }) => {

  const [clearAfterDate, setClearAfterDate] = useState <string> ("") // Start Date
  const [loadingMessage, setLoadingMessage] = useState<string> (null) // Blocking loading message

  const contentRef = useRef(null);

  useEffect(() => {
    
  }, [])

  const clearEvents = async (clearAfterDate) => {
    let value = window.prompt("Are you sure you want to clear " + (clearAfterDate ? "events after " + clearAfterDate : "all events") + " (other than bookings)? Type 'YES DELETE' to confirm");
    if (value && value.toLowerCase().trim() === "yes delete") {
      const allEvents = await getAllEvents();
      let firstEventDate = new Date();

      for (let i = 0; i < allEvents.length; i++) {
        const event = allEvents[i];
        if (event.startAsDate < firstEventDate) {
          firstEventDate = event.startAsDate;
        }
      }
      
      let clearFrom = new Date(clearAfterDate ? new Date(clearAfterDate) : new Date(2000, 1, 1));
      clearFrom.setHours(0);
      clearFrom.setMinutes(0);
      clearFrom.setSeconds(0);

      let clearUntil = Utilities.dateAdd(new Date(), "year", 10);
      clearUntil.setHours(23);
      clearUntil.setMinutes(59);
      clearUntil.setSeconds(59);

      console.log(firstEventDate + " -> " + clearUntil)
      const allEventsTimeline = await Utilities.generateEvents(firstEventDate, clearUntil, null, null, false);

      let eventIdsInRange = [];

      // @ts-ignore
      for (let key in allEventsTimeline.timeline) {
        const dateKey = new Date(key);
        // @ts-ignore
        const events = allEventsTimeline.timeline[key];
        for (let i = 0; i < events.length; i++) {
          if (!events[i].organisationUrl && eventIdsInRange.indexOf(events[i].id) === -1 && dateKey >= clearFrom) {
            eventIdsInRange.push(events[i].id);
          }
        }
      }

      let eventsInRange = allEvents.filter(event => eventIdsInRange.indexOf(event.id) !== -1);

      setLoadingMessage("Clearing Timeline...")

      for (let i = 0; i < eventsInRange.length; i++) {
        const event = JSON.parse(JSON.stringify(eventsInRange[i]));
        console.log(event.start + " - " + event.end + " " + event.repeatType + " " + event.repeatForever + " " + event.repeatUntil + " " + event.deleted)
        // Clearing between selected date and today
        if (clearAfterDate) {
          // Not repeating date, that was in the timeline, so can delete
          if (event.repeatType === 0) {
            event.deleted = true
            console.log("++1++")
          } else if (event.repeatForever) {
            // Repeating forever, so update end date if still has repeats before
            event.repeatForever = false;
            event.repeatUntil = new Date(clearAfterDate).toISOString();
            if (new Date(event.repeatUntil) < new Date(event.start)) {
              event.deleted = true;
              console.log("++2++")
            } else {
              console.log("++3++")
            }
          } else if (new Date(event.repeatUntil) > new Date(clearAfterDate)) {
            event.repeatUntil = new Date(clearAfterDate).toISOString();
            if (new Date(event.repeatUntil) < new Date(event.start)) {
              event.deleted = true;
              console.log("++4++")
            } else { 
              console.log("++5++")
            }
          } else {
            event.deleted = true;
            console.log("++6++")
          }
        } else {
          // Clearing all events if no range
          event.deleted = true;
        }
        event.updatedAt = (new Date()).toISOString();
        let saveEventRes = await saveEvent(event);
        console.log(event.start + " - " + event.end + " " + event.repeatType + " " + event.repeatForever + " " + event.repeatUntil + " " + event.deleted, "=============================================")
      }

      window.location.href = "/calendar"
    } else {
      (window as any).toast("Operation cancelled", "info");
    }
  }

  return (
    <IonPage data-page="timeline-clearup" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Timeline Clearup</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="timeline-clearup-wrapper" fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Clear Events (except bookings) after</IonLabel>
            <IonInput 
                value={clearAfterDate}
                placeholder="Tap to select Date"
                onIonChange={(e) => { 
                  setClearAfterDate(Utilities.formatDate(new Date((e.target as HTMLIonInputElement).value), "YYYY-MM-DD"))
                }}
              >
              </IonInput>
              <button onClick={() => {
                clearEvents(clearAfterDate);
              }}>
                { clearAfterDate ? "Clear Events " + clearAfterDate + " onward" : "Clear All Events" }
              </button>
          </IonItem>
        </IonList>
        <IonLoading
            isOpen={loadingMessage !== null}
            onDidDismiss={() => setLoadingMessage(null)}
            message={loadingMessage}
            duration={12000}
          />
      </IonContent>
    </IonPage>
  );
};

export default ProfileSettings;
