import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ConsoleLogObserver, AuthService } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';
import Configuration from '../Configuration';
import { appInsights } from '../AppInsights';

import { AxiosRequestor } from './AxiosService';
import { App } from '@capacitor/app';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

export class Auth  {

  private static authService : AuthService | undefined;

  private static buildAuthInstance() {

    const authService = new AuthService(new CapacitorBrowser(), new CapacitorSecureStorage(), new AxiosRequestor());
    authService.authConfig = {
      client_id: CONFIG.AUTH_APP_ID,
      server_host: CONFIG.AUTH_LINK,
      redirect_url: isPlatform('capacitor') ? CONFIG.AUTH_REDIRECT_MOBILE : CONFIG.AUTH_REDIRECT_WEB,
      end_session_redirect_url: isPlatform('capacitor') ? CONFIG.AUTH_END_REDIRECT_MOBILE : CONFIG.AUTH_END_REDIRECT_WEB,
      scopes: CONFIG.AUTH_MOBILE_SCOPE,
      pkce: false
    }

    console.log(authService.authConfig)

    if (isPlatform('capacitor')) {
      console.log("applistenercreated");
      App.addListener('appUrlOpen', (data: any) => {
        const urlParams = new URLSearchParams(data.url);
        const token: string = urlParams.get('access_token');
        appInsights.trackEvent({ name: "active_directory_callback_type" }, { data: JSON.stringify(data) })
        if (data.url !== undefined) {
          authService.handleCallback(data.url);
        }
        if (token) {
          localStorage.setItem("access_token", token)
          window.location.href = "/"
        }
      });
    }

    authService.addActionObserver(new ConsoleLogObserver());
    return authService;
  }

  public static get Instance() : AuthService {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
    }

    return this.authService;
  }
}
