import { IonBackButton, IonButtons, IonContent, IonHeader, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import Configuration from '../Configuration';
// import '../styles/Settings.scss';
// import '../styles/TabHeader.scss';
import { Event } from '../types/Event';
import { User } from '../types/User';
import RatingCollector from './Components/Rating/RatingCollector';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const DEFAULT_EVENT: Event = {
  "deleted": false,
  "updatedAt": "",
  "createdAt": "",
  "version": "",
  "id": "123",
  "orderingUpdatedDate": "",
  "groupId": "",
  "pinned": false,
  "notes": "Lorem Ipsum Dolor Sit Amet Conse Lorem Ipsum Dolor Sit Amet Conse",
  "googlePlacesId": "",
  "repeatType": 0,
  "isSynced": false,
  "repeatForever": false,
  "repeatUntil": "",
  "end": "2020-12-10T20:00:00+00:00",
  "start": "2020-12-10T08:00:00+00:00",
  "eventType": 1,
  "title": "Working at School",
  "userId": ""
}

interface Props { }

const Rating: React.FC<Props> = ({ }) => {
  
  const [user, setUser] = useState <User> (null) // User
  const [visibleRatingCollector, setVisibleRatingCollector] = useState <string> (null)

  const contentRef = useRef(null);

  useEffect(() => {
    (async function() {

      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        // @ts-ignore
        user.rating = 4.4;
        setUser(user as User);
      }

    })();
  }, [])

  return (
    <IonPage data-page="ratings" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Ratings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="ratings-wrapper">
        <button onClick={() => {
          setVisibleRatingCollector("worker")
        }}>Rate worker</button>
        <button onClick={() => {
          setVisibleRatingCollector("agency")
        }}>Rate agency</button>
        <button onClick={() => {
          setVisibleRatingCollector("hirer")
        }}>Rate hirer</button>
        <button onClick={() => {
          setVisibleRatingCollector("hirer-updatedge")
        }}>Rate hirer and updatedge</button>
        <button onClick={() => {
          setVisibleRatingCollector("hirer-agency")
        }}>Rate hirer and agency</button>
        <IonModal
            isOpen={visibleRatingCollector !== null}
            canDismiss={visibleRatingCollector === null}
            presentingElement={(contentRef && contentRef.current) ? contentRef.current : null}
            onDidDismiss={() => { setVisibleRatingCollector(null) }}
            data-modal="rating-collector"
            mode="ios"
        >
          <div id="rating-collector-wrapper" style={{ overflow: 'auto' }}>
            { (visibleRatingCollector === "worker") &&
              <RatingCollector 
                event={DEFAULT_EVENT}
                worker={{ id: "1e76410c-48b7-4e89-8bb9-79602cfff64f", name: "Dominik", questions: [1, 2] }}
                onSubmit={(ratings) => {
                  
                }}
                onClose={() => {
                 setVisibleRatingCollector(null)
                }}
              />
            }
            { (visibleRatingCollector === "agency") &&
              <RatingCollector 
                event={DEFAULT_EVENT}
                agency={{ id: "ba2c7d0d-4bec-4864-8edf-dd019a35754a", name: "Step Teacher", questions: [10, 11, 12] }}
                onSubmit={(ratings) => {
               
                  setVisibleRatingCollector(null)
                }}
                onClose={() => {
                  setVisibleRatingCollector(null)
                }}
              />
            }
            { (visibleRatingCollector === "hirer") &&
              <RatingCollector 
                event={DEFAULT_EVENT}
                hirer={{ id: "1c3eee78-6fe7-459a-8507-72885945ab7d", name: "Edinburgh University", questions: [20, 21, 22] }}
                onSubmit={(ratings) => {
                  setVisibleRatingCollector(null)
                }}
                onClose={() => {
                  setVisibleRatingCollector(null)
                }}
              />
            }
            { (visibleRatingCollector === "hirer-updatedge") &&
              <RatingCollector 
                event={DEFAULT_EVENT}
                updatedge={{ questions: [51], name: "Updatedge", id: "updatedge" }}
                hirer={{ id: "1c3eee78-6fe7-459a-8507-72885945ab7d", name: "Edinburgh University", questions: [20, 21, 22] }}
                onSubmit={(ratings) => {
                  setVisibleRatingCollector(null)
                }}
                onClose={() => {
                  setVisibleRatingCollector(null)
                }}
              />
            }
            { (visibleRatingCollector === "hirer-agency") &&
              <RatingCollector 
                event={DEFAULT_EVENT}
                hirer={{ id: "1c3eee78-6fe7-459a-8507-72885945ab7d", name: "Edinburgh University", questions: [20, 21, 22] }}
                agency={{ id: "ba2c7d0d-4bec-4864-8edf-dd019a35754a", name: "Step Teacher", questions: [10, 11, 12] }}
                onSubmit={(ratings) => {
                  setVisibleRatingCollector(null)
                }}
                onClose={() => {
                  setVisibleRatingCollector(null)
                }}
              />
            }
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Rating;
