import InternalAPI from './InternalAPI';
import Configuration from '../Configuration';
import { AllFilesResponse, DeviceDetails, User, UserDTO, UserFile } from '../types/User'
import { UserEmail } from '../types/UserEmail'
import versionName from '../versionName';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

class UserAPI {

    /**
     * Returns deatails about the logged in user
     * @returns user details
     */
    static async get(): Promise<User> { return InternalAPI.request("GET", "/api/user?"); }

    /**
     * Marks that the user has used chat before
     * @returns user details
     */
    static async markChatUsed(): Promise<User> { return InternalAPI.request("POST", "/api/user/chat?", {}, null, null, false, false); }

    /**
     * Returns deatails about the logged in user
     * @returns user details
     */
    static async getNew(): Promise<User> { return InternalAPI.request("GET", "/userprofile/?", null, false, false, true, true); }

    /**
     * Updates user details
     * @param user user details
     * @returns confirmation
     */
    static async update(user: UserDTO, returnUpdatedUser?: boolean): Promise<User> { return InternalAPI.request("PUT", "/userprofile?returnUpdatedUser=" + (returnUpdatedUser ? "true" : "false"), user, false, false, true); }

    /**
     * Accepts an invite
     * @param id invitation id
     * @returns confirmation
     */
    static async acceptInvite(token: string): Promise<User> { return InternalAPI.request("GET", "/publicapi/invitations/accept/" + token + "?", null, false, false); }

    /**
     * Returns the alternative emails of users
     * @param userId id of the user
     * @returns alternative emails
     */
    static async getAltEmails(userId: string): Promise<UserEmail[]> { 
        const data = await InternalAPI.request("GET", "/publicapi/userprofile/" + userId + "/emails?"); 
        // @ts-ignore
        if (data && data.results) { return data.results.map(item => { return item as UserEmail }) }
        return null;
    }

    /**
     * Adds an alternative email to the user's account
     * @param email new alternative email
     * @returns state of the request
     */
    static async addAltEmail(email: string): Promise<Object> { return InternalAPI.request("POST", "/publicapi/userprofile/emails/add?", { email: email }) }

    /**
     * Deletes an email from the user's account
     * @param emailId id of email to delete
     * @returns state of the request
     */
    static async removeAltEmail(id: number): Promise<Object> { return InternalAPI.request("DELETE", "/publicapi/userprofile/emails/" + id + "?") }

    static async setPrimaryEmail(id: number): Promise<Object> { return InternalAPI.request("PUT", "/publicapi/userprofile/emails/" + id + "/setprimary?") }

    /**
     * Closes the user's account
     * @param feedback feedback on why the account is closed
     * @returns state of the request
     */
    static async closeAccount(feedback: string): Promise<Object> { return InternalAPI.request("GET", "/api/user/closeAccount?feedback=" + feedback); }

    static async pushNotificationRegsiter(uuid: string, platform: string): Promise<Object> { return InternalAPI.request("POST", "/notifications/pushregistration?api-version=1.0", { "platform": platform,  "handle": uuid, "appVersion": versionName }, null, false, true); } // apns - fcm

    // static async pushNotificationRegsiter(uuid: string, userId: string): Promise<Object> { return InternalAPI.request("PUT", "/api/pushnotificationregistration/123?", { "platform": "apns",  "handle": uuid, "tags": ["userId:" + userId]}	); }

    static getProfilePicture(id: string, pictureVersion?: number): string { 
        return CONFIG.CDN_IMAGE_URI + "/images/profile/" + id + ".png" + 
        ( (pictureVersion || (window as any).globalUserId === id && localStorage.getItem("ownVersionOfProfileImage")) ? 
            "?v=" + (pictureVersion !== undefined ? pictureVersion : localStorage.getItem("ownVersionOfProfileImage")) : ""
        )
    }

    static getOrgPicture(id: string): string { 
        return CONFIG.CDN_IMAGE_URI + "/images/organisation/" + id.toLowerCase() + ".png" 
    }

    static getExtOrgPicture(id: string): string { return CONFIG.API_EXTERNAL_URI + "/organisations/external/logo/" + id + "?api-version=1.0" }

    // static uploadProfilePicture(id: string, file: File): Promise<Object> { return InternalAPI.request("POST", CONFIG.CDN_IMAGE_URI + "/image/profile/" + id + ".png", null, file, true); }

    static uploadProfilePicture(file: File, userId): Promise<Object> { 
        localStorage.setItem("ownVersionOfProfileImage", (localStorage.getItem("ownVersionOfProfileImage") ? ( parseInt(localStorage.getItem("ownVersionOfProfileImage")) + 1 ) : "2").toString() );
        return InternalAPI.request("POST", "/image/profile/" + userId + ".png", null, file, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true); 
    }

    static updateSetting(key: string, value: string): Promise<Object> { return InternalAPI.request("PATCH", "/api/settings/" + key + "/" + value + "?"); }

    static getSetting(): Promise<Object> { return InternalAPI.request("GET", "/api/settings?"); }

    static confirmEmail(token: string): Promise<Object> { return InternalAPI.request("GET", "/api/user/confirmEmail?token=" + token + "&"); }

    static requestPasswordReset(email: string): Promise<Object> { return InternalAPI.request("GET", "/api/user/requestPasswordReset?email=" + email, null, null, null, null, null, true); }

    static requestActivationEmail(): Promise<Object> { return InternalAPI.request("GET", "/api/user/sendActivateMail?"); }

    static getClearBitSuggestionByDomain(domainName: string): string { return "https://logo.clearbit.com/" + domainName }

    static sendVerificationSMS(number: string): Promise<Object> { return InternalAPI.request("POST", "/users/phone/send/verification?", { phoneNumber: number }, null, false, true, false, false) }

    static toggleSMSNotifications(on: boolean): Promise<Object> { return InternalAPI.request("PATCH", "/user?", { smsNotifications: on }, null, false, false, false, false, true) }

    static verifyOtp(number: string, code: string): Promise<Object> { return InternalAPI.request("GET", "/users/phone/verify/" + number + "/" + code + "?", null, null, false, true, false, false, false) }

    static generateUploadLink(file: UserFile): Promise<Object> { return InternalAPI.request("POST", "/file/upload-request?", file, null, false, true, false, false, false) }
    
    static markFileUplaoded(id: number, hasPreview: boolean): Promise<Object> { return InternalAPI.request("PUT", "/file/" + id + "/mark-uploaded?", { hasPreview: hasPreview }, null, false, true, false, false, false) }

    static updateFileMetadata(file): Promise<Object> { return InternalAPI.request("PUT", "/file/" + file.id + "?", file, null, false, true, false, false, false) }

    static updateFolderMetadata(folder): Promise<Object> { return InternalAPI.request("PUT", "/filefolder/" + folder.id + "?", folder, null, false, true, false, false, false) }

    static deleteFile(id): Promise<Object> { return InternalAPI.request("DELETE", "/file/" + id + "?", null, null, false, true, false, false, false) }

    static deleteFolder(id): Promise<Object> { return InternalAPI.request("DELETE", "/filefolder/" + id + "?", null, null, false, true, false, false, false) }

    static updateFilesOrder(newOrder): Promise<Object> { return InternalAPI.request("PUT", "/file/order?", newOrder, null, false, true, false, false, false) }

    static fetchLinkPreview(url): Promise<Object> { return InternalAPI.request("GET", "/GetLinkPreview?url=" + url, null, null, false, false, false, false, true) }

    static getAllFiles(): Promise<AllFilesResponse> { return InternalAPI.request("GET", "/file?", null, null, null, true) }

    static reportFile(id): Promise<null> { return InternalAPI.request("PUT", "/file/" + id + "/report?", null, null, null, true) }

    static requestFileAccess(id): Promise<null> { return InternalAPI.request("PUT", "/file/" + id + "/request?", null, null, null, true) }

    static requestFolderAccess(id): Promise<null> { return InternalAPI.request("PUT", "/filefolder/" + id + "/request?", null, null, null, true) }

    static setWorkerUserType(): Promise<null> { return InternalAPI.request("PUT", "/userprofile/usertype/1?", null, null, null, true) }

    static sendVerificationRequest(id): Promise<null> { return InternalAPI.request("GET", "/users/" + id + "/profile-completed?", null, null, null, null, null, null, null, null, true) }

    static searchOrganisations(query): Promise<{ id: string, name: string }[]> { return InternalAPI.request("GET", "/organisations/search/" + query + "?", null, null, null, true) }

    static getUserTypeByUserId(id): Promise<{
        nonExistent?: boolean,
        worker?: boolean,
        hirer?: boolean,
        agency?: boolean,
    }> { return InternalAPI.request("GET", "/users/type/byId/" + id + "?", null, null, null, true) }

    static reRegisterDeviceDetails(details: DeviceDetails): Promise<null> { return InternalAPI.request("PUT", "/userprofile/device?", details, null, null, true) }

    static getUserTypeByUserEmail(email): Promise<{
        nonExistent?: boolean,
        worker?: boolean,
        hirer?: boolean,
        agency?: boolean,
    }> { return InternalAPI.request("GET", "/users/type/byEmail?email=" + email + "&", null, null, null, true) }

    static report(entityId: number, typeId: number, extraNotes: string, reportedContactId: string, reportedUserId: string): Promise<null> { return InternalAPI.request("POST", "/report?", {
        entityId: entityId,
        typeId: typeId,
        notes: extraNotes,
        contactId: reportedContactId,
        userId: reportedUserId
    }, null, null, true) }

    static searchUsersInOrganisation(organisationId: string, query: string): Promise<{ data: { id: string, firstName: string, lastName: string, contactId: string }[] }> { return InternalAPI.request("GET", "/organisations/" + organisationId + "/users?term=" + query + "&", null, null, null, true) }
}

export default UserAPI;
