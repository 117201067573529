import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import UserAPI from '../../../apis/User';
import Availability1Img from '../../../images/help/availability-1-min.jpg';
import Availability2Img from '../../../images/help/availability-2-min.jpg';
import Availability3Img from '../../../images/help/availability-3-min.jpg';
import Delete1Img from '../../../images/help/delete-1-min.jpg';
import Edit1Img from '../../../images/help/edit-1-min.jpg';
import Edit2Img from '../../../images/help/edit-2-min.jpg';
import Edit3Img from '../../../images/help/edit-3-min.jpg';
import Edit4Img from '../../../images/help/edit-4-min.jpg';
import Edit5Img from '../../../images/help/edit-5-min.jpg';
import Edit6Img from '../../../images/help/edit-6-min.jpg';
import Multiday1Img from '../../../images/help/multiday-1-min.jpg';
import Multiday2Img from '../../../images/help/multiday-2-min.jpg';
import Multiday3Img from '../../../images/help/multiday-3-min.jpg';
import Multiday4Img from '../../../images/help/multiday-4-min.jpg';
import Recents1Img from '../../../images/help/recents-1-min.jpg';
import Recents2Img from '../../../images/help/recents-2-min.jpg';
import Recents3Img from '../../../images/help/recents-3-min.jpg';
import Logo from '../../../images/icon.svg';
// import '../../../styles/Components/Settings/HelpGuide.scss';
import Utilities from '../../../Utilities';
import Configuration from '../../../Configuration';
import { DestroyStorage } from '../../../stores/Index';
import InternalTracker from '../../../InternalTracker';
import versionName from '../../../versionName';
import versionCode from '../../../versionCode';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  match: object;
}

const HelpGuide: React.FC<Props> = ({ match }) => {
  
  const contentRef = useRef(null);
  const [section, setSection] = useState <string> (null) // Help section
  const [feedback, setFeedback] = useState <string> ("") // Feedback fro the delete account form

  const closeAccount = async function(feedback: string): Promise<void> {
    UserAPI
      .closeAccount(feedback)
      .then(function(data) {

        InternalTracker.trackEvent("Closed Account", {
          value: feedback
        })
        
        let hello = localStorage.getItem("hello");
        // @ts-ignore
        let idToken = hello ? JSON.parse(hello).b2cSignInAndUpPolicy.id_token : null;
        let redirectUri = CONFIG.PORTAL_URL + "/external/logged-out";
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("hello");
        localStorage.removeItem("firstSyncDone");
        localStorage.removeItem("existingNotifications");
        localStorage.removeItem("notificationToken");
        localStorage.removeItem("lastSync");
        DestroyStorage();
        window.open(`https://${CONFIG.AUTH_TENANT_NAME}.b2clogin.com/${CONFIG.AUTH_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${CONFIG.AUTH_POLICY_NAME}&id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`)
        setTimeout(function() {
          window.location.href = "/onboarding"
        }, 1500)

      }).catch(function(err) {
        (window as any).toast("Failed to send message " + JSON.stringify(err), "error")
      })
  }

  useEffect(() => {
    // @ts-ignore
    setSection(match.params.slug);
    // @ts-ignore
  }, [match.params.slug])

  // @ts-ignore
  const settings = useSelector(state => state.settings);

  return (
    <IonPage data-page="help-guide" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{
            (section === "availability") ?  "I'm Available Now" :
            (section === "recents") ?  "Recents" :
            (section === "delete") ?  "Delete Events" :
            (section === "edit") ?  "Editing Events" :
            (section === "about") ?  "About" :
            (section === "multiday") ?  "Multi-day events" :
            (section === "close-account") ?  "Close Account" : ""
            }</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="profile-settings-wrapper" fullscreen>
        { (section === "multiday") &&
          <div>
            <h1>Multi-day events</h1>
            <p>You can easily create an event which spans multiple days by tapping each date in the calendar then tapping "new event"</p>
            <img src={Multiday1Img} />
            <p>This will show on the edit page as spanning from the first to the last selected date</p>
            <img src={Multiday2Img} />
            <p>Once this multi-day event has been created they shall be grouped together - if you edit or delete one of them you will be asked whether you wish to apply the change to just the one event or all events in the group</p>  
            <img src={Multiday3Img} />
            <p>You can clear your selection of dates by tapping the calendar icon at the bottom of the screen</p>
            <img src={Multiday4Img} />
          </div>
        }
        { (section === "availability") &&
          <div>
            <h1>I'm Available Now</h1>
            <img src={Availability1Img} />
            <p>Add “Working” & “Private” events to show you as unavailable on those days, times, and available otherwise.</p>
            <p>Add <strong>“I’m available now”</strong> events to let your contacts know you are available on specific days, rather than when you are not.</p>
            <img src={Availability2Img} />
            <p>By adding <strong>“I’m available now”</strong> for example next Friday – the system <strong>infers that you are not available at any time before that day, time.</strong></p>
            <p>The system infers you are unavailable until the last “I’m available now” event in your calendar.</p>
            <img src={Availability3Img} />
          </div>
        }
        { (section === "recents") &&
          <div>
            <h1>Recents</h1>
            <p>You can easily add events which you have recently used by tapping the "recent" button in the lower right corner to expand the recents menu</p>
            <img src={Recents1Img} />
            <p>Tap any item from the recents menu to add it to your schedule on the selected date(s)</p>
            <p>You can mark an item as being a favourite by tapping the pin icon which will then turn blue</p>
            <img src={Recents2Img} />
            <p>You can view your favourites by selecting "Favourites" at the top - they will remain pinned until you unpin them</p>
            <img src={Recents3Img} />
            <p>Tap a favourite to add it to your schedule on the selected date(s)</p>
          </div>
        }
        { (section === "delete") &&
          <div>
            <h1>Delete Events</h1>
            <p>To delete an event first find it in your schedule then tap to open item</p>
            <p>There will be a delete button in the bottom left corner</p>
            <img src={Delete1Img} />
          </div>
        }
        { (section === "close-account") &&
          <div>
            <p>Closing your account will remove all information currently stored on this device and flag your account for deletion on the server as per our Privacy Policy.</p>
            <p>Please let us know why you are closing your account - feedback is very important to us!</p>
            <IonTextarea
              placeholder="Enter your feedback here"
              value={feedback} 
              rows={5}
              onIonInput={(e) => {  setFeedback(Utilities.capitalizeFirstLetter((e.target as HTMLIonTextareaElement).value));  }}
            />
            <div style={{
              marginTop: 12,
              display: "flex",
              justifyContent: "space-between"
            }}>
              <IonButton color="primary" onClick={() => { 
                window.open("mailto:support@updatedge.com?subject=Version 2 Support Request" + (feedback.trim() ? "&body=" + encodeURI(feedback) : ""));
              }}>Help me first</IonButton>
              <IonButton color="primary" fill="outline" onClick={() => { closeAccount(feedback); }}>Close account</IonButton>
            </div>
          </div>
        }
        { (section === "edit") &&
          <div>
            <h1>Editing Events</h1>
            Tap an event in your schedule to open it
            When you are done editing an event you can save and close it by tapping the tick button in the bottom right corner
            <h2>Title</h2>
            <p>Edit the title by tapping the box at the top</p>
            <p>The title of an event will only be visible to you and not your contacts</p>
            <img src={Edit1Img} />
            <h2>Type</h2>
            <p>You can select one of three types for an event:</p>
            <img src={Availability1Img} />
            <p style={{ marginBottom: 0 }}><strong>Working</strong> - I'm unavailable</p>
            <p>Your contacts will see that you're working and unavailable</p>
            <p style={{ marginBottom: 0 }}><strong>Private</strong> - I'm unavailable</p>
            <p>Your contacts will see that you have a private event and are unavailable</p>
            <p style={{ marginBottom: 0 }}><strong>Private</strong> - I'm unavailable but offers considered</p>
            <p>Your contacts can see that you're unavailable but would still consider an offer during this time (such as an appointment which you would consider moving)</p>
            {/* <h2>Date</h2>
            <p>You can change the date of an event from the edit window by tapping the date label, which will bring up a date picker, or by swiping the date label to move the event forward/back a day</p> */}
            <h2>Time</h2>
            <p>You can select one of the 3 time presets for an event - All Day, AM, or PM - or you can select a custom start and end time</p>
            <p>If you select Custom then just tap the times to bring up a time-picker to change them</p>
            <img src={Edit2Img} />
            <p>(see the "Time presets" how-to guide on changing the times of those 3 presets)</p>
            <h2>Repeat event</h2>
            <p>Repeat a single event by tapping the “Repeat” label. </p>
            <img src={Edit3Img} />
            <p>An event can be set to repeat daily, during weekdays, weekly, or monthly</p>
            <p>The event should be set to either repeat forever or only until a specified date (by tapping the "Ends" row)</p>
            <img src={Edit4Img} />
            <h2>Location</h2>
            <p>Tap the "location" label to set a location for an event</p>
            <p>You will then be able to search for a location</p>
            <img src={Edit5Img} />
            <h2>Notes</h2>
            <p>The notes field against an event can be used to record any additional information you desire - it will not be visible to any of your contacts</p>
            <img src={Edit6Img} />
          </div>
        }
        { (section === "about") &&
          <div>
            <div style={{ background: "#3573E6", width: 80, height: 80, margin: "0 auto 30px auto", borderRadius: 8, overflow: "hidden", padding: 15 }}>
              <img src={Logo} />
            </div>  
            <h1>Updatedge</h1>
            <label style={{ display: "block", textAlign: "center", fontWeight: 300, marginTop: -16 }}>v{versionName} {versionCode}</label>
            <h2>Share your latest availability</h2>
            <p>Easily share your latest availability with all your chosen contacts at once, with just three clicks.</p>
            <p>Save yourself and your agency, hirer contacts loads of time and it’s free.</p>
            <p>Add as many contacts as you like, there is no limit.</p>
            <p>Contacts can be agencies, business hirers, friends, family; anybody you want to share your availability with and to receive offers from.</p>
            <p>All they see is that you are working, have private unmovable or movable events and the times; they don’t see any other confidential details.</p>
            <h2>Receive and accept offers</h2>
            <p>Your contacts can see your latest availability at any time and send offers to you for opportunities that may be of interest; for free.</p>
            <h2>Agencies & Business Hirers - assignments and offers</h2>
            <p>Assignments and offers can be automatically entered into your schedule, on your approval, from good agencies using the free Application Program Interface to their systems.</p>
            <h2>Futures</h2>
            <p>There are plenty of exciting features to be added, such as sharing full event details with selected contacts, viewing contacts availability and many others.</p>
            <h2>Let us know what else you need</h2>
            <p>We really want to know how we can improve the service and let us know if you have any issues, email using the Feedback and Support link on the Settings page.</p>
          </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default HelpGuide;
