import { Plugins } from '@capacitor/core';
import { IonContent, IonHeader, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import UserAPI from '../apis/User';
import VerifyEmailImg from '../images/verify-email.svg';
// import '../styles/VerifyEmail.scss';
import { User } from '../types/User';
import { DestroyStorage } from '../stores/Index';
import Configuration from '../Configuration';
import { isPlatform } from '@ionic/react';
import { Auth } from '../services/AuthService';
import { App } from '@capacitor/app';
import Utilities from '../Utilities';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  onClose: () => void;
  email?: string;
}

const VerifyEmail: React.FC<Props> = ({ onClose, email }) => {

  const [loading, setLoading] = useState <boolean> (false);
  const [primaryEmail, setPrimaryEmail] = useState <string> ("");

  const onFocus = function(): void {
    setLoading(true);
    UserAPI.get().then(user => {
      setLoading(false);
      if ((user as User).canSync) {
        console.log("prev: ", JSON.parse(localStorage.getItem("user")), "new:", JSON.parse(JSON.stringify(user)), "<<<<<<<<");
        // @ts-ignore
        Utilities.upsertLocalUser(user as User);
        onClose();
      }
    }).catch(e => {
      console.error(e);
      setLoading(false);
      (window as any).toast("Please make sure you are connected to the internet", "error")
    })
  }

  const resendEmail = function(): void {
    setLoading(true);
    UserAPI.requestActivationEmail().then(user => {
      setLoading(false);
      (window as any).toast("Email Sent", "success")
    }).catch(e => {
      setLoading(false);
      (window as any).toast("Failed to send email again", "error")
    })
  }

  useEffect(() => {
    window.removeEventListener("focus", onFocus);
    window.addEventListener("focus", onFocus);
    App.addListener('appUrlOpen', async (data: any) => {
      if (data.url.indexOf("confirmEmail") !== -1) {
        setLoading(true);
        let token = data.url.split('token=').pop();
        await UserAPI.confirmEmail(token).catch(e => {
            (window as any).toast("Failed to activate account.", "error")
        })
        onFocus();
      }
    });
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = Utilities.decodeJWTToken(token);
      if (decodedToken && decodedToken.emails && decodedToken.emails.length > 0) {
        setPrimaryEmail(decodedToken.emails[0]);
      }
    }
  }, []);

  return (
    <IonPage data-page="verify-email">
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="verify-email-wrapper" fullscreen>
        <div>
          <div>
            <img src={VerifyEmailImg} />
            <h1>Verify your email</h1>
            <p>We have sent you an email to { ((primaryEmail) && <span>{primaryEmail}</span>) } to verify your account, click the link in the email and head back to get started</p>
            { loading && <CircularProgress /> }
            { !loading && <button onClick={() => { onFocus() }}>I verified</button> }
            { !loading && <button style={{ marginLeft: 12, backgroundColor: 'whitesmoke', color: 'black' }} onClick={() => { resendEmail() }}>Resend Email</button> }
            <button className="secondary-btn" onClick={ async () => {

              let hello = localStorage.getItem("hello");
              // @ts-ignore
              let idToken = hello ? JSON.parse(hello).b2cSignInAndUpPolicy.id_token : null;
              let redirectUri = CONFIG.PORTAL_URL + "/external/logged-out";
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("user");
              localStorage.removeItem("hello");
              localStorage.removeItem("firstSyncDone");
              localStorage.removeItem("existingNotifications");
              localStorage.removeItem("notificationToken");
              localStorage.removeItem("lastSync");
              await DestroyStorage();

              if (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) {
                Auth.Instance.addActionListener((action) => {
                  if (action && action.action === "Sign Out Success") {
                    if (!(window as any).justShowedSignedOut) {
                      alert("Successfully Signed Out");
                    }
                    (window as any).justShowedSignedOut = true;
                    setTimeout(() => {
                      (window as any).justShowedSignedOut = false;
                    }, 5000)
                    window.location.href = "/onboarding"
                  }
                });
                Auth.Instance.signOut();
              } else {
                window.open(`https://${CONFIG.AUTH_TENANT_NAME}.b2clogin.com/${CONFIG.AUTH_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${CONFIG.AUTH_POLICY_NAME}&id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`)
                setTimeout(function() {
                    window.location.href = "/onboarding"
                }, 1500);
              }

            }}>Log Out</button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmail;