import { Plugins } from '@capacitor/core';
import { IonIcon } from '@ionic/react';
import { open, map } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import Utilities from '../../Utilities';
import InternalTracker from '../../InternalTracker';
import { Device } from '@capacitor/device';

interface InlineSelectProps {
    googlePlacesId: string;
    showLocationNameFromGoogle?: boolean;
    locationFriendlyName?: string;
    locationFriendlyAddress?: string;
}

const PlaceFields = [
    'address_components',
    'geometry',
    'icon',
    'name',
    'place_id'
];

const LocationPreview: React.FC<InlineSelectProps> = ({ googlePlacesId, showLocationNameFromGoogle, locationFriendlyName, locationFriendlyAddress }) => {

    const [loading, setLoading] = useState <boolean> (true) // Whether the location is loaded
    const [locationName, setLocationName] = useState <string> ("") // Name of the location
    const [lat, setLat] = useState <number> (null) // lat of the location
    const [lng, setLng] = useState <number> (null) // lng of the location
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState <boolean> (false) // Whether Google Maps is loaded

    useEffect( () => {
        // lookupGooglePlace(googlePlacesId);
        checkGoogleMapsAPI();
    }, []);

    const checkGoogleMapsAPI = function() {

        if (typeof google === 'undefined' || !google) {
            const googleApiScript = document.createElement('script');
            googleApiScript.async = true;
            googleApiScript.defer = true;
            googleApiScript.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8';
            googleApiScript.onload = () => {
                setGoogleMapsLoaded(true);
                lookupGooglePlace();
            };
            document.body.appendChild(googleApiScript);
        } else {
            setGoogleMapsLoaded(true);
            lookupGooglePlace();
        }

    }

    const lookupGooglePlace = function() {
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails(
            {
                placeId: googlePlacesId,
                fields: PlaceFields
            }, (place) => {
                setLat(place.geometry.location.lat())
                setLng(place.geometry.location.lng())
                const formattedAddress = Utilities.formatAddressComponents(place.address_components);
                const firstPart = place.name.replace(" Road", " Rd").replace(" Street", " St").replace(" Avenue", " Ave").replace(" Close", " Cl").replace(" Lane", " Ln");
                const secondPart = formattedAddress.replace(" Road", " Rd").replace(" Street", " St").replace(" Avenue", " Ave").replace(" Close", " Cl").replace(" Lane", " Ln");
                let hideFriendlyName = (firstPart && secondPart && secondPart.includes(firstPart));
                setLocationName(hideFriendlyName ? formattedAddress : (place.name + " (" + formattedAddress + ")"));
            }
        );
    }

    const openDirections = async function(lat, lng) {

        let deviceInfo = await Device.getInfo();

        if (deviceInfo.platform === "ios") {
            window.open('maps://?q=' + lat + ',' + lng, '_system');
        } else if (deviceInfo.platform === "android") {
            window.open('geo://' + lat + ',' + lng + '?q=' + lat + ',' + lng);
        } else {
            window.open('https://www.google.com/maps/dir/?api=1&destination=' + lat + ',' + lng, '_system');
        }

        InternalTracker.trackEvent("Offer Opened on Map");

    }

    return (
        <div className="location-preview" onClick={() => { openDirections(lat, lng) }} >
            { (showLocationNameFromGoogle) && <p className="location-name">{locationName || "Loading..."}</p> }
            { (locationFriendlyAddress && locationFriendlyName) ? 
                <p className="location-name"><span>{locationFriendlyName + " | " + locationFriendlyAddress}</span></p> :
                (locationFriendlyName) ? <p className="location-name"><span>{locationFriendlyName}</span></p> :
                null
            }
            { (lat && lng) && 
                <div className="img">
                    <img src={"https://maps.googleapis.com/maps/api/staticmap?center=" + lat + "," + lng + "&zoom=18&size=560x300&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8"} />
                    <button><IonIcon icon={map} /> Open in Maps</button>
                </div>
            }
        </div>
    );
    
};

export default LocationPreview;
