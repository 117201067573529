import React, { useEffect, useState } from 'react';
import Configuration from '../../../Configuration';
// import '../../../styles/Components/Files/DocumentViewer.scss';
import { FileType } from '../../../types/User';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Drawer } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { closeCircle, download } from 'ionicons/icons';
import InternalTracker from '../../../InternalTracker';
import Utilities from '../../../Utilities';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface ContactListItemProps {
    uri: string;
    type: FileType;
    name?: string;
    id?: number;
    close?: () => void;
    report?: () => void;
}

const DocumentViewer: React.FC<ContactListItemProps> = ({ id, uri, type, name, close, report }) => {
    const youtubeId = type === FileType.VideoLink ? uri.split("v=")[1].split("&")[0] : null;
    const [lastUri, setLastUri] = useState<string> ("")

    useEffect(() => {
        console.log(uri)
        Utilities.onDrawerShow();
        InternalTracker.trackEvent("File Viewed", { id, type, name });
        if (lastUri !== uri) {
            setLastUri(uri)
        }
    }, [uri])

    return (
        <Drawer
            anchor="bottom"
            open={true}
            onClose={ async () => {
                Utilities.onDrawerShow();
                close();
            } }
            disableEnforceFocus
            className="document-viewer-drawer"
        >
            <div className="swipeable-drawer-body" data-visible={true}>
                <div className='document-viewer-wrapper'>
                    <div className='header'>
                        <h3>{name}</h3>
                        <div className='options'>
                            <IonIcon onClick={() => { close() }} icon={closeCircle} />
                        </div>
                    </div>
                    { (type === FileType.TextDocument) &&
                        <DocViewer prefetchMethod="GET" pluginRenderers={DocViewerRenderers} documents={[{ uri: lastUri }]} />
                    }
                    { (type === FileType.Image) &&
                        <div className='img'>
                            <img src={uri} />
                        </div>
                    }
                    { (type === FileType.Video) &&
                        <div className='video'>
                            <video controls autoPlay>
                                <source src={uri} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    }
                    { type === FileType.VideoLink &&
                        <iframe width="100%" height="360" src={"https://www.youtube.com/embed/" + youtubeId + "?autoplay=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                    }
                    { type === FileType.Link &&
                        <div className='link-warning'>
                            This is an external link. We have no control over the content on <strong>{uri}</strong>. Proceed with caution.
                        </div>
                    }
                    <div className='bottom-options'>
                        { type === FileType.Link &&
                            <button 
                                className='external-link'
                                onClick={() => {
                                    window.open(uri);
                                    InternalTracker.trackEvent("File Viewed", { id, type, name });
                                }}
                            >Open External Link</button>
                        }
                        { (report) &&
                            <button onClick={() => {
                                if (report) {
                                    InternalTracker.trackEvent("File Reported", { id, type, name });
                                    report();
                                }
                            }}>Report</button>
                        }
                        { (type === FileType.TextDocument || type === FileType.Image || type === FileType.Video) &&
                            <button onClick={() => {
                                InternalTracker.trackEvent("File Downloaded", { id, type, name });
                                window.open(uri)
                            }}>
                                Download
                            </button>
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default DocumentViewer;
