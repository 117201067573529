import { RatingRecord } from "./Offer";

export interface Contact {
    id?: string,
    userId?: string,
    contactId?: string,
    name?: string,
    firstName?: string,
    lastName?: string,
    notificationStatusTypeId?: number,
    emailOptOut?: number,
    deleted?: boolean,
    relationshipTypeId?: number,
    profilePictureUrl?: string,
    companyPictureUrl?: string,
    companyName?: string,
    email?: string,
    metadata?: object
    companyId?: string,
    emailDeliveryStatusTypeId?: number,
    inviteIgnored?: boolean,
    headline?: string
    thirdPartyOrganisationName?: string;
    thirdPartyLogoUrl?: string;
    organisationId?: string;
    organisationName?: string;
    verified?: boolean;
    rating?: RatingRecord;
    addedByEmail?: boolean,
    addedByDiscover? : boolean,
    nonDirect?: boolean,
    reported?: boolean,
};

export interface ContactType {
    organisationId?: string
    organisationName?: string,
    verified?: boolean,
    rating?: RatingRecord,
    avgRatingStars?: string;
    totalRatings?: number;
    isSharingWith?: boolean;
    hasAgreementWith?: boolean;
    organisationDomain?: string;
    contacts: [{
      reported: boolean;
      contactId: string,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      emailDeliveryStatusTypeId?: number,
      notificationStatusTypeId?: number,
      inviteIgnored?: boolean,
      headline?: string,
      thirdPartyOrganisationName?: string;
      thirdPartyLogoUrl?: string;
      organisationId?: string;
      organisationName?: string;
      verified?: boolean;
      rating?: RatingRecord,
      avgRating?: string,
      totalRatings?: number
    }],
    representedConfirmedByContactId?: string,
    vettingConfirmedByContactId?: string,
    identityConfirmedByContactId?: string,
    verificationRejectedByContactId?: string,
  }
  
export interface ContactSearchType {
    organisationId?: string
    organisationName?: string,
    isHirer: boolean,
    isAgency: boolean,
    verified?: boolean,
    matches: {
        contactId: string,
        userId: string,
        firstName: string,
        lastName: string,
        email: string,
        headline?: string,
        verified?: boolean,
        avgRating?: string,
        totalRatings?: number,
        ratings?: RatingRecord[],
        organisation?: ContactSearchType,
        reported?: boolean,
    }[],
    address?: string,
    emailDomain?: string,
    isNewOrganisation?: boolean
    thirdPartyId?: string;
    thirdPartyReference?: string;
    organisationImageUrl?: string;
    knownUnsignedUpAgency?: boolean;
}

export interface ContactResultDto {
  agencies: ContactType[],
  organisations: ContactType[],
  others: ContactType[],
  archivedChats: ContactType[],
  sharedFiles: FileShare[]
}

export interface FileShare {
  fileId: number,
  userId: string
}

export interface ReferralContact {
  name?: string,
  email?: string,
  userId?: string,
  notes?: string,
  location?: string,
  orgName: string,
  type: "worker" | "hirer" | "agency"
}

export interface Referral {
  id: number
  deleted: boolean,
  referringUserId: string,
  referringUserReported: boolean
  referredContactReported: boolean,
  referringUserName: string,
  referredContactId: string,
  referredContactFirstName: string,
  referredContactLastName: string,
  referredContactEmail: string,
  referredContactOrgName: string,
  referredToContactId: string,
  referredToContactFirstName: string,
  referredToContactLastName: string,
  referredToContactEmail: string,
  referredToContactOrgName: string,
  type: ReferralDirectionType,
  state: ReferralState,
  createdAt: string,
  updatedAt: string,
  location: string,
  notes: string,
  referredContactUserId? : string,
  referredToContactUserId? : string,
}

export enum ReferralDirectionType {
  WorkerToHirer = 1,
  WorkerToAgency = 2,
  HirerToAgency = 3,
}

export enum ReferralState
{
    Unknown = 0,
    PendingReferred = 1,
    ReferredRejected = 2,
    PendingReferredTo = 3,
    ReferredToRejected = 4,
    Completed = 5
}