import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './state/store';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { TourProvider } from '@reactour/tour'

Bugsnag.start({
    apiKey: 'd89c0c1a92d69262d9e284d8b1374ea0',
    plugins: [new BugsnagPluginReact()],
    onError: function(event) {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : { };
        if (user && user.id)
            event.setUser(user.id, user.email, user.firstName + " " + user.lastName);
    }
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <TourProvider steps={[]}>
            {/* <TourProvider nextButton={({Button: Error}) => {}} steps={[]}> */}
                <App />
            </TourProvider>
        </Provider>
    </ErrorBoundary>
    , 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();