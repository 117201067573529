const EventCache = (state = null, action) => {
    switch(action.type) {
        case "UPDATE_EVENT_CACHE":
            return action.data
        case "CLEAR_EVENT_CACHE":
            return null;
    }
    return state;
}

export default EventCache;
