export const updateTimelineCache = (timeline: Object) => {
    return {
        type: "UPDATE_TIMELINE_CACHE",
        data: timeline
    }
}

export const clearTimelineCache = () => {
    return {
        type: "CLEAR_TIMELINE_CACHE"
    }
}