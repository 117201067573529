import { IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

const LoginRedirect: React.FC<{
  match: object;
}> = ({ match }) => {

    useEffect( () => {
      // @ts-ignore
      const section = match.params.section;

      if (!localStorage.getItem("access_token")) {
        setTimeout(() => {
          redirect(section);
        }, 2000)
        return;
      } else {
        redirect(section)
      }

    }, []);

    const redirect = function(section: string) {

      if (section === "contacts" || section === "verified") {
        window.location.href = "/contacts";
      } else if (section === "image" || section === "headline" || section === "postcode" || section === "proximity" || section === "sectors") {
        window.location.href = "/settings/profile#" + section;
      } else if (section === "timePresets") {
        window.location.href = "/settings/time-presets";
      } else if (section === "chatted") {
        window.location.href = "/messages";
      } else if (section === "confirmeOffer") {
        window.location.href = "/notifications";
      } else {
        window.location.href = "/";
      }

    }
    
    return (
      <IonPage>
        Redirecting...
      </IonPage>
    ); 
};

export default LoginRedirect;
