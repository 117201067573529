
export async function getCacheValue(key) {
    if (!(window as any).db) return [];
    let row = await (window as any).db.cache.where({ key: key }).toArray();
    return row[0] ? row[0].value : null;
};

export async function setCacheValue(key, value) {
    if (!(window as any).db) return [];
    const res = await (window as any).db.cache.put({ key: key, value: value });
    return res;
}

export async function deleteCacheValue(key) {
    if (!(window as any).db) return [];
    const res = await (window as any).db.cache.delete(key);
    return res;
}

export async function destroyCache() {
    if (!(window as any).db) return [];
    const res = await (window as any).db.cache.clear();
    return res;
}