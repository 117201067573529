const TimelineCache = (state = null, action) => {
    switch(action.type) {
        case "UPDATE_TIMELINE_CACHE":
            return action.data
        case "CLEAR_TIMELINE_CACHE":
            return null;
    }
    return state;
}

export default TimelineCache;
