
import React, { useEffect, useState } from 'react';
import Configuration from '../../../Configuration';
// import '../../../styles/Components/Rating/RatingPreview.scss';
import { RatingDirection, RatingRecord } from '../../../types/Offer';
import ReactStars from "react-rating-stars-component";
import { IonIcon, IonLoading } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import Drawer from '@material-ui/core/Drawer';
import RatingApi from '../../../apis/Rating';
import RatingBanner from '../RatingBanner';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface RatingModalProps {
    onClose: (stars?: number, publicComment?: string) => void;
    rateeName?: string;
    recipient: {
        userId?: string,
        contactId?: string,
        organisationId?: string,
        organisationType?: string,
        organisationName? : string,
        organisationNameJustStr?: string,
    }
}

const RatingModal: React.FC<RatingModalProps> = ({ onClose, recipient, rateeName }) => {

    const [rating, setRating] = useState <RatingRecord> (null);

    useEffect( () => {
        async function onMount () {
            let existingRating = await RatingApi.getOwnRating((recipient.userId || recipient.contactId) ? 
                { 
                    userId: recipient.userId || null,
                    contactId: recipient.contactId || null,
                    recipientOrgId : recipient.organisationId,
                    ratingDirection: recipient.organisationType === "independent" ? RatingDirection.Worker_OrganisationsHirer : recipient.organisationType === "agency" ? RatingDirection.Worker_AgencysConsultant : RatingDirection.Worker_OrganisationsHirer
                } : 
                { 
                    recipientOrgId : recipient.organisationId,
                    ratingDirection: recipient.organisationType === "independent" ? RatingDirection.Worker_OrganisationsHirer : recipient.organisationType === "agency" ? RatingDirection.Worker_Agency : RatingDirection.Worker_HirersOrganisation
                } 
            )

            let rating = {
                updatedAt: existingRating ? (existingRating as RatingRecord).updatedAt : null,
                id: existingRating ? (existingRating as RatingRecord).id : null,
                stars: existingRating ? (existingRating as RatingRecord).stars : 0,
                publicComment: existingRating ? (existingRating as RatingRecord).publicComment : "",
                privateComment: existingRating ? (existingRating as RatingRecord).privateComment : "",
                recipientOrgId: recipient.organisationId,
                userId: recipient.userId,
                recipientContactId: recipient.userId ? null : recipient.contactId,
                ratingDirection: 
                    recipient.userId ? (recipient.organisationType === "independent" ? RatingDirection.Worker_OrganisationsHirer : recipient.organisationType === "agency" ?  RatingDirection.Worker_AgencysConsultant : RatingDirection.Worker_OrganisationsHirer) :
                    (recipient.organisationType === "independent" ? RatingDirection.Worker_OrganisationsHirer : recipient.organisationType === "agency" ?  RatingDirection.Worker_Agency : RatingDirection.Worker_HirersOrganisation)
            }

            setRating(rating as RatingRecord);

            setTimeout(() => {
                window.document.querySelectorAll('textarea').forEach(function(node) {
                    node.style.height = (node.scrollHeight - 8) + 'px';
                });
            }, 300)

            document.body.classList.remove("backdrop-no-scroll");
        }
        onMount();
    }, [])

    return (
        <React.Fragment>
            { (!rating) &&
                <IonLoading
                    isOpen={true}
                    message="Loading rating ..."
                />
            }
            <Drawer
                anchor="bottom"
                open={rating !== null}
                onClose={ async () => {
                    onClose(rating.stars || 0, rating.publicComment || "");
                } }
                disableEnforceFocus
                className="rating-wrapper"
            >
                <div className="swipeable-drawer-body" data-visible={true}>
                    { (rating !== null) &&
                        <div className="content">
                            <RatingBanner
                                existingRating={rating}
                                rateeName={rateeName}
                                name={recipient.organisationNameJustStr}
                                onSubmitted={(stars, publicComment) => {
                                    onClose(stars || 0, publicComment || "");
                                }}
                                showRaterUserId={rating.ratingDirection === RatingDirection.Worker_OrganisationsHirer || RatingDirection.Worker_AgencysConsultant ? recipient.userId : undefined}
                            />
                        </div>
                    }
                </div>
            </Drawer>
        </React.Fragment>
    );
    
};

export default RatingModal;
