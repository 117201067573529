// tslint:disable: no-console
import { IonLoading, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToast, IonToolbar, IonActionSheet } from '@ionic/react';
import { calendarClear, chevronBackOutline, chevronForwardOutline, closeCircle, star, syncCircle, cloudDone, cloudOffline, close, helpCircleSharp, alertCircle } from 'ionicons/icons';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import DoubleArrows from '../icons/DoubleArrows';
import Logo from '../images/icon.svg';
import { updateAvailabilityCircle } from '../state/actions/Availability';
import { updateCalendar } from '../state/actions/Calendar';
import { refreshOnboardingGuide } from '../state/actions/OnboardingGuide';
import store from '../state/store';
import { getLastEventDate, getEventById, hasUndeletedEvents, saveEvent, getInferredUnavailabilityDates } from '../stores/Event';
import { getSetting } from '../stores/Settings';
import { Event, CollidingEvent } from '../types/Event';
import Utilities from '../Utilities';
import AvailabilityCircle from './Components/Calendar/AvailabilityCircle';
import EventEditorDrawer from './Components/Calendar/EventEditorDrawer';
import TipsGuidePrompt from './Components/TipsGuidePrompt';
import EventTimelineItem from './Components/Calendar/EventTimelineItem';
import RecentEventsDrawer from './Components/Calendar/RecentEventsDrawer';
import ProfileProgress from './Components/ProfileProgress';
import { updateSyncState } from '../state/actions/Sync';
import InternalTracker from '../InternalTracker';
import { Tooltip } from '@material-ui/core';
import { useTour } from '@reactour/tour';

import OnboardingGuide from './Components/OnboardingGuide';
import { updateResolvedEvents, clearResolvedEvents } from '../state/actions/ResolvedEvents';
import UpgradeDrawer from './Components/UpgradeDrawer';
import { updateEventCache } from '../state/actions/EventCache';

interface Props {
  timelineP?: Event[],
  modal?: boolean,
  onEventsAdded?: () => void,
  onModalVisibilityChange?: (visible: boolean) => void,
  paddingTop?: number,
  paddingBottom?: number
}

const CalendarTimeline: React.FC<Props> = ({ timelineP, paddingTop, paddingBottom, modal, onEventsAdded, onModalVisibilityChange }) => {

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()
  const [value, onChange] = useState <Date> (new Date()); // Default value for calendar, only used for positioning
  const [selectedDays, onDayChange] = useState <string[]> ([]) // Array of all the selected days in YYYY-MM-DD format
  const [timeline, updateTimeline] = useState <object> ({}) // Processed timeline
  const [clashBannerTemporarilyHidden, setClashBannerTemporarilyHidden] = useState <boolean> (false) // Whether the clash banner is temporarily hidden
  const [overridenClashes, setOverridenClashes] = useState <string[]> ([]) // Clashing event ids from server confirmed offer auto-insert
  const [eventCount, setEventCount] = useState <number> (0) // Number of events in the timeline
  const [eventCountSelection, setEventCountSelection] = useState <number> (0) // Number of events on the selected days
  const [timelineRange, setTimelineRange] = useState <[Date, Date]> ([new Date(), new Date()]) // Range in which the user can see events
  const [timelineHeight, setTimelineHeight] = useState <number> (0) // Height of the timeline to form 100% height with the calendar
  const [eventEditingDates, setEventEditingDates] = useState <string[]> (null) // Array of datehashes (YYYY-MM-DD) that are being edited on the event drawer (ADD)
  const [eventEditingId, setEventEditingId] = useState<string> (null) // Id of the existing event that is being edited on the event drawer (EDIT/DELETE)
  const [toastMessage, setToastMessage] = useState<string> (null) // Toast message content
  const [timelineScrollEventEnabled, setTimelineScrollEventEnabled] = useState<boolean> (true)
  const [lastOpenedView, setLastOpenedView] = useState<number> (0); // Dates to see whether a new day has passed and the app was not killed
  const [lastOpenedViewCheck, setLastOpenedViewCheck] = useState<boolean> (false); // Dates to see whether a new day has passed and the app was not killed
  const [justChangedIds, setJustChangedIds] = useState<string[]> ([]); // Ids of the currently changed events
  const [recentEventsDrawer, setRecentEventsDrawer] = useState<string> (null); // Ids of the currently changed events
  const [recentEventAddModeAlert, setRecentEventAddModeAlert] = useState<string> (null) // Alert asking whether to add recent event immeidately or edit
  const [recentEventAddModeSelected, setRecentEventAddModeSelected] = useState<string> (null) // Mode of adding - amend / auto
  const [inferredUnavailableRanges, setInferredUnavailableRanges] = useState<Event[]> ([]) // Last available date used for infered availability
  const [eventEditingIdRepeatingDate, setEventEditingIdRepeatingDate] = useState<Date> (null) // The date on which a repeated date is editd (in case sole edit)
  const [calendarColor, setCalendarColor] = useState<number> (0) // Temporarily changes the color of the calendar
  const [lastEventCount, setLastEventCount] = useState<number> (0) // Event count the last time
  const [devConsoleCount, setDevConsoleCount] = useState<number> (0) // Number of times logo clicked to jump to the right testing date
  const [calendarShrinked, setCalendarShrinked] = useState<boolean> (false) // Whether the calendar is shrinked or not to allow the display of more events
  const [loadingMessage, setLoadingMessage] = useState<string> (null) // Blocking loading message
  const [collidingEvents, setCollidingEvents] = useState<Object> (null) // CollidingEvents
  const [collidingEventsRef, setCollidingEventsRef] = useState<CollidingEvent[]> (null) // Colliding Events
  const [eventEditedDraft, setEventEditedDraft] = useState<Event> (null) // Draft event to be restored
  const [addSource, setAddSource] = useState<string> (null) // Source of the new event
  const [userGuideOpenSection, setUserGuideOpenSection] = useState <string> (null) // Opened profile guide section
  const [cloudSyncFailedTooltip, setCloudSyncFailedTooltip] = useState<boolean> (false)
  const [uncompletedSteps, setUncompletedSteps] = useState <number> (0); // Number of uncompleted steps from profile
  const [confidentInAvailability, setConfidentInAvailability] = useState <number> (100); // Whether confident score is bigger than 0
  const [triggerProfileProgressBadgeUpdate, setTriggerProfileProgressBadgeUpdate] = useState <boolean> (false); // Triggers a profile badge update from profire progress component
  const [tipsGuideSection, setTipsGuideSection] = useState <string> (null); // Section of the tips guide
  const [tipsGuideSectionContent, setTipsGuideSectionContent] = useState <object> (null); // A/B content of the tips guide
  const [infoAdminCount, setInfoAdminCount] = useState <number> (0); // Number of times info admin clicked
  const [adminPanel, setAdminPanel] = useState <boolean> (false); // Whether admin panel is open
  const [hideEventDrawer, setHideEventDrawer] = useState <boolean> (false); // Whether event drawer is hidden - used when auto resolving to clean up ui
  const [extendedTimelineState, setExtendedTimelineState] = useState <"unloaded" | "loading" | "loaded"> ("unloaded"); // Whether to show all past events (default limit is 4 months)
  let swipeCalendarTimeout = null;
  let scrollRejectTimeout = null;
  const timelineRef = useRef(null);
  const calendarRef = useRef(null);
  const contentRef = useRef(null);
  const history = useHistory();
  var xDown = null;                                                        
  var yDown = null;

  function getTouches(evt) {
    return evt.touches ||  evt.originalEvent.touches;
  }                                                     

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];                                      
    xDown = firstTouch.clientX;                                      
    yDown = firstTouch.clientY;                                      
  };                                                

  function handleTouchMove(evt) {

    if ( ! xDown || ! yDown ) {
        return;
    }

    var xUp = evt.touches[0].clientX;                                    
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (swipeCalendarTimeout) {
      return;
    }

    swipeCalendarTimeout = true;

    setTimeout(function() {
      swipeCalendarTimeout = false;
    }, 300)

    let newDate = null;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
      if ( xDiff > 0 ) {
        newDate = Utilities.dateAdd(value, "month", 1)
      } else {
        newDate = Utilities.dateSub(value, "month", 1)
      }                       
    } else {
      if ( yDiff > 0 ) {
        newDate = Utilities.dateAdd(value, "month", 1)
      } else { 
        newDate = Utilities.dateSub(value, "month", 1)
      }                                                                 
    }

    if (newDate) {
      loadNextSection(timelineRange[0], newDate, undefined, undefined, undefined, undefined, 7, overridenClashes);
      onChange(newDate);
      scrollToSelector('div[data-month="' + Utilities.formatDate(newDate, "YYYY-MM") + '"]');
    }

    recalculateTimelineHeight();
    /* reset values */
    xDown = null;
    yDown = null; 

  };

  // Recalculates and sets the height of the timeline based on the viewport and calendar size
  const recalculateTimelineHeight = function(nth?: number, paddingBottom?: number): void {

    if (nth > 10) {
      return;
    }
    
    if (contentRef && contentRef.current && calendarRef && calendarRef.current) {
      let newHeight = contentRef.current.clientHeight - calendarRef.current.clientHeight - 60
      if (newHeight > 0 && timelineHeight !== contentRef.current.clientHeight - calendarRef.current.clientHeight - 60) {
        console.log("PAdding bottom set : " + paddingBottom)
        setTimelineHeight(contentRef.current.clientHeight - calendarRef.current.clientHeight - 60 - (paddingBottom || 0));
      }
    }
    
  }

  const onFocus = function(): void {
    setLastOpenedViewCheck(true);
    // (window as any).Sync.sync();
  }

  // Reload Calendar if a day passed
  useEffect( () => {
    const now = new Date();
    if (lastOpenedView && lastOpenedView !== now.getDate()) {
      // A day has passed need to refocus, reload
      window.location.href = "/calendar";
      setLastOpenedView(new Date().getDate());
    }
  }, [lastOpenedViewCheck])

  useEffect( () => {
    console.log("======= eventEditingDates =======", eventEditingDates)
    if (onModalVisibilityChange) {
      onModalVisibilityChange(eventEditingDates != null);
    }
  }, [eventEditingDates])

  useEffect( () => {

    InternalTracker.trackEvent("Calendar Navigation", {
      date: value.toISOString()
    })

  }, [value])

  useEffect(() => {
    console.log("======= " + extendedTimelineState + " =======")
  }, [extendedTimelineState])

  useEffect(() => {
    console.log("reclculate because prop padding bottom change" + paddingBottom)
    if (paddingBottom) {
      console.log("passing padding bottom " + paddingBottom)
      recalculateTimelineHeight(0, paddingBottom);
    }
  }, [paddingBottom]);

  useEffect( () => {

    window.removeEventListener("focus", onFocus);
    window.addEventListener("focus", onFocus);

    setTimeout(() => {
      if ((window as any).Sync && (window as any).Sync.sync) {
        (window as any).Sync.sync();
      } else {
        setTimeout(() => {
          if ((window as any).Sync && (window as any).Sync.sync) {
            (window as any).Sync.sync();
          }
        }, 750)
      }
    }, 250)

    recalculateTimelineHeight();

    if (!lastOpenedView) {
      setLastOpenedView(new Date().getDate());
    }

    if (timelineP) {

      reloadEvents(timelineP);

    } else {

      reloadEvents();

      store.subscribe(() => {
        const state = store.getState();
        if (state && state.sync && (state.sync.status === "done" || state.sync.status === "error")) {
          if (state.sync.updates) {
            store.dispatch(updateSyncState({ updates: false }));
            reloadEvents();
          } 
          if (state.sync.status === "error") {
            setCloudSyncFailedTooltip(true);
            setTimeout(() => { setCloudSyncFailedTooltip(false) }, 2000);
          }
        }
      });

    }

    history.listen(e => {
        if (e.pathname === "/calendar") {
          reloadAppearance();
          scrollToLastAdded();
          setTriggerProfileProgressBadgeUpdate(true)
        }
    })

    reloadAppearance();
    scrollToLastAdded();

    if (localStorage.getItem("userGuideOpenSection")) {
      setUserGuideOpenSection("files");
    }

    (window as any).showOnboardingTipPrompt = function(variation, task, config) {
      console.log("@@@ Opening NOW " + task)
      setTipsGuideSection(task);
      setTipsGuideSectionContent(config)
    }

  }, []);

  // Recalucalte the size of the timeline
  useLayoutEffect(() => {
    recalculateTimelineHeight(10);
    setTimeout(function() { recalculateTimelineHeight(10); }, 300)
    setTimeout(function() { recalculateTimelineHeight(10); }, 1000)
  }, [timeline, calendarShrinked, value])

  const scrollToLastAdded = async function() {
    if (localStorage.getItem("scrollToDate")) {
      setJustChangedIds(localStorage.getItem("justAddedEvent").split(","));
      reloadEvents(null, new Date(localStorage.getItem("scrollToDate")));
      localStorage.removeItem("scrollToDate")
      localStorage.removeItem("justAddedEvent")
    }
  }

  const reloadAppearance = async function() {
    let calendarColor = await getSetting("calendarColor");
    if (calendarColor)
      setCalendarColor(parseInt(calendarColor));
  }

  const scrollToSelector = async function(selector: string) {
    let el = document.querySelector(selector);
    if (el) {
      setTimelineScrollEventEnabled(false);
      // Scroll so this is in the end of the view (cannot set to beginning because for some reason the whole page shifts down even though overflow hiddens)
      el.scrollIntoView({ block: 'start', inline: 'start' });
      const ionContent = document.getElementById("calendar-wrapper");
      if (ionContent) {
        // @ts-ignore
        ionContent.style.setProperty('--offset-bottom', '0px') // Neds a nudge to prevent that full page shift
      }
      clearTimeout(scrollRejectTimeout);
      setTimeout(function() { setTimelineScrollEventEnabled(true); }, 300)
    }
  }

  const scrollToDate = async function(date, nthCall: number = 1) {
    const dateHash = Utilities.formatDate(date, "YYYY-MM-DD");
    let el = document.querySelector('div[data-date="' + dateHash + '"]');
    if (el && typeof el.scrollIntoView === "function") {
        setTimelineScrollEventEnabled(false);
        // Scroll so this is in the end of the view (cannot set to beginning because for some reason the whole page shifts down even though overflow hiddens)
        el.scrollIntoView({ block: 'start', inline: 'start' });
        const ionContent = document.getElementById("calendar-wrapper");
        if (ionContent) {
          // @ts-ignore
          ionContent.style.setProperty('--offset-bottom', '0px') // Neds a nudge to prevent that full page shift
        }
        // We scroll to the element by adding to the scrolltop with the total scroll height - padding
        onChange(new Date(date))
        clearTimeout(scrollRejectTimeout);
        setTimeout(function() { setTimelineScrollEventEnabled(true); }, 300)
    } else if (nthCall < 3) {
      setTimeout(function() {
        scrollToDate(date, nthCall + 1);
      }, 200);
    }
  }

  const reopenDraftEvent = async function(autoAdd: boolean) {
    InternalTracker.trackEvent("Colliding Events Dismised")
    store.dispatch(clearResolvedEvents());
    const draftEvent = localStorage.getItem("draftEvent");
    if (draftEvent) {
      // setHideEventDrawer(true);
      let eventObj: Event = JSON.parse(localStorage.getItem("draftEvent"))
      if (eventObj.days) {
        onDayChange(eventObj.days);
      }
      setEventEditedDraft(JSON.parse(JSON.stringify(eventObj)));
      localStorage.removeItem("draftItem"); 
      updateEventCache(null)
      if (autoAdd) {
        setTimeout(function() {
          const autoAddInterval = setInterval(() => {
            const saveEventBtn = document.getElementById("save-event-btn");
            if (saveEventBtn) {
              clearInterval(autoAddInterval);
              saveEventBtn.click();
              // (window as any).toast("Collisions are resolved and new event is saved", "success");
              // setHideEventDrawer(false);
              setLoadingMessage(null);
            }
            // window.location.reload();
          }, 100);
        }, 1000)
      }
    } else {
      setLoadingMessage(null);
      if (!window.location.href.includes("testing=true")) {
        let offerId = localStorage.getItem("collidingEventsOfferId");
        localStorage.removeItem("collidingEventsOfferId");
        if (offerId) {
          if (autoAdd) {
            history.push("/offer/" + offerId + "/autoadd")
          } else {
            history.push("/offer/" + offerId)
          }
        } else {
          history.push("/notifications");
        }
      }
    }
    localStorage.removeItem("collidingEvents");
    setCollidingEventsRef(null);
    setCalendarShrinked(false);
  }

  const loadNextSection = async function(firstDateLoaded: Date, centerDate: Date, scrollToThisDate?: Date, testEvents?: Event[], refreshLastAvailableDate?: boolean, iterationCount?: number, source?: number, overridenClashes?: string[]) {

    let i = 0;

    if (iterationCount) {
      i = iterationCount
    }

    // Iteration limit - this kicks in if there is no dates close to today, but there were some in the past
    if (i && i > 20 && window.location.href.indexOf("testing") === -1 && source !== 10) {
      return;
    }

    if (window.location.href.indexOf("testing") !== -1) {
      firstDateLoaded = new Date("2023-01-01");
      centerDate = new Date("2023-12-30");
    }

    const lastEventDate: Date = await getLastEventDate();
    console.log("loadNextSection(" + firstDateLoaded + ", " + centerDate + ") source: " + source + " firstDateLoaded: " + firstDateLoaded + " lastEventDate: " + lastEventDate);

    // @ts-ignore
    let existingEvents: Object = store.getState().calendar;
    let exitingAvailabilityCircles: Object = store.getState().availability;
    let loadedDays = Object.keys(existingEvents);

    let startBuffer = new Date(firstDateLoaded)
    let endBuffer = source === 10 ? Utilities.dateAdd(firstDateLoaded, "day", Utilities.differenceBetweenDatesDays(firstDateLoaded, centerDate)) : Utilities.dateAdd(centerDate, "day", 65);
    let neededDays: string[] = [];

    while (startBuffer < endBuffer) {
      let dateHash: string = Utilities.formatDate(startBuffer, "YYYY-MM-DD")
      if (loadedDays.indexOf(dateHash) === -1) {
        neededDays.push(dateHash);
      }
      startBuffer = Utilities.dateAdd(startBuffer, "day", 1);
    }

    if (neededDays.length !== 0) {

      setTimelineRange([firstDateLoaded, endBuffer]);

      let firstNewDateNeeded = new Date(neededDays[0]);
      let lastNewDateNeeded = new Date(neededDays[0]);

      for (let i = 0; i < neededDays.length; i++) {
        if (new Date(neededDays[i]) < firstNewDateNeeded) {
          firstNewDateNeeded = new Date(neededDays[i]);
        } else if (new Date(neededDays[i]) > lastNewDateNeeded) {
          lastNewDateNeeded = new Date(neededDays[i])
        }
      }

      let newEvents: Object = await generateEvents(firstNewDateNeeded, lastNewDateNeeded, testEvents, refreshLastAvailableDate);
      // @ts-ignore
      let newTimeline = newEvents.timeline
      // @ts-ignore
      let newAvailabilityCircle = newEvents.availabilityCircle
      // @ts-ignore
      let newEventCount = newEvents.eventCount

      setLastEventCount(newEventCount);

      if (scrollToThisDate) {
        setTimeout(function() { scrollToDate(scrollToThisDate); }, 300)
      }

      // @ts-ignore
      if (newEventCount === 0) {

        if (source === 10) {
          setExtendedTimelineState("loaded");
          setTimeout(function() { scrollToDate(value); }, 300)
          return;
        }

        let lastDayLoaded = new Date(loadedDays[loadedDays.length-1]);
        if (lastEventDate && Utilities.areDatesAreOnSameDay(lastDayLoaded, lastEventDate) || lastDayLoaded > lastEventDate) {

        } else if (lastEventDate) {
          loadNextSection(firstDateLoaded, lastEventDate, null, testEvents, null, ++i, 1, overridenClashes);
        }

      } else {

        if (newEventCount < 8 && scrollToThisDate && lastEventDate) {

          let lastDayLoaded = new Date(loadedDays[loadedDays.length-1]);
          if (lastEventDate && Utilities.areDatesAreOnSameDay(lastDayLoaded, lastEventDate) || lastDayLoaded > lastEventDate) {

          } else if (lastEventDate) {
            loadNextSection(firstDateLoaded, lastEventDate, null, testEvents, null, ++i, 2, overridenClashes);
          }

          return;

        }

        overridenClashes = overridenClashes || []
  
        for (let key in newTimeline) {
          existingEvents[key] = newTimeline[key];
            let overridenCollisions = existingEvents[key].filter(item => item.hasCollisions);
            if (overridenCollisions.length) {
              for (let i = 0; i < existingEvents[key].length; i++) {
                const event = existingEvents[key][i];
                // if (event.hasCollisions) {
                //   existingEvents[key][i].clashingWith = await Utilities.isEventColliding(new Date(event.start), new Date(event.end), event.id, event.eventType, true, false);
                //   console.log("@@@@@@@@ existingEvents[key][i].clashingWith", existingEvents[key][i].clashingWith)
                //   if (existingEvents[key][i].clashingWith) {
                //     overridenClashes = overridenClashes.concat(existingEvents[key][i].clashingWith.map(e => e.id));
                //   }
                //   if (!existingEvents[key][i].clashingWith || !existingEvents[key][i].clashingWith.length) {
                //     console.log("@@@@@@@@@@ ALL RESOLVED< SAVING BACK", existingEvents[key][i])
                //     existingEvents[key][i].hasCollisions = false;
                //     existingEvents[key][i].clashingWith = [];
                //     await saveEvent(existingEvents[key][i]);
                //   }
                // }
              }
            }
        }
    
        for (let key in newAvailabilityCircle) {
          exitingAvailabilityCircles[key] = newAvailabilityCircle[key];
        }
    
        // @ts-ignore
        setEventCount(eventCount + newEvents.eventCount);
        updateTimeline(existingEvents);
        setOverridenClashes([...new Set(overridenClashes)]);

        store.dispatch(updateCalendar(existingEvents));
        store.dispatch(updateAvailabilityCircle(exitingAvailabilityCircles));

        if (source === 10) {
          setExtendedTimelineState("loaded");
          setTimeout(function() { scrollToDate(value); }, 300)
        }

      }

    }

  }

  const handleCollisions = async function() {
    setCalendarShrinked(true);
    setEventCount(0);
    updateTimeline({});
    let collidingEventIds: string[] = (JSON.parse(localStorage.getItem("collidingEvents"))).map(item => item.id);
    let collidingEventDates: string[] = (JSON.parse(localStorage.getItem("collidingEvents"))).map(item => item.date);
    let collidingEvents: Object = [];
    for (let i = 0; i < collidingEventIds.length; i++) {
      const id = collidingEventIds[i];
      const event = await getEventById(id);
      if (event) {
        // let hash = Utilities.formatDate(new Date(event.start), "YYYY-MM-DD");
        let hash = Utilities.formatDate(new Date(collidingEventDates[i]), "YYYY-MM-DD");
        if (!collidingEvents[hash])
          collidingEvents[hash] = []

        collidingEvents[hash].push(event);
      }
    }
    InternalTracker.trackEvent("Colliding Events Shown")
    console.log("COLLIDING EVENTS: ", collidingEvents)
    setCollidingEvents(collidingEvents)
    setCollidingEventsRef(JSON.parse(localStorage.getItem("collidingEvents")))
    store.dispatch(updateCalendar(collidingEvents));
  }

  const reloadEvents = async function(testEvents?: Event[], scrollToThisDate?: Date, loadAll?: boolean) {
    (window as any).lastFirstDateLoaded = null;
    (window as any).lastCenterDate = null;
    console.log("@@@@@@ resset collisions")
    setOverridenClashes([]);
    if (localStorage.getItem("collidingEvents")) {
      handleCollisions();
    } else if (loadAll) {
      setEventCount(0);
      updateTimeline({});
      store.dispatch(updateCalendar({}));
      store.dispatch(updateAvailabilityCircle({}));
      setExtendedTimelineState("loading");
      loadNextSection(new Date(2016, 1, 1), Utilities.dateAdd(new Date(), "year", 5), undefined, undefined, true, undefined, 10, []);
    } else {
      setExtendedTimelineState("unloaded")
      setEventCount(0);
      updateTimeline({});
      store.dispatch(updateCalendar({}));
      store.dispatch(updateAvailabilityCircle({}));
      loadNextSection(Utilities.dateSub(new Date(), "month", 4), scrollToThisDate || new Date(), scrollToThisDate || new Date(), testEvents, true, undefined, 3, []);

      let haveEvents = await hasUndeletedEvents();
      if  (!haveEvents) {
        // @ts-ignore
        window.justHadEmptyTimeline = true
      }
    }
  }

  const generateEvents = async function(timelineStart: Date, timelineEnd: Date, testEvents?: Event[], refreshLastAvailableDate?: boolean): Promise<Object> {
    const res: Object = await Utilities.generateEvents(timelineStart, timelineEnd, testEvents, refreshLastAvailableDate);
    // @ts-ignore
    if (refreshLastAvailableDate && res.inferredUnavailableRanges) {
      // @ts-ignore
      console.log("refreshed inferredUnavailableRanges", res.inferredUnavailableRanges)
      // @ts-ignore
      setInferredUnavailableRanges(res.inferredUnavailableRanges)
    }

    return res;
  }

  // @ts-ignore
  const calendar = useSelector(state => state.calendar)
  // @ts-ignore
  if  (window.justHadEmptyTimeline && !Utilities.isObjectEmpty(calendar)) {
    // @ts-ignore
    window.justHadEmptyTimeline = false
    setTimeout(() => {
      setUserGuideOpenSection("a")
    }, 300)
  }

  // @ts-ignore
  const availability = useSelector(state => state.availability)
  // @ts-ignore
  const syncState = useSelector(state => state.sync);

  return (
    <IonPage data-page="calendar" style={{ '--overflow': 'hidden', overflow: "hidden" }}>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        id="calendar-wrapper" 
        fullscreen 
        ref={contentRef} 
        style={{ 
          '--overflow': 'hidden', 
          overflow: "hidden"
        }}
      >
        {/* <IonContent  style={{ '--overflow': 'hidden', overflow: "hidden" }}> */}
          <div 
            className="calendar-wrapper" 
            data-theme={calendarColor} 
            ref={calendarRef} 
            data-shrinked={calendarShrinked} 
            onTouchStart={(e) => { handleTouchStart(e) }}
            onTouchMove={(e) => { handleTouchMove(e) }}
            style={{
              paddingTop: paddingTop ? paddingTop + "px" : undefined,
              background: modal ? "#3573E6" : undefined
            }}
          >
            { (collidingEventsRef) &&
              <div className="collision-header">
                <h2>{collidingEventsRef.length}x Clashes Found</h2>
                {/* <h2>{collidingEventsRef.length === 1 ? "Resolve 1 clashing event" : "Resolve " + collidingEventsRef.length + " clashing events"} by tapping to edit or delete the clashing events</h2> */}
                <p>Check proposed resolution, or edit</p>
                <IonIcon onClick={() => {
                  InternalTracker.trackEvent("Colliding Events Dismised")
                  localStorage.removeItem("draftItem");
                  localStorage.removeItem("collidingEvents");
                  // localStorage.removeItem("resolvedCollidingEvents");
                  setCollidingEventsRef(null);
                  setCalendarShrinked(false);
                  reloadEvents();
                }} icon={close} />
              </div>
            }
            { (!modal) &&
              <img onClick={() => {
                if (infoAdminCount > 5) {
                    setAdminPanel(true);
                }
                setInfoAdminCount(infoAdminCount+1);
              }} alt="Updatedge Logo" className="logo" src={Logo} /> 
            }
            { (!modal && localStorage.getItem("env") === "dev") &&
              <label className="env" onClick={() => {
                if (infoAdminCount > 5) {
                  setAdminPanel(true);
                }
                setInfoAdminCount(infoAdminCount+1);
              }}>
                DEV
              </label>
            }
            { (!modal && syncState && !localStorage.getItem("collidingEvents")) &&
              <button
                id="sync-btn" 
                onClick={() => { (window as any).Sync.sync() }}
                data-expanded={(syncState.status === "done" || syncState.status === "syncing") && syncState.newPushCount > 0}
                data-text-status={syncState.status}
              >
                { syncState.status === "done" && syncState.newPushCount > 0 &&
                  <span>Availability Just Shared</span>
                }
                { syncState.status === "syncing" && syncState.newPushCount > 0 &&
                  <span>Publishing</span>
                }
                { (syncState.status === "syncing") && <IonIcon style={{ }} slot="start" className="rotate sync" icon={syncCircle} /> }
                { (syncState.status === "error") && 
                  <Tooltip placement="left-start" open={cloudSyncFailedTooltip} onClose={() => {}} onOpen={() => {}} title="No connection - we'll keep trying">
                    <IonIcon style={{ color: "#fb5b5a" }} slot="start" className="sync" icon={cloudOffline} />
                  </Tooltip>
                }
                { (syncState.status === "done") && <IonIcon style={{ color: "#50d890" }} slot="start" className="sync" icon={cloudDone} /> }
              </button>
            }
            { (!modal) &&
              <button id="jump-to-today-btn">
                <IonIcon onClick={() => {
                  onChange(new Date()); 
                  scrollToDate(new Date())
                  InternalTracker.trackEvent("Calendar Navigation Today")
                }} icon={calendarClear} />
                <span onClick={() => {
                  onChange(new Date()); 
                  scrollToDate(new Date())
                }}>{lastOpenedView}</span>
              </button>
            }
            <Calendar
              formatMonthYear={(locale, date) => { return Utilities.formatDate(date, "mms YYYY") }}
              onChange={(date) => { onChange(date); }}
              value={value}
              activeStartDate={value}
              prevLabel={<IonIcon icon={chevronBackOutline} />}
              nextLabel={<IonIcon icon={chevronForwardOutline} />}
              tileClassName={(curr) => {
                let classNames: string = "";
                let dateStr = Utilities.formatDate(curr.date, "YYYY-MM-DD")
                if (selectedDays.indexOf(dateStr) !== -1) {
                  classNames += "selected ";
                }
                if ( !((availability[dateStr] && availability[dateStr].length !== 0)) && Utilities.inRange(inferredUnavailableRanges, curr.date) ) {
                  classNames += "inferred "
                }
                return classNames;
              }}
              tileContent={(curr) => {

                if (curr.view !== "month")
                  return null

                let dateStr = Utilities.formatDate(curr.date, "YYYY-MM-DD")
                let AvailabilityCircleDOM = (availability[dateStr] && availability[dateStr].length !== 0) ? <AvailabilityCircle background={availability[dateStr]} /> : null;
                let selectedDOM =  (selectedDays.indexOf(dateStr) !== -1) && <div className="selected"></div>
                let inferredUnavailabilityDOM = (!AvailabilityCircleDOM && Utilities.inRange(inferredUnavailableRanges, curr.date)) ? <span className="crossed-out" /> : null
                let todayMarkerDOM = (Utilities.areDatesAreOnSameDay(new Date(), curr.date)) ? <div className="today"></div> : null
                let selectedMarkerDOM = (Utilities.areDatesAreOnSameDay(value, curr.date) && !selectedDOM) ? <div className="selected-on-timeline"></div> : null

                if (AvailabilityCircleDOM || inferredUnavailabilityDOM || selectedDOM || selectedMarkerDOM || todayMarkerDOM) {
                  return (
                    <React.Fragment>
                      {AvailabilityCircleDOM}
                      {selectedDOM}
                      {!collidingEventsRef && inferredUnavailabilityDOM}
                      {todayMarkerDOM}
                      {selectedMarkerDOM}
                    </React.Fragment>
                  )
                }

                return null

              }}
              onActiveStartDateChange={(change) => {
                
                if (timelineRange[0] > Utilities.dateSub(change.activeStartDate, "day", 40) && extendedTimelineState === "unloaded") {
                  reloadEvents(undefined, undefined, true);
                } else {
                  loadNextSection(timelineRange[0], change.activeStartDate, undefined, undefined, undefined, undefined, 4, overridenClashes);  
                }
              
                if (change.view === "month") {
                  InternalTracker.trackEvent("Calendar Navigation Month", {
                    direction: (value < change.activeStartDate ? "next" : "previous")
                  })

                  onChange(change.activeStartDate);
                  scrollToSelector('div[data-month="' + Utilities.formatDate(change.activeStartDate, "YYYY-MM") + '"]');
                } else {
                  onChange(change.activeStartDate)
                }

              }}
              onClickDay={(value) => {

                let selectedDate = new Date(value)
                let dateStr = Utilities.formatDate(selectedDate, "YYYY-MM-DD")
                let loc = selectedDays.indexOf(dateStr);

                InternalTracker.trackEvent("Calendar Day " + (loc === -1 ? "Selected" : "Deselected"), {
                  date: selectedDate.toISOString()
                })

                if (loc === -1) {
                  // Selecting date
                  selectedDays.push(dateStr);
                } else {
                  // Deselecting date
                  selectedDays.splice(loc, 1);
                }

                onDayChange(selectedDays)

                if (selectedDays.length === 0) {
                  let firstDayOfMonth = new Date();
                  firstDayOfMonth.setDate(1);
                  setTimeout(function() {
                    onChange(firstDayOfMonth); // new Date()
                  });
                  setTimeout(() => {
                    scrollToSelector('div[data-month="' + Utilities.formatDate(firstDayOfMonth, "YYYY-MM") + '"]');
                  }, 300);
                }

                let selectedDayEventCounts = 0;
                if (timeline) {
                  const KEYS: string[] = Object.keys(timeline)
                  for (let key in KEYS) {
                    if (selectedDays.indexOf(KEYS[key]) !== -1) {
                      selectedDayEventCounts += timeline[KEYS[key]].length;
                    }
                  }
                }

                if (isOpen && !Utilities.manualUserPaging()) {
                  setCurrentStep(1)
                }

                setEventCountSelection(selectedDayEventCounts);

              }}
            />
            { (!modal) &&
              <button id="resize-calendar-btn" onClick={() => { 
                InternalTracker.trackEvent("Calendar " + ((calendarShrinked) ? " Maximize" : " Minimize"))

                setCalendarShrinked(!calendarShrinked) 
              }}>
                <DoubleArrows />
              </button>
            }
          </div>
          <div
            className="timeline" 
            id="timeline" 
            data-height-loaded={timelineHeight > 0 ? "true" : "false"} 
            style={{
              height: collidingEventsRef ? (timelineHeight - 15) : timelineHeight,
              marginTop: collidingEventsRef ? 15 : 0,
              margin: modal ? 0 : undefined
            }} 
            ref={timelineRef} onScroll={(e) => {
            if (collidingEventsRef)
              return;

            clearTimeout(scrollRejectTimeout);
            setTimeout(function() {
              setTimelineScrollEventEnabled(true);
            }, 200)
            if (timelineScrollEventEnabled) {
              // @ts-ignore
              setTimelineScrollEventEnabled(false);

              // @ts-ignore
              if (e.target.scrollTop === 0) {
                reloadEvents(undefined, undefined, true);
                return;
              }

              // @ts-ignore
              if (e.target.scrollTop + e.target.clientHeight + timelineHeight + 240 > e.target.scrollHeight) {
                // Load more at the bottom of the list
                loadNextSection(timelineRange[0], value, undefined, undefined, undefined, undefined, 5, );
              }
              
              if (selectedDays.length === 0) {
                let els = document.querySelectorAll(".event-timeline-item-wrapper");
                if (els) {
                  for (let i = 0; i < els.length; i++) {
                    const element = els[i]
                    if (element instanceof HTMLElement && e.currentTarget instanceof HTMLElement) {
                      // position of the element - fixed height of timeline > scrolled height of the timeline
                      if (element.offsetTop + 60 /* calendarRef.current.clientHeight */ > e.currentTarget.scrollTop) {
                        if (element.clientHeight === 0) {
                          continue;
                        }
                        let dateHash = element.getAttribute("data-date");
                        let date = new Date(dateHash);
                        if (!Utilities.areDatesInSameMonth(date, value)) {
                          loadNextSection(timelineRange[0], value, undefined, undefined, undefined, undefined, 6, overridenClashes);
                        }
                        if (!Utilities.areDatesAreOnSameDay(date, value)) {
                          onChange(date)
                        }
                        break;
                      }
                    }
                  }
                }
              }
            }
          }}>
          { (overridenClashes.length !== 0 && !clashBannerTemporarilyHidden) &&
            <div
              className='error-banner'
              onClick={() => {
                setClashBannerTemporarilyHidden(true);
                const firstCollision = document.querySelector('.event-item[data-has-any-collisions="true"]');
                if (firstCollision) {
                  firstCollision.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
                }
              }}
            >
              <IonIcon icon={alertCircle} />
              <p>
                <span>An auto-inserted confirmed offer caused a clash in your timeline, tap to see.</span>
                <span>Edit, or remove the colliding events to resolve</span>
              </p>
            </div>
          }
          {/* { (collidingEventsRef) &&
            <div>
              <h2 style={{
                textAlign: 'center',
                fontSize: 20
              }}>Currently in your calendar:</h2>
            </div>
          } */}
          <div className="spinner"></div>
          { Object.keys(calendar).map(key => {

            let day = calendar[key];

            if (selectedDays.length !== 0 && selectedDays.indexOf(key) === -1) {
              return null;
            }

            let availableEvents = day.filter(item => item.eventType === 4);
            const inferredUnavailabilityRange = Utilities.inRange(inferredUnavailableRanges, new Date(key));
            let inferredAvailability = (selectedDays.length !== 0 && availableEvents.length === 0 && inferredUnavailabilityRange) ? <div className="inferred" onClick={() => { setEventEditingDates([key]) }}>Inferred as unavailable due to an available date ({ (Utilities.differenceBetweenDatesDays(new Date(), inferredUnavailabilityRange.end) > 1820) ? "end of time" : Utilities.formatDate(inferredUnavailabilityRange.end, "d mms YYYY")})</div> : null
            // let isLastAvailableDate = day.find(item => item.lastAvailableDate) ?  // TODONOW restore from event compiler
            //   <div 
            //     className='last-available-date'
            //     onClick={() => {
            //       if (isOpen) {
            //         setTimeout(() => {
            //           setCurrentStep(1)
            //         }, 700)
            //       }
            //       setEventEditingId(day.find(item => item.lastAvailableDate).id) 
            //       setEventEditingIdRepeatingDate(date) 
            //     }}
            //   >
            //     <IonIcon icon={alertCircle} />
            //     <p>Hidden “I’m available” under this event is affecting your earlier availability; Tap here to Edit or Delete</p>
            //   </div> : null;

            let date = new Date(key);

            let timestamp = <div className="day-header">
              <div className="weekday">{Utilities.formatDate(date, "ds")}</div>
              <div className="day">{Utilities.formatDate(date, "D")}</div>
              <div className="month-year">{Utilities.formatDate(date, "mms YYYY")}</div>
            </div>

            // No events on this day, but need to render the references so we can jump around with the calendar
            if (day.length === 0) {
              return <div className={"event-timeline-item-wrapper " + (inferredAvailability ? "" : "empty")} data-date={key} data-month={key.substr(0, 7)} key={key}> { (inferredAvailability) && timestamp } <div className="events"> { inferredAvailability } </div> </div>;
            }

            return (
              <div data-date={key} key={key} data-month={key.substr(0, 7)} className="event-timeline-item-wrapper">
                { timestamp }
                <div className="events">
                  { !collidingEventsRef && inferredAvailability }
                  { day.map((item, i) => {
                    const justEdited: boolean = justChangedIds.filter(jcId => item.id.startsWith(jcId)).length > 0;
                    // todonow update to range
                    // if (isLastAvailableDate !== null && item.eventType === 4 && day.find(item => item.eventType !== 4)) {
                    //   return isLastAvailableDate;
                    // }
                    return (
                        <EventTimelineItem 
                          collisionHandling={collidingEventsRef ? true : false} 
                          outstandingCollisionTarget={overridenClashes.indexOf(item.id) !== -1}
                          key={item.id} 
                          justEdited={justEdited} 
                          event={item} 
                          onEdit={() => {
                            if (isOpen) {
                              setTimeout(() => {
                                setCurrentStep(1)
                              }, 700)
                            }
                            setEventEditingId(item.id) 
                            setEventEditingIdRepeatingDate(date) 
                          }} 
                        />
                    )
                  }) }
                </div>
              </div>
            )

          }) }
          { (selectedDays.length === 0) && 
            <div style={{ height: timelineHeight - 60 }} className="end">

            </div>
          }
        </div>
        { (collidingEventsRef) &&
          <div className="bottom-fab-options" data-height-loaded={timelineHeight > 0 ? "true" : "false"}>
            <button className='add-event-btn' style={{
              height: 52,
              flexBasis: "50%",
              marginRight: 10
            }} onClick={() => {
              reopenDraftEvent(false)
            }}>
              { (localStorage.getItem("collidingEventsOfferId")) ? "Go back to Offer" : "Go back and edit new event" }
            </button>
            <button style={{
              height: 52,
              flexBasis: "50%"
            }} onClick={ async () => {
              const collidingEvents = JSON.parse(localStorage.getItem("collidingEvents") || "[]");
              const draftEventInstances = JSON.parse(localStorage.getItem("draftEventInstances") || "[]");
              console.log(collidingEvents, draftEventInstances)
              if (collidingEvents.length !== 0 && (draftEventInstances.length !== 0 || localStorage.getItem("newOfferEventsCount"))) {
                setTipsGuideSectionContent({
                  numberOfAdds: draftEventInstances.length || localStorage.getItem("newOfferEventsCount"),
                  numberOfDeletes: collidingEvents.filter(ce => ce.resolutionMethod === "delete").length,
                  numberOfAmends: collidingEvents.filter(ce => ce.resolutionMethod === "merge").length,
                  numberOfConfirmedOffers: [...new Set(collidingEvents.filter(ce => ce.disallowedMergeReason === "offer").map(ce => ce.name))].length
                })
                setTipsGuideSection("proposed-resolutions");
              }
            }} className="add-event-btn">Accept Proposed Resolution</button>
          </div>
        }
        { (!calendarShrinked) &&
          <div className="bottom-fab-options" data-height-loaded={timelineHeight > 0 ? "true" : "false"}>
            { (!modal) &&
              <button className="recents-btn left-btn" onClick={() => { 
                InternalTracker.trackEvent("Help Progress Modal Opened", {
                  manual: true
                })
                setUserGuideOpenSection("a");
              }}>
                <IonIcon icon={helpCircleSharp} />
                { (uncompletedSteps !== 0 || !confidentInAvailability) &&
                  <div className="badge">
                    {uncompletedSteps + (confidentInAvailability ? 0 : 1)}
                  </div>
                }
              </button>
            }
            <button style={{
              backgroundColor: selectedDays.length ? "#1bb161" : "#83bcff",
              height: modal ? 48 : undefined,
              flexBasis: modal ? "100%" : undefined
            }} className={(selectedDays.length ? "pulse no-size " : "") + "add-event-btn"} onClick={() => {
              if (selectedDays.length !== 0) {

                Utilities.showTourPaging(false);
                setTimeout(() => {
                  setCurrentStep(2);
                }, 700)

                setEventEditingDates(selectedDays)

                InternalTracker.trackEvent("Add Events Started")

              } else {
                (window as any).toast("Tap some dates on your calendar to add events to");
              }
            }}>{ (selectedDays.length > 1) ? ("Add to " + selectedDays.length + " days") : (((selectedDays.length !== 0) ? ("Add to " + Utilities.formatDate(new Date(selectedDays[0]), "d mms YYYY")) : "Select days on calendar" )) }</button>
            { (selectedDays.length !== 0 && !modal) &&
              <IonIcon onClick={() => {

                InternalTracker.trackEvent("Add Events Cleared")

                onDayChange([]) 
                setEventCountSelection(0);
                const now = new Date();;
                onChange(now);

                setTimeout(() => {
                  scrollToSelector('div[data-month="' + Utilities.formatDate(now, "YYYY-MM") + '"]');
                  reloadEvents();
                }, 300);

              }} id="clear-selection-btn" icon={closeCircle} />
            }
            { (!modal) &&
              <button className="favorites-btn right-btn" onClick={() => {

                Utilities.showTourPaging(false);

                setTimeout(() => {
                  setCurrentStep(2)
                }, 700)

                InternalTracker.trackEvent("Recent-Favorite Events Opened");

                setRecentEventsDrawer("favorites"); 

              }}>
                <IonIcon icon={star} />
              </button>
            }
          </div>
        }
        <UpgradeDrawer
        
        />
        { !tipsGuideSection && !collidingEventsRef && !calendarShrinked && !modal && 
          <OnboardingGuide
            section="calendar"
            haveSelectedDays={selectedDays.length !== 0}
            modal={modal}
          />
        }
        <RecentEventsDrawer
          isModalOpen={recentEventsDrawer}
          presentingElement={contentRef}
          onClose={(eventId) => {
            
            setRecentEventsDrawer(null);

            if (eventId) {

              if (selectedDays.length === 0) {
                (window as any).toast("Tap some dates on your calendar to add events to")
                return;
              }

              setTimeout(function() {
                setAddSource("recents");
                setEventEditingId(eventId);
                setEventEditingDates(selectedDays.length !== 0 ? selectedDays : [Utilities.formatDate(value, "YYYY-MM-DD")])
                setRecentEventAddModeSelected("amend");
              }, 300);

            }

          }}
        />
        <EventEditorDrawer
          onboardingModal={modal}
          presentingElement={contentRef}
          dates={eventEditingDates ? eventEditingDates.sort((a, b) => { return new Date(a) < new Date(b) ? -1 : 1 }) : eventEditingDates}
          id={eventEditingId ? eventEditingId.split("|")[0] : null}
          draft={eventEditedDraft}
          clashingEdit={collidingEventsRef !== null}
          recentEventAddMode={recentEventAddModeSelected}
          dateInstance={eventEditingIdRepeatingDate}
          eventT={null}
          source={addSource}
          openAnother={(newEventId) => {
            setEventEditingId(null);
            setTimeout(() => {
              setEventEditingId(newEventId)
            }, 300)
          }}
          hideContent={hideEventDrawer}
          onClose={ async (changedCount, changedIds, allChangedIds, changedDate, isDeleted?, standaloneUnrepeatedEventType?) => {
            if (onEventsAdded && changedCount && !isDeleted) {
              onEventsAdded();
            }
            
            setClashBannerTemporarilyHidden(false);

            if (standaloneUnrepeatedEventType !== null && (window as any).unRepeatedEventTypeCounts) {
              if (!(window as any).unRepeatedEventTypeCounts[standaloneUnrepeatedEventType]) {
                (window as any).unRepeatedEventTypeCounts[standaloneUnrepeatedEventType] = 0;
              }
              (window as any).unRepeatedEventTypeCounts[standaloneUnrepeatedEventType]++;
              if ((window as any).unRepeatedEventTypeCounts[standaloneUnrepeatedEventType] > 2) {
                if (!((window as any).Cypress)) {
                  setTipsGuideSection("events-repeat");
                }
                InternalTracker.trackEvent("Repeated Events Tips Guide Opened");
                (window as any).unRepeatedEventTypeCounts = null
              }
            }

            setEventEditingId(null);
            setRecentEventAddModeSelected(null);
            setEventEditingDates(null);
            setEventEditedDraft(null);
            setAddSource(null);

            store.dispatch(refreshOnboardingGuide());

            if (changedIds) {
              setJustChangedIds(changedIds as string[]);
              if (localStorage.getItem("collidingEvents") && allChangedIds) {
                store.dispatch(updateResolvedEvents(allChangedIds));
              }
            }

            if (changedCount === null) {
              reloadEvents();
            } else if (changedCount) {

              if (!localStorage.getItem("collidingEvents")) {
                setTimeout(() => {
                  (window as any).Sync.sync();
                }, 250)
              }

              if (changedDate) {
                reloadEvents(null, changedDate);
              } else {
                reloadEvents();
              }

              if (isDeleted) {
                setToastMessage( (!isDeleted) ? (changedCount + " event" + (changedCount === 1 ? " is" : "s are")  + " saved") : "Event deleted" )
                onChange(new Date()); 
                scrollToDate(new Date())
              }
              onDayChange([])
              setEventCountSelection(0);
            }

            if (
              localStorage.getItem("collidingEvents") && 
              localStorage.getItem("draftEventInstances") &&
              changedCount !== null
            ) {
              let refreshedCollisions = [];
              let draftEventInstances = JSON.parse(localStorage.getItem("draftEventInstances"));
              for (let i = 0; i < draftEventInstances.length; i++) {
                const draftEventInstance = draftEventInstances[i];
                const collisions = await Utilities.isEventColliding(new Date(draftEventInstance.start), new Date(draftEventInstance.end), draftEventInstance.id, draftEventInstance.type, true, draftEventInstance.repeatForever);
                if (typeof collisions !== "string" && collisions.length > 0) {
                  refreshedCollisions = refreshedCollisions.concat(collisions.filter(item => item))
                }
              }
              if (refreshedCollisions.length === 0) {
                localStorage.removeItem("collidingEvents");
                localStorage.removeItem("draftEventInstances");
                (window as any).toast("All conflicts have resolved", "success");
                reopenDraftEvent(true)
              } else {
                localStorage.setItem("collidingEvents", JSON.stringify(refreshedCollisions));
                window.location.reload();
              }
            }

            if (
              localStorage.getItem("collidingEvents") && 
              localStorage.getItem("collidingEventsOfferId") &&
              localStorage.getItem("offerEvents") &&
              changedCount !== null
            ) {
              let offerEvents = JSON.parse(localStorage.getItem("offerEvents"));
              let collisions: CollidingEvent[] = [];

              for (let i = 0; i < offerEvents.length; i++) {
                const offerEvent = offerEvents[i];
                let thisCollisions = await Utilities.isEventColliding(new Date(offerEvent.start), new Date(offerEvent.end), "0", offerEvent.eventType);
                if (typeof thisCollisions !== "string" && thisCollisions[0]) {
                  for (let j = 0; j < thisCollisions.length; j++) {
                    const tc = thisCollisions[j];
                    const tci = collisions.findIndex(c => c.id === tc.id);
                    offerEvent.title = "Incoming Offer Event"
                    offerEvent.eventType = 1;
                    if (tci !== -1) {
                      collisions[tci].collidingWithOfferEvents.push(JSON.parse(JSON.stringify(offerEvent)));
                    } else {
                      tc.collidingWithOfferEvents = [JSON.parse(JSON.stringify(offerEvent))];
                      collisions.push(tc);
                    }
                  }
                }
              }

              if (collisions.length === 0) {
                localStorage.removeItem("collidingEvents");
                localStorage.removeItem("draftEventInstances");
                (window as any).toast("All conflicts have resolved", "success");
                reopenDraftEvent(true)
              } else {
                localStorage.setItem("collidingEvents", JSON.stringify(collisions));
                window.location.reload();
              }

            }
            
          }}
        />
        <IonToast
          isOpen={toastMessage !== null}
          onDidDismiss={() => setToastMessage(null)}
          message={toastMessage}
          position="top"
          duration={2200}
          color="success"
        />
        { (!timelineP) &&
          <IonLoading
            isOpen={loadingMessage !== null || extendedTimelineState === "loading"}
            onDidDismiss={() => {
              setLoadingMessage(null)
              setExtendedTimelineState("loaded")
            }}
            message={extendedTimelineState === "loading" ? "Loading historical data" : loadingMessage}
            duration={12000}
          />
        }
        { tipsGuideSection &&
          <TipsGuidePrompt 
            section={tipsGuideSection}
            sectionContents={tipsGuideSectionContent}
            onClose={ async (actioned: boolean) => {
              setTipsGuideSection(null);
              if (actioned) {
                if (tipsGuideSection === "proposed-resolutions") {
                  InternalTracker.trackEvent("Colliding Events Auto Resolve Tapped")
                  setLoadingMessage("Resolving colliding events...");
                  const resolveRes = await Utilities.autoResolveCollodingEvents();
                  updateEventCache(null);
                  localStorage.removeItem("collidingEvents");
                  localStorage.removeItem("newOfferEventsCount");
                  // localStorage.removeItem("draftEvent");
                  setTimeout(() => {
                    reopenDraftEvent(true)
                  })
                } else {
                  localStorage.setItem("userGuideOpenSection", tipsGuideSection);
                  setUserGuideOpenSection(tipsGuideSection);
                  console.log("@@@ Set profile guide section to: " + tipsGuideSection)
                }
              }
            }}
          />
        }
        { (!modal) &&
          <React.Fragment>
            { (!((window as any).Cypress)) &&
              <ProfileProgress 
                triggerUpdate={triggerProfileProgressBadgeUpdate}
                onNumberOfUncompletedUpdate={(count) => {
                  setUncompletedSteps(count);
                  setTriggerProfileProgressBadgeUpdate(false);
                }}
                onAvailabilityConfidenceUpdate={(confident) => {
                  console.log("CONFIDENT: " + confident)
                  setConfidentInAvailability(confident)
                }}
              />
            }
            { userGuideOpenSection !== null &&
              <ProfileProgress
                onNumberOfUncompletedUpdate={(count) => {
                  setUncompletedSteps(count);
                  setTriggerProfileProgressBadgeUpdate(false);
                }}
                explicitOpen={true}
                onClosed={() => {
                  setUserGuideOpenSection(null);
                }}
                autoSelectSection={userGuideOpenSection}
                onAvailabilityConfidenceUpdate={(confident) => {
                  console.log("CONFIDENT: " + confident)
                  setConfidentInAvailability(confident)
                }} 
              />
            }
          </React.Fragment>
        }
          <IonActionSheet
            isOpen={adminPanel}
            onDidDismiss={() => { setAdminPanel(null) }}
            buttons={[
                {
                  text: "Set Date to next Prompt Schedule (" + localStorage.getItem("onboardingPromptScheduleNextDate") + ")",
                  handler: () => {
                    const timestamp = Math.floor(new Date(localStorage.getItem("onboardingPromptScheduleNextDate")).getTime() / 1000 + 43200);
                    localStorage.setItem("artificialDate", timestamp.toString());
                    window.location.href = "/";
                  }
                },
                {
                  text: "Set Artificial Date",
                  handler: () => {
                    const date = prompt("Date (YYYY-MM-DD)");
                    const timestamp = Math.floor(new Date(date).getTime() / 1000);
                    localStorage.setItem("artificialDate", timestamp.toString());
                    window.location.href = "/";
                  }
              },
              {
                  text: "Remove Artificial Date",
                  handler: () => {
                    localStorage.removeItem("artificialDate");
                    window.location.href = "/";
                  }
              },
              {
                  text: 'Disable Internal Tracking',
                  handler: () => { 
                    localStorage.setItem("disableTracking", "true") 
                    window.location.href = "/";
                  }
              },
              {
                text: 'Jump to Test Date',
                handler: () => { 
                  const urlParams = new URLSearchParams(window.location.href);
                  if (urlParams.get("year")) { onChange(new Date(parseInt(urlParams.get("year")), 11, 15)); }
                  setAdminPanel(null);
                }
              }
            ]}
          />
      </IonContent>
    </IonPage>
  );
};

export default CalendarTimeline;
