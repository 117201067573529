import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import gbLocale from "date-fns/locale/en-GB";
import { IonHeader, IonToolbar, IonTitle, IonContent, IonAlert, IonFab, IonFabButton, IonIcon, IonLabel, IonModal, IonSpinner, IonTextarea, IonToggle } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { repeatSharp, pencilSharp, locationSharp, addCircle, checkmarkOutline, close, create, moonOutline, repeatOutline, star, trash, caretDownCircle, layers, checkmarkCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import UserAPI from '../../../apis/User';
import BriefcaseIcon from '../../../icons/Briefcase';
import DateUnavailableIcon from '../../../icons/DateUnavailable';
import WaveIcon from '../../../icons/Wave';
import { clearTimelineCache, updateTimelineCache } from '../../../state/actions/TimelineCache';
import store from '../../../state/store';
import { getEventById, getEventsByGroupId, saveEvent, getSuggestions } from '../../../stores/Event';
import { CollidingEvent, Event } from '../../../types/Event';
import { TimePreset } from '../../../types/TimePreset';
import { User, UserDTO } from '../../../types/User';
import Utilities from '../../../Utilities';
import InlineSelect from '../InlineSelect';
import InternalTracker from '../../../InternalTracker';
import Configuration from '../../../Configuration';
import LocationPreview from '../LocationPreview';
import { Plugins } from '@capacitor/core';
import PadlockClosed from '../../../icons/PadlockClosed';
import PadlockOpen from '../../../icons/PadlockOpen';
import { useTour } from '@reactour/tour'
import TipsGuidePrompt from '../TipsGuidePrompt';
import { Keyboard } from '@capacitor/keyboard';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface EventEditorDrawerProps {
    dates: string[];
    id: string;
    draft?: Event; // Event draft to be restored
    clashingEdit?: boolean;
    eventT?: Event; // Directly load event for testing
    onClose?: (number, [], [], Date?, boolean?, booleana?) => void;
    presentingElement?: any,
    recentEventAddMode?: string, // Whether to add events right after load or let them amend
    dateInstance?: Date // Date when the repeating event is clicked
    source?: string // Source of the event
    openAnother?: (string) => void,
    hideContent?: boolean,
    onboardingModal?: boolean
}

const PlaceFields = [
    'address_components',
    'geometry',
    'icon',
    'name',
    'place_id'
];

const DEFAULT_EVENT:Event = {
    "deleted": false,
    "updatedAt": "",
    "createdAt": "",
    "version": "",
    "id": "",
    "orderingUpdatedDate": "",
    "groupId": "",
    "pinned": false,
    "notes": "",
    "googlePlacesId": "",
    "repeatType": 0,
    "isSynced": false,
    "repeatForever": false,
    "repeatUntil": "",
    "end": "",
    "start": "",
    "eventType": 4,
    "title": "",
    "userId": ""
}

const DEFAULT_PRESETS = [
    {
        name: "All Day",
        startHour: 8,
        startMinute: 0,
        endHour: 17,
        endMinute: 0,
    },
    {
        name: "AM",
        startHour: 8,
        startMinute: 0,
        endHour: 12,
        endMinute: 0,
    },
    {
        name: "PM",
        startHour: 13,
        startMinute: 0,
        endHour: 17,
        endMinute: 0,
    },
    {
        name: "Overnight",
        startHour: 22,
        startMinute: 0,
        endHour: 6,
        endMinute: 0,
    }
]

const EventEditorDrawer: React.FC<EventEditorDrawerProps> = ({ dates, id, onClose, openAnother, presentingElement, eventT, recentEventAddMode, dateInstance, draft, clashingEdit, source, hideContent, onboardingModal }) => {

    let isModalOpen:boolean = (dates !== null || id !== null || draft !== null || eventT !== null);
    const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()
    const [editedEvent, updateEditedEvent] = useState <Event> (null); // The unsaved event
    const [drawerTitle, setDrawerTitle] = useState <string> ("Editing 1 event");  // Title of the drawer indicating what is being edited
    const [errorMessage, setErrorMessage] = useState <string> (null); // Validation error toast
    const [collidingEventsError, setCollidingEventsError] = useState <string> (null); // Colliding event error
    const [collidingEvents, setCollidingEvents] = useState <CollidingEvent[]> (null); // Colliding events
    // const [formattedAddress, setFormattedAddress] = useState <string> ("") // Formatted address obtained from the google place id;
    const [updateEventTitlePrompt, setUpdateEventTitlePrompt] = useState <string | boolean> (false) // Prompt whether to update the title of an event when type changes
    const [hasTypeChanged, setHasTypeChanged] = useState <boolean> (false) // Whether type has changed
    const [groupDeleteConfirmAlert, setGroupDeleteConfirmAlert] = useState <number> (0) // Confirmation whether to only delete the selected or all events in the group
    const [groupRepeatDeleteConfirmAlert, setGroupRepeatDeleteConfirmAlert] = useState <number> (0) // Confirmation whether to only delete the selected or all events in the repeat group
    const [groupEditConfirmAlert, setGroupEditConfirmAlert] = useState <number> (0) // Confirmation whether to only edit the selected or all events in the group
    const [repeatEditConfirmAlert, setRepeatEditConfirmAlert] = useState <boolean> (false) // Confirmation whether to only edit the selected or all events in this repeat pattern
    const [newlySelectedGooglePlace, setNewlySelectedGooglePlace] = useState <object> (null) // Confirmation whether to only edit the selected or all events in this repeat pattern
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState <boolean> (false) // Whether Google Maps is loaded
    const [timePresets, setTimePresets] = useState <TimePreset[]> (DEFAULT_PRESETS) // User settings
    const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false) // Whether the keyboard is showing
    const [contentHeight, setContentHeight] = useState <string> (null);
    const [showLocationSection, setShowLocationSection] = useState <boolean> (true);
    const [showNotesSection, setShowNotesSection] = useState <boolean> (true);
    const [showRepeatsSection, setShowRepeatsSection] = useState <boolean> (true);
    const [eventSuggestions, setEventSuggestions] = useState <Event[]> ([]);
    const [eventNameFocused, setEventNameFocused] = useState <boolean> (false);
    const [sameGroupEvents, setSameGroupEvents] = useState <Event[]> ([]);
    const [repeatStartOverride, setRepeatStartOverride] = useState<string | null> (null);
    const [readOnlyEvent, setReadOnlyEvent] = useState<boolean> (false);
    const [preSavePreventedConfig, setPreSavePreventedConfig] = useState <{ confirmedGroupEdit?: boolean, confirmedRepeatEdit?: boolean, resetTitle?: boolean, readOnlyEvent?: boolean, confirmOvernight?: boolean }> (null);
    const [overnightConfirm, setOvernightConfirm] = useState <boolean> (false);
    const contentRef = useRef(null);
    const [hideExternalOrgImage, setHideExternalOrgImage] = useState <boolean> (null)
    const googleMapsAutocompleteInput = useRef(null);
    const history = useHistory();
    const [tipsGuideSection, setTipsGuideSection] = useState <string> (null); // Section of the tips guide
    const [keyBoardHeight, setKeyBoardHeight] = useState <number> (0); // Height of keyboard to calculate the height of the drawer

    useEffect(() => {
        if (editedEvent) {
            let actualDate = new Date(repeatStartOverride);
            let oldDateStart = new Date(editedEvent.start);
            let oldDateEnd = new Date(editedEvent.end);
            oldDateStart.setFullYear(actualDate.getFullYear());
            oldDateStart.setMonth(actualDate.getMonth(), actualDate.getDate());
            oldDateEnd.setFullYear(actualDate.getFullYear());
            oldDateEnd.setMonth(actualDate.getMonth(), actualDate.getDate());
            console.log(oldDateStart + " -> " + oldDateEnd)
            updateEditedEvent({
                ...editedEvent, 
                start: oldDateStart.toISOString(),
                end: oldDateEnd.toISOString(),
                startAsDate: new Date(oldDateStart),
                endAsDate: new Date(oldDateEnd)
            }); 
        }
    }, [repeatStartOverride])

    useEffect(() => {
        if ((window as any).os !== "web") {
            Keyboard.addListener('keyboardWillShow', (info) => {
                setKeyboardOpen(true)
                setKeyBoardHeight(info.keyboardHeight);
            });
            Keyboard.addListener('keyboardWillHide', () => {
                setKeyboardOpen(false)
            });
        }
    }, [])

    const scrollToSelectedGroupDay = (sameGroupEvents: Event[], eventId) => {
        if (sameGroupEvents.length) {
            const selected = sameGroupEvents.find(day => day.id === eventId);
            if (selected) {
                setTimeout(() => {
                    const el = document.querySelector(".days .day[data-id='" + selected.id + "']");
                    if (el) {
                        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                    }
                }, 300)
            }
            console.log("@@@@@@@@@", selected)
        }
    }

    /**
     * Sets the error message for collision
     * @param collisions colliding events
     */
    const handleCollisions = function(collisions: CollidingEvent[]) : void {

        console.log("HANDING: @@@",  collisions, ">>>>>>>")

        if (!collisions[0]) {
            setErrorMessage("Sorry you can’t currently add events in the past more than 2 years from today");
            scrollToBottom();
        } else {
            setCollidingEvents(collisions);
            if (collisions.length === 1) {
                setCollidingEventsError("This event is clashing with an existing event (" + Utilities.formatDate(new Date(collisions[0].date), "d mms YYYY") + ")")
            } else {
                let datesStr: string = ""
                for (let i = 0; i < collisions.length; i++) {
                    datesStr += Utilities.formatDate(new Date(collisions[i].date), "d mms YYYY")
                    if (i + 1 !== collisions.length) datesStr += ", ";
                }
                setCollidingEventsError("This event is clashing with " + collisions.length + " existing events (" + datesStr + ")")
            }
        }
    
    }

    /**
     * Saved collidion events to be passed back for review
     * @param collisions colliding events
     */
    const reviewCollidingEvents = function(collisions: CollidingEvent[], days: string[]) : void {
        editedEvent.days = days;
        localStorage.setItem("draftEvent", JSON.stringify({
            ...editedEvent,
            //dates: dates
        }));
        localStorage.setItem("collidingEvents", JSON.stringify(collisions));
        localStorage.setItem("draftEventInstances", JSON.stringify((window as any).draftEventInstances));
        onClose(null, [], []);
    }

    /**
     * Deletes the currently open event
     * @param deleteAllInGroup whether to delete all events in the same group
     * @returns status
     */
    const deleteEvent = async function(deleteAllInGroup?: boolean, deleteJustThisInRepeatGroup?: boolean): Promise<void> {

        if (deleteAllInGroup) {

            let sameGroupEvents: Event[] = editedEvent.groupId ? await getEventsByGroupId(editedEvent.groupId) : [];

            for (let i = 0; i < sameGroupEvents.length; i++) {
                let element = sameGroupEvents[i];
                element.deleted = true;
                element.updatedAt = (new Date()).toISOString();
                let saveEventRes = await saveEvent(element);
                console.log("SAVED", saveEventRes)
            }

            onClose(sameGroupEvents.length, null, [sameGroupEvents.map(item => item.id)], dateInstance, true)

        } else if (deleteJustThisInRepeatGroup) {

            let event = editedEvent
            const REPEAT_STARTED_TIME: Date = new Date(event.start);
            const REPEAT_ENDED_TIME: Date = new Date(event.end);
            const REPEAT_UNTIL: Date = event.repeatForever ? null : new Date(event.repeatUntil);

            let originalEvent = await getEventById(editedEvent.id);
            let sameGroupEvents: Event[] = editedEvent.groupId ? await getEventsByGroupId(editedEvent.groupId) : [];
            setSameGroupEvents(sameGroupEvents)
            scrollToSelectedGroupDay(sameGroupEvents, editedEvent.id);

            if (Utilities.areDatesAreOnSameDay(REPEAT_STARTED_TIME, dateInstance)) {

                // Increasing the start date on the original repeating event, no delete is required
                if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                    originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "day", 1).toISOString();
                    originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "day", 1).toISOString();
                } else if (editedEvent.repeatType === 3) {
                    originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "week", 1).toISOString();
                    originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "week", 1).toISOString();
                } else if (editedEvent.repeatType === 4) {
                    originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "month", 1).toISOString();
                    originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "month", 1).toISOString();
                }

                // Only one day left of repetition, so making that one not repeating
                if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), REPEAT_UNTIL)) { 
                    originalEvent.repeatType = 0;
                    originalEvent.repeatUntil = null;
                }

                console.log(originalEvent);
                let saveEventRes = await saveEvent(originalEvent);
                console.log("SAVED", saveEventRes)
                onClose(1, [originalEvent.id], [originalEvent.id], dateInstance, null);

            } else if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(dateInstance, REPEAT_UNTIL)) {

                // Decrease the end date on the original repeating event
                if (REPEAT_UNTIL) {
                    if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                        originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "day", 1).toISOString();
                    } else if (editedEvent.repeatType === 3) {
                        originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "week", 1).toISOString();
                    } else if (editedEvent.repeatType === 4) {
                        originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "month", 1).toISOString();
                    }
                }

                // Only one day left of repetition, so making that one not repeating
                if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), new Date(originalEvent.repeatUntil))) { 
                    originalEvent.repeatType = 0;
                    originalEvent.repeatUntil = null;
                }

                console.log(originalEvent);

                let saveEventRes = await saveEvent(originalEvent);
                console.log("SAVED", saveEventRes)
                onClose(1, [originalEvent.id], [originalEvent.id], dateInstance, null);

            } else {

                let firstHalfRepetition = JSON.parse(JSON.stringify(originalEvent));
                let lastHalfRepetition = JSON.parse(JSON.stringify(originalEvent));

                // Repeat right until the start of the deleted repeat instance
                if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                    firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "day", 1)).toISOString();
                } else if (editedEvent.repeatType === 3) {
                    firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "week", 1)).toISOString();
                } else if (editedEvent.repeatType === 4) {
                    firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "month", 1)).toISOString();
                }
                // Only one day left of repetition, so making that one not repeating
                if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(firstHalfRepetition.repeatUntil), new Date(firstHalfRepetition.start))) { 
                    firstHalfRepetition.repeatType = 0;
                    firstHalfRepetition.repeatUntil = null;
                }

                let saveEventRes = await saveEvent(firstHalfRepetition);
                console.log("SAVED", saveEventRes)

                // Start a day after the deleted repetition and repeat until the original end date
                if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                    lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "day", 1).toISOString();
                    lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "day", 1).toISOString();
                } else if (editedEvent.repeatType === 3) {
                    lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "week", 1).toISOString();
                    lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "week", 1).toISOString();
                } else if (editedEvent.repeatType === 4) {
                    lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "month", 1).toISOString();
                    lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "month", 1).toISOString();
                }

                if (REPEAT_UNTIL) {
                    lastHalfRepetition.repeatUntil = REPEAT_UNTIL.toISOString();
                    if (Utilities.areDatesAreOnSameDay(new Date(REPEAT_UNTIL), new Date(lastHalfRepetition.end))) {
                        lastHalfRepetition.repeatType = 0;
                        lastHalfRepetition.repeatUntil = null;
                    }
                }

                lastHalfRepetition.id = Utilities.uuidv4();
                // lastHalfRepetition.groupId = Utilities.uuidv4();

                saveEventRes = await saveEvent(lastHalfRepetition);
                console.log("SAVED", saveEventRes)

                console.log(firstHalfRepetition, lastHalfRepetition);

                onClose(1, [firstHalfRepetition.id], [firstHalfRepetition.id, lastHalfRepetition.id], dateInstance, null);

            }

        } else {

            const changedEvent = Object.assign({}, editedEvent);
            changedEvent.deleted = true;
            changedEvent.updatedAt = (new Date()).toISOString();
            let saveEventRes = await saveEvent(changedEvent);
            console.log("SAVED", saveEventRes)
            onClose(1, [changedEvent.id], [changedEvent.id], dateInstance, true);

        }

        InternalTracker.trackEvent("Event Deleted", {
            id: editedEvent.id,
            name: editedEvent.title,
            type: editedEvent.eventType,
        });

    }
    /**
     * Scrolls to the bottom of the modal
     */
    const scrollToBottom = function(): void {
        let el = document.querySelector('#event-editor-drawer-content');
        if (el) {
            el.scrollTop = 3000;
            setTimeout(function() { el.scrollTop = 3000; })
        }
    }

    /**
     * Updates the default preset to be shown the next time
     * @param timePresets all time presets
     * @param i the nth preset to set as default
     */
    const updateDefaultTimePreset = async function(timePresets: TimePreset[], i: number) {
        let timePresetsNew = JSON.parse(JSON.stringify(timePresets));
        for (let i = 0; i < timePresetsNew.length; i++) {
          delete timePresetsNew[i].default;
        }
        timePresetsNew[i].default = true;
        let user: string = localStorage.getItem("user");
        if (user) {
            let oldUser: User = JSON.parse(user);
            // @ts-ignore
            localStorage.setItem("user", JSON.stringify({...oldUser, ...{ timePresets: JSON.stringify(timePresetsNew) } }))
            UserAPI.update({ timePresets: JSON.stringify(timePresetsNew) } as UserDTO ).then(res => { }).catch(e => { })
          }
    }

    /**
     * Creates or edits the currently open event
     * @param confirmedGroupEdit whether the whole group should be edited
     * @param confirmedRepeatEdit whether all repeated event instance should be edited
     * @param resetTitle whether to update the title of the event
     * @returns status
     */
    const save = async function(confirmedGroupEdit?: boolean, confirmedRepeatEdit?: boolean, resetTitle?: boolean, readOnlyEvent?: boolean, confirmOvernight?: boolean): Promise<void> {

        setPreSavePreventedConfig({ confirmedGroupEdit, confirmedRepeatEdit, resetTitle, readOnlyEvent, confirmOvernight });

        (window as any).draftEventInstances = []; // Used to save all instances of draft events, so can be saved locally, and can run collision detection on it, if a manual edit of existing event occures

        if (readOnlyEvent) {
            onClose(0, [], []);
            return;
        }

        if (hasTypeChanged) {
            setHasTypeChanged(false);
            setUpdateEventTitlePrompt(editedEvent.title);
            return;
        }

        let event: Event = Object.assign({}, editedEvent);

        let eventFromLocalStorage = event.id ? (await getEventById(event.id)) : null;
        let start: Date = Utilities.dateAdd(new Date(event.start), "minute", 1)
        let end: Date = new Date(new Date(event.end));

        if (resetTitle) {
            event.title = "";
        }

        // Repeat until date is less than current date
        if (event.repeatUntil) {
            let repeatUntil = new Date(event.repeatUntil);
            repeatUntil.setHours(0);
            repeatUntil.setMinutes(0);
            let startFrom = new Date(event.start);
            startFrom.setHours(0);
            startFrom.setMinutes(0);
            startFrom = Utilities.dateAdd(startFrom, "day", 1);
            if (repeatUntil < startFrom) {
                setErrorMessage("Your repeat until date must be later than the start date.");
                scrollToBottom();
                return;
            }
        }

        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : null;

        if (
            new Date(event.end) < new Date(event.start) && 
            !confirmOvernight && 
            !localStorage.getItem("confirm-overnightTipsGuidePassed") &&
            (user && !user.settings.find(setting => setting.settingId === 31))
        ) {
            setOvernightConfirm(true);
            return;
        }

        if (new Date(event.start).getMinutes() === new Date(event.end).getMinutes() && new Date(event.start).getHours() === new Date(event.end).getHours()) {
            setErrorMessage("The start and end date are the same");
            scrollToBottom();
            return;
        }

        // If default is already entered, then overwrite it to the correct one in the next step
        if (
            event.title === "Working - I'm unavailable" ||
            event.title === "Private - I'm unavailable" ||
            event.title === "Private - I'm unavailable but offers considered" ||
            event.title === "I am available"
        ) {
            event.title = "";
        }

        // No title, fill out
        if (!event.title) {

            switch (event.eventType) {
                case 1: event.title = "Working - I'm unavailable"; break;
                case 2: event.title = "Private - I'm unavailable"; break;
                case 3: event.title = "Private - I'm unavailable but offers considered"; break;
                default: event.title = "I am available"; break;
            }

        }

        event.isSynced = false;

        if (dates || (event.days && draft )) {

            console.log("@@ 1")

            // Store to validate all before for collision
            let acceptedEvents: Event[] = [];
            const GROUP_iD: string = Utilities.uuidv4();

            if (event.days && draft) {
                dates = event.days // Overwriting days from localstraoge after collision handle, but not when editing a colliding event
            }

            let collisions: CollidingEvent[] = [];

            // USE THIS TODO if edited into a reepeating event
            if (dates.length === 1 && event.repeatType) {

                console.log("@@ 2")

                let repeatFrom: Date = new Date(event.start);
                let repeatUntil: Date = event.repeatUntil ? new Date(event.repeatUntil) : Utilities.dateAdd(repeatFrom, "year", 1);
                repeatUntil.setHours(23);
                repeatUntil.setMinutes(0);
                repeatUntil.setSeconds(0);

                let lastRepeatedDate = repeatUntil;

                while (repeatFrom < repeatUntil) {

                    console.log("@@ 3"  + repeatFrom + " " + repeatUntil)

                    if (event.repeatType === 1 && !Utilities.isWorkingDay(repeatFrom)) {
                        // Skip adding if not workday
                        repeatFrom = Utilities.dateAdd(repeatFrom, "day", 1);
                        continue;
                    }

                    lastRepeatedDate = new Date(repeatFrom);

                    let desiredDate:Date = new Date(repeatFrom);
                    let entryInstance = Object.assign({}, event);
                    let dateStart:Date = new Date(entryInstance.start);
                    let dateEnd:Date = new Date(entryInstance.end);
                    dateStart.setFullYear(desiredDate.getFullYear());
                    dateStart.setMonth(desiredDate.getMonth(), desiredDate.getDate());
                    dateEnd.setFullYear(desiredDate.getFullYear());
                    dateEnd.setMonth(desiredDate.getMonth(), desiredDate.getDate());
                    entryInstance.start = dateStart.toISOString();
                    entryInstance.end = dateEnd.toISOString();
                    entryInstance.id = Utilities.uuidv4();
                    // entryInstance.groupId = GROUP_iD;
                    
                    if (recentEventAddMode) {
                        // entryInstance.repeatType = 0;
                        // if (dateStart > dateEnd) {
                        //     dateEnd = Utilities.dateAdd(dateEnd, "day", 1);
                        //     entryInstance.end = dateEnd.toISOString();
                        // }
                    }

                    (window as any).draftEventInstances.push({ start: new Date(entryInstance.start), end: new Date(entryInstance.end), id: "0", type: entryInstance.eventType, repeatForever: entryInstance.repeatForever });
                    let newCollisions = await Utilities.isEventColliding(new Date(entryInstance.start), new Date(entryInstance.end), "0", entryInstance.eventType, (window as any).ignoreWorkOverAvailability, entryInstance.repeatForever);
                    console.log("@@@@@NEW collisions 1", newCollisions)

                    if (typeof newCollisions === "string") {
                        setTipsGuideSection(newCollisions);
                        return;
                    } else if (newCollisions.length) {
                        collisions = collisions.concat(newCollisions.filter(item => item));
                    }

                    if (entryInstance.repeatType === 1 || entryInstance.repeatType === 2) {
                        // Weekday and days
                        repeatFrom = Utilities.dateAdd(repeatFrom, "day", 1);
                    } else if (entryInstance.repeatType === 3) {
                        // Weeks
                        repeatFrom = Utilities.dateAdd(repeatFrom, "week", 1);
                    } else if (entryInstance.repeatType === 4) {
                        // Months
                        repeatFrom = Utilities.dateAdd(repeatFrom, "month", 1);
                    }

                }

                console.log("LAST REPETAED DATE: " + lastRepeatedDate + " REPEAT UNTIL ORIGI: " + repeatUntil);

                if (lastRepeatedDate < repeatUntil) {
                    console.log("REWRITTEN")
                    repeatUntil = lastRepeatedDate
                    event.repeatUntil = repeatUntil.toISOString();
                }

            }

            console.log("@@ 4")
             
            for (let i = 0; i < dates.length; i++) {

                let desiredDate:Date = new Date(dates[i]);

                if (event.repeatType === 1 && !Utilities.isWorkingDay(desiredDate)) {
                    desiredDate = Utilities.dateAdd(desiredDate, "day", 1);
                }

                if (event.repeatType === 1 && !Utilities.isWorkingDay(desiredDate)) {
                    desiredDate = Utilities.dateAdd(desiredDate, "day", 1);
                }

                let entryInstance = Object.assign({}, event);
                let dateStart:Date = new Date(entryInstance.start);
                let dateEnd:Date = new Date(entryInstance.end);
                dateStart.setFullYear(desiredDate.getFullYear());
                dateStart.setMonth(desiredDate.getMonth(), desiredDate.getDate());
                dateEnd.setFullYear(desiredDate.getFullYear());
                dateEnd.setMonth(desiredDate.getMonth(), desiredDate.getDate());
                entryInstance.start = dateStart.toISOString();
                entryInstance.end = dateEnd.toISOString();
                entryInstance.id = Utilities.uuidv4();
                entryInstance.groupId = dates.length !== 1 ? GROUP_iD : "";
                
                if (recentEventAddMode) {
                    // entryInstance.repeatType = 0;
                    // if (dateStart > dateEnd) {
                    //     dateEnd = Utilities.dateAdd(dateEnd, "day", 1);
                    //     entryInstance.end = dateEnd.toISOString();
                    // }
                }

                if (end <= start) {
                    console.log("OVERRIDE END because smaller")
                    end = Utilities.dateAdd(end, "day", 1);
                    //entryInstance.end = end.toISOString();
                }

                if (new Date(entryInstance.end) < new Date(entryInstance.start)) {
                    entryInstance.end = Utilities.dateAdd(new Date(entryInstance.end), "day", 1).toISOString();
                }

                (window as any).draftEventInstances.push({ start: new Date(entryInstance.start), end: new Date(entryInstance.end), id: "0", type: entryInstance.eventType, repeatForever: entryInstance.repeatForever });
                let newCollisions = await Utilities.isEventColliding(new Date(entryInstance.start), new Date(entryInstance.end), "0", entryInstance.eventType, (window as any).ignoreWorkOverAvailability, entryInstance.repeatForever);

                if (typeof newCollisions === "string") {
                    setTipsGuideSection(newCollisions);
                    return;
                } else if (newCollisions.length) {
                    collisions = collisions.concat(newCollisions.filter(item => item));
                }

                acceptedEvents.push(Object.assign(entryInstance, {}))
            }

            if (collisions.length) {
                handleCollisions(collisions);
                return;
            }

            acceptedEvents = JSON.parse(JSON.stringify(acceptedEvents));

            if (acceptedEvents.length === 1 && event.repeatType && !event.repeatForever) {

                let timeline = await Utilities.generateEvents(Utilities.dateSub(new Date(event.start), "year", 1), Utilities.dateAdd(new Date(event.repeatUntil), "year", 1), [acceptedEvents[0]]);
                
                // @ts-ignore
                let eventCount = timeline.eventCount;
                if (eventCount === 0) {
                    setErrorMessage("Your selection does not include any dates.");
                    scrollToBottom();
                    return;
                }

                if (eventCount === 1) {
                    // acceptedEvents[0].repeatType = 0;
                    // acceptedEvents[0].repeatForever = false;
                    // acceptedEvents[0].repeatUntil = null;
                    setErrorMessage("Your selection only includes one day, please turn off repeats to continue");
                    scrollToBottom();
                    return;
                }

            }

            for (let i = 0; i < acceptedEvents.length; i++) {
                acceptedEvents[i].updatedAt = (new Date()).toISOString();
                let saveEventRes = await saveEvent(acceptedEvents[i])
                console.log("SAVED", saveEventRes)
            }

            onClose(
                dates.length,
                acceptedEvents.map(item => item.id),
                [], 
                new Date(acceptedEvents[0].start),
                null,
                acceptedEvents.length === 1 && acceptedEvents[0].repeatType === 0 ? acceptedEvents[0].eventType : null
            );

        } else {

            // console.log("@@ 5")

            (window as any).draftEventInstances.push({ start: new Date(event.start), end: new Date(event.end), id: id, type: event.eventType, repeatForever: editedEvent.repeatForever });
            let collisions: CollidingEvent[] | string = await Utilities.isEventColliding(new Date(event.start), new Date(event.end), id, event.eventType, (window as any).ignoreWorkOverAvailability, editedEvent.repeatForever);
            console.log("@@@@@NEW collisions 3", collisions)

            console.log("@@ 6")

            if (typeof collisions === "string") {
                setTipsGuideSection(collisions);
                return;
            } else if (collisions.filter(item => item).length) {
                handleCollisions(collisions.filter(item => item));
                return;
            }

            let sameGroupEvents: Event[] = editedEvent.groupId ? await getEventsByGroupId(editedEvent.groupId) : [];

            // Haven't asked whether all should be edited yet
            if (confirmedGroupEdit === undefined) {
                // More group events so must ask
                if (sameGroupEvents.length > 1) {
                    setGroupEditConfirmAlert(sameGroupEvents.length);
                    return;
                }
            }

            // Haven't asked whether the repeat pattern should be changed
            if (confirmedRepeatEdit === undefined) {
                // This is a repeating event so must ask
                if (event.repeatForever || event.repeatUntil) {
                    if (eventFromLocalStorage && !eventFromLocalStorage.repeatType) {
                        // Editing an existing event, and making it repeat, so overriding to edit all - which is just one
                        confirmedRepeatEdit = false;
                    } else {
                        setRepeatEditConfirmAlert(true);
                        return;
                    }
                }
            }

            // Confirmed to edit all
            if (confirmedGroupEdit === true) {
                for (let i = 0; i < sameGroupEvents.length; i++) {
                    let element: Event = sameGroupEvents[i];
                    const newStartDate: Date = new Date(element.start);
                    const newEndDate: Date = new Date(element.end);
                    newStartDate.setHours(new Date(event.start).getHours())
                    newStartDate.setMinutes(new Date(event.start).getMinutes())
                    newEndDate.setHours(new Date(event.end).getHours())
                    newEndDate.setMinutes(new Date(event.end).getMinutes())
                    element.title = event.title;
                    element.googlePlacesId = event.googlePlacesId;
                    element.notes = event.notes;
                    element.eventType = event.eventType;
                    element.start = newStartDate.toISOString();
                    element.end = newEndDate.toISOString();
                    element.updatedAt = (new Date()).toISOString();
                    let saveEventRes = await saveEvent(element)
                    console.log("SAVED", saveEventRes)
                }
                onClose(sameGroupEvents.length, sameGroupEvents.map(item => item.id), sameGroupEvents.map(item => item.id), new Date(sameGroupEvents[0].start), null);

            } if (confirmedRepeatEdit === true) {

                const REPEAT_STARTED_TIME: Date = new Date(event.start);
                const REPEAT_ENDED_TIME: Date = new Date(event.end);
                const REPEAT_UNTIL: Date = event.repeatForever ? null : new Date(event.repeatUntil);

                let originalEvent = await getEventById(editedEvent.id);

                if (Utilities.areDatesAreOnSameDay(REPEAT_STARTED_TIME, dateInstance)) {

                    // Start date is edited, we duplicate the repeating event and make it not repeating on the first date
                    let newSingleEvent: Event = JSON.parse(JSON.stringify(event))
                    newSingleEvent.repeatType = 0;
                    newSingleEvent.id = Utilities.uuidv4();
                    // newSingleEvent.groupId = Utilities.uuidv4();
                    let saveEventRes = await saveEvent(newSingleEvent);
                    console.log("SAVED", saveEventRes)

                    // Increasing the start date on the original repeating event
                    originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "day", 1).toISOString();
                    originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "day", 1).toISOString();

                    // Increasing the start date on the original repeating event, no delete is required
                    if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "day", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "day", 1).toISOString();
                    } else if (editedEvent.repeatType === 3) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "week", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "week", 1).toISOString();
                    } else if (editedEvent.repeatType === 4) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "month", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "month", 1).toISOString();
                    }

                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), REPEAT_UNTIL)) { 
                        originalEvent.repeatType = 0;
                        originalEvent.repeatUntil = null;
                    }

                    saveEventRes = await saveEvent(originalEvent);
                    console.log("SAVED", saveEventRes)
                    onClose(1, [newSingleEvent.id], [newSingleEvent.id, originalEvent.id], new Date(newSingleEvent.start), null);

                } else if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(dateInstance, REPEAT_UNTIL)) {

                    // End date is edited, we duplicate the repeating event and make it not repeating on the last date
                    let newSingleEvent: Event = JSON.parse(JSON.stringify(event))
                    newSingleEvent.repeatType = 0;
                    newSingleEvent.repeatUntil = null;
                    newSingleEvent.id = Utilities.uuidv4();
                    // newSingleEvent.groupId = Utilities.uuidv4();
                    newSingleEvent.start = new Date(REPEAT_UNTIL.getFullYear(), REPEAT_UNTIL.getMonth(), REPEAT_UNTIL.getDate(), new Date(newSingleEvent.start).getHours(), new Date(newSingleEvent.start).getMinutes()).toISOString();
                    newSingleEvent.end = new Date(REPEAT_UNTIL.getFullYear(), REPEAT_UNTIL.getMonth(), REPEAT_UNTIL.getDate(), new Date(newSingleEvent.end).getHours(), new Date(newSingleEvent.end).getMinutes()).toISOString();

                    let saveEventRes = await saveEvent(newSingleEvent);
                    console.log("SAVED", saveEventRes)

                    // Decrease the end date on the original repeating event
                    if (REPEAT_UNTIL) {
                        if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "day", 1).toISOString();
                        } else if (editedEvent.repeatType === 3) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "week", 1).toISOString();
                        } else if (editedEvent.repeatType === 4) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "month", 1).toISOString();
                        }
                    }

                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), new Date(originalEvent.repeatUntil))) { 
                        originalEvent.repeatType = 0;
                        originalEvent.repeatUntil = null;
                    }

                    saveEventRes = await saveEvent(originalEvent);
                    console.log("SAVED", saveEventRes)
                    onClose(1, [newSingleEvent.id], [newSingleEvent.id, originalEvent.id], new Date(newSingleEvent.start), null);

                } else {

                    let newSingleEvent: Event = JSON.parse(JSON.stringify(event))
                    newSingleEvent.repeatType = 0;
                    newSingleEvent.repeatUntil = null;
                    newSingleEvent.id = Utilities.uuidv4();
                    // newSingleEvent.groupId = Utilities.uuidv4();
                    newSingleEvent.start = new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(newSingleEvent.start).getHours(), new Date(newSingleEvent.start).getMinutes()).toISOString();
                    newSingleEvent.end = new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(newSingleEvent.end).getHours(), new Date(newSingleEvent.end).getMinutes()).toISOString();

                    let saveEventRes = await saveEvent(newSingleEvent);
                    console.log("SAVED", saveEventRes)

                    let firstHalfRepetition = JSON.parse(JSON.stringify(originalEvent));
                    let lastHalfRepetition = JSON.parse(JSON.stringify(originalEvent));

                    // Repeat right until the start of the deleted repeat instance
                    if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "day", 1)).toISOString();
                    } else if (editedEvent.repeatType === 3) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "week", 1)).toISOString();
                    } else if (editedEvent.repeatType === 4) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "month", 1)).toISOString();
                    }

                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(firstHalfRepetition.repeatUntil), new Date(firstHalfRepetition.start))) { 
                        firstHalfRepetition.repeatType = 0;
                        firstHalfRepetition.repeatUntil = null;
                    }

                    saveEventRes = await saveEvent(firstHalfRepetition);
                    console.log("SAVED", saveEventRes)

                    // Start a day after the deleted repetition and repeat until the original end date
                    if (editedEvent.repeatType === 1 || editedEvent.repeatType === 2) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "day", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "day", 1).toISOString();
                    } else if (editedEvent.repeatType === 3) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "week", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "week", 1).toISOString();
                    } else if (editedEvent.repeatType === 4) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "month", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "month", 1).toISOString();
                    }

                    if (REPEAT_UNTIL) {
                        lastHalfRepetition.repeatUntil = REPEAT_UNTIL.toISOString();
                        if (Utilities.areDatesAreOnSameDay(new Date(REPEAT_UNTIL), new Date(lastHalfRepetition.end))) {
                            lastHalfRepetition.repeatType = 0;
                            lastHalfRepetition.repeatUntil = null;
                        }
                    }

                    lastHalfRepetition.id = Utilities.uuidv4();
                    // lastHalfRepetition.groupId = Utilities.uuidv4();

                    saveEventRes = await saveEvent(lastHalfRepetition);
                    console.log("SAVED", saveEventRes)

                    onClose(1, [newSingleEvent.id], [lastHalfRepetition.id, firstHalfRepetition.id], newSingleEvent.start, null);

                }

            } else {
                const eventStart = new Date(event.start);
                let eventEndResetToStart = new Date(event.end);
                eventEndResetToStart.setFullYear(eventStart.getFullYear())
                eventEndResetToStart.setMonth(eventStart.getMonth(), eventStart.getDate())
                // Reset end date to today in case switiching from overnight
                event.end = new Date(eventEndResetToStart).toISOString();
                // Reset to a day ahead if it's overnight
                if (eventStart > eventEndResetToStart) {
                    event.end = Utilities.dateAdd(new Date(eventEndResetToStart), "day", 1).toISOString();
                }
                event.updatedAt = (new Date()).toISOString();
                let saveEventRes = await saveEvent(event)
                console.log("SAVED", saveEventRes)
                onClose(1, [event.id], [event.id], new Date(event.start), null);
            }

        }

        InternalTracker.trackEvent("Event Saved", {
            name: event.title,
            type: event.eventType,
            start: event.start,
            end: event.end,
            repeatType: event.repeatType,
            repeatUntil: event.repeatUntil
        });

    }

    useEffect( () => {
        if (recentEventAddMode === "auto")  {
            save();
        }
    }, [editedEvent && editedEvent.id]);

    /**
     * Attepts to load Google Maps API if not already loaded
     * @param lookupPlaceId place to look up as callback
     */
    const checkGoogleMapsAPI = function(lookupPlaceId?: string) {

        if (typeof google === 'undefined' || !google) {
            const googleApiScript = document.createElement('script');
            googleApiScript.async = true;
            googleApiScript.defer = true;
            googleApiScript.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8';
            googleApiScript.onload = () => {
                setGoogleMapsLoaded(true);
                initializeGoogleMaps();
                // if (lookupPlaceId) lookupGooglePlace(lookupPlaceId)
            };
            document.body.appendChild(googleApiScript);
        } else {
            setGoogleMapsLoaded(true);
            initializeGoogleMaps();
            // if (lookupPlaceId) lookupGooglePlace(lookupPlaceId)
        }

    }

    /**
     * Initialized Google Maps API
     */
    const initializeGoogleMaps = function() {

        if (!google.maps.places) return;// todo check why

        const inputElement = googleMapsAutocompleteInput.current!;
        // Initialise autocomplete on the search input
        const autocomplete = new google.maps.places.Autocomplete(inputElement);
        // Specify fields to return in lookup
        autocomplete['setFields'](PlaceFields);
        // Restrict autocomplete to their current country
        autocomplete.setComponentRestrictions({ country: 'uk' });

        const placeChanged = (place: google.maps.places.PlaceResult) => {
            setNewlySelectedGooglePlace(place)
           
        };

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            placeChanged(place);
        });

    }

    useEffect( () => {

        if (newlySelectedGooglePlace) {

            // @ts-ignore
            updateEditedEvent({...editedEvent, googlePlacesId: newlySelectedGooglePlace.place_id }); 
            // setFormattedAddress(newlySelectedGooglePlace.name + " (" + Utilities.formatAddressComponents(newlySelectedGooglePlace.address_components) + ")"); 

            InternalTracker.trackEvent("Event Location Set", {
                // @ts-ignore
                name: newlySelectedGooglePlace.name,
                // @ts-ignore
                id: newlySelectedGooglePlace.place_id
            })

        }
    }, [newlySelectedGooglePlace])

    useEffect( () => {
        let header = document.getElementById("event-ion-header");
        if (header && header.clientHeight) {
            setContentHeight("calc(100% - " + header.clientHeight + "px)")
        }
    }, [editedEvent])

    // Runs when modal is opened / closed
    useEffect( () => {
        (async function() {

            setShowNotesSection(false);
            setShowLocationSection(false);
            setShowRepeatsSection(false);
            updateEditedEvent(null);

            if (isModalOpen) {

                let user: any = localStorage.getItem("user");
                if (user) user = JSON.parse(user) as User;
                if (user && user.timePresets && typeof JSON.parse(user.timePresets) === 'object') setTimePresets(JSON.parse(user.timePresets));

                setTimeout(function() {
                    checkGoogleMapsAPI();
                }, 500)
                
                store.dispatch(clearTimelineCache());
                setReadOnlyEvent(false)

                // Opened from a recent evern click, at this point the id of the event has been read (and loaded?) which replaces the old dates (but still here as props, just not applied)
                if (recentEventAddMode) {
                    if (recentEventAddMode === "auto")  {
                        // setDrawerTitle("Autosaving...");
                        return;
                    } else {
                        setTimeout(function() {
                            if (dates) {
                                setDrawerTitle((dates.length === 1) ? ("Creating one event on " + Utilities.formatDate(new Date(dates[0]), "d mms YYYY") + (draft ? " (draft)" : "")) : ("Creating " + dates.length + " events"));
                                // if (source === "recents" && dates.length === 1) {
                                //     (window as any).repeatStartOverride = dates[0];
                                // }
                                setRepeatStartOverride(new Date(dates[0]).toISOString());
                            }
                        }, 500)
                        return;
                    }
                }

                // Editing an existing event, fetching event from localDb
                if (id) {

                    setRepeatStartOverride(null);

                    let event = await getEventById(id);

                    if (!event) {
                        (window as any).toast("Failed to fetch event", "error")
                        onClose(0, null, []);
                        return;
                    }

                    let sameGroupEvents: Event[] = event.groupId ? await getEventsByGroupId(event.groupId) : [];
                    setSameGroupEvents(sameGroupEvents);
                    scrollToSelectedGroupDay(sameGroupEvents, id);
                    
                    if (source === "recents") {
                        event.repeatType = 0;
                        event.repeatForever = null;
                        event.repeatUntil = null;
                        event.deleted = false;
                    }

                    updateEditedEvent(event);
                    setShowNotesSection(event.notes ? true : false);
                    setShowLocationSection(event.googlePlacesId ? true : false);
                    setShowRepeatsSection(event.repeatType !== 0);

                    if (event.repeatType) {
                        setDrawerTitle("Editing one" + (clashingEdit ? " clashing" : "") + " event on " + Utilities.formatDate(new Date(dateInstance), "d mms YYYY"));
                    } else {
                        setDrawerTitle("Editing one" + (clashingEdit ? " clashing" : "") + " event on " + Utilities.formatDate(new Date(event.start), "d mms YYYY"));
                    }

                    // event.creator = {
                    //     userId: "",
                    //     contactId: "",
                    //     firstName: "",
                    //     lastName: ""
                    // }

                    setReadOnlyEvent(event.creator !== undefined)

                    if (event.googlePlacesId) {
                        checkGoogleMapsAPI(event.googlePlacesId);
                    }

                    setTimeout(function() {
                        const el = document.getElementById("event-name-textarea");
                        if (el) {
                            // @ts-ignore
                            el.style.height = el.scrollHeight + 'px';
                        }
                    }, 300);

                } else if (draft) {
                    setRepeatStartOverride(null);
                    setSameGroupEvents([]);
                    updateEditedEvent(draft);
                    setDrawerTitle("Creating one event (draft)")
                    if (draft.repeatType !== 0) {
                        setShowRepeatsSection(true);
                    }
                    if (draft.notes) {
                        setShowNotesSection(true);
                    }
                } else if (dates) {
                    setRepeatStartOverride(null);
                    setSameGroupEvents([]);
                    if (dates.length === 1) {
                        setDrawerTitle("Creating one event on " + Utilities.formatDate(new Date(dates[0]), "d mms YYYY") + "" + (draft ? " (draft)" : ""));
                    } else {

                        if (dates.length < 4) {
                            let datesStr: string = ""
                            // if (dates.length < 6) {
                                datesStr += " on ";
                                for (let i = 0; i < dates.length; i++) {
                                    datesStr += Utilities.formatDate(new Date(dates[i]), "d mms YYYY")
                                    if (i + 1 !== dates.length) datesStr += ", ";
                                }
                            // }
                            setDrawerTitle("Creating " + dates.length + " events" + datesStr);
                        } else {

                            let firstDate: Date = new Date(dates[0]);
                            let lastDate: Date = new Date(dates[0]);
                            for (let i = 0; i < dates.length; i++) {
                                // if (dates.length < 6) {
                                    if (new Date(dates[i]) < firstDate) {
                                        firstDate = new Date(dates[i]);
                                    } else if (new Date(dates[i]) > lastDate) {
                                        lastDate = new Date(dates[i]);
                                    }
                                // }
                            }

                            setDrawerTitle("Creating " + dates.length + " events between " + Utilities.formatDate(firstDate, "d mms YYYY") + " and " + Utilities.formatDate(lastDate, "d mms YYYY"));

                        }
                        
                    }

                    let newEvent:Event = Object.assign({}, DEFAULT_EVENT);

                    let defaultEventType = user?.settings?.find(setting => setting.settingId === 18);
                    if (defaultEventType) {
                        newEvent.eventType = Number(defaultEventType.value) || 4;
                    }

                    newEvent.title = "";
                    let now:Date = new Date();
                    // Just one event must change now date
                    if (dates.length === 1) {
                        let singleDayDate: Date = new Date(dates[0]);
                        now.setFullYear(singleDayDate.getFullYear());
                        now.setMonth(singleDayDate.getMonth(), singleDayDate.getDate());
                    }

                    newEvent.start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0).toISOString();
                    newEvent.end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0).toISOString();
                    newEvent.id = Utilities.uuidv4();

                    // let user: any = localStorage.getItem("user");
                    // if (user) user = JSON.parse(user) as User;

                    if (user && user.timePresets && typeof JSON.parse(user.timePresets) === 'object' && JSON.parse(user.timePresets).filter(item => item.default).length !== 0) {
                        let defaultPreset: TimePreset = JSON.parse(user.timePresets).filter(item => item.default)[0];
                        let start = new Date(newEvent.start);
                        start.setHours(defaultPreset.startHour);
                        start.setMinutes(defaultPreset.startMinute);
                        newEvent.start = start.toISOString()
                        let end = new Date(newEvent.end);
                        end.setHours(defaultPreset.endHour);
                        end.setMinutes(defaultPreset.endMinute);
                        newEvent.end = end.toISOString()
                    }

                    updateEditedEvent(newEvent);

                } else if (eventT) {
                    updateEditedEvent(eventT)
                }
            
            } else {
                setErrorMessage(null)
            }
        })();

    }, [isModalOpen, recentEventAddMode])

    let MODAL_CONTENT_DOM = null;

    // @ts-ignore
    const settings = useSelector(state => state.settings);
    const orgLogoUrl: string = (editedEvent && editedEvent.creator && editedEvent.creator.organisation) ? UserAPI.getOrgPicture(editedEvent.creator.organisation.id) : null;

    if (isModalOpen && editedEvent) {
        MODAL_CONTENT_DOM = <div id="event-editor-drawer-content" style={{ height: '100%' }} className="swipeable-drawer-body" data-drawer="event-editor">
        <div className="content" style={{ overflowX: 'hidden' }} ref={contentRef}>
            <h1>{drawerTitle}</h1>
            <IonIcon id="entry-editor-drawer-close-btn" onClick={() => { onClose(0, null, []) }} icon={close} />
            { readOnlyEvent &&
                <div className='top-banner'>
                    This is a confirmed booking, you cannot edit details, but can delete the events to cancel (your contact will be notified).
                </div>
            }
            <div className="details">
                <div className="info-tags">
                    { (editedEvent.end < editedEvent.start) ?
                        <span className="info-tag" style={{ right: 0 }}>
                            <IonIcon icon={moonOutline} />
                            <span>Occurs Overnight</span>
                        </span> : null
                    }
                    { (editedEvent.groupId && editedEvent.offerEventId === undefined)  ?
                        <span className="info-tag" style={{ right: 0 }}>
                            <IonIcon icon={layers} />
                            <span>Group Event</span>
                        </span> : null
                    }
                    { (editedEvent.repeatType) ?
                        <span className="info-tag" style={{ right: 0 }}>
                            <IonIcon icon={repeatOutline} />
                            <span>{editedEvent.repeatForever ? "Repeat forever" : "Repeating event"}</span>
                        </span> : null
                    }
                </div>
                { (sameGroupEvents && sameGroupEvents.length > 1) &&
                    <div className="days">
                        { sameGroupEvents.map(day => {
                            let dayLabel = Utilities.formatDate(new Date(day.start), "YYYY-MM-DD");
                            let timeStart = Utilities.formatDate(new Date(day.start), "HH:MM");
                            let timeEnd = Utilities.formatDate(new Date(day.end), "HH:MM");
                            return (
                                <div 
                                    className="day"
                                    data-id={day.id}
                                    onClick={() => {
                                        openAnother(day.id);
                                    }}
                                    data-selected={day.id === editedEvent.id}
                                >
                                    <div>{Utilities.formatDate(new Date(dayLabel), "ds")}</div>
                                    <div>{Utilities.formatDate(new Date(dayLabel), "D")} {Utilities.formatDate(new Date(dayLabel), "mms")}</div>
                                    <div>{timeStart} - {timeEnd}</div>
                                </div>
                            )
                        }) }
                    </div>
                }
                { (dates && dates.length > 1) &&
                    <div className="days">
                        { dates.map(dayLabel => {
                            return (
                                <div className="day">
                                    <div>{Utilities.formatDate(new Date(dayLabel), "ds")}</div>
                                    <div style={{ marginBottom: 0 }}>{Utilities.formatDate(new Date(dayLabel), "D")} {Utilities.formatDate(new Date(dayLabel), "mms")}</div>
                                </div>
                            )
                        }) }
                    </div>
                }
                <div className="event-type">
                    <h2>Event Type</h2>
                    <div className="selected-indicator" data-type={editedEvent.eventType}>
                        { (editedEvent.eventType === 1) && <BriefcaseIcon /> }
                        { (editedEvent.eventType === 2) && <PadlockClosed /> }
                        { (editedEvent.eventType === 3) && <PadlockOpen /> }
                        { (editedEvent.eventType === 4) && <WaveIcon /> }
                        { (editedEvent.eventType === 3) && <label>Offers considered</label> }
                    </div>
                    { (orgLogoUrl || (editedEvent.organisationUrl && !hideExternalOrgImage && !(window as any).hiddenExternalOrgImages[editedEvent.organisationUrl])) ?
                        <div className="logos">
                            { orgLogoUrl &&
                                <div>
                                    <img src={orgLogoUrl} />
                                </div>   
                            }
                            { (editedEvent.organisationUrl && !hideExternalOrgImage && !(window as any).hiddenExternalOrgImages[editedEvent.organisationUrl]) &&
                                <div>
                                    <img onError={() => { (window as any).hiddenExternalOrgImages[editedEvent.organisationUrl] = true; setHideExternalOrgImage(true) }} onLoad={() => { setHideExternalOrgImage(false) }} src={CONFIG.API_EXTERNAL_URI + "/organisations/external/logo/" + editedEvent.organisationUrl + "?api-version=1.0"} />
                                </div>   
                            }
                        </div>
                        : null
                    }
                    <InlineSelect
                        id="event-type-selector"
                        selected={editedEvent.eventType.toString()}
                        options={[
                            { id: "1", icon: <BriefcaseIcon />, label: "Working" },
                            { id: ["2", "3"], icon: <PadlockClosed />, label: "Private" },
                            { id: ["4"], icon: <WaveIcon />, label: "I'm available" },
                        ]}
                        onSelect={(newId) => {

                            InternalTracker.trackEvent("Event Type Set", {
                                value: newId
                            });

                            let updated: Event = Object.assign({}, editedEvent);
                            updated.eventType = parseInt(newId);
                            if (["", "Working - I'm unavailable", "Private - I'm unavailable", "Private - I'm unavailable but offers considered", "I am available"].indexOf(editedEvent.title) !== -1) {
                                updated.title = "";
                                setHasTypeChanged(false)
                            } else {
                                setHasTypeChanged(true)
                            }
                            updateEditedEvent(updated);

                        }}
                    />
                </div>
                { (editedEvent.eventType === 2 || editedEvent.eventType === 3) &&
                    <div id="repeat-type-selector-wrapper">
                        <div className="line"></div>
                        <InlineSelect
                            id="repeat-type-selector"
                            selected={editedEvent.eventType.toString()}
                            options={[
                                { id: "2", label: "No Offers Please" },
                                { id: "3", label: "Offers Considered" },
                            ]}
                            onSelect={(type) => {
                                InternalTracker.trackEvent("Event Type Set", {
                                    value: type
                                });

                                updateEditedEvent({
                                    ...editedEvent, 
                                    eventType: parseInt(type),
                                    title: (["", "Working - I'm unavailable", "Private - I'm unavailable", "Private - I'm unavailable but offers considered", "I am available"].indexOf(editedEvent.title) !== -1) ? "" : editedEvent.title
                                })
                            }}
                        />
                    </div>
                }
                <div className="event-name">
                    <h2 style={{ marginBottom: 6 }}>Event Name</h2>
                    { (!editedEvent.title) &&
                        <div className="pulse"></div>
                    }
                    <IonTextarea
                        id="event-name-input"
                        placeholder={ 
                            (editedEvent.eventType === 1) ? "Enter an event name - Working - I'm unavailable" :  
                            (editedEvent.eventType === 2) ? "Enter an event name - Private - I'm unavailable" :  
                            (editedEvent.eventType === 3) ? "Enter an event name - Private - I'm unavailable but offers considered" :  
                            (editedEvent.eventType === 4) ? "I am available" : "Event Name"
                        }
                        value={editedEvent.title} 
                        onIonInput={ async (e) => { 
                            const newTitle: string = Utilities.capitalizeFirstLetter((e.target as HTMLIonTextareaElement).value);
                            updateEditedEvent({...editedEvent, title: newTitle})
                            if (newTitle.trim()) {
                                let suggestions = await getSuggestions(newTitle);
                                setEventSuggestions(suggestions);
                            } else {
                                let suggestions = await getSuggestions(newTitle);
                                setEventSuggestions([]);
                            }
                        }}
                        onIonFocus={() => {
                            setEventNameFocused(true);
                            const el = document.getElementById("event-name-input");
                            if (el) {
                                setTimeout(() => {
                                    el.scrollIntoView({ block: 'start', inline: 'nearest' });
                                }, 150)
                            }
                        }}
                        onIonBlur={() => {
                            setTimeout(() => {
                                setEventNameFocused(false)
                            }, 150);
                            if (editedEvent.title) {
                                InternalTracker.trackEvent("Event Name Set", {
                                    value: editedEvent.title
                                })
                            }
                        }}
                    />
                    { (eventSuggestions.length !== 0 && eventNameFocused) &&
                        <div className='suggestions'>
                            <h3>Suggestions - tap to autofill title and time</h3>
                            { eventSuggestions.map(eventSuggestion => {
                                return (
                                    <div
                                        onClick={() => {
                                            InternalTracker.trackEvent("Event Suggestion Fill", {
                                                start: eventSuggestion.start,
                                                end: eventSuggestion.end,
                                                title: eventSuggestion.title,
                                                eventType: eventSuggestion.eventType
                                            })

                                            let start = new Date(editedEvent.start);
                                            start.setHours(new Date(eventSuggestion.start).getHours());
                                            start.setMinutes(new Date(eventSuggestion.start).getMinutes());
                                            start.setSeconds(0);
                                            start.setMilliseconds(0);
                                            
                                            let end = new Date(editedEvent.end);
                                            end.setHours(new Date(eventSuggestion.end).getHours());
                                            end.setMinutes(new Date(eventSuggestion.end).getMinutes());
                                            end.setSeconds(0);
                                            end.setMilliseconds(0);

                                            updateEditedEvent({
                                                ...editedEvent, 
                                                start: start.toISOString(),
                                                end: end.toISOString(),
                                                title: eventSuggestion.title,
                                                eventType: eventSuggestion.eventType
                                            });

                                            setEventSuggestions([]);
                                        }}
                                    >
                                        <div className="work-type" data-type={eventSuggestion.eventType}></div>
                                        <span>{eventSuggestion.title}</span> 
                                        <span>{Utilities.formatDate(new Date(eventSuggestion.start), "HH:MM")} - {Utilities.formatDate(new Date(eventSuggestion.end), "HH:MM")}</span>
                                    </div>
                                )
                            }) }
                        </div>
                    }
                </div>
                <div style={{ position: 'relative', marginBottom: 4 }}>
                    <h2>
                        Duration
                        <span>
                            { (dates && dates.length > 1) && dates.length + " X " }
                            {
                                Utilities.toHoursMinutes(
                                    new Date(editedEvent.start) < new Date(editedEvent.end)
                                        ? Utilities.differenceBetweenDatesSeconds(new Date(editedEvent.start), new Date(editedEvent.end))
                                        : Utilities.differenceBetweenDatesSeconds(new Date(editedEvent.start), new Date(Utilities.dateAdd(new Date(editedEvent.end), "day", 1)))
                                )
                            }
                        </span>
                    </h2>
                    <div className="duration">
                        <label>from</label>
                        <div className="start">
                            <TextField
                                type="time"
                                id="datetime-local-ended"
                                value={Utilities.formatDate(new Date(editedEvent.start), "HH:MM")}
                                onBlur={() => {
                                    InternalTracker.trackEvent('Event Start Set', {
                                        value: Utilities.formatDate(new Date(editedEvent.start), "HH:MM")
                                    })
                                }}
                                onChange={(date) => { 
                                    let hours = date.target.value.substr(0, 2);
                                    let minutes = date.target.value.substr(3, 2);
                                    if (hours.length === 2 && minutes.length === 2) {
                                        let newDate = new Date(editedEvent.start);
                                        newDate.setHours(parseInt(hours));
                                        newDate.setMinutes(parseInt(minutes));
                                        updateEditedEvent({...editedEvent, start: newDate.toISOString()});
                                    }
                                }}
                            />
                        </div>
                        <label>to</label>
                        <div className="end">
                            <TextField
                                type="time"
                                id="datetime-local-ended"
                                value={Utilities.formatDate(new Date(editedEvent.end), "HH:MM")}
                                onBlur={() => {
                                    InternalTracker.trackEvent('Event End Set', {
                                        value: Utilities.formatDate(new Date(editedEvent.start), "HH:MM")
                                    })
                                }}
                                onChange={(date) => { 
                                    let hours = date.target.value.substr(0, 2);
                                    let minutes = date.target.value.substr(3, 2);
                                    if (hours.length === 2 && minutes.length === 2) {
                                        let newDate = new Date(editedEvent.end);
                                        newDate.setHours(parseInt(hours));
                                        newDate.setMinutes(parseInt(minutes))
                                        updateEditedEvent({...editedEvent, end: newDate.toISOString()}) 
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="presets">
                        <div onClick={() => {
                            onClose(null, [], []);
                            history.push("/settings/time-presets");
                        }}> <IonIcon icon={create} />Edit Presets</div>
                        { timePresets.map((preset, i) => {
                            const selectedStartDate: Date = new Date(editedEvent.start);
                            const selectedEndDate: Date = new Date(editedEvent.end);
                            return (
                                <div key={preset.name} data-selected={(selectedStartDate.getHours() === preset.startHour && selectedStartDate.getMinutes() === preset.startMinute && selectedEndDate.getHours() === preset.endHour && selectedEndDate.getMinutes() === preset.endMinute )} onClick={() => {

                                    InternalTracker.trackEvent("Event Time Preset Set", {
                                        value: preset.name,
                                        start: preset.startHour + ":" + preset.startMinute,
                                        end: preset.endHour + ":" + preset.endMinute
                                    });

                                    let newDateEnd = new Date(editedEvent.end);
                                    newDateEnd.setHours(preset.endHour);
                                    newDateEnd.setMinutes(preset.endMinute)
                                    let newDateStart = new Date(editedEvent.start);
                                    newDateStart.setHours(preset.startHour);
                                    newDateStart.setMinutes(preset.startMinute)
                                    updateEditedEvent({...editedEvent, end: newDateEnd.toISOString(), start: newDateStart.toISOString()}) 
                                    updateDefaultTimePreset(timePresets, i)

                                }}>{preset.name}</div>
                            )
                        }) }
                    </div>
            </div>

                <div
                    className="location"
                    style={(showLocationSection) ? { } : { height: 0, overflow: 'hidden', marginBottom: 0 }}
                >
                    <h2 
                        onClick={() => {
                            if (document.getElementById("location-search-input")) {
                                // @ts-ignore
                                document.querySelectorAll("#location-search-input")[0].focus();
                            }
                        }}
                        style={{ 
                            marginBottom: 2, 
                            position: 'relative' 
                        }}
                    >
                        Location
                        <IonIcon 
                            className="attachment-remove-btn"
                            icon={close}
                            onClick={() => {
                                InternalTracker.trackEvent("Event Location Removed", {
                                    id: editedEvent.googlePlacesId
                                })
                                updateEditedEvent({...editedEvent, googlePlacesId: null});
                                setShowLocationSection(false);
                            }}
                        />
                    </h2>
                    <div>
                    </div>
                    <div className="search-results">
                        <input
                            style={editedEvent.googlePlacesId ? { display: "none" } : {}}
                            id="location-search-input"
                            ref={googleMapsAutocompleteInput}
                            type="text"
                            placeholder="Enter a location..."
                            className="form-control"
                        />
                    </div>
                    { (editedEvent.googlePlacesId) &&
                        <div className="address">
                            {/* <div>
                                {formattedAddress || "Loading location..."}
                            </div> */}
                            <LocationPreview showLocationNameFromGoogle={true} googlePlacesId={editedEvent.googlePlacesId} />
                            {/* <IonIcon icon={close} onClick={() => { 
                                updateEditedEvent({...editedEvent, googlePlacesId: null}) 
                                // setFormattedAddress(null)
                            }} /> */}
                        </div>
                    }
                </div>

                { (drawerTitle.indexOf("events") === -1 && /*!recentEventAddMode &&*/ showRepeatsSection) &&
                    <div className="repeats">
                        <div className="header">
                            <h2 onClick={() => { updateEditedEvent({
                                ...editedEvent, 
                                repeatForever: false, 
                                repeatUntil: ( editedEvent.repeatType === 0 ? (new Date(editedEvent.start)).toISOString() : null), 
                                repeatType: editedEvent.repeatType === 0 ? 1 : 0})
                            }}>
                                Repeat
                                { (editedEvent.repeatType !== 0) && <span>From {Utilities.formatDate(new Date(editedEvent.start), "d mms YYYY")}</span> }
                            </h2>
                            <IonToggle 
                                id="repeat-toggle" 
                                checked={editedEvent.repeatType !== 0} 
                                onIonChange={e => {

                                    InternalTracker.trackEvent('Event Repeat Set', {
                                        value: editedEvent.repeatType === 0 ? "On" : "Off"
                                    });
                                    
                                    updateEditedEvent({
                                        ...editedEvent, 
                                        repeatForever: false, 
                                        repeatUntil: ( editedEvent.repeatType === 0 ? (new Date(editedEvent.start)).toISOString() : null), 
                                        repeatType: editedEvent.repeatType === 0 ? 1 : 0
                                    })

                                    if (editedEvent.repeatType !== 0) {
                                        setShowRepeatsSection(false);
                                    }

                                }} />
                        </div>
                        { (editedEvent.repeatType !== 0) &&
                            <div className="type">
                                <InlineSelect
                                    id="repeat-type-selector"
                                    selected={editedEvent.repeatType.toString()}
                                    options={[
                                        { id: "1", label: "Weekdays" },
                                        { id: "2", label: "Daily" },
                                        { id: "3", label: "Weekly" },
                                        { id: "4", label: "Monthly" },
                                    ]}
                                    onSelect={(newId) => {

                                        updateEditedEvent({...editedEvent, repeatType: parseInt(newId)})

                                        InternalTracker.trackEvent('Event Repeat Type Set', {
                                            value: (newId === "1") ? "Weekdays" : (newId === "2") ? "Daily" : (newId === "3") ? "Weekly" : "Monthly"
                                        });
                                        
                                    }}
                                />
                            </div>
                        }
                        { (editedEvent.repeatType !== 0) &&
                            <div className="type">
                                <h2>Repeat Until</h2>
                                <InlineSelect
                                    id="repeat-interval-selector"
                                    selected={editedEvent.repeatForever.toString()}
                                    options={[
                                        { id: "true", label: "Repeat Forever" },
                                        { id: "false", label: "Custom Date" }
                                    ]}
                                    onSelect={(newId) => {

                                        InternalTracker.trackEvent('Event Repeat Until Set', {
                                            value: editedEvent.repeatForever ? "Repeat Forever" : (new Date(editedEvent.start)).toISOString()
                                        });

                                        const newRepeatUntil = newId === "true" ? null : (new Date(editedEvent.start)).toISOString();

                                        updateEditedEvent({
                                            ...editedEvent, 
                                            repeatUntil: newRepeatUntil, 
                                            repeatForever: newId === "true"
                                        })

                                        if (newRepeatUntil && settings && settings.version === "mobile" && document.getElementById("repeat-until-input")) {
                                            let dateInput = document.getElementById("repeat-until-input");
                                            if (settings.os === "ios") {
                                                dateInput.focus();  
                                            } else {
                                                dateInput.click();  
                                            }
                                        }

                                    }}
                                />
                            </div>
                        }
                        { (editedEvent.repeatType !== 0 && !editedEvent.repeatForever) &&
                            <div className="end-date" onClick={() => {
                                if (settings && settings.version === "mobile" && document.getElementById("repeat-until-input")) {
                                    let dateInput = document.getElementById("repeat-until-input");
                                    if (settings.os === "ios") {
                                        dateInput.focus();  
                                    } else {
                                        dateInput.click();  
                                    }
                                }
                            }}>
                                <h2 style={{ marginBottom: 6 }}>End Date</h2>
                                { ( (settings && settings.version !== "desktop") || ((window as any).Cypress)) &&
                                    <p>{Utilities.formatDate(new Date(editedEvent.repeatUntil), "d mms YYYY")}</p>
                                }
                                { ( (settings && settings.version !== "desktop") || ((window as any).Cypress)) &&
                                    <input id="repeat-until-input" type="date" value={Utilities.formatDate(new Date(editedEvent.repeatUntil), "YYYY-MM-DD")} onChange={(e) => {
                                        if (Utilities.isValidDate(new Date(e.target.value)))
                                            updateEditedEvent({...editedEvent, repeatUntil: new Date(e.target.value).toISOString() })
                                    }} />
                                }
                                { ( (settings && settings.version === "desktop") || ((window as any).Cypress)) &&
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={gbLocale}>
                                        <KeyboardDatePicker
                                            format="dd MMM yyyy"
                                            shouldDisableDate={(date) => { return (date < new Date(editedEvent.start)) }}
                                            margin="normal"
                                            value={Utilities.formatDate(new Date(editedEvent.repeatUntil), "YYYY-MM-DD")}
                                            onChange={(date) => {
                                                if (Utilities.isValidDate(date)) {
                                                    updateEditedEvent({...editedEvent, repeatUntil: date.toISOString()})
                                                    InternalTracker.trackEvent('Event Repeat Until Set', {
                                                        value: date.toISOString()
                                                    });
                                                }
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                }
                            </div>
                        }
                    </div>
                }
                { showNotesSection &&
                    <div>
                        <h2 
                            style={{
                                marginBottom: 6
                            }}
                        >
                            Notes
                            <span className="attachment-remove-btn">
                                <label>Remove Note</label>
                                <IonIcon 
                                    icon={close}
                                    onClick={() => {
                                        updateEditedEvent({...editedEvent, notes: "" });
                                        setShowNotesSection(false);
                                    }}
                                />
                            </span>
                        </h2>
                        <IonTextarea
                            value={editedEvent.notes} 
                            onIonInput={(e) => { 
                                updateEditedEvent({...editedEvent, notes: Utilities.capitalizeFirstLetter((e.target as HTMLIonTextareaElement).value)}) 
                            }}
                            rows={2}
                            autoGrow={true}
                            onIonBlur={() => {
                                if (editedEvent.notes) {
                                    InternalTracker.trackEvent('Event Description Set', {
                                        value: editedEvent.notes
                                    })
                                }
                            }}
                            placeholder="Notes"
                            id="event-notes-input"
                        />
                    </div>
                }
                <div 
                    className="attachments"
                    style={{ marginBottom: keyboardOpen ? 360 : 92, marginTop: 16 }}
                >
                    <div>
                        { (!showRepeatsSection || !showLocationSection || !showNotesSection) &&
                            <IonIcon icon={addCircle} />
                        }
                        { (!showRepeatsSection && drawerTitle.indexOf("events") === -1) &&
                            <button
                                id="repeat-enable-btn"
                                onClick={() => {
                                    if (editedEvent.groupId) {
                                        (window as any).toast("This is an instance of a groupped event, to use repeats please select a single event", "error")
                                    } else if (dates && dates.length > 1) {
                                        (window as any).toast("You have selected multiple days, to use repeats please select a single day", "error")
                                    } else {
                                        setShowRepeatsSection(true);
                                        if (editedEvent.repeatType === 0) {

                                            let repeatStartOverride = null;

                                            updateEditedEvent({
                                                ...editedEvent, 
                                                repeatForever: false, 
                                                repeatUntil: repeatStartOverride ? repeatStartOverride.toISOString() : (new Date(editedEvent.start)).toISOString(), 
                                                repeatType: 1
                                            })
                                        }
                                    }
                                }}
                            >
                                <IonIcon icon={repeatSharp} />
                                <span>Repeat</span>
                            </button>
                        }
                        { (!showLocationSection) &&
                            <button
                                onClick={() => {
                                    setShowLocationSection(true)
                                    setTimeout(() => {
                                        const el = document.getElementById("location-search-input") as HTMLInputElement;
                                        if (el) {
                                            el.value = "";
                                            el.focus();
                                            setTimeout(() => {
                                                el.scrollIntoView({ block: 'start', inline: 'nearest' });
                                            }, 250)
                                        }
                                    }, 250);
                                }}
                            >
                                <IonIcon icon={locationSharp} />
                                <span>Location</span>
                            </button>
                        }
                        { (!showNotesSection) &&
                            <button
                                onClick={() => {
                                    setShowNotesSection(true);
                                    setTimeout(() => {
                                        const el = document.getElementById("event-notes-input");
                                        if (el) {
                                            (el.firstChild.firstChild as HTMLElement).focus();
                                            setTimeout(() => {
                                                el.scrollIntoView({ block: 'start', inline: 'nearest' });
                                            }, 150)
                                        }
                                    }, 150);
                                }}
                            >
                                <IonIcon icon={pencilSharp} />
                                <span>Notes</span>
                            </button>
                        }
                    </div>
                </div>
            </div>  
            { (errorMessage) && <p className="error-msg">{errorMessage}</p> }
            <IonAlert
                isOpen={updateEventTitlePrompt !== false}
                onDidDismiss={() => setUpdateEventTitlePrompt(false)}
                header={'Change event title?'}
                message={/*'You updated the event type, do you want to keep your existing title (' + updateEventTitlePrompt + ') or update it with the default (' + (editedEvent.eventType === 1 ? "Working - I'm unavailable" : editedEvent.eventType === 2 ? "Private - I'm unavailable" : editedEvent.eventType === 3 ? "Private - I'm unavailable but offers considered" : "I am available" )+ ') ?'*/ null}
                buttons={[
                    {
                        text: 'Keep existing: "' + updateEventTitlePrompt + '"',
                        handler: () => {
                            save();
                        }
                    },
                    {
                        text: 'Update to default: "' + (editedEvent.eventType === 1 ? "Working - I'm unavailable" : editedEvent.eventType === 2 ? "Private - I'm unavailable" : editedEvent.eventType === 3 ? "Private - I'm unavailable but offers considered" : "I am available" ) + '"',
                        handler: () => {
                            updateEditedEvent({...editedEvent, title: "" })
                            // save(null, null, true)
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={overnightConfirm}
                onDidDismiss={() => setOvernightConfirm(false)}
                header={'Your event is running overnight. Are you sure you want to save it?'}
                message={/*'You updated the event type, do you want to keep your existing title (' + updateEventTitlePrompt + ') or update it with the default (' + (editedEvent.eventType === 1 ? "Working - I'm unavailable" : editedEvent.eventType === 2 ? "Private - I'm unavailable" : editedEvent.eventType === 3 ? "Private - I'm unavailable but offers considered" : "I am available" )+ ') ?'*/ null}
                buttons={[
                    {
                        text: 'Keep it overnight (' + (new Date(editedEvent.start).getHours() + ":" + new Date(editedEvent.start).getMinutes()) + "-" + (new Date(editedEvent.end).getHours() + ":" + new Date(editedEvent.end).getMinutes()) + ')',
                        handler: () => {
                            save(preSavePreventedConfig.confirmedGroupEdit, preSavePreventedConfig.confirmedRepeatEdit, preSavePreventedConfig.resetTitle, preSavePreventedConfig.readOnlyEvent, true)
                        }
                    },
                    {
                        text: 'Swap start and end dates (' + (new Date(editedEvent.end).getHours() + ":" + new Date(editedEvent.end).getMinutes()) + "-" + (new Date(editedEvent.start).getHours() + ":" + new Date(editedEvent.start).getMinutes()) + ')',
                        handler: () => {
                            updateEditedEvent({
                                ...editedEvent,
                                start: editedEvent.end,
                                end: editedEvent.start
                            })
                        }
                    },
                    {
                        text: 'Keep it overnight (' + (new Date(editedEvent.start).getHours() + ":" + new Date(editedEvent.start).getMinutes()) + "-" + (new Date(editedEvent.end).getHours() + ":" + new Date(editedEvent.end).getMinutes()) + '), and never show agani',
                        handler: () => {
                            save(preSavePreventedConfig.confirmedGroupEdit, preSavePreventedConfig.confirmedRepeatEdit, preSavePreventedConfig.resetTitle, preSavePreventedConfig.readOnlyEvent, true)
                            UserAPI.updateSetting("31", "true");
                            localStorage.setItem("confirm-overnightTipsGuidePassed", "true");
                        }
                    },
                ]}
            />
            <IonAlert
                isOpen={groupDeleteConfirmAlert !== 0}
                onDidDismiss={() => setGroupDeleteConfirmAlert(0)}
                header={"Do you want to delete just this event or all " + groupDeleteConfirmAlert + " events in this group?"}
                buttons={[
                    {
                        text: 'Delete All',
                        handler: () => {
                            deleteEvent(true)
                        }
                    },
                    {
                        text: 'Delete This',
                        handler: () => {
                            deleteEvent();
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={groupRepeatDeleteConfirmAlert !== 0}
                onDidDismiss={() => setGroupRepeatDeleteConfirmAlert(0)}
                header={"Do you want to delete just this event or all events in this repeat group?"}
                buttons={[
                    {
                        text: 'Delete All',
                        handler: () => {
                            deleteEvent()
                        }
                    },
                    {
                        text: 'Delete This',
                        handler: () => {
                            deleteEvent(null, true);
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={groupEditConfirmAlert !== 0}
                onDidDismiss={() => setGroupEditConfirmAlert(0)}
                header={"Grouped Event"}
                message={"Do you want to update all " + groupEditConfirmAlert + " events in this group"}
                buttons={[
                    {
                        text: 'Edit All',
                        handler: () => {
                            save(true);
                        }
                    },
                    {
                        text: 'Edit Only This',
                        handler: () => {
                            save(false);
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={repeatEditConfirmAlert}
                onDidDismiss={() => setRepeatEditConfirmAlert(false)}
                header={"Repeating Event"}
                message={"Edit this event only or all events?"}
                buttons={[
                    {
                        text: 'Edit All Events',
                        handler: () => {
                            save(undefined, false);
                        }
                    },
                    {
                        text: 'This Event Only',
                        handler: () => {
                            save(undefined, true);
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={collidingEventsError !== null}
                onDidDismiss={() => setCollidingEventsError(null)}
                header={"Clashing Events"}
                message={collidingEventsError}
                buttons={[
                    { text: 'Modify this Event', handler: () => { setCollidingEventsError(null) } },
                    { text: 'Discard this Event', handler: () => { onClose(0, null, []) } },
                    { text: 'Save as draft and review', handler: () => { reviewCollidingEvents(collidingEvents, dates); } },
                ]}
            />
        </div>
        { (keyboardOpen) &&
            <div className="keyboard-hide">
                <span>Hide Keyboard</span>
                <IonIcon icon={caretDownCircle} />
            </div>
        }
        { true &&
            <IonFab vertical="bottom" horizontal="start" slot="fixed" style={ keyboardOpen ? { /*position: "fixed"*/ } : { } }>
                { (!recentEventAddMode && !draft) &&
                    <IonFabButton id="delete-event-btn" style={{ display: (drawerTitle.indexOf("Creating") === -1) ? "block": "none" }} className="delete-btn" onClick={ async () => {     
                            
                        if (isOpen) {
                            setIsOpen(false);
                        }

                        let sameGroupEvents: Event[] = editedEvent.groupId ? await getEventsByGroupId(editedEvent.groupId) : [];

                        // There is at least one more event that is not deleted in this group
                        if (sameGroupEvents.length > 1) {
                            setGroupDeleteConfirmAlert(sameGroupEvents.length);
                        } else if (editedEvent.repeatType) {
                            setGroupRepeatDeleteConfirmAlert(1)
                        } else {
                            if (window.confirm("Are you sure you want to delete this event?")) {
                                deleteEvent();
                            }
                        }
                            
                    }}>
                        <IonIcon icon={trash} />
                    </IonFabButton>
                }
                { (!recentEventAddMode && !draft && !onboardingModal) &&
                    <IonFabButton 
                        id="favorite-toggle-btn" style={{ width: 166, '--border-radius': '56px', "--background": (editedEvent.pinned) ? "#FFA400" : "#D6D6D6", "--background-activated": (editedEvent.pinned) ? "#FFA400" : "#D6D6D6", "--background-focused": (editedEvent.pinned) ? "#FFA400" : "#D6D6D6", "--background-hover": (editedEvent.pinned) ? "#FFA400" : "#D6D6D6" }} 
                        className="favorite-btn" 
                        data-active={editedEvent.pinned ? "true" : "false"} 
                        onClick={() => { 

                            updateEditedEvent({...editedEvent, pinned: !editedEvent.pinned})

                            InternalTracker.trackEvent(editedEvent.pinned ? "Event Unfavorited" : "Event Favorited", {
                                id: editedEvent.id,
                            })

                        }}
                    >
                        <IonIcon style={{ fontSize: '1.6em', marginRight: 8, color: (editedEvent.pinned ? "white" : "#555") }} icon={editedEvent.pinned ? star : star} />
                        <IonLabel style={{ fontWeight: 600, position: "relative", top: -1, color: (editedEvent.pinned ? "white" : "#555") }}>
                            {editedEvent.pinned ? "Added to favorites" : "Add to favorites"}
                        </IonLabel>
                    </IonFabButton>
                }
                <IonFabButton 
                    className="save-btn" 
                    id="save-event-btn" 
                    onClick={() => { 
                        if (isOpen) {
                            setIsOpen(false)
                        }
                        save(undefined, undefined, undefined, readOnlyEvent)
                    }
                    }
                    style={{
                        width: 138,
                        '--border-radius': '52px',
                    }}
                >
                    <IonIcon style={{ fontSize: '2em', marginRight: 8 }} icon={checkmarkCircle} />
                    <IonLabel style={{ fontWeight: 600, position: "relative", top: -1 }}>Save {dates && dates.length === 1 ? "Event" : "Events"}</IonLabel>
                </IonFabButton>
            </IonFab>
        }
        { tipsGuideSection &&
          <TipsGuidePrompt 
            section={tipsGuideSection}
            onClose={(actioned: boolean) => {
                if (tipsGuideSection === "availability-over-work") {
                    if (actioned) {
                        (window as any).ignoreWorkOverAvailability = true;
                        if (isOpen) {
                            setIsOpen(false)
                        }
                        save(undefined, undefined, undefined, readOnlyEvent)
                    }
                }
                setTipsGuideSection(null);
            }}
          />
        }
    </div>
    }

    if (recentEventAddMode === "auto" && !errorMessage) {
        MODAL_CONTENT_DOM = <div id="event-editor-drawer-content" style={{ height: '100%' }} className="swipeable-drawer-body" data-drawer="event-editor">
            <div className="content" style={{ overflowX: 'hidden' }} ref={contentRef}>
                <div className="full-screen-loading" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IonSpinner name="crescent" style={{ '--color': "black", height: 72, width: 72 }} />
                </div>
            </div>
        </div>
    }
    
    if (isModalOpen)
        return (
            <div className="event-editor-wrapper" style={ hideContent ? { visibility: 'hidden' } : { } }>
                <IonHeader mode="ios" id="event-ion-header">
                    <IonToolbar>
                    <IonTitle></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent
                    data-modal="entry"
                    style={{ height: contentHeight || "100%" }}
                >
                    { MODAL_CONTENT_DOM }
                </IonContent>
            </div>
        );
    
    return null;
    
};

export default EventEditorDrawer;
