import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { ConsoleLogObserver, AuthService } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';
import Configuration from '../Configuration';
import { appInsights } from '../AppInsights';
import ChatAPI from '../apis/Chat';
import Utilities from '../Utilities';

import { AxiosRequestor } from './AxiosService';
import { ChatContact, LoadedConversation, LastMessageCacheEntry } from '../pages/Chat';
import { Conversation, Media, Message, Participant, Client, User } from '@twilio/conversations';
import store from '../state/store';
import { updateChats } from '../state/actions/Chats';
import { getCacheValue, setCacheValue } from '../stores/Cache';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

let tokenLast

export class ChatService {
  public static twillioClient: Client;
  public static contacts;
  public static twillioInit;
  public static ownTwilioIdentity;
  public static loadedConversations: LoadedConversation[] = [];
  public static unInitedThreads: ChatContact[] = [];
  public static startedInit;
  public static onlineStatuses: { [key: string]: boolean } = {};
  public static isLoaded: boolean = false;
  public static twillioFrindlyNames: { [key: string]: string } = {};
  public static staleData: boolean = true;
  public static lastMessages: { 
    [key: string]: LastMessageCacheEntry 
  } = localStorage.getItem('chat-last-messages') ? JSON.parse(localStorage.getItem('chat-last-messages') || "{}") : {};

  public static async reloadContacts() {
    if (this.twillioClient) {
      await this.twillioClient.shutdown();
      this.destroyClient();
      await this.init(false);
    }
  }

  public static destroyClient() {
    this.twillioClient = null;
    (window as any).twillioClient = null;
  }

  public static async init(preventFreshContactFetch?: boolean) {

    if (!(window as any).db) {
      setTimeout(() => {
        this.init();
      }, 200)
      return;
    }

    if (this.twillioClient) {
      return this.twillioClient;
    } else {
      if (this.startedInit) {
        return this.twillioClient;
      }

      this.startedInit = true;
      this.twillioInit = await this.getToken();
      this.contacts = await this.getContacts(preventFreshContactFetch);
      console.log("NEW CONTACTS: ", this.contacts)
      this.twillioFrindlyNames = localStorage.getItem("twillioFrindlyNames") ? JSON.parse(localStorage.getItem("twillioFrindlyNames")) : {}
      this.ownTwilioIdentity = this.twillioInit.self;
      const staleData = await getCacheValue("chat");
      if (staleData) {
        this.loadedConversations = staleData as LoadedConversation[];
        this.unInitedThreads = this.contacts.filter(contact => !this.loadedConversations.find(convo => convo.contactId === contact.userId));
        store.dispatch(updateChats(this.loadedConversations));
        this.isLoaded = true;
        this.staleData = true;
      } else {
        this.staleData = false;
      }

      console.log("@@@@ RECONNECTED")

      if ((window as any).onTwillioReconnect) {
        (window as any).onTwillioReconnect();
      }

      let tokenLastUpdate = new Date();

      this.twillioClient = new Client(this.twillioInit.token);
      (window as any).twillioClient = this.twillioClient;

      this.startedInit = false;

      this.twillioClient.on("connectionStateChanged", async (e) => {
        console.log("@@@ connectionStateChanged" + e)
      })

      this.twillioClient.on("connectionError", async (e) => {
        console.log("@@@ connectionError", e);
        this.twillioClient.removeAllListeners();
        this.twillioClient.shutdown();
        setTimeout(() => {
          this.destroyClient();
          this.init(true);
          // window.location.href = "/"
        }, 4000)
      })

      this.twillioClient.on('stateChanged', async (state) => {
          console.log("@@@ State change - " + state, this.twillioClient)
          if (state === 'initialized') {
            let allConvos: Conversation[] = [];
            let convos = await this.twillioClient.getSubscribedConversations();
            allConvos = allConvos.concat(convos.items);
            while (convos.hasNextPage) {
                convos = await convos.nextPage();
                allConvos = allConvos.concat(convos.items);
            }
        
            this.handleNewChannels(allConvos);
            ChatService.isLoaded = true;
            this.twillioClient.on('messageAdded', m => this.handleReceive(m));

          }
      })
      return this.twillioClient;
    }
  }

  public static async handleReceive (msg: Message) {
    let newConversationGroup: LoadedConversation | null = null;

    for (let i = 0; i < ChatService.loadedConversations.length; i++) {
        const conversation = ChatService.loadedConversations[i];
        if (conversation.conversation.sid === msg.conversation.sid) {
            let newConversations = ChatService.loadedConversations
            newConversations[i].messages.push(msg);
            const lastMessageIsFromMe = newConversations[i].messages[newConversations[i].messages.length - 1].author === this.ownTwilioIdentity;
            newConversations[i].hasUnread = !lastMessageIsFromMe;
            newConversations[i].diffBetweenLastAndNow = Utilities.differenceBetweenDatesSeconds(new Date(msg.dateCreated), new Date());
            newConversations.unshift(newConversations[i]);
            newConversations.splice(i+1, 1);
            newConversationGroup = newConversations[0]
            ChatService.loadedConversations = newConversations
            break;
        }
    }

    if (!newConversationGroup) {
      const newChannel = await this.twillioClient.getConversationBySid(msg.conversation.sid);
      this.contacts = await this.getFreshContacts();
      if (newChannel) {
          this.handleNewChannels([newChannel]);
      }
    }

    let senderId = msg.author;
    let senderName = senderId ? ChatService.contacts.find(cont => cont.userId === senderId)?.fullName || ChatService.twillioFrindlyNames[senderId] || "Unknown User" : "";
    const newMsgStr = (msg.body && msg.body.indexOf("contentType") !== -1 ? "New File" : (msg.body || "-"));

    if (!msg.body && msg.attachedMedia && msg.attachedMedia[0]) {
      if ((window as any).handleFileUploaded && this.ownTwilioIdentity === senderId) {
        (window as any).handleFileUploaded(msg);
      }
    } else {
      if (this.ownTwilioIdentity !== senderId) {
        if (senderName === "Unknown User") {
          (window as any).toast("New Message", "info")
          this.reloadContacts();
        } else {
          (window as any).toast(senderName + " says: " + newMsgStr, "info");
        }
      }

      setTimeout(() => {
        this.cacheLastMessagesDirectEntry(msg.conversation.sid, msg.index, newMsgStr, msg.dateCreated);
      }, 1000)

      if ((window as any).scrollToThreadBottom) {
        (window as any).scrollToThreadBottom();
      }
    }
    // if (activeChannel && newConversationGroup?.conversation.sid.trim() == activeChannel.conversation.sid.trim()) {
    //   if ((window as any).scrollToThreadBottom)
    //     (window as any).scrollToThreadBottom
    // }
  };

  public static updateState() {
    store.dispatch(updateChats(this.loadedConversations));
  }

  public static async handleOnlineStatusChange(userId, online) {
    let newOnlineStatuses = this.onlineStatuses;
    newOnlineStatuses[userId] = online;
    this.onlineStatuses = newOnlineStatuses;
  }

  public static async handleNewChannels(conversations: Conversation[], hasUnreadOverride?: boolean) {
    let newLoadedConversations: LoadedConversation[] = [];

    for (let i = 0; i < conversations.length; i++) {
        const conversation = conversations[i];

        let participants = await conversation.getParticipants();
        let participantsExcludingCurrentUser = participants.filter(item => item.identity !== this.ownTwilioIdentity);

        if (participantsExcludingCurrentUser.length !== 0) {
          const user = this.twillioClient.getUser(participantsExcludingCurrentUser[0].identity)
          .then(user => {
            this.twillioFrindlyNames[user.identity] = user.friendlyName
            localStorage.setItem("twillioFrindlyNames", JSON.stringify(this.twillioFrindlyNames));
            this.handleOnlineStatusChange(user.identity, user.isOnline)
            user.on('updated', (data) => {
              if(data.updateReasons.includes('reachabilityOnline')){
                this.handleOnlineStatusChange(data.user.identity, data.user.isOnline)
              }
            });
          });
        }

        const contactId = participantsExcludingCurrentUser[0] ? participantsExcludingCurrentUser[0].identity : "a";
        const contactName = this.contacts.find(cont => cont.userId === contactId)?.fullName || ChatService.twillioFrindlyNames[contactId] || "Unknown User";

        newLoadedConversations.push({
          hasUnread: hasUnreadOverride !== undefined ? hasUnreadOverride : ((conversation && conversation.lastMessage && conversation.lastMessage.index && conversation.lastReadMessageIndex && conversation.lastMessage.index !== conversation.lastReadMessageIndex) ? true : false),
          conversation: conversation,
          participantsAll: participants,
          participants: participantsExcludingCurrentUser.map(item => { return { id: item.identity, name: contactName } }),
          lastReadMessageIndex: conversation.lastReadMessageIndex,
          messages: [],
          diffBetweenLastAndNow: Utilities.differenceBetweenDatesSeconds(new Date(conversation.lastMessage ? conversation.lastMessage.dateCreated : conversation.dateUpdated), new Date()),
          dateUpdated: new Date(conversation.lastMessage ? conversation.lastMessage.dateCreated : conversation.dateUpdated),
          contactId: contactId
        })
    }

    this.loadedConversations = [
      ...this.loadedConversations.filter(convo => newLoadedConversations.map(item => item.conversation.sid).indexOf(convo.conversation.sid) === -1),
      ...newLoadedConversations.map(item => {
          item.messages = this.loadedConversations.find(cItem => cItem.conversation.sid === item.conversation.sid)?.messages || [];
          return item;
      })
    ]
      
    this.staleData = false;

    const chatCache = this.loadedConversations.map(item => this.removeCircularRefsFromConversations(item));
    setCacheValue("chat", chatCache);

    this.unInitedThreads = this.contacts.filter(contact => !this.loadedConversations.find(convo => convo.contactId === contact.userId));
    store.dispatch(updateChats(this.loadedConversations));

    setTimeout(() => {
      this.cacheLastMessages(newLoadedConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow")).map(item => item.conversation));
    }, 1000)
  }

  private static removeCircularRefsFromConversations(originalObj) {
    let obj = Object.assign({}, originalObj)

    return {
        sid: obj.conversation.sid,
        conversation: {
            sid: obj.conversation.sid,
        },
        messages: [],
        diffBetweenLastAndNow: obj.diffBetweenLastAndNow,
        hasUnread: obj.hasUnread,
        lastReadMessageIndex: obj.lastReadMessageIndex,
        participants: obj.participants && obj.participants[0] ? [{
            id: obj.participants[0].id,
            name: obj.participants[0].name
        }] : [],
        participantsAll: obj.participantsAll.map(item => {
            return {
                id: item.identity,
                dateUpdated: item.dateUpdated
            }   
        }),
        stale: true
    }
  }

  private static async cacheLastMessagesDirectEntry(channelId: string, index: number, msgStr: string, date: Date) {
    let newCaches = this.lastMessages;
    newCaches[channelId] = {
      body: msgStr,
      date: date,
      index: index
    }
    this.lastMessages = newCaches;
    localStorage.setItem("chat-last-messages", JSON.stringify(newCaches));
  }

  private static async cacheLastMessages(conversations: Conversation[]) {
    let newCaches = this.lastMessages
    for (let i = 0; i < conversations.length; i++) {
        const convo = conversations[i];
        const currentCache = newCaches[convo.sid];
        if (convo.lastMessage && (!currentCache || currentCache.index !== convo.lastMessage.index)) {
            let latestMessages = await convo.getMessages(5);
            if (latestMessages && latestMessages.items[0]) {
              latestMessages.items = latestMessages.items.reverse();
              
              let msg = null
              let mainMessageBody = "No Messages";
              
              let i = 0;
              
              // Rolling back to previous messages if the last one is an unset file
              while (i < latestMessages.items.length) {
                msg = latestMessages.items[i];
                if (msg) {
                  mainMessageBody = msg.body ? (msg.body.indexOf("contentType") !== -1 ? "Files Received" : msg.body) : "No Messages";
                  // i++;
                  if (mainMessageBody !== "No Messages") {
                    break;
                  } else {
                    latestMessages.items.splice(0, 1);
                  }
                }
              }       

              newCaches[convo.sid] = {
                  body: mainMessageBody,
                  date: msg.dateCreated,
                  index: msg.index
              }
            }
        }
    }
    this.lastMessages = newCaches;
    localStorage.setItem("chat-last-messages", JSON.stringify(newCaches));
  }

  private static async getFreshToken () {
    const init = await ChatAPI.getToken()
    localStorage.setItem("twillio-token", JSON.stringify({
        ...init,
        timestamp: new Date().getTime()
    }));
    return init;
  }

  private static async getToken() {
      let token = localStorage.getItem("twillio-token") ? JSON.parse(localStorage.getItem("twillio-token") || "") : null;
      let tokenRefreshedSinceSeconds = token ? Utilities.differenceBetweenDatesSeconds(new Date(), token.timestamp) : null;
      if (!tokenRefreshedSinceSeconds || tokenRefreshedSinceSeconds > (3600 * 2)) {
        let freshToken = await this.getFreshToken();
        return freshToken;
      } else {
        this.getFreshToken();
        return token;
      }
  }

  private static async getFreshContacts() {
      let data = await ChatAPI.getContactable();
      if (data) {
        // @ts-ignore
        data = data.filter((v,i,a)=>a.findIndex(t=>(t.userId === v.userId))===i)
        this.contacts = data as ChatContact[];
        localStorage.setItem("chat-contacts", JSON.stringify(data));
        return data;
      }
  }

  private static async getContacts(preventFreshContactFetch?: boolean) {
      if (localStorage.getItem("chat-contacts")) {
        if (!preventFreshContactFetch) {
          this.getFreshContacts();
        }
        return JSON.parse(localStorage.getItem("chat-contacts") || "");
      } else {
        let contacts = this.getFreshContacts();
        return contacts;
      }
  }
}
