
import React, { useEffect, useState } from 'react';
import Configuration from '../../../Configuration';
// import '../../../styles/Components/Rating/RatingPreviewExtended.scss';
import { RatingRecord } from '../../../types/Offer';
import ReactStars from "react-rating-stars-component";
import Utilities from '../../../Utilities';
import UserApi from '../../../apis/User';
import RatingApi from '../../../apis/Rating';
import { IonAlert, IonIcon, IonLoading } from '@ionic/react';
import { alert, checkmarkDone, close } from 'ionicons/icons';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const RatingPreviewExtended: React.FC<{
    rating: RatingRecord,
    hideRatee?: boolean,
    promptRateBack?: boolean,
    onClick?: () => void,
    refresh?: () => void,
    preview?: boolean
    notOwn?: boolean
}> = ({ rating, hideRatee, promptRateBack, onClick, refresh, preview, notOwn }) => {
    const hasPublicRating = !!rating.publicComment;
    const [raterImageFailedToLoad, setRaterImageFailedToLoad] = useState <boolean> (false);
    const [recipientImageFailedToLoad, setRecipientImageFailedToLoad] = useState <boolean> (false);
    const [loading, setLoading] = useState <boolean> (false);
    const [confirmHideId, setConfirmHideId] = useState <number> (null);
    const [reportConfirmId, setReportConfirmId] = useState <number> (null);

    const publish = async function(id) {
        setLoading(true);
        RatingApi
            .approve(id)
            .then(data => {
                setLoading(false);
                (window as any).toast("Review Approved", "success");
                if (refresh) {
                    refresh()
                }
            }).catch(error => {
                setLoading(false);
                (window as any).toast("Coudn't Approve Review", "error");
            })
    }

    const hide = async function(id) {
        setLoading(true);
        RatingApi
            .hide(id)
            .then(data => {
                setLoading(false);
                (window as any).toast("Review Hidden", "success");
                if (refresh) {
                    refresh()
                }
            }).catch(error => {
                setLoading(false);
                (window as any).toast("Coudn't Hide Review", "error");
            })
    }

    const report = async function(id) {
        if (!window.confirm("Are you sure you want to report this review?")) {
            return;
        }
        setLoading(true);
        RatingApi
            .report(id)
            .then(data => {
                setLoading(false);
                (window as any).toast("Thank you, we will look into it.", "success");
                if (refresh) {
                    refresh()
                }
            }).catch(error => {
                setLoading(false);
                (window as any).toast("Coudn't Report Review", "error");
            })
    }

    if (rating && rating.recipientHidden) {
        return null;
    }

    return (
        <div 
            className='rating-preview-extended' 
            data-show-ratee={!hideRatee}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            data-id={rating.id}
            data-published={((((rating.recipientApproved && hasPublicRating) || !hasPublicRating)) ? "true" : "false")}
        >
            <div className='header'>
                <div className='profile'>
                    <div className="rater-profile">
                        <img 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                setRaterImageFailedToLoad(true)
                            }}
                            data-user-id={rating.raterContactId}
                            data-report-blurred={rating.raterUserReported}
                            src={
                                raterImageFailedToLoad ?
                                "https://test-images.updatedge.com/profile/noprofile.png" :
                                (rating.raterExternalOrgName && rating.raterExternalOrgDomain) ?
                                    UserApi.getExtOrgPicture(rating.raterExternalOrgDomain) :
                                    (!rating.raterContactId && rating.providerOrgName && rating.providerOrgId && rating.raterOrgId && rating.raterOrganisationName) ?
                                        UserApi.getOrgPicture(rating.raterOrgId) :
                                        UserApi.getProfilePicture(rating.raterUserId || "undefined") 
                            } 
                        />
                        { (rating.raterContactId && rating.raterOrgId) &&
                            <img data-user-id={rating.raterContactId} data-report-blurred={rating.raterUserReported} className="secondary" src={UserApi.getOrgPicture(rating.raterOrgId)} />
                        }
                        { (rating.providerOrgName && rating.providerOrgId) &&
                            <img className="secondary" src={UserApi.getOrgPicture(rating.providerOrgId)} />
                        }
                        <h2 data-user-id={rating.raterContactId} data-report-blurred={rating.raterUserReported} >{(rating.raterExternalOrgName && rating.raterExternalOrgDomain) ?
                            <React.Fragment>
                                <span>{rating.raterExternalOrgName}</span>
                                { (rating.providerOrgName) &&
                                    <span>care of {rating.providerOrgName}</span>
                                }
                            </React.Fragment> :    
                        (!rating.raterInternalContactId && rating.providerOrgName && rating.providerOrgId && rating.raterOrganisationName) ?
                            <React.Fragment>
                                <span>{rating.raterOrganisationName}</span>
                                <span>care of {rating.providerOrgName}</span>
                            </React.Fragment> :
                         (rating.raterFirstName && rating.raterLastName) ? 
                            <React.Fragment>
                                <span>{rating.raterFirstName} {rating.raterLastName}</span>
                                { (rating.raterOrganisationName) &&
                                    <span>from {rating.raterOrganisationName}</span>
                                }
                            </React.Fragment> :
                            <span>Anonymous</span>
                        }</h2>
                    </div>
                    { (!hideRatee) &&
                        <React.Fragment>
                            <div className='rated'>
                                rated
                            </div>
                            <div className="recipient-profile">
                                <img
                                    src={
                                        recipientImageFailedToLoad ? "https://test-images.updatedge.com/profile/noprofile.png" :
                                        rating.recipientExternalOrgName && rating.recipientExternalOrgDomain ? UserApi.getExtOrgPicture(rating.recipientExternalOrgDomain) :
                                        (!rating.recipientContactId && rating.providerOrgName && rating.providerOrgId && rating.recipientOrgId && rating.recipientOrganisationName) ? UserApi.getOrgPicture(rating.recipientOrgId) :
                                        rating.recipientUserId ? UserApi.getProfilePicture(rating.recipientUserId) :
                                        rating.recipientOrgId ? UserApi.getOrgPicture(rating.recipientOrgId) :
                                        UserApi.getProfilePicture("undefined")
                                    }
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        setRecipientImageFailedToLoad(true)
                                    }}
                                />
                                { (rating.recipientContactId && rating.recipientOrgId) &&
                                    <img className="secondary" src={UserApi.getOrgPicture(rating.recipientOrgId)} />
                                }
                                <h2>{
                                    (rating.recipientFirstName && rating.recipientLastName) ? 
                                    <React.Fragment>
                                        <span>{rating.recipientFirstName}</span>
                                        <span>{rating.recipientLastName}</span>
                                    </React.Fragment> :
                                        (rating.recipientOrganisationName) ? 
                                        <span>{rating.recipientOrganisationName}</span> :
                                            <span>Anonymous</span>
                                }</h2>
                                <h2>
                                    { (rating.recipientFirstName && rating.recipientLastName || rating.recipientExternalContactName) &&
                                        <React.Fragment>
                                            { (rating.recipientFirstName) &&
                                                <span>{rating.recipientFirstName}</span>
                                            }
                                            { (rating.recipientLastName) &&
                                                <span>{rating.recipientLastName}</span>
                                            }
                                            { (rating.recipientExternalContactName) &&
                                                <span>{rating.recipientExternalContactName}</span>
                                            }
                                        </React.Fragment>
                                    }
                                    <span>{rating.recipientOrganisationName || rating.recipientExternalOrgName || ((rating.recipientFirstName && rating.recipientLastName || rating.recipientExternalContactName) ? "" : "Anonymous")}</span>
                                </h2>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className='right'>
                    <ReactStars
                        value={rating.stars}
                        count={5}
                        edit={false}
                        size={24}
                        activeColor="#ffd700"
                    />
                    <div className='bottom-right'>
                        <div className='date'>
                            {Utilities.formatDate(new Date(rating.updatedAt || ""), "d mms YYYY")}
                        </div>
                        { (!rating.recipientHidden && rating.publicComment) &&
                            <button onClick={(e) => {
                                e.stopPropagation();
                                setReportConfirmId(rating.id)
                            }}>
                                {/* <IonIcon icon={alert} /> */}
                                Report
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div 
                className='body' 
                data-published={((((rating.recipientApproved && hasPublicRating) || !hasPublicRating)) ? "true" : "false")}
                data-own={!notOwn}
            >
                { (hasPublicRating) ?
                    <p>{rating.publicComment && Utilities.capitalizeFirstLetter(rating.publicComment)}</p>
                    :
                    null
                }
                { (
                    (!rating.recipientApproved) ||
                    (!rating.recipientHidden && !rating.recipientApproved) ||
                    (promptRateBack)
                ) &&
                    <div className='options'>
                        { (!rating.recipientApproved && hasPublicRating) &&
                            <label>Unpublished, please approve</label>
                        }
                        <div>
                            { (!rating.recipientApproved && !preview) &&
                                <button className='main' onClick={(e) => {
                                    e.stopPropagation();
                                    publish(rating.id)
                                }}>
                                    <IonIcon icon={checkmarkDone} />
                                    Publish
                                </button>
                            }
                            { (!rating.recipientHidden && !rating.recipientApproved && !preview) &&
                                <button className='main' onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmHideId(rating.id)
                                }}>
                                    <IonIcon icon={close} />
                                    Hide
                                </button>
                            }
                            { (promptRateBack) &&
                                <button className='main'>Rate Back</button>
                            }
                        </div>
                    </div>
                }
            </div>
            <IonAlert
                isOpen={confirmHideId !== null}
                onDidDismiss={() => { setConfirmHideId(null) } }
                header={"Hiding ratings will prevent this rating to be shown to other users, but the star rating will be still calculated into your average rating"}
                buttons={[
                {
                    text: 'Hide Rating',
                    handler: () => {
                        hide(confirmHideId)
                    }
                },
                {
                    text: 'Cancel',
                    handler: () => { }
                }
                ]}
            />
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={"Loading"}
                duration={12000}
            />
            <IonAlert
                isOpen={reportConfirmId !== null}
                onDidDismiss={() => { setReportConfirmId(null) }}
                header={"Confirm Report"} 
                message={"We will hide the comment until reviewed. Only inappropriate reviews are removed."}               
                buttons={[
                {
                    text: 'Report',
                    handler: () => {
                        report(rating.id);
                    }
                },
                {
                    text: 'Cancel',
                    handler: () => { setReportConfirmId(null) }
                }
                ]}
            />
        </div>
    );
    
};

export default RatingPreviewExtended;