import * as React from 'react';
import { DestroyStorage } from '../stores/Index';
import { destroyCache } from '../stores/Cache';

class Logos extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        // @ts-ignore
        const impersonatedAccessToken = /access_token=([^&]+)/.exec(window.location.href)[1];
        localStorage.setItem("admin_access_token", localStorage.getItem("access_token"));
        localStorage.setItem("access_token", impersonatedAccessToken || "");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("hello");
        localStorage.removeItem("firstSyncDone");
        localStorage.removeItem("existingNotifications");
        localStorage.removeItem("notificationToken");
        localStorage.removeItem("lastSync");
        localStorage.removeItem("lastLocalEventsCount");
        const resDbDestroy = await DestroyStorage();
        setTimeout(() => {
            window.location.href = "/";
        }, 1000);
    }

    render() {
        return <div>Impersonating...</div>;
    }

}

export default Logos;