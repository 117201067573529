import React from 'react';
// import '../../styles/Components/InlineSelect.scss';

interface InlineSelectOption {
    id?: any,
    label: string,
    icon?: any,
    iconImg?: any,
}

interface InlineSelectProps {
    onSelect: (string) => void;
    selected: string;
    options: any;
    id?: string;
    hideUnselectedOptions?: boolean;
    altSelectedStyle?: boolean;
    disabledToastMsg?: string;
    disableOption?: string[];
}

const InlineSelect: React.FC<InlineSelectProps> = ({ onSelect, selected, options, id, hideUnselectedOptions, altSelectedStyle, disabledToastMsg, disableOption }) => {

    // const [drawerTitle, setDrawerTitle] = useState <string> ("Editing 1 event");  // Title of the drawer indicating what is being edited

    return (
        <div className="inline-select" id={id}>
            { options.map((item, i) => {
                let opt = item as InlineSelectOption;
                if (hideUnselectedOptions && !((typeof opt.id === "object") ? opt.id.indexOf(selected) !== -1 : opt.id === selected.toString())) {
                    return null;
                }
                return (
                    <div 
                        data-id={typeof opt.id === "object" ? opt.id[0] : opt.id} key={i}
                        onClick={() => { 
                            if (disableOption && disableOption.indexOf(typeof opt.id === "object" ? opt.id[0] : opt.id) !== -1 ) {
                                (window as any).toast("This option is disabled", "info");
                            } else if (disabledToastMsg) {
                                (window as any).toast(disabledToastMsg, "info");
                            } else {
                                onSelect(typeof opt.id === "object" ? opt.id[0] : opt.id)
                            }
                        }} 
                        data-disabled={ disableOption && disableOption.indexOf(typeof opt.id === "object" ? opt.id[0] : opt.id) !== -1 }
                        data-selected={ (typeof opt.id === "object") ? opt.id.indexOf(selected) !== -1 : opt.id === selected.toString() }
                        data-alt-selected={ altSelectedStyle ? ((typeof opt.id === "object") ? opt.id.indexOf(selected) !== -1 : opt.id === selected.toString()) : false }
                    >
                        { (opt.icon) && opt.icon }
                        { (opt.iconImg) && <img style={{ maxHeight: 60, marginBottom: 10 }} src={opt.iconImg} alt={opt.label} /> }
                        <label>{opt.label}</label>
                    </div>
                )
            }) }
        </div>
    );
    
};

export default InlineSelect;
