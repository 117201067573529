import { Plugins } from '@capacitor/core';
import { IonAlert, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { helpCircleOutline, helpOutline, star, stopwatch } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import Configuration from '../Configuration';
import { User } from '../types/User';
import Utilities from '../Utilities';
import { CircularProgress } from '@material-ui/core';
import RatingPreviewExtended from './Components/Rating/RatingPreviewExtended';
import { updateNewRatings } from '../state/actions/Ratings';
import store from '../state/store';
import UserApi from '../apis/User';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const COLOR_CODES = {
  1: '#50D890',
  2: '#B5BC0E',
  3: '#FB5B5A',
  4: '#FFA400',
  5: '#509ffe',
  6: '#777',
  7: '#333',
}

let alreadyCalledRegistration = false;

interface Props { }

const Settings: React.FC<Props> = ({ }) => {
  const [user, setUser] = useState <User> (null) // User
  const contentRef = useRef(null);
  const history = useHistory();
  const [pictureVersion, setPictureVersion] = useState <number> (0) // Picture version, to reload on change
  const [loading, setLoading] = useState <boolean> (false);
  const [avgResponseDescriptionModal, setAvgResponseDescriptionModal] = useState <boolean> (false);

  const reload = async (withoutServerFetch?: boolean) => {
    setLoading(true);
    store.dispatch(updateNewRatings([]));
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user) as User;
      setUser(user as User);
    }
    if (!withoutServerFetch) {
      let newUser = await Utilities.getUserProfile();
      if (newUser) {
        setUser(newUser as User);
        markRatingsAsSeen(newUser as User);
      }
    }
    setLoading(false)
  }

  const markRatingsAsSeen = async (user: User) => {
    const ratings = user.ratings;
    if (ratings) {
      let existingRatings: number[] = localStorage.getItem("existingRatings") ? JSON.parse(localStorage.getItem("existingRatings")) : [];
      localStorage.setItem("existingRatings", JSON.stringify(ratings.map(r => r.id)));
      store.dispatch(updateNewRatings([]));
    }
  }

  useEffect(() => {

      (window as any).reloadInsightsOnPage = function() {
        reload(true);
      }.bind(this);

      reload();

      history.listen(e => {
        if (e.pathname === "/insight") {
          setUser(null);
          reload();
        }
      })

  }, [])

  return (
    <IonPage data-page="me" ref={contentRef}>
      <IonHeader mode='md'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Insights</IonTitle>
          <div className="nonblocking-loading-wrapper" style={(loading) ? { display: 'flex' } : { display: 'none' }}>
            <CircularProgress />
            Loading Updates
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent id="me-wrapper" fullscreen>
        { (user) &&
          <div className='header'>
            <img src={UserApi.getProfilePicture(user.id)} />
            <h1>{user.firstName} {user.lastName}</h1>
            <div className='me-stats'>
              <div 
                className='me-stat'
                onClick={() => {
                  
                }}
              >
                <h3 style={{
                  color: '#FFC300'
                }}>
                  <IonIcon icon={star} />
                  {user.avgRating || "-"}
                </h3>
                <label>Avg Rating</label>
              </div>
              <div 
                className='me-stat'
                onClick={() => {
                  setAvgResponseDescriptionModal(true)
                }}
              >
                <h3 style={{
                  color: 'green'
                }}>
                  <IonIcon icon={stopwatch} />
                  {user.averageOfferResponseSeconds ? Utilities.timeToFormattedDate(user.averageOfferResponseSeconds) : "-"}
                </h3>
                <label>Avg Response Time</label>
                <IonIcon icon={helpCircleOutline} />
              </div>
            </div>
          </div>
        }
        <div className='lists'>
          { (user && user.ratings) &&
            <section data-section="ratings">
              <h2>Ratings, reviews received</h2>
              { user.ratings.map(rating => {
                return (
                  <RatingPreviewExtended
                    key={rating.id + "-" + rating.stars}
                    hideRatee={true}
                    rating={rating}
                    onClick={() => {
                      if (rating.raterContactId) {
                        history.push('/contacts/' + rating.raterContactId)
                      }
                    }}
                    refresh={() => {
                      reload();
                    }}
                  />
                )
              }) }
            </section>
          }
        </div>
        <IonAlert
            isOpen={avgResponseDescriptionModal}
            onDidDismiss={() => { setAvgResponseDescriptionModal(false) }}
            header={"Rapid offer responses improves your profile, & helps contacts fill jobs quicker."} 
            buttons={[
                {
                    text: 'Close',
                    handler: () => { 
                        setAvgResponseDescriptionModal(false)
                    }
                }
            ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Settings;
