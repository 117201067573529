import { IonContent, IonIcon, IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react';
import { close, star, starOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { getEventById, getPinnedEvents, getRecentUniqueEvents, saveEvent } from '../../../stores/Event';
// import '../../../styles/Components/Calendar/RecentEventsDrawer.scss';
// import '../../../styles/DrawerBase.scss';
import { Event } from '../../../types/Event';
import EventTimelineItem from './EventTimelineItem';
import UserApi from '../../../apis/User';
import Utilities from '../../../Utilities';
import { useTour } from '@reactour/tour'
import InternalTracker from '../../../InternalTracker';

interface RecentEventsDrawerProps {
    isModalOpen: string;
    testing?: boolean;
    onClose?: (string?) => void;
    presentingElement?: any;
}

const DEFAULT_EVENT:Event = {
    "deleted": false,
    "updatedAt": "",
    "createdAt": "",
    "version": "",
    "id": "",
    "orderingUpdatedDate": "",
    "groupId": "",
    "pinned": false,
    "notes": "",
    "googlePlacesId": "",
    "repeatType": 0,
    "isSynced": false,
    "repeatForever": false,
    "repeatUntil": "",
    "end": "",
    "start": "",
    "eventType": 4,
    "title": "",
    "userId": ""
}

const RecentEventsDrawer: React.FC<RecentEventsDrawerProps> = ({ onClose, presentingElement, isModalOpen, testing }) => {

    const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()
    const [recentUniqueEvents, setRecentUniqueEvents] = useState <Event[]> ([]); // All events that are unique
    const [favoriteEvents, setFavoriteEvents] = useState <Event[]> ([]);  // Favorite events
    const [section, setSection] = useState <string> ("recents");  // Favorite events

    const reloadRecents = async function(): Promise<void> {
        let recents: Event[] = await getRecentUniqueEvents();
        setRecentUniqueEvents(recents);
    }

    const reloadFavorites = async function(): Promise<void> {
        let favorites: Event[] = await getPinnedEvents();
        setFavoriteEvents(favorites);
    }

    const toggleFavorite = async function(eventId: string): Promise<void> {
        if (eventId.startsWith("Events_DefaultPinned")) {
            const SETTING_FIELD_NAME: string = eventId + "_Dismissed"
            let update = {};
            update[SETTING_FIELD_NAME.replace("Events", "events")] = 'true';
            Utilities.upsertLocalUser(update)
            UserApi.updateSetting(SETTING_FIELD_NAME, "true");
        } else {
            let event: Event = await getEventById(eventId);
            if (event && event.id) {
                event.pinned = !event.pinned;
                await saveEvent(event);
            }
        } 
        reloadRecents();
        reloadFavorites();
    }

    useEffect( () => {
        (async function() {
            if (isModalOpen) {
                setSection(isModalOpen);
                reloadRecents();
                reloadFavorites();
            }
        })();
    }, [isModalOpen])

    let MODAL_CONTENT_DOM = null;

    if (isModalOpen) {
        MODAL_CONTENT_DOM = <div id="recents-wrapper" style={{ height: '100%' }} className="swipeable-drawer-body" data-drawer="recent-events">
        <IonContent>
            <div className="header">
                <IonSegment mode="ios" value={section} onIonChange={e => { 
                    setSection(e.detail.value.toString());

                    InternalTracker.trackEvent("Switched Recents Section", {
                        category: 'Event',
                        action: "Switched to " + (e.detail.value === "recents" ? "Recents" : "Favorites") + " section"
                    });

                    if (isOpen) {
                        setTimeout(() => {
                            setCurrentStep(3)
                        }, 400);
                    }
                } }>
                    <IonSegmentButton value="recents">
                        <IonLabel>Recent Events</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="favorites">
                        <IonLabel>Favourites</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonIcon id="recent-events-drawer-close-btn" onClick={() => { onClose(null) }} icon={close} />
            </div>
            { (section === "recents") &&
                <section data-section="recents">
                    <div className="list">
                        { recentUniqueEvents.map(item => {
                            return (
                                <div key={item.id}>
                                    <EventTimelineItem event={item} onEdit={() => {
                                        if (isOpen) {
                                            setTimeout(() => {
                                                setCurrentStep(4)
                                            }, 700);
                                        }

                                        InternalTracker.trackEvent("Loaded event from recents", item);
                                        onClose(item.id)
                                    }} />
                                    <IonIcon className="favorite-toggle-btn" data-pinned={item.pinned ? "true" : "false"} onClick={() => { toggleFavorite(item.id) }} icon={ (item.pinned) ? star : starOutline } />
                                </div>
                            )
                        }) }
                    </div>
                </section>
            }
            { (section === "favorites") &&
                <section data-section="favorites">
                    <div className="list">
                        { favoriteEvents.map(item => {
                            return (
                                <div key={item.id}>
                                    <EventTimelineItem 
                                        event={item} 
                                        onEdit={() => { 
                                            onClose(item.id);
                                            InternalTracker.trackEvent("Loaded event from favorites", item);
                                        }}
                                    />
                                    <IonIcon className="favorite-remove-btn" style={{ fontSize: '1.4em', top: -8 }} onClick={() => { toggleFavorite(item.id) }} icon={close} />
                                </div>
                            )
                        }) }
                    </div>
                </section>
            }
        </IonContent>
    </div>
    }

    if (testing) {
        return MODAL_CONTENT_DOM;
    }

    return (
        <IonModal
            isOpen={isModalOpen !== null}
            canDismiss={isModalOpen === null}
            presentingElement={(presentingElement && presentingElement.current) ? presentingElement.current : null}
            onDidDismiss={() => { onClose(null) }}
            data-modal="entry"
            mode="ios"
        >
        { MODAL_CONTENT_DOM }
    </IonModal>
    );
    
};

export default RecentEventsDrawer;
