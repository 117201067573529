import { IonList, IonLabel, IonInput, IonItem, IonModal, IonAlert, IonActionSheet, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonSearchbar, IonTitle, IonToast, IonToolbar, IonButton, IonSegment, IonSegmentButton } from '@ionic/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addCircle, closeCircle, arrowBackOutline, share, informationCircle, location, briefcase, caretDownCircle, close, chevronBackCircle, personAdd, checkmarkDone, chevronForwardCircle, search, closeSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import ContactAPI from '../apis/ContactAPI';
import AvailabilityApi from '../apis/Availability';
import ContactsHero from '../images/contacts-hero.png';
import { refreshOnboardingGuide } from '../state/actions/OnboardingGuide';
import store from '../state/store';
import { Contact, ContactSearchType, ContactType, Referral, ReferralState } from '../types/Contact';
import Utilities from '../Utilities';
import ReferralListItem from './Components/Contacts/ReferralListItem';
import OnboardingGuide from './Components/OnboardingGuide';
import InternalTracker from '../InternalTracker';
import Configuration from '../Configuration';
import { Plugins } from '@capacitor/core';
import ProfileProgress from './Components/ProfileProgress';
import { ChatService } from '../services/ChatService';
import RatingModal from './Components/Rating/RatingModal';
import { getDummyRatingRating } from '../types/Offer';
import UserAPI from '../apis/User';
import PublicEmailProviders from '../PublicEmailProviders.json';
import ContactListItem from './Components/Contacts/ContactListItem';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

enum ReferralStatus {
  COMPLETE = "Complete",
  REJECTED_REFERRED = "Referral Rejected",
  REJECTED_THIRDPARTY = "Third Party Rejected",
  AWATING_REFERRED = "Awaiting Referred Acceptance",
  AWATING_THIRDPARTY = "Awaiting Third Party Acceptance",
}

interface ReferralGroupedByReferredToOrganisation {
  organisationName: string;
  label: string;
  referrals: Referral[];
}

const ReferralsList: React.FC<{}> = ({ }) => {
  
  const [loading, setLoading] = useState <boolean> (null)
  const [referrals, setReferrals] = useState <Referral[]> ([])
  const [searchText, setSearchText] = useState <string> ("")
  const [grouppedReferrals, setGrouppedReferrals] = useState <ReferralGroupedByReferredToOrganisation[]> ([])

  const contentRef = useRef(null);
  const history = useHistory();

  history.listen(e => {
    if (e.pathname.startsWith("/referrals/list")) {
      refreshReferrals();
    }
  })

  useEffect( () => {
    refreshReferrals();
  }, [])

  const refreshReferrals = async function() {
    InternalTracker.trackEvent("Loaded Referrals Sent List");
    setLoading(true);
    const referrals = (await ContactAPI.getReferrals()) as Referral[];
    const grouppedReferrals: ReferralGroupedByReferredToOrganisation[] = [];
    referrals.forEach(referral => {
      const orgName = referral.referredToContactOrgName || "Others";
      const existingGroup = grouppedReferrals.find(gr => gr.organisationName === orgName);
      if (existingGroup) {
        existingGroup.referrals.push(referral);
      } else {
        grouppedReferrals.push({
          organisationName: orgName,
          referrals: [referral],
          label: "Referred to " + orgName
        })
      }
    })
    setLoading(false);
    setGrouppedReferrals(grouppedReferrals);
    setReferrals(referrals as Referral[]);
  }

  return (
    <IonPage data-page="referrals-list" ref={contentRef}>
      <IonHeader mode='md'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>
            Referrals
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="referrals-list-wrapper" >
        <div className='list' style={{ paddingTop: 10 }}>
          <IonSearchbar 
            placeholder={"Search Referrals"} 
            debounce={600} 
            id="referrals-search-input"
            defaultValue={searchText}
            onKeyUp={e => {
              const text = (e.target as HTMLIonSearchbarElement).value
              setSearchText(text);
            }}
          ></IonSearchbar>
          { referrals.map(referral => {
            const stateStr = 
              referral.state === ReferralState.Completed ? "Completed" :
              referral.state === ReferralState.PendingReferred ? "Awaiting Referred Party's Acceptance" :
              referral.state === ReferralState.ReferredRejected ? "Referred Party Rejected" :
              referral.state === ReferralState.PendingReferredTo ? "Awaiting Third Party's Acceptance" :
              referral.state === ReferralState.ReferredToRejected ? "Third Party Rejected" :
              "Unknown";

            const index = referral.referredContactFirstName + " " + referral.referredContactLastName + " " + referral.referredContactOrgName + " " + referral.referredToContactFirstName + " " + referral.referredToContactLastName + " " + referral.referredToContactOrgName;
            if (searchText && index.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
              return null; 
            }

            return (
              <ReferralListItem 
                key={referral.id}
                referredContactName={referral.referredContactFirstName + " " + referral.referredContactLastName}
                referredToContactName={referral.referredToContactFirstName + " " + referral.referredToContactLastName}
                referredContactUserId={referral.referredContactUserId}
                referredToContactUserId={referral.referredToContactUserId}
                referredContactEmailDomain={referral.referredContactEmail && PublicEmailProviders.indexOf(referral.referredContactEmail.split("@")[1]) === -1 ? referral.referredContactEmail.split("@")[1] : null}
                referredToContactEmailDomain={referral.referredToContactEmail && PublicEmailProviders.indexOf(referral.referredToContactEmail.split("@")[1]) === -1 ? referral.referredToContactEmail.split("@")[1] : null}
                subtext={stateStr + " | " +  Utilities.formatDate(new Date(referral.createdAt), "d mms YYYY")}
              />
            )
          }) }  
          { (!searchText) ?
            <div className="bottom-fab-options" data-keyboard-open="false">
              <button onClick={() => { 
                history.push("/referrals/add")
              }} className="add-btn">
                <IonIcon icon={addCircle} />
                Refer New Contacts
              </button>
            </div>
            :
            <div className="bottom-fab-options" data-keyboard-open="false">
              <button onClick={() => { 
                setSearchText("");
                const input = document.getElementById("referrals-search-input") as HTMLIonSearchbarElement;
                if (input) {
                  input.value = "";
                }
              }} className="add-btn delete-btn">
                <IonIcon icon={closeSharp} />
                Clear Filters
              </button>
            </div>
          }
          { grouppedReferrals.map(grouppedReferral => {
            return (
              <div className="group scrollable-group">
                <div className="head">{grouppedReferral.label}</div>
                { grouppedReferral.referrals.map(referral => {

                  const stateStr = 
                  referral.state === ReferralState.Completed ? "Completed" :
                  referral.state === ReferralState.PendingReferred ? "Awaiting Referred Party's Acceptance" :
                  referral.state === ReferralState.ReferredRejected ? "Referred Party Rejected" :
                  referral.state === ReferralState.PendingReferredTo ? "Awaiting Third Party's Acceptance" :
                  referral.state === ReferralState.ReferredToRejected ? "Third Party Rejected" :
                  "Unknown";

                  return (
                    <ContactListItem 
                      key={referral.id}
                      everFirst={false} 
                      list="REFERRALS"
                      boringAvatarFallback={true}
                      hideOrgImg={true}
                      onEdit={(name) => { }}
                      contact={{
                        id: referral.id.toString(),
                        headline: stateStr + " | " +  Utilities.formatDate(new Date(referral.createdAt), "d mms YYYY"),
                        name: referral.referredContactFirstName + " referred to " + referral.referredToContactFirstName
                      } as Contact} 
                    />
                  )
                }) }
              </div>
            )
          }) }
        </div>
        <div className="bottom-fab-options" data-keyboard-open="false">
          <button onClick={() => { 
            history.push("/referrals/add")
          }} className="add-btn">
            <IonIcon icon={addCircle} />
            Refer New Contacts
          </button>
        </div>
      </IonContent>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(null)}
        message={loading ? 'Loading...' : null}
      />
    </IonPage>
  );
};

export default ReferralsList;
