import Hello from 'hellojs';
import JwtDecode from 'jwt-decode';

const LoginDisplayType = {
    PopUp: 'popup',
    None: 'none',
    Page: 'page'
};

class authToken {
    tokenType: string | undefined;
    accessToken: string | undefined;
    expires?: number | undefined;
}

enum B2CPolicies {
    SignInAndUp = 'b2cSignInAndUpPolicy'
}

export enum TokenStatus {
    None,
    Expired,
    Active
}

const NULL_DECODED_TOKEN = {
    iat: 0,
    exp: 0,
    nbf: 0,
    iss: '',
    aud: '',
    sub: '',
    idp: '',
    tfp: '',
    scp: '',
    azp: '',
    ver: '',
    extension_ParentId: '',
    oid: '',
    emails: [],
    given_name: '',
    family_name: '',
    name: '',
    postalCode: ''
};

var self = {
    registerProfiles: (
        appId: string,
        scope: string,
        b2cSignInAndUpPolicyName: string,
        tenantName: string,
        redirectUri: string
    ) => {
        const B2CUrl = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com`;

        const splitPath = window.location.pathname.split('/');
        const invitationId =
            (splitPath[1] === 'invitations' && splitPath[2]) || null;

        const authorizeParams = new URLSearchParams();
        if (invitationId != null)
            authorizeParams.append('invitationId', invitationId);

        // add Azure B2C initialisation
        Hello.init({
            b2cSignInAndUpPolicy: {
                name: 'Azure Active Directory B2C',
                base: 'https://graph.microsoft.com/v1.0/',
                oauth: {
                    version: 2,
                    auth: `${B2CUrl}/${b2cSignInAndUpPolicyName}/oauth2/v2.0/authorize?${authorizeParams.toString()}`,
                    grant: `${B2CUrl}/${b2cSignInAndUpPolicyName}/oauth2/v2.0/token`
                },
                refresh: true,
                scope_delim: ' ',
                login: function(r) {
                    const tokenStatus = self.getTokenStatus();

                    if (tokenStatus === TokenStatus.Expired) {
                        r.qs.prompt = 'none';
                    }
                    
                },
                logout(): void {
                    // get id_token from auth response
                    const idToken: string | undefined = Hello(
                        B2CPolicies.SignInAndUp
                    ).getAuthResponse().id_token;

                    // clearing local storage session
                    Hello.utils.store(B2CPolicies.SignInAndUp, null);

                    // redirecting to Azure B2C logout URI
                    window.location.href = `${B2CUrl}/oauth2/v2.0/logout?p=${b2cSignInAndUpPolicyName}&id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;
                },
                // don't even try submitting via form.
                // this means no POST operations in <=IE9
                form: false,
                xhr(p: any): void {
                    const token: any = p.query.access_token;
                    delete p.query.access_token;

                    if (token) {
                        p.headers = {
                            Authorization: `Bearer ${token}`
                        };
                    }

                    if (p.method === 'post' || p.method === 'put') {
                        // toJSON(p);
                        if (typeof p.data === 'object') {
                            // convert the POST into a javascript object
                            try {
                                p.data = JSON.stringify(p.data);
                                p.headers['content-type'] = 'application/json';
                            } catch (e) {
                                console.log(e);
                            }
                        } else if (p.method === 'patch') {
                            Hello.utils.extend(p.query, p.data);
                            p.data = null;
                        }
                    }
                }
            }
        });

        Hello.init(
            {
                b2cSignInAndUpPolicy: appId
            },
            {
                redirect_uri: redirectUri,
                scope: `openid ${scope}`,
                response_type: 'id_token token' // the d.ts typing has been updated locally to reflect this use of token id_token
            } as Hello.HelloJSLoginOptions
        );

        if (self.getTokenStatus() === TokenStatus.Active) {
            const authResponse: any = Hello(
                B2CPolicies.SignInAndUp
            ).getAuthResponse();
            const accessToken = JwtDecode(authResponse.access_token);

            // Current claims do not have the account as being activated, redirect to holding page
            if (
                !accessToken.extensions_CanSync // &&
                // unauthenticatedPages.indexOf(window.location.pathname) === -1
            ) {
                window.location.replace('/activationPending');
            }
        }
    },
    getTokenStatus: (auth?: authToken): TokenStatus => {
        if (!auth) {
            auth = self.getPortalAuth();
        }

        if (auth && auth.accessToken) {
            return self.isTokenExpired(auth)
                ? TokenStatus.Expired
                : TokenStatus.Active;
        }

        return TokenStatus.None;
    },
    isTokenExpired: (auth: authToken): boolean => {
        const currentTime: number = new Date().getTime() / 1000;

        return auth.expires! < currentTime;
    },
    login: () => {
        self.policyLogin();
    },
    refresh: () => {
        Hello(B2CPolicies.SignInAndUp).login({
            force: false,
            network: B2CPolicies.SignInAndUp,
            options: {
                display: 'none'
            }
        } as Hello.HelloJSLoginOptions);
    },
    policyLogin: (): void => {
        Hello(B2CPolicies.SignInAndUp)
            .login({
                network: B2CPolicies.SignInAndUp,
                options: {
                    display: LoginDisplayType.Page as Hello.HelloJSDisplayType
                }
            } as Hello.HelloJSLoginOptions)
            .then(
                (d) => { alert("refreshed" + JSON.stringify(d)) },
                (signInError) => { alert("error " + JSON.stringify(signInError)) }
            );
    },
    /**
     * Logs the user out of the network
     * @param networkName Network to logout from
     */
    policyLogout: () => {
        if (self.getTokenStatus() === TokenStatus.Active) {

            Hello.logout(B2CPolicies.SignInAndUp, { force: true }).then(
                () => {  },
                (signOutError) => {  }
            );
        }
    },
    getCookie(name: string) {
        const re = new RegExp(name + '=([^;]+)');
        const value = re.exec(document.cookie);
        return value != null ? unescape(value[1]) : null;
    },
    /**
     * Retrieves the current access token for the named network
     * @param defaultNetworkName Network name to retrieve access token for
     */
    getToken: (triggerLogin = true): authToken | void => {
        // Check whether we need to pass-through an external auth
        const externalAuth = self.getMobileAuth() || self.getJwtAuth();
        if (externalAuth) {
            return externalAuth;
        }

        const authToken = self.getPortalAuth();

        if (!authToken && triggerLogin) {
            return self.policyLogin();
        }

        return authToken!;
    },
    getDecodedToken () {
        const { accessToken } = self.getToken(false) || {};
        if (!accessToken) return NULL_DECODED_TOKEN;
        return {
            ...NULL_DECODED_TOKEN,
            ...JwtDecode(accessToken)
        };
    },
    getPortalAuth () {
        const authResponse = Hello(B2CPolicies.SignInAndUp).getAuthResponse();

        if (authResponse) {
            return {
                accessToken: authResponse.access_token,
                tokenType: authResponse.token_type,
                expires: authResponse.expires
            };
        }

        return undefined;
    },
    getMobileAuth: (): authToken | null => {
        // If there is authentication already provided (as page embedded in mobile app)
        const isMobilePage = window.location.pathname.startsWith('/mobile/');
        const authCookie = self.getCookie('AuthorizationPassthrough');

        if (isMobilePage && authCookie) {
            // Pass-through that auth
            const parts = authCookie.split(' ');

            return {
                tokenType: parts[0],
                accessToken: parts[1]
            };
        }

        return null;
    },
    getJwtAuth: (): authToken | null => {
        if (!localStorage) return null;
        const isExternalPage = window.location.pathname.startsWith('/external');
        const accessToken = localStorage.getItem("EphemeralAccess");

        if (isExternalPage && accessToken) {
            return {
                tokenType: 'JWT',
                accessToken: accessToken
            };
        }

        return null;
    }
};

export default self;
