import InternalTracker from '../InternalTracker';
import { RatingDirection, RatingRecord } from '../types/Offer';
import InternalAPI from './InternalAPI';

export interface RatingQuery {
    userId?: string,
    contactId?: string,
    recipientOrgId?: string,
    ratingDirection?: RatingDirection
}

class RatingAPI {

    /**
     * Submits a rating
     * @returns status
     */
    static async submit(rating: RatingRecord): Promise<Object> { 
        InternalTracker.trackEvent("Rating Submitted", rating)
        return InternalAPI.request("POST", "/rating/?", {
            ratingDirection: rating.ratingDirection,
            ratings: [rating]
        }, null, false, true) 
    }

    /**
     * Gets own rating
     * @returns ratings
     */
    static async getOwnRating(ratingQuery: RatingQuery): Promise<Object> { return InternalAPI.request("POST", "/rating/get?", ratingQuery, null, false, true) }

     /**
     * Gets contact ratings
     * @returns ratings
     */
      static async getContactRatings(contactId: string): Promise<Object> { return InternalAPI.request("GET", "/rating/" + contactId + "?", null, null, false, true) }

    /**
     * Approves own rating
     * @returns ratings
     */
    static async approve(id: number): Promise<Object> {
        InternalTracker.trackEvent("Approved Rating", {id: id})
        return InternalAPI.request("PUT", "/rating/" + id + "/recipient/approve?", null, null, null, true) 
    }

    /**
     * Hides own rating
     * @returns ratings
     */
     static async hide(id: number): Promise<Object> {
        InternalTracker.trackEvent("Hid Rating", {id: id})
        return InternalAPI.request("PUT", "/rating/" + id + "/recipient/hide?", null, null, null, true)
    }

     /**
     * Reports a rating
     * @returns ratings
     */
      static async report(id: number): Promise<Object> {
        InternalTracker.trackEvent("Reported Rating", {id: id})
        return InternalAPI.request("PUT", "/rating/" + id + "/report?", null, null, null, true) 
    }

}

export default RatingAPI;
