import { Plugins } from '@capacitor/core';
import { IonTextarea, IonIcon, IonList, IonLoading, IonItem, IonLabel, IonSelect, IonSelectOption, IonInput } from '@ionic/react';
import Drawer from '@material-ui/core/Drawer';
import { arrowBackCircle, arrowForwardOutline, checkmark, checkmarkSharp, create, reload } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { WithContext as ReactTags } from 'react-tag-input';
import { User, UserDTO } from '../../types/User';
import UserAPI from '../../apis/User';
import Logo from '../../images/icon.svg';
import Utilities from '../../Utilities';
import { getLastEventDate } from '../../stores/Event';
import SectorsAPI from '../../apis/Sectors';
// import '../../styles/Components/ProfileProgress.scss';
// import '../../styles/Components/TagManager.scss';
import versionName from '../../versionName';
import { platform } from 'os';
import { Device } from '@capacitor/device';

interface ProfileProgressProps {
    onClosed?: () => void;
}

const UpgradeDrawer: React.FC<ProfileProgressProps> = ({ onClosed }) => {

    const [open, setOpen] = useState <boolean> (false); 
    const [platform, setPlatform] = useState <string> (null) // Platform
    
    useEffect(() => {
        Device.getInfo().then(deviceInfo => {
            setPlatform(deviceInfo.platform)
        })

        setTimeout(() => {
            compileList();
        }, 4000);
    }, [])

    const compileList = async function() {

        let user: User = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) as User : null;
        let deviceInfo = await Device.getInfo();
        let platform = deviceInfo.platform;

        if (!user) {
            setOpen(false);
            return;
        }
        
        // let versionName = '2.1.92';
        // user.lastVersion = '2.4.12';

        if (!user.lastVersion) {
            setOpen(false);
            return;
        }

        let versionNameParts = versionName.split(".")?.map(item => parseInt(item));
        let lastVersionParts = user.lastVersion.split(".")?.map(item => parseInt(item));
    
        console.log("@@@ Server's version: " + lastVersionParts + ", app version: " + versionNameParts);

        const buildNumServer = lastVersionParts[0]*10000 + lastVersionParts[1]*100 + lastVersionParts[2]*1;
        const buildNumCurrent = versionNameParts[0]*10000 + versionNameParts[1]*100 + versionNameParts[2]*1;

        localStorage.setItem("updateAvailable", buildNumServer > buildNumCurrent ? "true" : "false");
        if (buildNumServer > buildNumCurrent) {
            console.log("@@@@ SHow")
        } else {
            setOpen(false);
            return;
        }

        if (platform === "web") {
            setOpen(false);
            return;
        }

        let lastOpenDate = new Date(localStorage.getItem("lastOpenDateUpgrade") || "");

        let now = new Date();
        let earliestOpen = Utilities.dateAdd(lastOpenDate, "day", 1);

        if (earliestOpen > now) {
            setOpen(false);
            return;
        }

        setOpen(true)

    }

    const close = async function() {

        localStorage.setItem("lastOpenDateUpgrade", new Date().toString());
        setOpen(false);

        if (onClosed) {
            onClosed();
        }

    }

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={ async () => {
                close();
            } }
            disableEnforceFocus
            className="upgrade-wrapper"
        >
            <div className="swipeable-drawer-body">
                {/* <div className="drag-indicator"></div> */}
                <div className="header">
                    <div className="profile-image">
                        <div className="logo-wrapper">
                            <img src={Logo} />
                        </div>
                    </div>
                    <div className="side">
                        <h1>A new upgrade is available</h1>
                        <h2>Upgrade to get the most recent features</h2>
                    </div>
                </div>
                <div className="footer">
                    <button onClick={() => {
                        close()
                    }}>Later</button>
                    <button className="main" onClick={() => {
                        if (platform === "ios") {
                            window.open("https://apps.apple.com/gb/app/id1559217533")
                        } else if (platform === "android") {
                           window.open("https://play.google.com/store/apps/details?id=com.updatedge")
                        }
                        close();
                    }}>Upgrade Now</button>
                </div>
            </div>
        </Drawer>
    );
    
};

export default UpgradeDrawer;
