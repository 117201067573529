/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { RatingDirection, RatingRecord } from '../../types/Offer';
import { RaterDetail } from '../../types/User';
// import '../../styles/RatingBanner.scss';
import RatingApi from '../../apis/Rating';

import Configuration from '../../Configuration';
import { IonIcon, IonLoading } from '@ionic/react';
import { checkmarkCircle, closeCircle, lockClosed, globe } from 'ionicons/icons';
import Utilities from '../../Utilities';
import InternalTracker from '../../InternalTracker';
import UserAPI from '../../apis/User';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

type Props = {
    existingRating: RatingRecord;
    name?: string;
    onlyPreview?: boolean;
    onSubmitted?: (stars?: number, publicComment?: string, privateComment?: string) => void;
    reverseRatingMetadata?: boolean;
    raterContact?: RaterDetail | null;
    raterOrganisation?: RaterDetail | null;
    rateeName?: string;
    showRaterUserId?: string;
}

const RatingBanner: React.FC<Props> = ({ showRaterUserId, existingRating, name, onSubmitted, onlyPreview, raterContact, raterOrganisation, rateeName }) => {

    const [rating, setRating] = useState<RatingRecord> (null) // Rating
    const [hasChanged, setHasChanged] = useState<boolean> (false)
    const [loading, setLoading] = useState<boolean> (null);
    const [textareaFocused, setTextareaFocused] = useState<boolean | string> (false)

    useEffect( () => { 
        if (!rating) {
            setRating(existingRating)
        }
        setHasChanged(false);
    }, [existingRating])

    const submitRating = async (rating: RatingRecord, hasChanged: boolean) => {
        if (!hasChanged) {
            if (onSubmitted) onSubmitted(rating.stars, rating.publicComment, rating.privateComment);
            return;
        }

        if (!rating.stars) {
            (window as any).toast("Please select a star rating from 1 to 5", "error")
            return;
        }

        setLoading(true);

        RatingApi.submit(rating).then(d => {
            if (onSubmitted) onSubmitted(rating.stars, rating.publicComment, rating.privateComment);
            (window as any).toast("Your Rating has been submitted", "success");
            setLoading(false);
        }).catch(e => {
            if (onSubmitted) onSubmitted(rating.stars, rating.publicComment, rating.privateComment);
            (window as any).toast("Faield to submit Rating", "error");
            setLoading(false);
        });
    }


    const onTextareaFocus = async(txtElement) => {
        setTimeout(() => {
            txtElement.focus();
            txtElement.setSelectionRange(0,0);
        }, 100)
    }

    let ratingToRender = rating

    if (ratingToRender) {
        return (
            <React.Fragment>
                <div className='hirer-rater' data-preview={onlyPreview ? "true" : "false"}>
                    <h1>
                        <span onClick={() => {
                            if (onSubmitted) onSubmitted(0);
                        }}>
                            <IonIcon icon={closeCircle} />
                            Back
                        </span>
                        <span onClick={() => {
                            submitRating(ratingToRender, hasChanged);
                        }}>
                            <IonIcon icon={checkmarkCircle} />
                            Submit
                        </span>
                    </h1>
                    <div className='header'>
                        <div className="profile">
                            { (!onlyPreview && ratingToRender.recipientOrgId) &&
                                <img className={showRaterUserId ? "small" : ""} src={UserAPI.getOrgPicture(ratingToRender.recipientOrgId)} />
                            }
                            { (!onlyPreview && ratingToRender.recipientExternalId) &&
                                <img src={UserAPI.getProfilePicture(ratingToRender.recipientExternalId)} />
                            }
                            { raterContact &&
                                <img src={UserAPI.getProfilePicture(raterContact.id)} />
                            }
                            { raterOrganisation &&
                                <img className={showRaterUserId ? "small" : ""} src={UserAPI.getOrgPicture(raterOrganisation.id)} />
                            }
                            { showRaterUserId &&
                                <img src={UserAPI.getProfilePicture(showRaterUserId)} />
                            }
                            <div>
                                { (!onlyPreview) &&
                                    <React.Fragment>
                                        {rateeName && <h3>Rating '{rateeName}'</h3> }
                                        {name && <p>@ {name}</p>}
                                    </React.Fragment>
                                }
                                { (onlyPreview) &&
                                    <h3>{ 
                                        (raterOrganisation && raterContact) ? (raterContact.name + " from " + raterOrganisation.name) : 
                                        (raterOrganisation) ? raterOrganisation.name : 
                                        (raterContact) ? raterContact.name : 
                                        "No Rater Details"
                                    }</h3>
                                }
                            </div>
                        </div>
                        <div className='side'>
                            <ReactStars
                                value={ratingToRender.stars}
                                count={5}
                                edit={!onlyPreview}
                                onChange={(newStars) => {
                                    let newRating = JSON.parse(JSON.stringify(ratingToRender));
                                    newRating.stars = newStars;
                                    setRating(newRating);
                                    setHasChanged(true);
                                }}
                                size={24}
                                activeColor="#ffd700"
                            />
                            { rating.updatedAt &&
                                <div className='date'>
                                    Rated on {Utilities.formatDate(new Date(rating.updatedAt), "d mms YYYY")}
                                </div>
                            }
                        </div>
                    </div>
                    { onlyPreview && (ratingToRender.publicComment || ratingToRender.updatedAt) &&
                        <div className='comments-preview'>
                            {ratingToRender.publicComment}
                            <span> at {Utilities.formatDate(new Date(ratingToRender.updatedAt), "d mms YYYY")}</span>
                        </div>
                    }
                    { (!onlyPreview) &&
                        <div className='comments'>
                            <label>Public comment for all to see</label>
                            <div className='comment public'>
                                <IonIcon icon={globe} />
                                <textarea
                                    className="form-control"
                                    placeholder="Public comment for all to see"
                                    value={ratingToRender.publicComment}
                                    onChange={(ev) => {
                                        let newRating = JSON.parse(JSON.stringify(ratingToRender));
                                        newRating.publicComment = ev.target.value;
                                        setRating(newRating);
                                        ev.target.style.height = (ev.target.scrollHeight - 8) + 'px';
                                        setHasChanged(true);
                                    }}
                                    rows={textareaFocused === "public" ? 1 : 1}
                                    onFocus={((e) => {
                                        onTextareaFocus(e.target);
                                        setTimeout(() => {
                                            setTextareaFocused("public");
                                        }, 400)
                                    })}
                                    onBlur={(() => {
                                        setTimeout(() => {
                                            setTextareaFocused(false)
                                        }, 200)
                                    })}
                                />
                            </div>
                            <label>Private comment only you can see</label>
                            <div className='comment private'>
                                <IonIcon icon={lockClosed} />
                                <textarea
                                    className="form-control"
                                    placeholder="Private comment only you can see"
                                    value={ratingToRender.privateComment}
                                    onChange={(ev) => {
                                        let newRating = JSON.parse(JSON.stringify(ratingToRender));
                                        newRating.privateComment = ev.target.value;
                                        setRating(newRating);
                                        ev.target.style.height = (ev.target.scrollHeight - 8) + 'px';
                                        setHasChanged(true);
                                    }}
                                    rows={textareaFocused === "private" ? 1 : 1}
                                    onFocus={((e) => {
                                        onTextareaFocus(e.target);
                                        setTimeout(() => {
                                            setTextareaFocused("private");
                                        }, 400)
                                    })}
                                    onBlur={(() => {
                                        setTimeout(() => {
                                            setTextareaFocused(false)
                                        }, 200)
                                    })}
                                />
                            </div>
                        </div>   
                    }
                    { (!onlyPreview) &&
                        <div className='options'>
                            <button onClick={() => {
                                submitRating(ratingToRender, hasChanged);
                            }}>Submit Rating</button>
                        </div>
                    }
                </div>
                { (loading) &&
                    <IonLoading
                        isOpen={true}
                        message="Submitting rating ..."
                    />
                }
            </React.Fragment>
        )
    }

    return null;
}

export default RatingBanner;