import React from 'react';

function PadlockClosed(props) {
    return (
        <svg id="bold" enable-background="new 0 0 24 24" height="" viewBox="0 0 24 24" width="" xmlns="http://www.w3.org/2000/svg">
            <path d="M 18.75 9 L 18 9 L 18 6 C 18 2.691 15.309 0 12 0 C 8.691 0 6 2.691 6 6 L 6 9 L 5.25 9 C 4.01 9 3 10.009 3 11.25 L 3 21.75 C 3 22.991 4.01 24 5.25 24 L 18.75 24 C 19.99 24 21 22.991 21 21.75 L 21 11.25 C 21 10.009 19.99 9 18.75 9 Z M 8 6 C 8 3.794 9.794 2 12 2 C 14.206 2 16 3.794 16 6 L 16 9 L 8 9 L 8 6 Z"/>
        </svg>
    );
}

export default PadlockClosed;