import { IonIcon, IonLoading } from '@ionic/react';
import { addCircle, alertCircle, alertCircleOutline, checkbox, checkmarkCircle, checkmarkCircleOutline, chevronDown, chevronUp, closeCircle, closeCircleOutline, documentLockOutline, documentSharp, ellipsisHorizontal, globeOutline, helpCircleOutline, peopleOutline, shield, shieldCheckmark, squareOutline, star, warning } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import NotificationAPI from '../../../apis/Notification';
import Configuration from '../../../Configuration';
import BusinessImg from '../../../images/business.png';
import PersonImg from '../../../images/person.png';
// import '../../../styles/Components/Files/FileListItem.scss';
import { Contact } from '../../../types/Contact';
import ShieldVerified from '../../../icons/shield-verified.svg';
import ShieldUnverified from '../../../icons/shield-unverified.svg';
import { RatingRecord, getDummyRatingRating } from '../../../types/Offer';
import RatingPreview from '../Rating/RatingPreview';
import Drawer from '@material-ui/core/Drawer';
import RatingAPI from '../../../apis/Rating';
import RatingPreviewExtended from '../Rating/RatingPreviewExtended';
import { UserFile, UserFileWitUserDetails, UserFolderWithFilterType } from '../../../types/User';
import Utilities from '../../../Utilities';
import UserAPI from '../../../apis/User';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface ContactListItemProps {
    file: UserFileWitUserDetails;
    fileI: number;
    onClick?: () => void;
    onOrder?: (i: number, asc: boolean) => void;
    orderingFiles?: boolean;
    userFiles?: UserFile[];
    showOwner?: boolean;
    showAccess?: boolean;
    showDescription?: boolean;
    showCategory?: boolean;
    showAVScan? : boolean;
    folder?: UserFolderWithFilterType
}

const FileListItem: React.FC<ContactListItemProps> = ({ folder, showAVScan, showCategory, file, fileI, onClick, orderingFiles, userFiles, onOrder, showOwner, showAccess, showDescription }) => {

    const [ratings, setRatings] = useState <RatingRecord[]> ([]);

    return (
        <div 
            data-name={file.fileName}
            data-has-access={file.accessLink ? true : false}
            data-access={file.access}
            className='file item'
            onClick={() => {
                onClick()
            }}
        >
            <div
                className='preview'
                style={ file.previewAccessLink ? { backgroundImage: 'url(' + file.previewAccessLink + ')' } : {} }
            >
                <div>
                    { !file.previewAccessLink && <IonIcon icon={documentSharp} /> }
                    { (!file.previewAccessLink && file.fileFormat) && <label>{file.fileFormat}</label> }
                </div>
            </div>
            <div className='meta'>
                <h2>
                    {file.fileName}
                    { (showCategory && file.fileCategoryName) &&
                        <span>{file.fileCategoryName}</span>
                    }
                    { (showAVScan && !file.virusScanned) &&
                        <span className='warning'>Checking for Viruses...</span>
                    }
                    { (showDescription && file.description) &&
                        <p className='description'>{file.description}</p>
                    }
                </h2>
                { (showAccess) &&
                    <h3>
                        {file.fileCategoryName && Utilities.capitalizeFirstLetter(file.fileCategoryName)}
                        { folder ?
                            <label style={ file.fileCategoryName ? { marginLeft: 8 } : { } } >
                                <IonIcon icon={folder.access === 0 ? globeOutline : folder.access === 1 ? peopleOutline : documentLockOutline} />
                                {folder.access === 0 ? "Public" : folder.access === 1 ? "Only Contacts" : "Private"}
                            </label>
                            :
                            <label style={ file.fileCategoryName ? { marginLeft: 8 } : { } } >
                                <IonIcon icon={file.access === 0 ? globeOutline : file.access === 1 ? peopleOutline : file.access === 2 ? helpCircleOutline : documentLockOutline} />
                                {file.access === 0 ? "Public" : file.access === 1 ? "Only Contacts" : file.access === 2 ? "On Request" : "Private"}
                            </label>
                        }
                    </h3>
                }
                { (showOwner && file.userName) &&
                    <div className='owner' data-user-id={file.userId} data-report-blurred={file.userReported}>
                        { (file.organisationId) &&
                            <img src={UserAPI.getOrgPicture(file.organisationId)} />
                        }
                        <img src={UserAPI.getProfilePicture(file.userId)} />
                        <span>by {file.userName}</span>
                    </div>
                }
            </div>
            { orderingFiles &&
                <div className='options'>
                    <IonIcon 
                        style={{ color: fileI !== 0 ? 'black' : 'whitesmoke' }} 
                        icon={chevronUp}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (fileI !== 0)
                                onOrder(fileI, true)
                        }}
                    />
                    <IonIcon
                        style={{ color: fileI !== userFiles.length - 1 ? 'black' : 'whitesmoke' }}
                        icon={chevronDown}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (fileI !== userFiles.length - 1)
                                onOrder(fileI, false)
                        }}
                    />
                </div>
            }
        </div>
    );
};

export default FileListItem;
