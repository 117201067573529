import { IonAlert, IonIcon } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import Configuration from '../../../Configuration';
// import '../../../styles/Components/Contacts/ContactListItem.scss';
// import '../../../styles/Components/Notification/OfferListItem.scss';
import { Event } from '../../../types/Event';
import { Offer } from '../../../types/Offer';
import Utilities from '../../../Utilities';
import SwipeToDelete from 'react-swipe-to-delete-ios'
import OfferAPI from '../../../apis/Offer';
import { arrowRedo, locateSharp, thumbsDown, thumbsUp } from 'ionicons/icons';
import InternalTracker from '../../../InternalTracker';
import UserAPI from '../../../apis/User';


const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface OfferListItem {
    offer: Offer;
    type: string;
    deletable?: boolean;
    onClick?: () => void;
    justReceived?: boolean;
    showCountdown?: boolean;
    showReply?: boolean;
}

const OfferListItem: React.FC<OfferListItem> = ({ offer, type, onClick, deletable, justReceived, showCountdown, showReply }) => {

    // @ts-ignore
    let imgLink: string = UserAPI.getOrgPicture(offer.metadata.createdByOrganisationId);
    // @ts-ignore
    const companyName = offer.metadata.createdByOrganisationName;
    // @ts-ignore
    const fullName = offer.metadata.createdByFullName;

    const [hideOption, setHideOption] = useState<boolean> (false)
    const [showSwipeableItem, setShowSwipeableItem] = useState<boolean> (true)
    const [showUnswipeableItem, setShowUnswipeableItem] = useState<boolean> (false)
    const [countdown, setCountdown] = useState <{ days: number, hours: number, minutes: number, seconds: number }> (null); // Countdown for the offer
    const [secondaryImgLinkErrored, setSecondaryImgLinkErrored] = useState<boolean>(false);

    let countdownInterval: any = null;

    useEffect(() => { 
        if (showCountdown) {
            const deadline = new Date(offer.respondByDeadline)
            countdownInterval = setInterval(() => {
                if (deadline && deadline > new Date()) {
                    setCountdown(Utilities.secondsToDhms(Utilities.differenceBetweenDatesSeconds(deadline, new Date())))
                } else {
                    clearInterval(countdownInterval);
                    setCountdown(null);
                }
            }, 1000)
        }
        if (showReply) {
            const offersMarkedAsSeen = JSON.parse(localStorage.getItem("offersMarkedAsSeen") || "[]");
            if (!offersMarkedAsSeen.includes(offer.id)) {
                InternalTracker.trackEvent("Offer First Shown on List", {
                    id: offer.id,
                })
                offersMarkedAsSeen.push(offer.id);
                localStorage.setItem("offersMarkedAsSeen", JSON.stringify(offersMarkedAsSeen));
            }
        }
    }, [])

    let noLongerAcceptable: boolean = false;
    if (/*type === "PENDING" &&*/ offer.events) {
        let firstEvent: Date = null;
        for (let i = 0; i < offer.events.length; i++) {
            const event = offer.events[i];
            if (!firstEvent || firstEvent > new Date(event.start)) {
                firstEvent = new Date(event.start);
            }
        }
        if (firstEvent < new Date()) {
            noLongerAcceptable = true
        }
    }

    let secondaryImgLink: string = null;
    if (offer.metadata && offer.metadata.onBehalfOfExternalExternalWebsiteUrl && !secondaryImgLinkErrored) {
        secondaryImgLink = imgLink;
        imgLink = CONFIG.API_EXTERNAL_URI + "/organisations/external/logo/" + offer.metadata.onBehalfOfExternalExternalWebsiteUrl + "?api-version=1.0";
    }

    const LIST_ITEM = <div 
            className="contact-item offer-item"
            key={offer.id} 
            onTouchStart={event => { }}
            data-id={offer.id} 
            onClick={(e) => { 
                onClick();
            }}
            style={{
                opacity: noLongerAcceptable ? 0.4 : 1
            }}
        >
            <div className="details">
                <div
                    className="picture"
                    data-report-blurred={offer.metadata && offer.metadata.createdByReporeted}
                    data-user-id={offer.metadata && offer.metadata.createdById}
                >
                    <img src={imgLink} onError={() => {
                        if (secondaryImgLink) {
                            setSecondaryImgLinkErrored(true);
                        }
                    }}  />
                    { (secondaryImgLink) &&
                        <img className='secondary' src={secondaryImgLink} />
                    }
                </div>
                <div className="contact-details">
                    <h2>
                        <span className='offer-title'>
                            { offer.financial && offer.financial.totalGrossPay &&
                                <span>
                                    {offer.financial.currencyCode === "GBP" ? "£" : ""}{offer.financial.totalGrossPay}
                                    {' ·'}
                                </span>
                            }
                            {offer.title}
                        </span>
                        { (countdown) &&
                            <div className='deadline'>
                                <p>Expiring in</p>
                                <ul>
                                    <li><span id="days">{countdown.days}</span>days, </li>
                                    <li><span id="hours">{countdown.hours < 10 ? ("0" + countdown.hours) : countdown.hours}</span></li>
                                    <li>:<span id="minutes">{countdown.minutes < 10 ? ("0" + countdown.minutes) : countdown.minutes}</span></li>
                                    <li>:<span id="seconds">{countdown.seconds < 10 ? ("0" + countdown.seconds) : countdown.seconds}</span></li>
                                </ul>
                            </div>
                        }
                    </h2>
                    { (type === "PENDING" || type === "APPLIED" || type === "WITHDRAWN") && <p data-report-blurred={offer.metadata && offer.metadata.createdByReporeted} data-user-id={offer.metadata && offer.metadata.createdById} >Offer from {fullName} ({companyName})</p> }
                    { (type === "CONFIRMED") && <p data-report-blurred={offer.metadata && offer.metadata.createdByReporeted} data-user-id={offer.metadata && offer.metadata.createdById}>Assignment confirmed by {fullName} ({companyName})</p> }
                    { (type === "PENDING_DIRECT") && <p data-report-blurred={offer.metadata && offer.metadata.createdByReporeted} data-user-id={offer.metadata && offer.metadata.createdById}>Events from {fullName} ({companyName})</p> }
                    { (type === "PENDING" || type === "PENDING_DIRECT" || type === "CONFIRMED" || type === "APPLIED" || type === "WITHDRAWN") && <p>{Utilities.formatOfferDate(offer.events ? offer.events : offer.pendingEvents)}</p> }
                    { (offer.autoInsert === false) && <p style={{ color: "#FB5B5A" }} >Your current availability prevented this offer's events being added to your timeline automatically. Tap to resolve the conflict</p> }
                    { (offer.autoInsert === true) && <p style={{ color: "#50D890" }} >Added to your timeline - Tap to review</p> }
                    { (offer.autoDeclined && !offer.complete && type === "PENDING" && !noLongerAcceptable) && <p style={{ color: "#FB5B5A" }} >Auto-declined, but still open for applications</p> }
                    {/* { (!offer.autoDeclined && offer.respondByDeadline && type === "PENDING") && <p style={{ color: "#FB5B5A" }}>Respond required by { Utilities.formatDate(new Date(offer.respondByDeadline), "HH:MM d mms (YYYY)") } ({ Utilities.formatDate(new Date(offer.respondByDeadline), "HH:MM") })</p> } */}
                </div>
                { (justReceived) ?
                    <IonIcon className="just-edited" icon={locateSharp} />
                : null}
                { (showReply) &&
                    <button className='reply-btn'>
                        <IonIcon icon={arrowRedo} />
                        <span>Reply</span>
                    </button>
                }
            </div>
        </div>

    if (deletable)
        return (
            <React.Fragment>
                <IonAlert
                    isOpen={hideOption}
                    onDidDismiss={() => {
                        setHideOption(false)
                    }}
                    header={'Select an Option'}
                    buttons={[
                        { text: 'Hide & Reject Offer',
                            handler: () => {
                                Promise.all([
                                    Utilities.rejectOffer(offer.id),
                                    OfferAPI.remove(offer.id)
                                ]).then(data => {
                                    (window as any).toast("Offer Rejected & Hidden")
                                    setShowSwipeableItem(false)
                                    setShowUnswipeableItem(false)
                                }).catch(error => {
                                    (window as any).toast("Failed to hide Offer")
                                })
                            }
                        },
                        { text: 'Just Hide Offer',
                            handler: async () => {
                                OfferAPI.remove(offer.id).then(data => {
                                    (window as any).toast("Offer Hidden")
                                    setShowSwipeableItem(false)
                                    setShowUnswipeableItem(false)
                                }).catch(error => {
                                    (window as any).toast("Failed to hide Offer")
                                })
                            }
                        },
                        { text: 'Cancel',
                            handler: async () => {
                                setShowSwipeableItem(true)
                                setShowUnswipeableItem(false)
                            }
                        }
                    ]}
                />
                { (showSwipeableItem) &&
                    <SwipeToDelete onDelete={(a, b) => {
                        setShowSwipeableItem(false);
                        setShowUnswipeableItem(true);
                        if (type === "PENDING" || type === "APPLIED") {
                            setHideOption(true);
                        } else {
                            OfferAPI.remove(offer.id).then(data => {
                                (window as any).toast("Offer Hidden")
                                setShowSwipeableItem(false)
                                setShowUnswipeableItem(false)
                            }).catch(error => {
                                (window as any).toast("Failed to hide Offer")
                            })
                        }
                    }}>
                        {LIST_ITEM}
                    </SwipeToDelete>
                }
                { (showUnswipeableItem) &&
                    LIST_ITEM
                }
            </React.Fragment>
        );

    return LIST_ITEM;

};

export default OfferListItem;
