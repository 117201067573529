import React from 'react';

function Wave(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.901" height="29.741" viewBox="0 0 27.901 29.741">
        <g id="hi_2_" data-name="hi (2)" transform="translate(-21.89 -12.932) rotate(21)">
            <g id="Group_1471" data-name="Group 1471" transform="translate(33.057 1.163)">
            <path id="Path_225" data-name="Path 225" d="M59.175,11.358a1.567,1.567,0,0,0-1.127-.067,1.548,1.548,0,0,0-.86.9l-2.4,6.658,1.47-7.458a1.552,1.552,0,1,0-3.046-.6l-1.586,8.05V9.052a1.552,1.552,0,0,0-3.1,0V19.161l-1.876-8.386a1.552,1.552,0,1,0-3.03.678l1.8,8.051.4,2a.633.633,0,0,1-.849.714l-3.387-1.31A1.637,1.637,0,0,0,39.425,22a.823.823,0,0,0,.337.908L46.5,27.073V30.65a.72.72,0,0,0,.72.72H53.5l1.1-.1a.645.645,0,0,0,.72-.624V28.341l1.6-1.123a1.921,1.921,0,0,0,.816-1.571v-6.09l2.276-6.308A1.4,1.4,0,0,0,59.175,11.358Z" transform="translate(-39.394 -7.5)" fill="#50d890"/>
            <g id="Group_1470" data-name="Group 1470" transform="translate(14.104 3.669)">
                <path id="Path_226" data-name="Path 226" d="M338.724,83.988a1.547,1.547,0,0,0-1.127.029,1.552,1.552,0,0,1,.86,1.958l-2.233,6.19a.721.721,0,0,0-.043.245v5.964a1.921,1.921,0,0,1-.816,1.571l-1.291.908a.72.72,0,0,0-.306.589v1.935a.72.72,0,0,1-.72.72h1.2a.72.72,0,0,0,.72-.72v-2.309l1.6-1.123a1.921,1.921,0,0,0,.816-1.571v-6.09l2.276-6.308A1.552,1.552,0,0,0,338.724,83.988Z" transform="translate(-333.047 -83.895)" fill="#50d890"/>
            </g>
            </g>
        </g>
        </svg>
    );
}

export default Wave;
