import InternalAPI from './InternalAPI';
import Configuration from '../Configuration';
import { Attribute, AttributeBreakdown, User } from '../types/User'
import { UserEmail } from '../types/UserEmail'
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

class SectorsAPI {

    /**
     * Returns all matching selectable sectors
     * @returns sectors details
     */
    static async get(text: string): Promise<Object> { return InternalAPI.request("GET", "/publicapi/lookups/industries?query=" + text, null, false, null, false, true); }

    /**
     * Returns all selectable sectors
     * @returns sectors details
     */
     static async getSector(text: string): Promise<Object> { return InternalAPI.request("GET", "/organisations/sectors?query=" + text, null, false, null, true, true); }

    /**
     * Returns all selectable subsectors
     * @returns sectors details
     */
    static async getSubsector(text: string, sectorId: number, prioritizeSectorId?: number): Promise<Object> { return InternalAPI.request("GET", "/organisations/sectors/subsectors?sectorId=" + sectorId + (prioritizeSectorId ? ("&prioritizeSectorId=" + prioritizeSectorId) : "") + "&query=" + text, null, false, null, true, true); }

    /**
     * Creates a sector suggestions
     * @param text name of the suggested sector
     * @returns new sector id
     */
    static async suggestSector(text: string): Promise<Object> { return InternalAPI.request("POST", "/sectors?name=" + text + "&", null, false, null, true, true); }

    /**
     * Creates a sub-sector suggestions
     * @param text name of the suggested sector
     * @param sectorId id of the sector
     * @returns new sector id
     */
    static async suggestSubSector(text: string, sectorId: number): Promise<Object> { return InternalAPI.request("POST", "/sectors/subsector?name=" + text + "&sectorId=" + sectorId + "&", null, false, null, true, true); }

    static async searchSkills(query: string, sectorId?: number, subSectorId?: number, prioritizeSectorId?: number, prioritizeSubSectorId?: number): Promise<AttributeBreakdown[]> { return InternalAPI.request("GET", "/sectors/skills?query=" + query + "&api-version=1.0" + (sectorId ? "&sectorId=" + sectorId : "") + (subSectorId ? "&subSectorId=" + subSectorId : "") + (prioritizeSectorId ? "&prioritizeSectorId=" + prioritizeSectorId : "") + (prioritizeSubSectorId ? "&prioritizeSubSectorId=" + prioritizeSubSectorId : ""), null, null, null, true); }

    static async createOrGetSkillAttributes(skillName: string, sectorName: string, subsectorName?: string): Promise<Attribute> { return InternalAPI.request("PUT", "/sectors/skill?sectorName=" + sectorName + "&subsectorName=" + subsectorName + "&skillName=" + skillName + "&api-version=1.0", null, null, null, true); }

}

export default SectorsAPI;
