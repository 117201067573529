import { Event } from '../types/Event'
import Utilities from '../Utilities';

export async function getSetting(key: string) {
    // @ts-ignore
    if (!window.db) return [];
    // @ts-ignore
    let settings = await window.db.settingss.where("key").equals(key).toArray();
    return settings && settings[0] ? settings[0].value : null;
};

export async function saveSetting(key: string, value: string) {

    // @ts-ignore
    let existingSettings = await window.db.settingss.where("key").equals(key).toArray();

    if (existingSettings && existingSettings[0]) {
        // @ts-ignore
        await window.db.settingss.update(key, { key: key, value: value })
    } else {
        // @ts-ignore
        await window.db.settingss.put({ key: key, value: value });
    }

    return true;

};