import { IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { locateSharp, moon, repeat, checkmark, checkmarkCircle, layers, closeCircle, arrowUndo, alertCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import BriefcaseIcon from '../../../icons/Briefcase';
import PadlockOpen from '../../../icons/PadlockOpen';
import PadlockClosed from '../../../icons/PadlockClosed';
import WaveIcon from '../../../icons/Wave';
// import '../../../styles/Components/Calendar/EventTimelineItem.scss';
import { CollidingEvent, Event } from '../../../types/Event';
import Utilities from '../../../Utilities';
import Configuration from '../../../Configuration';
import { useSelector } from 'react-redux';
import UserAPI from '../../../apis/User';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface EventTimelineItemProps {
    event: Event;
    justEdited?: boolean;
    onEdit?: () => void;
    collisionHandling?: boolean;
    outstandingCollisionTarget?: boolean;
}

const EventTimelineItem: React.FC<EventTimelineItemProps> = ({ event, onEdit, justEdited, collisionHandling, outstandingCollisionTarget }) => {

    let start: Date = new Date(event.start);
    let end: Date = new Date(event.end);
    let overnight: boolean = event.overnight || false;
    const [hideExternalOrgImage, setHideExternalOrgImage] = useState <boolean> (null);;
    const [overridenCollisionResolutionMethod, setOverridenCollisionResolutionMethod] = useState <CollidingEvent['resolutionMethod']> (null);
    const [afterResolutionTimeline, setAfterResolutionTimeline] = useState<Event[]> ([])

    // Correcting overnight events to round hours
    if (event.overnight) {
        if (end.getMinutes() === 59) {
            end.setMinutes(0);
            end.setHours(0);
        } else if (start.getMinutes() === 1) {
            start.setMinutes(0);
            start.setHours(0);
        }
    } else if (start.getHours() > end.getHours()) {
        overnight = true;
    }

    const orgLogoUrl: string = (event.creator && event.creator.organisation) ? UserAPI.getOrgPicture(event.creator.organisation.id) : null;
    const orgName: string = (event.creator && event.creator.organisation) ? event.creator.organisation.name : null;

    // @ts-ignore
    const resolvedEvents = useSelector(state => state.resolvedEvents);

    let collidionResolutionMethod: CollidingEvent['resolutionMethod'] | null = null;
    let allowedMergeToResolve: boolean = false;
    let disallowedMergeReason: string | null = null;

    if (collisionHandling) {
      let draftEvent = localStorage.getItem("draftEvent") ? JSON.parse(localStorage.getItem("draftEvent") || "{}") : null;
      if (draftEvent || localStorage.getItem("collidingEventsOfferId")) {
        const collidingEvents = JSON.parse(localStorage.getItem("collidingEvents") || "[]");
        const collidingEvent = collidingEvents.find(ce => ce.id === event.id);
        if (collidingEvent) {
          collidionResolutionMethod = overridenCollisionResolutionMethod || collidingEvent.resolutionMethod;
          allowedMergeToResolve = collidingEvent.allowedMergeToResolve;
          disallowedMergeReason = collidingEvent.disallowedMergeReason;
          let afterResolutionTimelineNew = [];

          console.log(draftEvent, "@@@@@@@@@@@@@@")

          // No draft event saved, so must be colliding with an offer event
          if (!draftEvent) {
            draftEvent = collidingEvent.collidingWithOfferEvents
          }

          if (collidionResolutionMethod === "delete") {
            if (Array.isArray(draftEvent)) {
                for (let i = 0; i < draftEvent.length; i++) {
                    afterResolutionTimelineNew.push({
                        ...draftEvent[i],
                        splitInstance: i+1,
                    });
                }
                afterResolutionTimelineNew.push({
                    ...event,
                    deleted: true,
                    existing: true
                });
            } else {
                afterResolutionTimelineNew.push(draftEvent);
                afterResolutionTimelineNew.push({
                    ...event,
                    deleted: true,
                    existing: true
                });
            }
          } else if (collidionResolutionMethod === "merge") {

            if (Array.isArray(draftEvent)) {
                let collidingEventTodayStart = new Date();
                collidingEventTodayStart.setHours(new Date(collidingEvent.start).getHours());
                collidingEventTodayStart.setMinutes(new Date(collidingEvent.start).getMinutes());
    
                let collidingEventTodayEnd = new Date();
                collidingEventTodayEnd.setHours(new Date(collidingEvent.end).getHours());
                collidingEventTodayEnd.setMinutes(new Date(collidingEvent.end).getMinutes());

                for (let i = 0; i < draftEvent.length; i++) {
                    const newEventInstance = draftEvent[i];
                    let draftEventTodayStart = new Date();
                    draftEventTodayStart.setHours(new Date(newEventInstance.start).getHours());
                    draftEventTodayStart.setMinutes(new Date(newEventInstance.start).getMinutes());
                    let draftEventTodayEnd = new Date();
                    draftEventTodayEnd.setHours(new Date(newEventInstance.end).getHours());
                    draftEventTodayEnd.setMinutes(new Date(newEventInstance.end).getMinutes());
                    console.log("@@@ LOOPING Draft event instance" + collidingEventTodayStart + " " + collidingEventTodayEnd + " || " + draftEventTodayStart + " " + draftEventTodayEnd)

                    if (i === 0 && collidingEventTodayStart < draftEventTodayStart) {
                        console.log("@@@ GAP HERE Start")
                        // There is a gap at the start to fill out with the existing event before the first new event start
                        afterResolutionTimelineNew.push({
                            ...event,
                            start: collidingEvent.start,
                            end: draftEventTodayStart,
                            existing: true,
                            deleted: false,
                            splitInstance: i+1
                        });
                    }
                    
                    afterResolutionTimelineNew.push(newEventInstance);

                    if (draftEvent[i+1] && !Utilities.isSameDate(draftEventTodayEnd, new Date(draftEvent[i+1].start))) {
                        // There is a gap between this new event and the next new one
                        afterResolutionTimelineNew.push({
                            ...event,
                            start: draftEventTodayEnd,
                            end: draftEvent[i+1].start,
                            existing: true,
                            deleted: false,
                            splitInstance: i+1
                        });
                    }
                    
                    if (i === draftEvent.length - 1 && collidingEventTodayEnd > draftEventTodayEnd) {
                        console.log("@@@ GAP HERE End")
                        // There is a gap at the end to fill out with the existing event after the last new event end
                        afterResolutionTimelineNew.push({
                            ...event,
                            start: draftEventTodayEnd,
                            end: collidingEvent.end,
                            existing: true,
                            deleted: false,
                            splitInstance: i+1
                        });
                    }
                }
            } else {
                console.log("@@@ Draft event", new Date(draftEvent.start) + " " + new Date(draftEvent.end) + "; " + new Date(collidingEvent.start) + " " + new Date(collidingEvent.end));

                let draftEventTodayStart = new Date();
                draftEventTodayStart.setHours(new Date(draftEvent.start).getHours());
                draftEventTodayStart.setMinutes(new Date(draftEvent.start).getMinutes());
    
                let draftEventTodayEnd = new Date();
                draftEventTodayEnd.setHours(new Date(draftEvent.end).getHours());
                draftEventTodayEnd.setMinutes(new Date(draftEvent.end).getMinutes());
    
                let collidingEventTodayStart = new Date();
                collidingEventTodayStart.setHours(new Date(collidingEvent.start).getHours());
                collidingEventTodayStart.setMinutes(new Date(collidingEvent.start).getMinutes());
    
                let collidingEventTodayEnd = new Date();
                collidingEventTodayEnd.setHours(new Date(collidingEvent.end).getHours());
                collidingEventTodayEnd.setMinutes(new Date(collidingEvent.end).getMinutes());
    
                if (draftEventTodayStart > collidingEventTodayStart && draftEventTodayEnd < collidingEventTodayEnd) {
                    console.log("@@@ DRAFT EVENT IS INSIDE COLLIDING EVENT");
                    afterResolutionTimelineNew.push({
                        ...collidingEvent,
                        end: draftEvent.start,
                        existing: true,
                        splitInstance: 1
                    });
                    afterResolutionTimelineNew.push(draftEvent);
                    afterResolutionTimelineNew.push({
                        ...collidingEvent,
                        start: draftEvent.end,
                        existing: true,
                        splitInstance: 2
                    });
                } else if (draftEventTodayStart < collidingEventTodayStart || (draftEventTodayStart.getHours() === collidingEventTodayStart.getHours() && draftEventTodayStart.getMinutes() === collidingEventTodayStart.getMinutes())) {
                    console.log("@@@ Draft event is outside colliding event - beginning");
                    afterResolutionTimelineNew.push(draftEvent);
                    afterResolutionTimelineNew.push({
                        ...collidingEvent,
                        start: draftEvent.end,
                        existing: true
                    });
                } else if (draftEventTodayEnd > collidingEventTodayEnd || (draftEventTodayEnd.getHours() === collidingEventTodayEnd.getHours() && draftEventTodayEnd.getMinutes() === collidingEventTodayEnd.getMinutes())) {
                    console.log("@@@ Draft event is outside colliding event - end");
                    afterResolutionTimelineNew.push({
                        ...collidingEvent,
                        end: draftEvent.start,
                        existing: true
                    });
                    afterResolutionTimelineNew.push(draftEvent);
                } else {
                    console.log("@@@ Draft event is somethng else");
                }
            }

          }

          if (JSON.stringify(afterResolutionTimeline) !== JSON.stringify(afterResolutionTimelineNew) && JSON.stringify(afterResolutionTimeline).length !== JSON.stringify(afterResolutionTimelineNew).length) {
            console.log("@@@@ NEw timeline " + JSON.stringify(afterResolutionTimelineNew).length)
            setAfterResolutionTimeline(afterResolutionTimelineNew);
          }
          
        }
      }
    }

    return (
        <React.Fragment>
            <div className='event' data-resolving={collisionHandling ? "true" : "false"}>
                { (collidionResolutionMethod) &&
                    <div className="resolution-method-toggle">
                        <IonSegment mode="ios" value={collidionResolutionMethod} onIonChange={e => { 
                            const newValue = e.detail.value;
                            if (newValue === "delete" || newValue === "merge") {
                                let reverseToggle = false;
                                if (newValue === "merge" && !allowedMergeToResolve) {
                                    if (disallowedMergeReason === "offer") {
                                        (window as any).toast("We can't amend automatically as it's a confirmed offer", "error");
                                    } else {
                                        (window as any).toast("We can't amend automatically, as the existing event completely overlaps", "error");
                                    }
                                    reverseToggle = true;
                                }
                                setOverridenCollisionResolutionMethod(newValue);
                                if (reverseToggle) {
                                    setTimeout(() => {
                                        setOverridenCollisionResolutionMethod("delete");
                                    }, 100);
                                    setTimeout(() => {
                                        setOverridenCollisionResolutionMethod("delete");
                                    }, 500);
                                } else {
                                    let localStorageCollisions = JSON.parse(localStorage.getItem("collidingEvents") || "[]");
                                    localStorageCollisions = localStorageCollisions.map((ce: CollidingEvent) => {
                                        if (ce.id === event.id) {
                                            ce.resolutionMethod = newValue;
                                        }
                                        return ce;
                                    })
                                    localStorage.setItem("collidingEvents", JSON.stringify(localStorageCollisions));
                                }
                            } else {
                                onEdit();
                            }
                            //setActiveTab(e.detail.value as ('received' | 'own'));
                        } }>
                            <IonSegmentButton value="delete">
                                <IonLabel>Auto Delete</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="merge">
                                <IonLabel>Auto Amend</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="edit">
                                <IonLabel>Manual Edit</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </div>
                }
                <div 
                    className="event-item" 
                    data-from={Utilities.formatDate(new Date(start), "HH:MM")} 
                    data-to={Utilities.formatDate(new Date(end), "HH:MM")}
                    data-name={event.title} 
                    key={event.id} 
                    data-just-edited={justEdited} 
                    data-id={event.id} 
                    data-type={event.eventType} 
                    data-resolve-existing={collidionResolutionMethod !== null}
                    onClick={() => { 
                        if (resolvedEvents && resolvedEvents.indexOf(event.id) !== -1) { } else {
                            onEdit()
                        }
                    }}
                    data-has-collisions={(event.hasCollisions && event.clashingWith && event.clashingWith.length) ? true : false}
                    data-collision-target={outstandingCollisionTarget}
                    data-has-any-collisions={((event.hasCollisions && event.clashingWith && event.clashingWith.length) || outstandingCollisionTarget) ? true : false}
                >
                    {/* { (collisionHandling && resolvedEvents && resolvedEvents.indexOf(event.id) !== -1) &&
                        <div className="resolved">
                            <IonIcon icon={checkmarkCircle} />
                            <p>Resolved</p>
                        </div>
                    } */}
                    {/* { (collisionHandling && resolvedEvents && resolvedEvents.indexOf(event.id) === -1) &&
                        <div className="not-resolved pulse">
                            <IonIcon icon={closeCircle} />
                            <p>Tap to Resolve</p>
                        </div>
                    } */}
                    <div className="icon">
                        { (event.eventType === 4) && <WaveIcon className="wave" /> }
                        { (event.eventType === 1) && <BriefcaseIcon className="briefcase" /> }
                        { (event.eventType === 3) && <PadlockOpen className="dateunavailable" /> }
                        { (event.eventType === 2) && <PadlockClosed className="dateunavailable" /> }
                    </div>
                    { (event.eventType === 3) &&
                        <span className="half-available"></span>
                    }
                    <div className="details">
                        <h3>
                            { (event.hasCollisions && (event.clashingWith && event.clashingWith.length) || outstandingCollisionTarget) && <p className='autoinserted-collision'>
                                <IonIcon icon={alertCircle} />
                                { (event.hasCollisions && event.clashingWith && event.clashingWith.length) ?
                                    <span>Auto-inserted Confirmed Event (conflicting)</span> :
                                    <span>Existing Event (conflicting)</span>
                                }
                            </p>}
                            {orgName && event.title.toLocaleLowerCase().indexOf(orgName) === -1 ? orgName + " - " : "" }
                            {Utilities.capitalizeFirstLetter(event.title)}
                        </h3>
                        <h2>{Utilities.formatDate(new Date(start), "HH:MM")} - {Utilities.formatDate(new Date(end), "HH:MM")}</h2>
                        { overnight && end.getHours() === 0 && <h3>Event continues the next day.</h3> }
                        { overnight && start.getHours() === 0 && <h3>Event continued from previous day.</h3> }
                    </div>
                    <div className="options">
                        { (orgLogoUrl || (event.organisationUrl && !hideExternalOrgImage && !(window as any).hiddenExternalOrgImages[event.organisationUrl])) ?
                            <div className="logos">
                                { (orgLogoUrl) &&
                                    <div>
                                        <img src={orgLogoUrl} />
                                    </div>
                                }
                                { (event.organisationUrl && !hideExternalOrgImage && !(window as any).hiddenExternalOrgImages[event.organisationUrl]) &&
                                    <div>
                                        <img onError={() => { (window as any).hiddenExternalOrgImages[event.organisationUrl] = true; setHideExternalOrgImage(false) }} src={CONFIG.API_EXTERNAL_URI + "/organisations/external/logo/" + event.organisationUrl + "?api-version=1.0"} />
                                    </div>
                                }
                            </div>
                            : null
                        }
                        { (justEdited) &&
                            <IonIcon className="just-edited" icon={locateSharp} />
                        }
                        { (overnight) &&
                            <IonIcon className="overnight" icon={moon} />
                        }
                        { (event.repeatType !== 0) &&
                            <IonIcon className="repeat" icon={repeat} style={{ fontSize: '1.2em' }} />
                        }
                        { (event.groupId && (event.offerEventId === undefined || event.multipleGrouped)) &&
                            <IonIcon className="group" icon={layers} style={{ fontSize: '1.2em' }} />
                        }
                    </div>
                    { (collidionResolutionMethod) && 
                        <div className='state' data-state="existing">
                            Existing Event ({event.eventType === 1 ? "Working - I'm unavailable" : event.eventType === 2 ? "Private - I'm unavailable" : event.eventType === 3 ? "Private - I'm unavailable but offers considered" :  "I am available"})
                        </div>
                    }
                </div>
            </div>
            { (collisionHandling && afterResolutionTimeline) &&
                <div className='resolved-state'>
                    <div className='upper-line-horizontal'> </div>
                    <div className='upper-line-vertical'> </div>
                    { afterResolutionTimeline.map((event: any) => {
                        return (
                            <div 
                                className='event-item'
                                data-from={Utilities.formatDate(new Date(event.start), "HH:MM")} 
                                data-to={Utilities.formatDate(new Date(event.end), "HH:MM")}
                                data-name={event.title} 
                                data-just-edited={justEdited} 
                                data-id={event.id} 
                                data-type={event.eventType}
                                data-existing={event.existing}
                                data-deleted={event.deleted}
                                data-date={Utilities.formatDate(new Date(event.start), "YYYY-MM-DD")}
                                key={event.id + "_" + event.splitInstance + "_" + event.existing + "_" + event.deleted + "_" + event.start + "_" + event.end}
                                onClick={() => {
                                    (window as any).toast("Tap the first row in this group to edit the original event, as this is just a preview", "info")
                                }}
                            >  
                                <div className='outside-arrow-wrapper'>
                                    <div className='vertical-line'> </div>
                                    <div className='horizontal-line'> </div>
                                    <div className='arrow-alt right'> </div>
                                </div>
                                { (event.deleted) &&
                                    <div className="deleted-warning">

                                    </div>
                                }
                                { (event.deleted) &&
                                    <div className="deleted-warning-label">
                                        TO BE DELETED!
                                    </div>
                                }
                                <div className="icon">
                                    { (event.eventType === 4) && <WaveIcon className="wave" /> }
                                    { (event.eventType === 1) && <BriefcaseIcon className="briefcase" /> }
                                    { (event.eventType === 3) && <PadlockOpen className="dateunavailable" /> }
                                    { (event.eventType === 2) && <PadlockClosed className="dateunavailable" /> }
                                </div>
                                { (event.eventType === 3) &&
                                    <span className="half-available"></span>
                                }
                                <div className="details">
                                    <h3>
                                        {orgName && event.title && event.title.toLowerCase().indexOf(orgName) === -1 ? orgName + " - " : "" }
                                        {event.title && Utilities.capitalizeFirstLetter(event.title)}
                                    </h3>
                                    <h2>{Utilities.formatDate(new Date(event.start), "HH:MM")} - {Utilities.formatDate(new Date(event.end), "HH:MM")}</h2>
                                    {/* TODO OVERNIGHT */}
                                </div>
                                <div className='state' data-state={event.existing ? "existing" : "new"}>
                                    { event.existing ? ("Edited Existing Event (" + (event.eventType === 1 ? "Working - I'm unavailable" : event.eventType === 2 ? "Private - I'm unavailable" : event.eventType === 3 ? "Private - I'm unavailable but offers considered" :  "I am available") + ")") : ("New Event (" + (event.eventType === 1 ? "Working - I'm unavailable" : event.eventType === 2 ? "Private - I'm unavailable" : event.eventType === 3 ? "Private - I'm unavailable but offers considered" :  "I am available") + ")") }
                                </div>
                            </div>
                        )
                    }) }
                </div>
            }
        </React.Fragment>
    );
};

export default EventTimelineItem;
