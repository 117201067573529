import { Plugins } from '@capacitor/core';
import { IonToggle, IonAlert, isPlatform, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToast, IonToolbar, IonLoading } from '@ionic/react';
import { documentAttach, location, car, calendarSharp, videocam, reader, colorPalette, helpCircle, informationCircle, logOut, mail, people, share, shield, shieldCheckmark, time, trash, notifications, laptop, logoAppleAppstore, logoGooglePlaystore, phonePortrait, analytics, personCircle, briefcase, school, checkmarkCircle, star, stopwatch } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import UserAPI from '../apis/User';
import Configuration from '../Configuration';
import { User, UserDTO } from '../types/User';
import InternalTracker from '../InternalTracker';
import ProfileProgress, { Tag, Task } from './Components/ProfileProgress';
import Utilities from '../Utilities';
import RatingPreview from './Components/Rating/RatingPreview'
import RatingBanner from './Components/RatingBanner';
import { CircularProgress } from '@material-ui/core';
import RatingPreviewExtended from './Components/Rating/RatingPreviewExtended';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const COLOR_CODES = {
  1: '#50D890',
  2: '#B5BC0E',
  3: '#FB5B5A',
  4: '#FFA400',
  5: '#509ffe',
  6: '#777',
  7: '#333',
}

let alreadyCalledRegistration = false;

interface Props {
  match?: any;
}

const RatingView: React.FC<Props> = ({ match }) => {
  const [user, setUser] = useState <User> (null) // User
  const contentRef = useRef(null);
  const history = useHistory();
  const [pictureVersion, setPictureVersion] = useState <number> (0) // Picture version, to reload on change
  const [loading, setLoading] = useState <boolean> (false)
  const [idFromUrl, setIdFromUrl] = useState <number> (null);

  const reload = async (withoutServerFetch?: boolean) => {
    setLoading(true);
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user) as User;
      setUser(user as User);
    }
    if (!withoutServerFetch) {
      let newUser = (await Utilities.getUserProfile()) as User;
      if (newUser) {;
        console.log(newUser, match.params.id)
        let rating = newUser.ratings.find(r => r.id.toString() === match.params.id);
        if (!rating) {
          alert("This rating is not found.");
          history.push("/insight");
        } else {
          setUser(newUser);
        }
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    reload();
    setIdFromUrl(parseInt(match.params.id))
  }, [match?.params?.id])

  return (
    <IonPage data-page="me" ref={contentRef}>
      <IonHeader mode='md'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Rating</IonTitle>
          <div className="nonblocking-loading-wrapper" style={(loading) ? { display: 'flex' } : { display: 'none' }}>
            <CircularProgress />
            Loading Rating
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent id="me-wrapper" fullscreen>
        <div className='lists'>
          { (user && user.ratings) &&
            <section data-section="ratings">
              {/* <h2>Ratings, reviews received</h2> */}
              { user.ratings.map(rating => {
                if (rating.id !== idFromUrl) {
                  return null;
                }
                return (
                  <RatingPreviewExtended
                    hideRatee={true}
                    rating={rating}
                    promptRateBack={true}
                    onClick={() => {
                      if (rating.raterContactId) {
                        history.push('/contacts/' + rating.raterContactId + "#rate")
                      }
                    }}
                  />
                )
              }) }
            </section>
          }
          <div>
            <button style={{
              backgroundColor: "#3573E6",
              color: "white",
              borderRadius: "6px",
              padding: "12px 16px",
              fontSize: 14,
              display: "block",
              width: "calc(100% - 20px)",
              margin: '20px 10px 0 10px'
            }} onClick={() => {
              history.push("/insight");
            }} className='main'>Show All Ratings</button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RatingView;
