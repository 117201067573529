import InternalAPI from './InternalAPI';
import { SyncDTO } from '../types/SyncDTO';

class SyncAPI {

    /**
     * Uploads all new changes
     * @returns status
     */
    static async push(updates: SyncDTO[]): Promise<Object> { return InternalAPI.request("POST", "/sync?", updates, false, false, true, false); }

    /**
     * Returns all new updates
     * @param lastUpdated last successful pull date
     * @returns new changes
     */
    static async pull(lastUpdated?): Promise<Object> { 
        let url: string = "/sync?";
        // Only getting new ones, including deleted ones
        if (lastUpdated) {
            url += "updatedSince=" + lastUpdated + "&includeDeleted=true"
        }
        return InternalAPI.request("GET", url, null, false, null, true, false); 
    }

}

export default SyncAPI;
