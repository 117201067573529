import InternalAPI from './InternalAPI';
import versionName from '../versionName';
import { ContactResultDto, ReferralContact } from '../types/Contact';
import axios from "axios";

interface ClearBitResult {
    name: string;
    domain: string;
    logo: string;
}

interface ClearBitResultExtendedWithAgencyInformation extends ClearBitResult {
    email: string;
}

interface AgencyInformation {
    domain: string;
    email: string;
    name: string;
    notes: string;
    org: string;
}

class ContactAPI {

    /**
     * Returns all contacts of the user
     * @returns contact list
     */
    static async getContacts(): Promise<ContactResultDto> { return InternalAPI.request("GET", "/contacts?", null, false, false, true, true); }

    /**
     * Searches contacts
     * @param keywords query search keywords
     * @returns contact list
     */
    static async search(keywords: string, orgNameOnly?: boolean): Promise<Object> { return InternalAPI.request("POST", "/contact/search/?app-version=" + versionName + "&", { searchTerm: keywords, orgNameOnly: orgNameOnly || false }, null, null, true, true); }

    static async searchClearbit(keyword): Promise<ClearBitResult[]> {
        return new Promise(async function(resolve, reject) {
            const data = await axios.get("https://autocomplete.clearbit.com/v1/companies/suggest?query=" + keyword).catch(e => {
                resolve([]);
            });
            if (data && data.data) {
                resolve(data.data);
            }
        })
    }

    /**
     * Invites a hirer or agency
     * @param contactId id of contact
     * @param email email of contact
     * @param name name of contact
     * @returns confirmation
     */
    static async invite(
        contactId?: string, 
        email?: string, 
        name?: string, 
        confirmRisky?: boolean, 
        thirdPartyId?: string,
        thirdPartyReference?: string,
        orgId?: string,
        overrideMembership?: boolean,
        userId?: string
    ): Promise<Object> { 
        return InternalAPI.request("POST", "/contact/?" + (confirmRisky ? "&force=true&" : "") + (overrideMembership ? "&overrideMembership=true&" : ""), { 
            contactId: contactId, 
            userId: userId,
            email: email, 
            name: name,
            orgId: orgId || null,
            thirdPartyId: thirdPartyId || null,
            thirdPartyReference: thirdPartyReference || null
        }, false, false, true, true); 
    }

    /**
     * Removes a contact
     * @param contactId id of contact
     * @returns confirmation
     */
    static async remove(contactId: string): Promise<Object> { return InternalAPI.request("DELETE", "/contact/" + contactId + "?", null, false, false, true, true); }

    /**
     * Removes a temp org
     * @param rowId id of temp org
     * @returns confirmation
     */
    static async removeTempOrg(tempOrgId: string): Promise<Object> { return InternalAPI.request("DELETE", "/users/workerTempOrganisation/" + tempOrgId + "?", null, false, false, true, true); }

    /**
     * Adds an agency by only an org name
     * @param orgName orgName
     * @returns confirmation
     */
    static async addAgency(orgName: string, contactEmail?: string, contactName?: string, organisationId?: string): Promise<Object> { 
        return InternalAPI.request("POST", "/users/workerTempOrganisation/?", {
            tempOrganisationName: !organisationId ? orgName : undefined,
            tempContactName: contactName,
            tempContactEmail: contactEmail,
            organisationId: organisationId
        }, null, false, true, true); 
    }

    /**
     * Create an agency relationship by org id
     * @param orgName orgId
     * @returns confirmation
     */
    static async addAgencyById(orgId: string): Promise<Object> { return InternalAPI.request("POST", "/users/workerTempOrganisation/?", { OrganisationId: orgId }, null, false, true, true); }

    static async shareFile(fileId: number, userId: string): Promise<Object> { return InternalAPI.request("PUT", "/file/" + fileId + "/share/" + userId +  "?", null, null, false, true, true); }

    static async shareFolder(folderId: number, userId: string): Promise<Object> { return InternalAPI.request("PUT", "/filefolder/" + folderId + "/share/" + userId +  "?", null, null, false, true, true); }

    static async removeFileShare(fileId: number, userId: string): Promise<Object> { return InternalAPI.request("DELETE", "/file/" + fileId + "/share/" + userId +  "?", null, null, false, true, true); }

    static async removeFolderShare(folderId: number, userId: string): Promise<Object> { return InternalAPI.request("DELETE", "/filefolder/" + folderId + "/share/" + userId +  "?", null, null, false, true, true); }

    static async rejectFileRequest(fileId: number, userId: string): Promise<Object> { return InternalAPI.request("PUT", "/file/" + fileId + "/request/" + userId +  "/reject?", null, null, false, true, true); }

    static async rejectFileFolderRequest(folderId: number, userId: string): Promise<Object> { return InternalAPI.request("PUT", "/filefolder/" + folderId + "/request/" + userId +  "/reject?", null, null, false, true, true); }

    static async getAgencyContactInformation(keywords: string): Promise<AgencyInformation[]> { return InternalAPI.request("GET", "/agencysuggestion/" + keywords + "/?", null, null, null, true); }

    static async sendReferral(referredContact: ReferralContact, referredToContact: ReferralContact): Promise<Object> { return InternalAPI.request("PUT", "/referral/?", { referredContact: referredContact, referredToContact: referredToContact }, null, null, true); }

    static async getReferrals(): Promise<Object> { return InternalAPI.request("GET", "/referral/sent?", null, null, null, true); }

    static async acceptReferral(id): Promise<Object> { return InternalAPI.request("PUT", "/referral/" + id + "/accept?", null, null, null, true); }

    static async rejectReferral(id): Promise<Object> { return InternalAPI.request("PUT", "/referral/" + id + "/reject?", null, null, null, true); }

    static async requestReVerification(contactId?: string, tempOrgId?: string): Promise<Object> { return InternalAPI.request("PUT", "/verification/resend?" + (contactId ? "verifyingContactId=" + contactId : "") + (tempOrgId ? "&verifyingContactTempOrgId=" + tempOrgId : ""), null, null, null, true); }
}

export default ContactAPI;
