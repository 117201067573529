import React from 'react';

function PadlockOpen(props) {
    return (
        <svg id="bold" height="" viewBox="4 3 16.037 21" width="" xmlns="http://www.w3.org/2000/svg" >
            <path d="m12 3c.65 0 1.28.1 1.87.3l-1.71 1.71c-.05-.01-.11-.01-.16-.01-2.21 0-4 1.79-4 4v2.25c0 .55-.45 1-1 1s-1-.45-1-1v-2.25c0-3.31 2.69-6 6-6z"/>
            <path d="m18 9v2.25c0 .55-.45 1-1 1s-1-.45-1-1v-2.25c0-.63-.15-1.23-.41-1.76l1.46-1.46c.61.93.95 2.03.95 3.22z" />
            <path d="M 17.75 11 L 6.25 11 C 5.01 11 4 12.009 4 13.25 L 4 21.75 C 4 22.991 5.01 24 6.25 24 L 17.75 24 C 18.99 24 20 22.991 20 21.75 L 20 13.25 C 20 12.009 18.99 11 17.75 11 Z" />
            <path d="M 12.155 10.976 H 17.677 A 2.36 2.36 0 0 1 20.037 13.336 V 21.729 A 2.271 2.271 0 0 1 17.766 24 H 12.155 V 10.976 Z" />
        </svg>
    );
}

export default PadlockOpen;