import CalendarReducer from './Calendar';
import AvailabilityReducer from './Availability';
import TimelineCache from './TimelineCache';
import EventCache from './EventCache';
import SettingsReducer from './Settings';
import SyncReducer from './Sync';
import OnboardingGuideReducer from './OnboardingGuide';
import NotificationsReducer from './Notifications';
import RatingsReducer from './Ratings';
import ChatsReducer from './Chats';
import ResolvedEventsReducer from './ResolvedEvents';

import { combineReducers } from "redux"

const allReducers = combineReducers({
    calendar: CalendarReducer,
    availability: AvailabilityReducer,
    timelineCache: TimelineCache,
    eventCache: EventCache,
    settings: SettingsReducer,
    sync: SyncReducer,
    onboardingGuide: OnboardingGuideReducer,
    ratings: RatingsReducer,
    notifications: NotificationsReducer,
    chats: ChatsReducer,
    resolvedEvents: ResolvedEventsReducer
})

export default allReducers;
