export const updateEventCache = (timeline: Object) => {
    return {
        type: "UPDATE_EVENT_CACHE",
        data: timeline
    }
}

export const clearEventCache = () => {
    return {
        type: "CLEAR_EVENT_CACHE"
    }
}