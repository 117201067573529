import { IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

interface LoginRedirectPageProps extends RouteComponentProps {}

const LoginRedirect : React.FC<LoginRedirectPageProps> = (props: LoginRedirectPageProps) => {

    useEffect( () => {
      const urlParams = new URLSearchParams(window.location.href);
      const token: string = urlParams.get('access_token');
      if (token) {
        localStorage.setItem("access_token", token)
        window.location.href = "/"
      }
    }, []);
    
    return (
      <IonPage>
        <p>Signing in...</p>
      </IonPage>
    ); 
};

export default LoginRedirect;
