import { IonTextarea, IonAlert, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import { arrowUp, caretDownCircle, checkmarkCircleOutline, trash, warningOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import SectorsAPI from '../apis/Sectors';
import UserAPI from '../apis/User';
import Configuration from '../Configuration';
// import '../styles/Components/Settings/ProfileSettings.scss';
// import '../styles/TabHeader.scss';
import { User, UserDTO } from '../types/User';
import { UserEmail } from '../types/UserEmail';
import Utilities from '../Utilities';
import InternalTracker from '../InternalTracker';
// import '../styles/Components/TagManager.scss';
import { Plugins } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  
}

interface Tag {
  id: string,
  text: string,
  value: string
}

const ProfileSettings: React.FC<Props> = ({ }) => {
  
  const [loading, setLoading] = useState <boolean> (true) // Full screen loading
  const [user, setUser] = useState <User> (null) // User settings
  const [altEmails, setAltEmails] = useState <UserEmail[]> (null) // Alternative email addresses
  const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
  const [errorToast, setErrorToast] = useState <string> (null) // Error toast message
  const [lastUpdatedAt, setLastUpdatedAt] = useState <number> ((new Date().getTime())) // timestamp used to update image without needing to reload the page
  const [newAlternativeEmailModal, setNewAlternativeEmailModal] = useState <boolean> (false) // New email alert modal
  const [hasChanged, setHasChanged] = useState <boolean> (false) // Whether a field has been changed
  const [sectorSuggestions, setSectorSuggestions] = useState <Tag[]> ([]) // SectorSuggestions
  const [subSectorSuggestions, setSubSectorSuggestions] = useState <Tag[]> ([]) // SubSectorSuggestions
  const [subSectorSuggestionsId, setSubSectorSuggestionsId] = useState <number> (null) // Id of the currenctly queried subsector
  const [lastAddedSubSectorId, setLastAddedSubSectorId] = useState <number> (null) // The latest added subsector
  const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false) // Whether the keyboard is showing

  const contentRef = useRef(null);

  useEffect(() => {

    if ((window as any).os !== "web") {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setKeyboardOpen(true)
      });

      Keyboard.addListener('keyboardWillHide', () => {
          setKeyboardOpen(false)
      });
    }
    
    reload();
    
  }, [])

  const reload = function() {

    UserAPI.getNew().then(data => {

      // @ts-ignore
      data = data.results;
      data.maxDistance = data.maxDistance || 0;
      data.postCode = data.postalCode ? data.postalCode.toUpperCase() : "";
      data.industries = data.industries || [];
      setUser(data);

      UserAPI.getAltEmails(data.id).then(data => {
        setLoading(false)
        setAltEmails(data);
      }).catch(e => {
        console.error("Failed to emails", e);
        setLoading(false)
      })

    }).catch(e => {
      console.error("Failed to get user", e);
      setLoading(false)
    })

  }

  const addEmail = function(email) {

    InternalTracker.trackEvent("Alternative Email Added");

    if (email.trim()) {

      setLoading(true)

      UserAPI.addAltEmail(email).then(res => {
        setSuccessToast("Email added");
        reload();
      }).catch(e => {
        console.error("Failed to add email", e);
        setErrorToast("Invalid or already used email");
        setLoading(false)
      })

    }

  }

  const deleteEmail = function(id) {

    InternalTracker.trackEvent("Email Deleted");

    setLoading(true)

    UserAPI.removeAltEmail(id).then(res => {
      setSuccessToast("Email removed");
      reload();
    }).catch(e => {
      console.error("Failed to remove email", e);
      setErrorToast("Failed to remove email");
      setLoading(false)
    })

  }

  const setPrimaryEmail = function(id) {
    
    InternalTracker.trackEvent("Email Made Primary");

    const email = altEmails.find(item => item.id === id);
    if (!email.verified) {
      setErrorToast("Email must be verified");
      return;
    }

    setLoading(true)

    UserAPI.setPrimaryEmail(id).then(res => {
      setSuccessToast("Primary email changed");
      reload();
    }).catch(e => {
      setErrorToast("Failed to change primary email");
      setLoading(false)
    })

  }

  const updateSettings = function(user: User) {

    InternalTracker.trackEvent("Profile Settings Updated");

    let userDTO: UserDTO = {
      firstName: user.firstName,
      lastName: user.lastName,
      postalCode: user.postCode,
      maxDistance: user.maxDistance,
      allowRecording: user.allowRecording,
      industries: user.industries,
      headline: user.headline
    };

    console.group("SAVING: ", userDTO)

    UserAPI.update(userDTO).then(res => {
      setSuccessToast("Settings updated");
      setHasChanged(false);
      saveLocalSettings(userDTO)
    }).catch(e => {
      console.error(e);
      setErrorToast("Failed to update settings");
    })

  }
  
  const saveLocalSettings = function(newFields: UserDTO) {
    let user: string = localStorage.getItem("user");
    if (user) {
      let oldUser: User = JSON.parse(user);
      localStorage.setItem("user", JSON.stringify({...oldUser, ...newFields}))
    }
  }

  return (
    <IonPage data-page="profile-settings" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile Settings</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="profile-settings-wrapper" fullscreen>
        <IonList>
          <IonItem style={{ "--min-height": "32px" }} lines="none">
            <IonLabel position="stacked">Emails</IonLabel>
          </IonItem>
          { altEmails && altEmails.sort((email1, email2) => {
            if (email1.primaryEmail) return -1;
            if (email2.primaryEmail) return 1;
            if (email1.verified && !email2.verified) return -1;
            if (!email1.verified && email2.verified) return 1;
            return email1.email.localeCompare(email2.email);
          }).map(item => {
            return (
              <IonItemSliding>
                  <IonItem>
                      <IonLabel className="ion-text-wrap">
                        <h2>{item.email}</h2>
                        <p>{item.verified ? "Verified" : "Verification email sent"} {(item.primaryEmail ? " - Primary Email" : "")}</p>
                      </IonLabel>
                      {/* <IonIcon style={{ color: '#333' }} icon={(item.verified) ? checkmarkCircleOutline : warningOutline} slot="end" /> */}
                      <IonIcon onClick={() => {
                        if (item.primaryEmail) {
                          setErrorToast("You cannot delete your primary email");
                          return;
                        }
                        if (window.confirm("Are you sure you want to remove this email?")) {
                          deleteEmail(item.id)
                        }
                      }} style={{ color: '#333', opacity: item.primaryEmail ? 0.3 : 1 }} icon={trash} slot="end" />
                      <IonIcon onClick={() => {
                        if (item.primaryEmail) {
                          setErrorToast("This is already your primary email");
                          return;
                        }
                        if (window.confirm("Are you sure you want to make this the primary email?")) {
                          setPrimaryEmail(item.id)
                        }
                      }} style={{ color: '#333', opacity: item.primaryEmail ? 0.3 : 1 }} icon={arrowUp} slot="end" />
                  </IonItem>
                  {/* <IonItemOptions side="end">
                      <IonItemOption color="danger" onClick={() => { deleteEmail(item.id) }} >Delete</IonItemOption>
                  </IonItemOptions> */}
              </IonItemSliding>
            )
          }) }
          <IonItem onClick={() => { setNewAlternativeEmailModal(true) }}>
            <IonLabel>Add alternative email</IonLabel>
          </IonItem>
          <IonItem style={{ marginTop: 28 }}>
            <IonLabel position="stacked">First name</IonLabel>
            <IonInput
              placeholder="First name" 
              id="first-name"
              onIonBlur={() => { if (hasChanged) updateSettings(user); }}
              value={user ? (user.firstName || "") : ""} 
              onKeyDown={() => { setHasChanged(true); }}
              onIonInput={(e) => { 
                setUser({...user, firstName: (e.target as HTMLIonInputElement).value.toString() })
                saveLocalSettings({ firstName: (e.target as HTMLIonInputElement).value.toString() })
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Last name</IonLabel>
            <IonInput
              placeholder="Last name" 
              id="last-name"
              onIonBlur={() => { if (hasChanged) updateSettings(user); }}
              value={user ? (user.lastName || "") : ""} 
              onKeyDown={() => { setHasChanged(true); }}
              onIonInput={(e) => { 
                setUser({...user, lastName: (e.target as HTMLIonInputElement).value.toString() })
                saveLocalSettings({ lastName: (e.target as HTMLIonInputElement).value.toString() })
              }} 
            />
          </IonItem>
        </IonList>
        { (keyboardOpen) &&
            <div className="keyboard-hide">
                <IonIcon icon={caretDownCircle} />
            </div>
        }
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Please wait...'}
          duration={12000}
        />
          <input id="image-upload-input" style={{ display: 'none' }} type='file' onChange={ async (e) => {

            setLoading(true);

            const files = Array.from(e.target.files);
            const resized: File | void = await Utilities.resizeAndRotateImage(files[0] as Blob).catch(e => {
              (window as any).toast("Invalid image", "error")
              setLoading(false);
            });

            if (resized) {
              UserAPI
              .uploadProfilePicture(resized, user.id)
              .then(data => {
                setSuccessToast("Profile Picture Updated")
                setLoading(false);
                setLastUpdatedAt(new Date().getTime())
              }).catch(e => {
                console.error(e)
                setLoading(false);
                (window as any).toast("Failed to upload profile image, make sure it is less than 10MB and that you have internet connection.", "error")
              })
            }

        }} />
        <IonAlert
          isOpen={newAlternativeEmailModal}
          onDidDismiss={() => { setNewAlternativeEmailModal(false) }}
          header={"New email"}
          inputs={[
            {
              name: 'email',
              placeholder: "New alternative email",
              type: 'text',
              value: ""
            },
          ]}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => { }
            },
            {
              text: 'Add',
              handler: async (e) => {
                addEmail(e.email)
              }
            }
          ]}
        />
        <IonToast
          isOpen={successToast !== null}
          color="success"
          onDidDismiss={() => setSuccessToast(null)}
          message={successToast}
          position="top"
          duration={2500}
          buttons={[ { text: 'Hide', role: 'cancel' } ]}
        />
        <IonToast
            isOpen={errorToast !== null}
            color="danger"
            onDidDismiss={() => setErrorToast(null)}
            message={errorToast}
            position="top"
            duration={2500}
            buttons={[ { text: 'Hide', role: 'cancel' } ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default ProfileSettings;
