import { Plugins } from '@capacitor/core';
import { IonToggle, IonAlert, isPlatform, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToast, IonToolbar, IonActionSheet, IonLoading } from '@ionic/react';
import { documentAttach, location, car, calendarSharp, videocam, reader, colorPalette, helpCircle, informationCircle, logOut, mail, people, share, shield, shieldCheckmark, time, trash, notifications, laptop, logoAppleAppstore, logoGooglePlaystore, phonePortrait, analytics, personCircle, briefcase, school, checkmarkCircle, gitNetworkOutline, gitNetwork, calendar } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import UserAPI from '../apis/User';
import ContactAPI from '../apis/ContactAPI';
import Configuration from '../Configuration';

import { getSetting, saveSetting } from '../stores/Settings';
import { getLastSharedDate, hasUndeletedEvents, saveEvent } from '../stores/Event';
import { User, UserDTO, VerificationType } from '../types/User';
import Utilities from '../Utilities';
import BriefcaseIcon from './../icons/Briefcase';
import WaveIcon from './../icons/Wave';
import InlineSelect from './Components/InlineSelect';
import InternalTracker from '../InternalTracker';
import PadlockClosed from '../icons/PadlockClosed';
import PadlockOpen from '../icons/PadlockOpen';
import ProfileProgress, { Task } from './Components/ProfileProgress';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { PushNotifications } from '@capacitor/push-notifications';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const COLOR_CODES = {
  1: '#50D890',
  2: '#B5BC0E',
  3: '#FB5B5A',
  4: '#FFA400',
  5: '#509ffe',
  6: '#777',
  7: '#333',
}

let alreadyCalledRegistration = false;

interface Props { }

interface GenerticAlertMessage {
  message: string,
  title: string
}

const Settings: React.FC<Props> = ({ }) => {
  
  const [platform, setPlatform] = useState <string> (null) // Platform
  const [calendarColor, setCalendarColor] = useState <number> (null) // Calendar Color
  const [calendarColorOpen, setCalendarColorOpen] = useState <boolean> (null) // Calendar Color picker open
  const [defaultEventOpen, setDefaultEventOpen] = useState <boolean> (null) // Default event type picker open
  const [defaultEvent, setDefaultEvent] = useState <string> (null) // Default event
  const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
  const [UUID, setUUID] = useState <string> (null) // UUID
  const [user, setUser] = useState <User> (null) // User
  const [lastSync, setLastSync] = useState <Date> (null) // Last synced date
  const [lastUpdated, setLastUpdated] = useState <Date> (null) // Events last updated
  const [desktopPrompt, setDesktopPrompt] = useState <boolean> (false) // Desktop Prompt
  const [mobileAppPrompt, setMobileAppPrompt] = useState <boolean> (false) // Mobile app download Prompt
  const [eventTypeColors, setEventTypeColors] = useState <Object> (null) // Event type colors
  const [eventTypeColorsOpen, setEventTypeColorsOpen] = useState <boolean> (null) // Event type color editing
  const [totalProfileSteps, setTotalProfileSteps] = useState <number> (null) // total profile steps
  const [profileSteps, setProfileSteps] = useState <Task[]> ([]) // Tasks of profile progress
  const [completedProfileSteps, setCompletedProfileSteps] = useState <number> (null) // completed profile steps
  const [progressGuideOpen, setProgressGuideOpen] = useState <boolean> (null) // Wheter the progress guide is open
  const [progressGuideSection, setProgressGuideSection] = useState <number | null> (null) // Section to scroll to
  const [userGuideOpenSection, setUserGuideOpenSection] =  useState <string | null> (null) // Section to open
  const [pictureVersion, setPictureVersion] = useState <number> (0) // Picture version, to reload on change
  const [nextProfileStep, setNextProfileStep] = useState <string> (null) // Next profile step
  const [nextProfileStepI, setNextProfileStepI] = useState <number> (null) // Next profile step
  const [adminPanel, setAdminPanel] = useState <boolean> (null) // Admin panel
  const [adminPanelCount, setAdminPanelCount] = useState <number> (0) // Admin panel count
  const [showUserProfilePhoto, setShowUserProfilePhoto] = useState <boolean> (true) // Show user profile photo
  const [rejectedVerifications, setRejectedVerifications] = useState <string[]> ([]) // Has any rejected verifications
  const [loadingMessage, setLoadingMessage] = useState <string> (null) // Loading message
  const [confirmRequestVerification, setConfirmRequestVerification] = useState <boolean> (false) // Confirm request verification
  const [genericAlertMessage, setGenericAlertMessage] = useState <GenerticAlertMessage> (null) // Generic alert message
  const contentRef = useRef(null);
  const history = useHistory();

  const printStorage = async () => {
    console.log((window as any).db);
    let iConsoleLogs = 0;
    const events = await (window as any).db.events.toArray();
    const settings = await (window as any).db.settingss.toArray();
    const cache = await (window as any).db.cache.toArray();
    const localStorageItems = { ...localStorage };
    let totalConsoleLogs = events.length + settings.length + cache.length + Object.keys(localStorageItems).length;

    console.log("Events", events.length);
    console.log("Settings", settings.length);
    console.log("Cache", cache.length);
    console.log("localStorage", Object.keys(localStorageItems).length);

    for (let i = 0; i < events.length; i++) {
      console.log("event: " + JSON.stringify(events[i]));
      await new Promise(resolve => setTimeout(resolve, 100));
      if (iConsoleLogs % 30 === 0) {
        (window as any).toast("Sending Logs: " + iConsoleLogs + " / " + totalConsoleLogs, "info");
      }
      iConsoleLogs++;
    }

    for (let i = 0; i < settings.length; i++) {
      console.log("setting: " + JSON.stringify(settings[i]));
      await new Promise(resolve => setTimeout(resolve, 100));
      if (iConsoleLogs % 30 === 0) {
        (window as any).toast("Sending Logs: " + iConsoleLogs + " / " + totalConsoleLogs, "info");
      }
      iConsoleLogs++;
    }

    for (let i = 0; i < cache.length; i++) {
      console.log("cache: " + JSON.stringify(cache[i]));
      await new Promise(resolve => setTimeout(resolve, 100));
      if (iConsoleLogs % 30 === 0) {
        (window as any).toast("Sending Logs: " + iConsoleLogs + " / " + totalConsoleLogs, "info");
      }
      iConsoleLogs++;
    }

    for (let key in localStorageItems) {
      console.log(key + ": " + JSON.stringify(localStorageItems[key]));
      await new Promise(resolve => setTimeout(resolve, 100));
      if (iConsoleLogs % 30 === 0) {
        (window as any).toast("Sending Logs: " + iConsoleLogs + " / " + totalConsoleLogs, "info");
      }
      iConsoleLogs++;
    }

    (window as any).toast("All logs have been sent", "info");
  }

  const reload = async () => {

    let deviceInfo = await Device.getInfo();
    let lastSynced = (await getLastSharedDate()) || null //localStorage.getItem("lastSync")
    if (lastSynced) {
      setLastUpdated(new Date(lastSynced))
    }
    if (localStorage.getItem("lastSync")) {
      setLastSync(new Date(localStorage.getItem("lastSync")));
    }
    let user: any = localStorage.getItem("user");
    if (user) {

      user = JSON.parse(user) as User;
      // @ts-ignore
      user.rating = 4.4;
      setUser(user as User);
      setEventTypeColors(user && user.eventColors ? JSON.parse(user.eventColors) : { availability: 5, work: 1, private: 7 });

      let haveEvent = await hasUndeletedEvents();
      let haveContact = (localStorage.getItem("haveContact") === "true");

      const hirersCount = localStorage.getItem("hirersCount") ? parseInt(localStorage.getItem("hirersCount")) : null;
      const agenciesCount = localStorage.getItem("agenciesCount") ? parseInt(localStorage.getItem("agenciesCount")) : null;
      const contactsCount = localStorage.getItem("contactsCount") ? parseInt(localStorage.getItem("contactsCount")) : null;

      let tasks: Task[] = [
        {
            id: "image",
            title: "Profile Photo",
            description: "Upload a profile image that contacts recognise",
            descriptionCompleted: "Profile image has been uploaded",
            completed: user.userSetProfileImage,
            color: "#509ffe",
        },
        {
            id: "headline",
            title: "Headline",
            description: "Explain what you do in one sentence",
            descriptionCompleted: "Headline has been saved",
            completed: user.headline !== undefined && user.headline !== null && user.headline !== "",
            color: "#3FA796",
        },
        {
            id: "postalcode",
            title: "Postcode & Distance of travel",
            description: "Set your normal Zip/Postcode",
            descriptionCompleted: "Zip/Postcode has been saved",
            completed: user.postalCode !== null && user.postalCode !== undefined && user.postalCode !== "" && user.maxDistance !== null && user.maxDistance !== undefined && user.maxDistance !== 0,
            color: "#FFA400",
        },
        {
            id: "sectorssubsectors",
            title: "Sectors & Skills",
            description: "Enter each sector you work in, e.g. Education, subsector Primary",
            descriptionCompleted: "Sectors & skills have been saved",
            completed: user.workerAttributes && user.workerAttributes.length !== 0,
            color: "#4C3F91",
        },
        {
            id: "qualifications",
            title: "Qualifications",
            description: "Your qualifications",
            descriptionCompleted: "Qualifications have been saved",
            completed: user.qualifications && user.qualifications.length !== 0,
            color: "#6E3CBC",
        },
        {
          id: "smsnotifications",
          title: "SMS Instant text offer alerts",
          description: "SMS Instant text offer alerts",
          descriptionCompleted: "SMS Instant text offer alerts",
          completed: user.phoneNumber && user.phoneNumberVerified,
          color: "#6E3CBC",
      },
        localStorage.getItem("FeatureFiles") ? {
          id: "files",
          title: "Show What I Do",
          description: "Add examples of your work files to showcase your expertise",
          descriptionCompleted: "Add examples of your work files to showcase your expertise",
          completed: user.files && user.files.length !== 0,
        } : null,
        {
          id: "verify",
          title: "Verification",
          description: "To be verified you must have your image, your headlines & be connected with two or more verified employers/hirers, or a verified recruitment agency who view your availability.",
          descriptionCompleted: "Account has been verified",
          completed: user.verified || user.agencyVerified,
          color: "#FABB51"
        },
        {
          id: "share",
          title: haveEvent ? "Your Schedule" : "Add your first events",
          description: "Add your first event",
          descriptionCompleted: "First event has been added",
          completed: haveEvent,
          color: "#509ffe",
        },
        {
          id: "contacts",
          title: !haveContact ? "Add contacts" : (((hirersCount !== null && agenciesCount !== null) ? (hirersCount + agenciesCount) : (contactsCount || "1")) + " contacts added"),
          description: "Find & add contacts to share with",
          descriptionCompleted: "First contact has been added, you can add more any time",
          completed: haveContact,
          color: "#3FA796",
        }
      ]

      tasks = tasks.filter(item => item !== null)

      console.log(tasks, "<<<<<<")

      setProfileSteps(tasks)
      setTotalProfileSteps(tasks.length);
      setCompletedProfileSteps(tasks.filter(item => item.completed).length);

      const nextUncompletedTask = tasks.find((item, itemI) => itemI > 1 && !item.completed);
      const nextUncompletedTaskI = tasks.findIndex((item, itemI) => itemI > 1 && !item.completed);

      if (nextUncompletedTask) {
        setNextProfileStep(nextUncompletedTask.id);
        setNextProfileStepI(nextUncompletedTaskI);
      }
    }

    const contacts = localStorage.getItem("contacts") ? JSON.parse(localStorage.getItem("contacts")) : [];
    if (contacts) {
      let rejectedVerifications = contacts.agencies?.filter(o => o.verificationRejectedByContactId).map(o => o.verificationRejectedByContactId);
      setRejectedVerifications(rejectedVerifications)
    }

    setPlatform(deviceInfo.platform)
    const id = await Device.getId();
    if (id && id.identifier)
      setUUID(id.identifier)

    let calendarColor = await getSetting("calendarColor");
    setCalendarColor(calendarColor ? parseInt(calendarColor) : null);
    setDefaultEvent(user?.settings?.find(setting => setting.settingId === 18)?.value || "4");

  }

  const impersonate = async() => {
    let email = window.prompt("Enter Email");
    let ownAccessToken = localStorage.getItem("access_token");
    let link = 
      'https://' + CONFIG.AUTH_TENANT_NAME + '.b2clogin.com/' + CONFIG.AUTH_TENANT_NAME + '.onmicrosoft.com/oauth2/v2.0/authorize?targetEmail=' +
      email +
      '&p=B2C_1A_Impersonation&client_id=' + 
      CONFIG.AUTH_APP_ID + 
      '&nonce=defaultNonce&redirect_uri=' +
      ( (localStorage.getItem("env") === "dev"
      ? 'http://localhost:5000'
      : 'https://my.updatedge.com')) +
      '/redirectimp&scope=openid%20https://' + 
      CONFIG.AUTH_TENANT_NAME + 
      '.onmicrosoft.com%2Fmobapi%2Fuser_impersonation&response_type=id_token%20token&prompt=login&authUserToken=' +
      ownAccessToken;
      console.log(link);
    window.location.href = link;
  }

  useEffect(() => {

      reload();

      history.listen(e => {
        if (e.pathname === "/settings") {
          reload();
        }
      })

  }, [])

  const saveLocalSettings = function(newFields: UserDTO) {
    let user: string = localStorage.getItem("user");
    if (user) {
      let oldUser: User = JSON.parse(user);
      localStorage.setItem("user", JSON.stringify({...oldUser, ...newFields}))
    }
  }

  const enableNotifications = function(confirm?: boolean) {

    let enabledNotifs = localStorage.getItem("notificationToken") && localStorage.getItem("notificationToken") !== "skipped";

    if (enabledNotifs && !confirm) {
      let reenable = window.confirm("Notifications are enabled. Reregister for notifications");
      if (reenable) {
        enableNotifications(true);
      }
      return;
    }

    InternalTracker.trackEvent("Push Notifications Enabled")

    PushNotifications
      .register()
      .then(data => {
        PushNotifications
          .requestPermissions()
          .then(data => { }).catch(error => { })
        localStorage.setItem("askedForNotifications", "true");
      }).catch(error => { })

    PushNotifications.addListener(
      'registration',
      (token) => {

        if (alreadyCalledRegistration) {
          return;
        } else {
          alreadyCalledRegistration = true;
        }

        // @ts-ignore
        UserAPI.pushNotificationRegsiter(token.value, isPlatform("android") ? "fcm" : "apns").then(data => {

          localStorage.setItem("notificationToken", token.value);

          (window as any).toast("Notifications enabled", "success")
        }).catch(e => {
          console.error("Failed to REGISTER WIHT SERVER", e);
        })

      },
    );

  }

  return (
    <IonPage data-page="settings" ref={contentRef}>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle onClick={() => {
            Utilities.attemptRefresh();
          }}>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="settings-wrapper" fullscreen>
        { user &&
          <div className="header">
            <div 
              className="profile-img"
              onClick={() => {
                setProgressGuideOpen(true);
                setProgressGuideSection(2);
                setUserGuideOpenSection("image");
              }}
            >
              { showUserProfilePhoto &&
                <img src={UserAPI.getProfilePicture(user.id)} />
              }
              { (totalProfileSteps) &&
                <div className="circle">
                  <CircularProgressbar
                      value={Math.round(completedProfileSteps / totalProfileSteps * 100)}
                      text="" 
                      styles={{
                          path: {
                              stroke: `rgba(53,115,230, 0.85)`,
                          },
                          trail: {
                              stroke: 'rgba(255, 255, 255, 0.15)',
                          }
                      }}
                  />
                </div>
              }
            </div>
            <div className="details">
              <h2>{user.firstName + " " + user.lastName}</h2>
              { (user.headline) ?
                <h3>{user.headline}</h3>
                :
                <h3>Add Profile Headline: Primary SEND; Sous Chef, LGV Cat C+E</h3>
              }
              <div className="verification" onClick={() => {
                let verifiedBy = [];
                if (user.verified) {
                  verifiedBy.push("Updatedge")
                }

                const identityVerifyingAgencies = [...new Set(user.verifications.filter(o => o.typeId === VerificationType.IdentityVerified).map(o => o.verifyingOrgName || o.contactName))];
                if (identityVerifyingAgencies.length) {
                  verifiedBy = verifiedBy.concat(identityVerifyingAgencies)
                }

                setGenericAlertMessage({
                  title: (user.verified || user.verifiedByAgency) ? "Your Profile has been Approved" : "Profile Approval Pending",
                  message: ((user.verified || user.verifiedByAgency) ? ("Verified by: " + verifiedBy.join(", ")) : "Your profile suitability is assessed by verified agencies. Updatedge may also approve your profile and will automatically request approval from your agencies. Profile suitability evaluates your image, headline, skills, and qualifications to ensure your profile presents you in the best possible way and is accurate.")
                })
              }}>
                <IonIcon icon={ (user.verified || user.verifiedByAgency) ? shieldCheckmark : shield } style={{ color: ((user.verified || user.verifiedByAgency) ? "#50D890" : "#FB5B5A") }} /> 
                <span style={{
                  marginLeft: 6,
                  color: ((user.verified || user.verifiedByAgency) ? "#50D890" : "#FB5B5A")
                }}>{ (user.verified || user.verifiedByAgency) ? "Profile Approved" : "Waiting for Approval" }</span>
              </div>
              <p>Last shared with contacts { (lastSync) ? Utilities.timeSince(lastSync) : "Never" }</p>
              <p>Event changes last shared { (lastUpdated) ? Utilities.timeSince(lastUpdated) : "Never" }</p>
              { (totalProfileSteps) &&
                <React.Fragment>
                  { (completedProfileSteps !== totalProfileSteps && completedProfileSteps+1 !== totalProfileSteps) ?
                    <button style={{
                      padding: '8px 12px',
                      borderRadius: 6,
                      color: 'white',
                      background: '#3573E6',
                      margin: '0 4px'
                    }} onClick={() => {
                      if (nextProfileStep) {
                        setProgressGuideOpen(true);
                        setProgressGuideSection(nextProfileStepI)
                        setUserGuideOpenSection(nextProfileStep)
                      }
                    }}>Continue Setup</button>
                  : <button style={{
                      padding: '6px 10px',
                      borderRadius: 6,
                      color: 'white',
                      background: '#3573E6',
                      margin: 4
                    }} onClick={() => {
                      setProgressGuideSection(null)
                      setUserGuideOpenSection(null)
                      setProgressGuideOpen(true);
                    }}>Profile Setup Completed</button>
                  }
                </React.Fragment>
              }
              { (rejectedVerifications.length) ?
                <button style={{
                  padding: '6px 10px',
                  borderRadius: 6,
                  color: 'white',
                  background: '#3573E6',
                  margin: 4,
                }} onClick={() => {
                  setConfirmRequestVerification(true);
                }}>Re-request Verification</button>
              : null }
            </div>
            {/* <div className="rating" onClick={() => { history.push("/ratings") }}>
              <IonIcon icon={star} style={{ color: "#50D890" }} />
              <span>{user.rating || "-"}</span>
            </div> */}
          </div>
        }
        <IonList>

          <IonListHeader mode="ios" onClick={() => {
            if (adminPanelCount > 10) {
              printStorage();
            } else {
              setAdminPanelCount(adminPanelCount+1)
            }
          }}>
            <IonLabel>Your Account</IonLabel>
          </IonListHeader>

          <div className='profile-settings-grid'>
            { profileSteps.map((task, i) => {
              return (
                <div
                  className='task'
                  onClick={() => {
                    if (task.id === "verify") {
                      window.alert((user.verified || user.verifiedByAgency) ? "Your profile has been verified" : "We will progress your verification and will let you know. No further action is required by you, if you are connected with a verified agency, or two verified hirers, and have added your image & headlines. Push OK to dismiss this alert.");
                    } else if (task.id === "contacts") {
                      history.push("/contacts");
                    } else if (task.id === "share") {
                      history.push("/calendar");
                    } else {
                      setProgressGuideOpen(true);
                      setProgressGuideSection(i);
                      setUserGuideOpenSection(task.id);
                    }
                  }}
                  style={{
                    background: task.color
                  }}
                >
                  <div className='icon'>
                    <IonIcon 
                      icon={ task.id === "image" ? personCircle : task.id === "headline" ? reader : task.id === "postalcode" ? location : task.id === "proximity" ? car : task.id === "sectorssubsectors" ? briefcase : task.id === "qualifications" ? school : task.id === "verify" ? shieldCheckmark : task.id === "files" ? documentAttach : task.id === "videos" ? videocam : task.id === "share" ? calendar : task.id === "contacts" ? people : videocam}
                      style={{
                        color: task.color
                      }}
                    />
                    { (task.completed) &&
                      <IonIcon icon={checkmarkCircle} />
                    }
                  </div>
                  <h4>{task.title}</h4>
                  {/* { (task.id === "videos" || task.id === "files") &&
                    <label>Coming Soon</label>
                  } */}
                </div>
              )
            }) }
          </div>

          <IonListHeader mode="ios">
            <IonLabel>Other</IonLabel>
          </IonListHeader>
          <IonItem onClick={() => { history.push("/settings/profile") }}>
            <IonIcon icon={people} />
            <IonLabel>Profile Settings</IonLabel>
          </IonItem>
          <IonItem onClick={() => { history.push("/settings/timeline-clearup") }}>
            <IonIcon icon={gitNetwork} />
            <IonLabel>One Tap Timeline Clearup</IonLabel>
          </IonItem>
          <IonItem onClick={() => { history.push("/settings/time-presets") }}>
            <IonIcon icon={time} />
            <IonLabel>Time presets</IonLabel>
          </IonItem>
          { (platform !== "web") &&
            <IonItem onClick={() => { setDesktopPrompt(true) }}>
              <IonIcon icon={laptop} />
              <IonLabel>Use Updatedge from a browser</IonLabel> 
            </IonItem>
          }
          { (platform === "web") &&
            <IonItem onClick={() => { setMobileAppPrompt(true) }}>
              <IonIcon icon={phonePortrait} />
              <IonLabel>Download the Updatedge app</IonLabel> 
            </IonItem>
          }
          <IonItem lines={(defaultEventOpen ? "none" : "inset")} onClick={() => { setDefaultEventOpen(true) }}>
            <IonIcon icon={calendarSharp} />
            <IonLabel>Default Event Type</IonLabel> 
          </IonItem>
          { defaultEventOpen &&
            <IonItem>
              { (defaultEvent) &&
                <InlineSelect
                  selected={defaultEvent}
                  options={[
                      { id: ["1"], icon: <BriefcaseIcon />, label: "Working" },
                      { id: ["2"], icon: <PadlockClosed />, label: "Private, no offers" },
                      { id: ["3"], icon: <PadlockOpen />, label: "Private, but offers considered" },
                      { id: ["4"], icon: <WaveIcon />, label: "I'm available" },
                  ]}
                  onSelect={(newId) => {

                    setDefaultEventOpen(false);
                    setSuccessToast("New default event type has been set");

                    let user: User = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
                    if (user) {
                      let foundSetting = false;
                      let settings = user.settings || [];
                      for (let i = 0; i < settings.length; i++) {
                        if (settings[i].settingId === 18) {
                          settings[i].value = newId
                          foundSetting = true;
                          break;
                        }
                      }
                      if (!foundSetting) {
                        settings.push({ settingId: 18, settingName: "EventType_Default", value: newId})
                      }
                      Utilities.upsertLocalUser({ settings: settings })
                      UserAPI.updateSetting("18", newId);
                    }

                    InternalTracker.trackEvent("Updated Default Event Type", {
                      value: newId
                    });

                    setDefaultEvent(newId);
                    saveSetting("defaultEvent", newId);

                  }}
                />
              }
            </IonItem>
          }
          
          <IonItem lines={(calendarColorOpen ? "none" : "inset")} onClick={() => { setCalendarColorOpen(true); }}>
            <IonIcon icon={colorPalette} />
            <IonLabel>Calendar Color</IonLabel> 
          </IonItem>
          { (calendarColorOpen) &&
            <IonItem>
              <div className="color-picker">
                { ["#3573E6", "#389393", "#0f3057", "#2d4059", "#ddd", "#555", "#333", "black"].map((item, i) => {
                  return (<div key={item} onClick={() => {

                    setCalendarColor(i);
                    setCalendarColorOpen(false);
                    saveSetting("calendarColor", i.toString());
                    setSuccessToast("New color has been set");

                    InternalTracker.trackEvent("Updated Calendar Color", {
                      value: ["#3573E6", "#389393", "#0f3057", "#2d4059", "#ddd", "#555", "#333", "black"][i]
                    });

                  }} data-selected={calendarColor === i} style={{ backgroundColor: item }}></div>)
                }) }
              </div>
            </IonItem>
          }

          <IonItem lines="inset" onClick={() => {

          }}>
            <IonIcon icon={analytics} />
            <IonLabel>Animations</IonLabel> 
            <IonToggle slot="end" checked={localStorage.getItem("animation") ? true : false} onIonChange={() => {
                InternalTracker.trackEvent("Updated UI Animations", {
                  value: localStorage.getItem("animation")
                })

                if (localStorage.getItem("animation")) {
                  localStorage.removeItem("animation");
                } else {
                  localStorage.setItem("animation", "true");
                }

                localStorage.setItem("disablePushReRegisterOnInit", "true");
                window.location.href = "/"
            }}></IonToggle>
          </IonItem>

          { (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) &&
            <IonItem onClick={() => {
              enableNotifications();
            }}>
              <IonIcon icon={notifications} />
              <IonLabel>Enable notifications</IonLabel>
            </IonItem>
          }

          <IonItem lines={(eventTypeColorsOpen ? "none" : "inset")} onClick={() => { setEventTypeColorsOpen(true); }}>
            <IonIcon icon={colorPalette} />
            <IonLabel>Event Type Colors</IonLabel> 
          </IonItem>
          { (eventTypeColorsOpen) &&
              <div className="color-picker-event-wrapper">
                { [
                  {
                    label: "Working",
                    field: "work"
                  },
                  {
                    label: "Private",
                    field: "private"
                  },{
                    label: "I'm available",
                    field: "availability"
                  }/*,{
                    label: "Private (Offers considered)",
                    field: ""
                  }*/,
                ].map(item => {
                  return (
                    <div>
                      <h3>{item.label}</h3>
                      <div className="color-picker">
                        { Object.keys(COLOR_CODES).map(colorKey => {
                          return (
                            <div onClick={() => {
                              let newColors = Object.assign({}, eventTypeColors);
                              newColors[item.field] = parseInt(colorKey);
                              setEventTypeColors(newColors);
                              const body = document.getElementsByTagName("body");
                              body[0].setAttribute("data-" + item.field + "-color", colorKey);

                              let userDTO: UserDTO = {
                                eventColors: JSON.stringify(newColors)
                              }

                              InternalTracker.trackEvent("Updated Event Colors", {
                                colors: newColors
                              })
                          
                              UserAPI.update(userDTO).then(res => {
                                setSuccessToast("Settings updated");
                                saveLocalSettings(userDTO)
                              }).catch(e => {
                                console.error(e);
                              })

                            }} className="color" data-selected={parseInt(colorKey) == eventTypeColors[item.field] ? "true" : "false"} style={{ backgroundColor: COLOR_CODES[parseInt(colorKey)] }}></div>
                          )
                        }) }
                      </div>
                    </div>
                  )
                }) }
                <button onClick={() => {
                  localStorage.setItem("disablePushReRegisterOnInit", "true");
                  window.location.href = "/";
                }}>Apply Color Changes</button>
              </div>
          }

          <IonListHeader mode="ios" onClick={() => {
            if (adminPanelCount > 2) {
              setAdminPanel(true)
            } else {
              setAdminPanelCount(adminPanelCount+1)
            }
          }}>
            <IonLabel>Support</IonLabel>
          </IonListHeader>
          <IonItem onClick={() => {
            // history.push("/help") 
            setProgressGuideSection(3)
            setProgressGuideOpen(true)
          }}>
            <IonIcon icon={helpCircle} />
            <IonLabel>Help Guides</IonLabel>
          </IonItem>
          <IonItem onClick={() => {

            InternalTracker.trackEvent("Contacted Us", {
              source: "Settings"
            })

            window.open("mailto:support@updatedge.com?subject=Version 2 Support Request") 
            
          }}>
            <IonIcon icon={mail} />
            <IonLabel>Contact Us</IonLabel>
          </IonItem>
          { (platform === "ios") &&
              <IonItem onClick={() => { 
                InternalTracker.trackEvent("Rate App Opened", {
                  source: "Settings",
                  platform: "ios"
                })
                window.open("https://apps.apple.com/gb/app/id1559217533") 
              }}>
                  <IonIcon icon={logoAppleAppstore} />
                  <IonLabel>Rate in AppStore</IonLabel>
              </IonItem>
          }
          { (platform === "android") &&
              <IonItem onClick={() => {
                InternalTracker.trackEvent("Rate App Opened", {
                  source: "Settings",
                  platform: "android"
                })
                window.open("https://play.google.com/store/apps/details?id=com.updatedge")
              }}>
                  <IonIcon icon={logoGooglePlaystore} />
                  <IonLabel>Rate in GooglePlay</IonLabel>
              </IonItem>
          }
          { (platform === "ios" || platform === "android") &&
            <IonItem onClick={() => {
              if (localStorage.getItem("updateAvailable")) {
                if (platform === "android") {
                  window.open("https://play.google.com/store/apps/details?id=com.updatedge")
                } else {
                  window.open("https://apps.apple.com/gb/app/id1559217533")
                }
              } else {
                (window as any).toast("You are on the latest version", "info");
              }
            }}>
              <IonIcon icon={informationCircle} />
              <IonLabel>Check for Updates</IonLabel>
            </IonItem>
          }
          <IonItem onClick={() => { history.push("/help/about") }}>
            <IonIcon icon={informationCircle} />
            <IonLabel>About</IonLabel>
          </IonItem>
          <IonItem onClick={() => {

            InternalTracker.trackEvent("Recommended Us", {
              source: "Settings"
            })

            if (platform === "web") {

              Clipboard.write({
                  string: `I have been using Updatedge to easily share my availability with my contacts. Visit https://www.updatedge.com`
              });

              setSuccessToast("Message copied, you can paste it to anywhere")

            } else {

              Share.share({
                  title: "Updatedge",
                  text: "I like using updatedge because...",
                  url: "https://www.updatedge.com",
                  dialogTitle: "I have been using Updatedge to easily share my availability with my contacts. Visit ",
              }).catch(e => {});

            }

          }}>
            <IonIcon icon={share} />
            <IonLabel>Recommend Updatedge</IonLabel>
          </IonItem>
          <IonItem onClick={() => { history.push("/help/close-account") }}>
            <IonIcon icon={trash} />
            <IonLabel>Close Account & Delete Data</IonLabel>
          </IonItem>
          <IonItem onClick={ async () => {
            Utilities.logOut();
          }}>
            <IonIcon icon={logOut} />
            <IonLabel>Log Out</IonLabel>
          </IonItem>

          <IonListHeader mode="ios">
            <IonLabel>Legal</IonLabel>
          </IonListHeader>
          <IonItem onClick={() => { Browser.open({ url: 'https://ukliveue.blob.core.windows.net/policies/EULA.htm' }); }}>
            <IonLabel>Terms of Use</IonLabel>
          </IonItem>
          <IonItem onClick={() => { Browser.open({ url: 'https://ukliveue.blob.core.windows.net/policies/Privacy.htm' }); }}>
            <IonLabel>Privacy</IonLabel>
          </IonItem>
          <IonItem onClick={() => { Browser.open({ url: 'https://policies.google.com/terms' }); }}>
            <IonLabel>Google Terms of Use</IonLabel>
          </IonItem>
          { (user && user.email && user.email.endsWith("@updatedge.com")) &&
            <IonItem onClick={() => {
              impersonate();
            }}>
              <IonLabel>Impersonate</IonLabel>
            </IonItem>
          }
          { (user && user.email && user.email.endsWith("@updatedge.com")) &&
            <IonItem onClick={() => {
              if (localStorage.getItem("debugMessages")) {
                localStorage.removeItem("debugMessages")
                window.location.href = "/"
              } else {
                localStorage.setItem("debugMessages", "true");
                window.location.href = "/"
              }
            }}>
              <IonLabel>{ localStorage.getItem("debugMessages") ? "Disable Debug messages" : "Enable Debug messages" }</IonLabel>
            </IonItem>
          }

          <IonToast
              isOpen={successToast !== null}
              color="success"
              onDidDismiss={() => setSuccessToast(null)}
              message={successToast}
              position="top"
              duration={2500}
              buttons={[ { text: 'Hide', role: 'cancel' } ]}
          />


          <IonAlert
            isOpen={genericAlertMessage !== null}
            onDidDismiss={() => setGenericAlertMessage(null)}
            header={genericAlertMessage ? genericAlertMessage.title : ""}
            message={genericAlertMessage ? genericAlertMessage.message : ""}
            buttons={[
                {
                  text: 'Close',
                  handler: () => {}
              }
            ]}
          />

          <IonAlert
            isOpen={desktopPrompt}
            onDidDismiss={() => setDesktopPrompt(false)}
            header={"You can use Updatedge from your computer's browser by going to my.updatedge.com and signing in"}
            buttons={[
                {
                    text: 'Copy Website Link',
                    handler: () => {
                      Clipboard.write({
                        string: `https://my.updatedge.com`
                      });
                    }
                },
                {
                    text: 'Visit Website Now',
                    handler: () => {
                      window.open("https://my.updatedge.com")
                    }
                },
                {
                  text: 'Close',
                  handler: () => {}
              }
            ]}
          />


          <IonAlert
            isOpen={confirmRequestVerification}
            onDidDismiss={() => setConfirmRequestVerification(false)}
            header={"Are you sure you want to request verification from " + (rejectedVerifications.length > 1 ? ("all " + rejectedVerifications.length + " agencies") : "1 agency") + "?"}
            buttons={[
                {
                    text: 'Yes, I fixed my Profile',
                    handler: async () => {
                      setLoadingMessage("Requesting Re-verification");
                      let anyError = false;
                      for (let i = 0; i < rejectedVerifications.length; i++) {
                        const id = rejectedVerifications[i];
                        await ContactAPI.requestReVerification(id, id).catch(e => {
                          anyError = true;
                          (window as any).toast("Failed to request verification", "error");
                        })
                      }
                      if (!anyError) {
                        (window as any).toast("Verification requested", "success");
                      }
                      setLoadingMessage(null);
                      Utilities.getContacts();
                      setRejectedVerifications([]);
                    }
                },
                {
                    text: 'Re-request Later',
                    handler: () => {
                      setConfirmRequestVerification(false);
                    }
                }
            ]}
          />

          <IonAlert
            isOpen={mobileAppPrompt}
            onDidDismiss={() => setMobileAppPrompt(false)}
            header={"Download the Updatedge mobile app by selecting your operating system"}
            buttons={[
                {
                    text: 'iOS (iPhone, iPod, iPad)',
                    handler: () => {
                      window.open("https://apps.apple.com/gb/app/id1559217533")
                    }
                },
                {
                  text: 'Android',
                  handler: () => {
                    window.open("https://play.google.com/store/apps/details?id=com.updatedge")
                  }
                },
                {
                  text: 'Close',
                  handler: () => {}
              }
            ]}
          />

        </IonList>
        {/* { (progressGuideOpen !== null || userGuideOpenSection !== null || progressGuideSection !== null) && */}
          <ProfileProgress
            noRemindLaterSet={true}
            explicitOpen={progressGuideOpen}
            section={progressGuideSection}
            autoSelectSection={userGuideOpenSection}
            onImageUpdate={() => {
              setShowUserProfilePhoto(false);
              setTimeout(() => {
                setShowUserProfilePhoto(true);
              }, 200)
            }}
            onClosed={() => {
              reload();
              setProgressGuideOpen(false);
              setProgressGuideSection(null);
              setUserGuideOpenSection(null);
              setPictureVersion(pictureVersion + 1);
              (window as any).pictureVersions = pictureVersion + 1;
            }}
          />
        {/* } */}
        <IonActionSheet
          isOpen={adminPanel}
          onDidDismiss={() => { setAdminPanel(false) }}
          header='Internal Settings'
          buttons={[
              {
                  text: (localStorage.getItem("debugAlerts")) ? "Hide debug messages" : "Show debug messages",
                  handler: () => {
                      if (localStorage.getItem("debugAlerts")) {
                          localStorage.removeItem("debugAlerts");
                      } else {
                          localStorage.setItem("debugAlerts", "true");
                      }
                      window.location.href = "/";
                  }
              },
              {
                text: "Print Storage",
                handler: async () => {
                  printStorage();
                }
              },
              {
                text: "Simulate colliding auto-inserted work event",
                handler: async () => {
                  const date = Utilities.formatDate(new Date(), "YYYY-MM-DD");
                  const userEnteredDate = window.prompt("Enter date to simulate", date);
                  if (userEnteredDate && userEnteredDate.length === 10) {
                    await saveEvent({
                      "deleted": false,
                      "updatedAt": (new Date()).toISOString(),
                      "createdAt": "",
                      "version": "",
                      "id": Utilities.uuidv4(),
                      "orderingUpdatedDate": "",
                      "groupId": "",
                      "pinned": false,
                      "notes": "",
                      "googlePlacesId": "",
                      "repeatType": 0,
                      "isSynced": false,
                      "repeatForever": false,
                      "repeatUntil": "",
                      "end": userEnteredDate + "T17:00:00.000Z",
                      "start": userEnteredDate + "T08:00:00.000Z",
                      "eventType": 1,
                      "title": "Auto-inserted confirmed event [SIMULATED]",
                      "userId": "",
                      "hasCollisions": true
                    })
                    window.location.href = "/calendar";
                  } else {
                    (window as any).toast("Invalid Date");
                  }
                }
              },
              {
                text: "Erase local event database",
                handler: async () => {
                  const res = await (window as any).db.events.clear();
                  (window as any).toast("Removed events, refreshing...", "success");
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 4000)
                }
              }
          ]}
        />
        <IonLoading isOpen={loadingMessage !== null} message={loadingMessage} />
      </IonContent>
    </IonPage>
  );
};

export default Settings;
