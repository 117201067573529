import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api';
import GoogleMapsAPI from '../apis/GoogleMaps';


interface Props {
  center: {
    lat: number,
    lng: number
  },
  radius: number,
  onPostcodeChange?: (postcode: string) => void
  onCoordinatesChange?: (lat: number, lng: number) => void
}

let disposableMapElements = [];
let justRendered = false;
let enteredWhileRendering: any = false;
let postcodeGetTimeout: any = null;
let coordinatesUpdateTimeout: any = null;

const DynamicMap: React.FC<Props> = ({ center, radius, onPostcodeChange, onCoordinatesChange }) => {
  const [map, setMap] = React.useState(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8"
  })
  
  const centerMap = async (map: any, center: { lat: number, lng: number }, radius: number) => {

    if (!map) {
      return;
    }

    if (justRendered) {
      console.log("prevent render with " + center + " and " + radius + " because just rendered")
      enteredWhileRendering = radius;
      return;
    }

    console.log("rendering with center: ", center, " and radius: ", radius)

    justRendered = true;
    setTimeout(() => {
      justRendered = false;
      if (enteredWhileRendering) {
        console.log("rendering cooldown center: ", center, " and radius: ", radius + " || " + enteredWhileRendering)
        centerMap(map, center, enteredWhileRendering || radius)
        enteredWhileRendering = null;
      }
    }, 100);
      
    if (disposableMapElements.length) {
      disposableMapElements.forEach(circle => {
        circle.setMap(null)
      })
      disposableMapElements = []
    }

    let newCircleRadius = new window.google.maps.Circle({
        strokeColor: "#444",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#666",
        fillOpacity: 0.4,
        map,
        center: center,
        radius: radius,
    });

    let newCenterMarker = new window.google.maps.Marker({
      position: center,
      map: map,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 6,
        fillColor: "#666",
        fillOpacity: 1,
        strokeWeight: 0
      }
    });
    
    newCircleRadius.setMap(map);
    newCenterMarker.setMap(map);
    if ((window as any).allowFitBounds) {
      map.fitBounds(newCircleRadius.getBounds());
      console.log("Fitting bounds because of allowFitBounds")
    } else {
      console.log("Not fitting bounds because of allowFitBounds")
    }
    disposableMapElements.push(newCircleRadius)
    disposableMapElements.push(newCenterMarker)
  }

  useEffect(() => {
    if (center && radius !== null) {
      centerMap(map, center, radius)
    }
  }, [isLoaded, center, radius, map])

  const onMapLoad = React.useCallback(function callback(map) {
    // set styles
    map.setOptions({
        styles: [
            {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "administrative.land_parcel",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "poi",
              "elementType": "labels.text",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "road.local",
              "elementType": "labels",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            },
            {
              "featureType": "transit",
              "stylers": [
                {
                  "visibility": "off"
                }
              ]
            }
          ],
          disableDefaultUI: true,
          zoomControl: false,
          // gestureHandling: 'none'
    })
    setMap(map)
  }, [])

  const onMapUnmount = React.useCallback(function callback(map) {
      setMap(null)
  }, [])

  const postcodeGetWithTimeout = async (lat: number, lng: number) => {
    if (postcodeGetTimeout) {
      clearTimeout(postcodeGetTimeout)
    }
    postcodeGetTimeout = setTimeout(async () => {
      const postcode = await GoogleMapsAPI.getPostcodeByCoordinates(lat, lng).catch(e => { console.error(e) })
      if (onPostcodeChange) {
        if (postcode) {
          onPostcodeChange(postcode || "")
        } else {
          onPostcodeChange("")
        }
      }
    }, 500)
  }

  const updateCoordinates = async (lat: number, lng: number) => {
    if (coordinatesUpdateTimeout) {
      clearTimeout(coordinatesUpdateTimeout)
    }
    coordinatesUpdateTimeout = setTimeout(async () => {
      if (onCoordinatesChange) {
        onCoordinatesChange(lat, lng)
      }
    }, 100)
  }

  return (
      <div>
        { (isLoaded) &&
          <GoogleMap
              mapContainerStyle={{
                  width: window.innerWidth + "px",
                  height: (window.innerHeight - 132) + "px"
              }}
              center={center}
              zoom={5}
              onLoad={onMapLoad}
              onUnmount={onMapUnmount}
              onDragEnd={() => {
                if (map) {
                  const newCenter = map.getCenter();
                  const oldLat4Precision = Math.round(center.lat * 10000) / 10000;
                  const oldLng4Precision = Math.round(center.lng * 10000) / 10000;
                  const newLat4Precision = Math.round(newCenter.lat() * 10000) / 10000;
                  const newLng4Precision = Math.round(newCenter.lng() * 10000) / 10000;
                  if (updateCoordinates && (oldLat4Precision !== newLat4Precision || oldLng4Precision !== newLng4Precision)) {
                    console.log(center.lat + " vs " + newCenter.lat() + " || " + center.lng + " vs " + newCenter.lng())
                    updateCoordinates(newCenter.lat(), newCenter.lng())
                  }
                }
              }}
          >
          </GoogleMap>
        }
    </div>
  );
};

export default DynamicMap;
