import axios, { AxiosRequestConfig } from "axios";
import Configuration from '../Configuration';
import Utilities from "../Utilities";
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

class InternalAPI {

    /**
     * Returns deatails about the logged in user
     * @returns user details
     */
    static async request(
        method: string, 
        url: string, 
        body?: object, 
        file?: Object, 
        fullUrl?: boolean, 
        newApi?: boolean, 
        allowForTest?: boolean, 
        unauthenticated?: boolean, 
        newestFunctionsApi?: boolean, 
        detectNoInternetConnection?: boolean,
        newestApi?: boolean,
        cdnApi?: boolean
    ): Promise<any> {

        const defaultHeaders: object = {
            'Authorization': 'Bearer ' + localStorage.getItem("access_token")
        }

        if (!unauthenticated && (!localStorage.getItem("access_token") || localStorage.getItem("access_token") === "undefined")) {
            Utilities.logOut();
            return;
        }

        if (/*detectNoInternetConnection && */ !(window as any).hasInternetConnection) {
            setTimeout(() => {
                (window as any).toast("No internet connection", "error")
            }, 100)
            return new Promise((resolve, reject) => {
                reject({
                    noInternetConnection: true
                })
            })
        }

        if (window.location.href.indexOf("testing=true") === -1 ) { } else {
            if (!allowForTest) {
                return;
            }
        }

        return new Promise(function(resolve, reject) {

            let headers = defaultHeaders;
            headers['X-UE-Client-UTCOffset'] = new Date().getTimezoneOffset()

            if (unauthenticated) {
                // @ts-ignore
                delete headers.Authorization;
            }

            if (file) {
                headers['Content-Type'] = "application/x-www-form-urlencoded"
            } else {
                headers['Content-Type'] = "application/json"
            }

            let requestUrl = CONFIG['API_EXTERNAL_URI_MOBILE'];
            let apiVersion = "ZUMO-API-VERSION=2.0.0"

            if (newApi) {
                requestUrl = CONFIG['API_EXTERNAL_URI'];
                apiVersion = "api-version=1.0"
            } else if (newestFunctionsApi) {
                requestUrl = CONFIG['API_FUNCTIONS_URI'];
                apiVersion = "code=ue"
            } else if (newestApi) {
                requestUrl = CONFIG['API_NEW_NODE'];
            } else if (cdnApi) {
                requestUrl = CONFIG['CDN_IMAGE_URI'];
            }

            console.log("requesting " + requestUrl + " " + apiVersion)
            
            let requestOpts = {
                url: (fullUrl) ? url : requestUrl + url + (!cdnApi ? ("&" + apiVersion) : ""),
                method: method,
                headers: headers,
            }

            if (body) {
                // @ts-ignore
                requestOpts.data = body
            } else if (file) {
                // @ts-ignore
                requestOpts.data = file
            }


            axios(requestOpts as AxiosRequestConfig)
            .then(res => { resolve(res.data); })
            .catch(e => { 
                // if (localStorage.getItem("debugMessages")) {
                //     if (e && e.response) {
                //         alert(JSON.stringify(e.response));
                //     } else {
                //         alert("No valid error to dispaly " + e)
                //     }
                // }
                // alert("Error " + (e.response ? e.response.status : e))
                if (e && e.response && (e.response.status === 401) ) {
                    // Utilities.logOut();
                    Utilities.attemptRefresh();
                } else {
                    reject(e);
                }
            reject(e) })
        })
    }

}

export default InternalAPI;
