import { IonIcon } from '@ionic/react';
import { close, document } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BriefcaseIcon from '../../icons/Briefcase';
import ContactsIcon from '../../icons/Contacts';
import LongArrow from '../../icons/LongArrow';
import LongArrowUp from '../../icons/LongArrowUp';
import { hasUndeletedEvents } from '../../stores/Event';
import UserAPI from '../../apis/User';
// import '../../styles/Components/OnboardingGuide.scss';

interface CalendarProps {
    // onSelect: (string) => void;
    section: string;
    haveSelectedDays?: boolean; // Whether there are any selected days
    hide?: boolean;
    haveMessages?: boolean, // Whether the user has messages
    modal?: boolean;
}

const OnboardingGuide: React.FC<CalendarProps> = ({ section, haveSelectedDays, hide, haveMessages, modal }) => {

    const [mode, setMode] = useState <string> ("hidden"); // View mode
    const [nextStep, setNextStep] = useState <number> (0); // Next pending step

    const [haveEvents, setHaveEvents] = useState <boolean> (null);
    const [haveContacts, setHaveContacts] = useState <boolean> (null);

    const reload = async function() {

        let haveEvent = await hasUndeletedEvents()
        let haveContact = (localStorage.getItem("haveContact") === "true")
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

        setHaveContacts(haveContact);
        setHaveEvents(haveEvent);

        if (true) {

            if (mode === "hidden" || mode === "prompt") {

                if (section === "calendar" || section === "chats") {

                    if ( (haveEvent && !haveContact && section === "calendar") || (!haveContact && section === "chats") ) {
                        setNextStep(2);
                        setMode("prompt");
                        return;
                    }

                    if (!haveEvent && section === "calendar") {
                        setNextStep(1);
                        setMode("prompt");
                        return;
                    }

                    if (
                        haveEvent && 
                        haveContact && 
                        section === "calendar" && 
                        !localStorage.getItem("dismissedHelpGuideGuide") &&
                        !modal &&
                        user.settings && !user.settings.find(setting => setting.settingId === 39)
                    ) {
                        setNextStep(5);
                        setMode("prompt");
                        return;
                    }

                }
                
                if (section === "contacts") {

                    if (!haveContact) {
                        setNextStep(3);
                        setMode("prompt")
                        return;
                    }

                    if (!localStorage.getItem("contactsRatingScreenPassed") && (!user || (user.settings && !user.settings.find(setting => setting.settingId === 29)) )) {
                        setNextStep(20);
                        setMode("prompt")
                        return;
                    }

                }

                if (section === "chats") {

                    if (!haveMessages) {
                        setNextStep(4);
                        setMode("prompt")
                        return;
                    }

                }

                if (section === "files" && !localStorage.getItem("filesOnboardingScreen2Passed") && (!user || (user.settings && !user.settings.find(setting => setting.settingId === 28)))) {
                    setNextStep(
                        localStorage.getItem("filesOnboardingScreen1Passed") ?
                        11 :
                            10
                    );
                    setMode("prompt")
                    return;
                }

            }

        }

        setNextStep(0);
        setMode("hidden");

    }

    // @ts-ignore
    const lastUpdated = useSelector(state => state.onboardingGuide);

    useEffect(() => { reload(); }, [])
    useEffect(() => { reload(); }, [lastUpdated, haveMessages])

    if (hide) {
        return null;
    }

    let top: any = 'auto';
    let bottom: any = 0;
    let left: any = 0;
    let right: any = 'auto';
    let arrowStyles: any = null;
    let icon = null;

    if (nextStep === 1 || nextStep === 2) {

        icon = <BriefcaseIcon />

        if (haveSelectedDays) {
            bottom = modal ? 260 : 280;
            top = 'auto';
        } else {
            top = modal ? 402 : 352;
            bottom = 'auto'
        }

        if (nextStep === 2) {
            arrowStyles = {
                left: '25%'
            }
            bottom = 230;
            top = 'auto';
            icon = <ContactsIcon />
        }

    } else if (nextStep === 3) {
        bottom = 310;
        top = 'auto';
        icon = <ContactsIcon />
        arrowStyles={
            left: 'calc(50% - 50px)'
        }
    } else if (nextStep === 4) {
        bottom = 310;
        top = 'auto';
        icon = <ContactsIcon />
    } else if (nextStep === 5) {
        bottom = 310;
        top = 'auto';
        icon = <ContactsIcon /> // TODO UPDATE
        arrowStyles = {
            left: 15
        }
    } else if (nextStep === 10 || nextStep === 11 || nextStep === 12) {
        bottom = nextStep === 11 ? 'auto' : nextStep === 10 ? 372 : 260;
        top = nextStep === 11 ? 120 : 'auto'
        icon = <IonIcon style={{ color: 'white', fontSize: 32 }} icon={document} />
    } else if (nextStep === 20) {
        top = 230;
        bottom = 'auto';
        icon = <ContactsIcon />
    }

    if (mode === "prompt") {
        return (
            <React.Fragment>
                { (nextStep === 10 || nextStep === 11 || nextStep === 12) &&
                    <div 
                        className="onboarding-guide-prompt-backdrop"
                        onClick={() => {

                        }}
                    >
                    </div>
                }
                <div className="onboarding-guide-prompt" style={{ bottom: bottom, top: top, left: left, right: right }} data-step={nextStep} data-alt={nextStep === 1 && haveSelectedDays}>
                    { (nextStep < 10 || nextStep === 20) &&
                        <IonIcon 
                            icon={close}
                            onClick={() => {
                                setMode("hidden");
                                if (nextStep === 20) {
                                    localStorage.setItem("contactsRatingScreenPassed", "true")
                                    UserAPI.updateSetting("29", "true");
                                } else {
                                    if (nextStep === 2) {
                                        setTimeout(() => {
                                            setMode("prompt")
                                        }, 10000)
                                    } else if (nextStep === 5) {
                                        localStorage.setItem("dismissedHelpGuideGuide", "true")
                                        UserAPI.updateSetting("39", "true");
                                    }   
                                }
                            }}
                        />
                    }
                    { (nextStep === 1 || nextStep === 2) &&
                        <section data-section="events">
                            <div className="details">
                                <div className="header"> <div className="image-wrapper"> { (icon) && icon } </div> </div>
                                <h2>{ (nextStep === 1) ? (haveSelectedDays ? "Tap the green button below, and fill out the event details" : "Tap today and or other day/s to add events.") : "Add contacts" }</h2>
                                { (nextStep === 2 ) && <p>Go to the contacts page to add new contacts</p> }
                            </div>
                            <div className="arrow-wrapper" data-rotated={haveSelectedDays !== true && nextStep !== 2} style={arrowStyles || {}} >
                                { (haveSelectedDays || nextStep === 2) ? <LongArrow /> : <LongArrowUp /> }
                            </div>  
                        </section>
                    }
                    { (nextStep === 3 || nextStep === 20) &&
                        <section data-section="contacts">
                            <div className="details">
                                <div className="header"> <div className="image-wrapper"> { (icon) && icon } </div> </div>
                                <h2>{ nextStep === 3 ? "Add Contacts" : "Rate your Contacts" }</h2>
                                <p>{ nextStep === 3 ? "Add agencies, hirers and contacts you know to share your availability, for offers & to chat with." : "Help others users by sharing your review and rating for any of your contacts or organisations by tapping on them." }</p>
                            </div>
                            <div className="arrow-wrapper" data-rotated={nextStep === 20} style={arrowStyles || {}} >
                                { nextStep === 3 ? <LongArrow /> : <LongArrowUp /> }
                            </div>  
                        </section>
                    }
                    { (nextStep === 4) &&
                        <section data-section="chats">
                            <div className="details">
                                <div className="header"> <div className="image-wrapper"> { (icon) && icon } </div> </div>
                                <h2>Start messaging</h2>
                                <p>Send your first message to discuss the details of a job</p>
                            </div>
                            <div className="arrow-wrapper" data-rotated={false} style={arrowStyles || {}} >
                                <LongArrow />
                            </div>  
                        </section>
                    }
                    { (nextStep === 5) &&
                        <section data-section="chats">
                            <div className="details">
                                <div className="header"> <div className="image-wrapper"> { (icon) && icon } </div> </div>
                                <h2>Progress & Help</h2>
                                <p>Tap here any time you want to see your profile progress and metrics, and to get help</p>
                            </div>
                            <div className="arrow-wrapper" data-rotated={false} style={arrowStyles || {}} >
                                <LongArrow />
                            </div>  
                        </section>
                    }
                    { (nextStep === 10 || nextStep === 11 || nextStep === 12) &&
                        <section data-section="files">
                            <div className="details">
                                <div className="header"> <div className="image-wrapper"> { (icon) && icon } </div> </div>
                                <h2>
                                    { 
                                        (nextStep === 10) ? 
                                        "Showcase your expertise" :
                                            (nextStep === 11) ? 
                                            "Filter & Search" :
                                                (nextStep === 12) ?
                                                "" : 
                                                    ""
                                    }
                                </h2>
                                <p>
                                    { 
                                        (nextStep === 10) ? 
                                        "Share your CV, and best work with contacts" :
                                            (nextStep === 11) ? 
                                            "See files contacts share with you" : 
                                                (nextStep === 12) ?
                                                "" :
                                                ""
                                    }
                                </p>
                                <div className='options'>
                                    { nextStep !== 10 &&
                                        <button className='main' onClick={() => {
                                            if (nextStep === 11) {
                                                localStorage.removeItem("filesOnboardingScreen1Passed");
                                            } /* else  if (nextStep === 12) {
                                                localStorage.removeItem("filesOnboardingScreen2Passed");
                                            }*/
                                            reload();
                                        }}>
                                            Previous
                                        </button>
                                    } 
                                    <button className='main' onClick={() => {
                                        if (nextStep === 10) {
                                            localStorage.setItem("filesOnboardingScreen1Passed", "true");
                                        } else if (nextStep === 11) {
                                            localStorage.setItem("filesOnboardingScreen2Passed", "true")
                                            UserAPI.updateSetting("28", "true");
                                        } /*else if (nextStep === 12) {
                                            localStorage.setItem("filesOnboardingScreen3Passed", "true")
                                            UserAPI.updateSetting("28", "true");
                                        } */
                                        reload();
                                    }}>
                                        { nextStep === 12 ? "Close" : "Next" }
                                    </button>
                                </div>
                            </div>
                            { (nextStep === 11 || nextStep === 10) &&
                                <div className="arrow-wrapper" data-rotated={nextStep === 11} style={arrowStyles || {}} >
                                    { (nextStep === 11) ? <LongArrowUp /> : <LongArrow /> }
                                </div>  
                            }
                        </section>
                    }
                </div>
            </React.Fragment>
        )
    }

    if (mode === "fullscreen") {
        return (
            <div className="oboarding-guide">
                Onbaording Guide
            </div>
        );
    }

    return null;
    
};

export default OnboardingGuide;
