import { IonIcon, IonLoading } from '@ionic/react';
import { addCircle, alertCircle, alertCircleOutline, arrowForward, checkbox, checkmarkCircle, checkmarkCircleOutline, closeCircle, closeCircleOutline, ellipsisHorizontal, shield, shieldCheckmark, squareOutline, star, warning } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import NotificationAPI from '../../../apis/Notification';
import Configuration from '../../../Configuration';
import BusinessImg from '../../../images/business.png';
import PersonImg from '../../../images/person.png';
import { Contact } from '../../../types/Contact';
import ShieldVerified from '../../../icons/shield-verified.svg';
import ShieldUnverified from '../../../icons/shield-unverified.svg';
import { RatingRecord, getDummyRatingRating } from '../../../types/Offer';
import RatingPreview from '../Rating/RatingPreview';
import Drawer from '@material-ui/core/Drawer';
import RatingAPI from '../../../apis/Rating';
import RatingPreviewExtended from '../Rating/RatingPreviewExtended';
import UserAPI from '../../../apis/User';
import Avatar from "boring-avatars";
import ContactAPI from '../../../apis/ContactAPI';
import SwipeToDelete from 'react-swipe-to-delete-ios'

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface ContactListItemProps {
    highlight?: boolean;
    referredContactName?: string;
    referredContactUserId?: string;
    referredContactEmailDomain?: string;
    referredToContactName?: string;
    referredToContactUserId?: string;
    referredToContactEmailDomain?: string;
    subtext?: string;
}

const ContactListItem: React.FC<ContactListItemProps> = ({ 
    highlight,
    referredContactName,
    referredContactUserId,
    referredContactEmailDomain,
    referredToContactName,
    referredToContactUserId,
    referredToContactEmailDomain,
    subtext
}) => {

    const [referredContactOrgImageFailed, setReferredContactOrgImageFailed]= useState <boolean> (false);
    const [referredToContactOrgImageFailed, setReferredToContactOrgImageFailed]= useState <boolean> (false);

    let referredContactPrimaryImgUrl = referredContactUserId ? UserAPI.getProfilePicture(referredContactUserId) : ("https://source.boringavatars.com/beam/80/" + referredContactName + "?colors=264653,2a9d8f,e9c46a,f4a261,e76f51");
    let referredContactSecondaryImgUrl = referredContactEmailDomain ? UserAPI.getExtOrgPicture(referredContactEmailDomain) : null;
    let referredToContactPrimaryImgUrl = referredToContactUserId ? UserAPI.getProfilePicture(referredToContactUserId) : ("https://source.boringavatars.com/beam/80/" + referredToContactName + "?colors=264653,2a9d8f,e9c46a,f4a261,e76f51");
    let referredToContactSecondaryImgUrl = referredToContactEmailDomain ? UserAPI.getExtOrgPicture(referredToContactEmailDomain) : null;

    const CONTACT_ITEM_DOM = <div 
        className="contact-item referral-item" 
        onClick={() => {
          
        }}
    >
        <div className="details">
            <div className='avatars'>
                <div className="primary-contact-image">
                    <img className='primary' src={referredContactPrimaryImgUrl} />
                    { (!referredContactOrgImageFailed) &&
                        <img 
                            className='secondary' 
                            src={referredContactSecondaryImgUrl}
                            onError={() => {
                                setReferredContactOrgImageFailed(true);
                            }}
                        />
                    }
                </div>
                <IonIcon icon={arrowForward} />
                <div className="secondary-contact-image">
                    <img className='primary' src={referredToContactPrimaryImgUrl} />
                    { (!referredToContactOrgImageFailed) &&
                        <img 
                            className='secondary' 
                            src={referredToContactSecondaryImgUrl}
                            onError={() => {
                                setReferredToContactOrgImageFailed(true);
                            }}
                        />
                    }
                </div>
            </div>
            <div className="contact-details">
                <h2>
                    {referredContactName} referred to {referredToContactName}
                </h2>
                { subtext && <p className="headline">{subtext}</p> }
{/* <div>referredContactName: {referredContactName} </div>
<div>referredContactUserId: {referredContactUserId} </div>
<div>referredContactEmailDomain: {referredContactEmailDomain} </div>
<div>referredToContactName: {referredToContactName} </div>
<div>referredToContactUserId: {referredToContactUserId} </div>
<div>referredToContactEmailDomain: {referredToContactEmailDomain} </div> */}
            </div>
        </div>
    </div>

    return (
        <div 
            data-highlight={highlight ? 'true' : 'false'}
            style={{
                position: 'relative'
            }}
        >
            {CONTACT_ITEM_DOM}
        </div>
    );
};

export default ContactListItem;
