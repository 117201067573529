import React, { useEffect, useRef, useState } from 'react';
import Utilities from '../../Utilities';
import { useHistory } from "react-router-dom";

let tickInterval;

interface OfferCountdownProps {
    deadline: string;
}

const OfferCountdown: React.FC<OfferCountdownProps> = ({ deadline }) => {
    const history = useHistory();
    const [countdown, setCountdown] = useState <{ days: number, hours: number, minutes: number, seconds: number }> (null); // Countdown for the offer

    const startCountdownInterval = async function() {
        clearInterval(tickInterval);
        countdownIntervalFunction();
        tickInterval = setInterval(countdownIntervalFunction, 1000)
    }

    const countdownIntervalFunction = async function() {
        let deadlineL = new Date(deadline);
        let now = new Date();
        if (deadlineL && deadlineL > now) {
            setCountdown(Utilities.secondsToDhms(Utilities.differenceBetweenDatesSeconds(deadlineL, now)))
        } else {
            clearInterval(tickInterval);
            setCountdown(null);
            if (Utilities.dateSub(now, "second", 3) < deadlineL) {
                window.location.href = "notifications";
            }
        }
    }

    useEffect(() => {
        clearInterval(tickInterval);
        setCountdown(null);
        startCountdownInterval();
    }, [])

    if (!countdown)
        return null;

    return (
        <div className="countdown">
            <h3>PLEASE RESPOND NOW!</h3>
            <p>Improve
                <span onClick={() => {
                    history.push("/insight")
                }} >
                    your average response rate
                </span>
            </p>
            <p>This offer will auto-decline in:</p>
            <ul>
                <li><span id="days">{countdown.days}</span>days</li>
                <li><span id="hours">{countdown.hours}</span>Hours</li>
                <li><span id="minutes">{countdown.minutes}</span>Minutes</li>
                <li><span id="seconds">{countdown.seconds}</span>Seconds</li>
            </ul>
        </div>
    )
}

export default OfferCountdown