import { IonBackButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonList, IonLoading, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import { addCircle, create, trash, caretDownCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import UserAPI from '../apis/User';
import Configuration from '../Configuration';
// import '../styles/Components/BottomFabOptions.scss';
// import '../styles/Components/Settings/TimePresets.scss';
import { TimePreset } from '../types/TimePreset';
import { User, UserDTO } from '../types/User';
import Utilities from '../Utilities';
import InternalTracker from '../InternalTracker';
import { Plugins } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  
}

const DEFAULT_PRESETS = [
  {
      name: "All Day",
      startHour: 8,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
  },
  {
      name: "AM",
      startHour: 8,
      startMinute: 0,
      endHour: 12,
      endMinute: 0,
  },
  {
      name: "PM",
      startHour: 13,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
  },
  {
      name: "Overnight",
      startHour: 22,
      startMinute: 0,
      endHour: 6,
      endMinute: 0,
  }
]

const TimePresetSettings: React.FC<Props> = ({ }) => {
  
  const [loading, setLoading] = useState <boolean> (true) // Full screen loading
  const [timePresets, setTimePresets] = useState <TimePreset[]> (null) // User settings
  const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
  const [errorToast, setErrorToast] = useState <string> (null) // Error toast message
  const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false) // Whether the keyboard is showing

  const contentRef = useRef(null);

  useEffect(() => {
    if ((window as any).os !== "web") {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setKeyboardOpen(true)
      });
      Keyboard.addListener('keyboardWillHide', () => {
          setKeyboardOpen(false)
      });
    }
    reload();
  }, [])

  const reload = function() {

    UserAPI.getNew().then(data => {
      
      // @ts-ignore
      let timePresetsCompiled: TimePreset[] = (data.results && data.results.timePresets) ? JSON.parse(data.results.timePresets) : DEFAULT_PRESETS;

      for (let i = 0; i < timePresetsCompiled.length; i++) {
        timePresetsCompiled[i].start = new Date();
        timePresetsCompiled[i].start.setHours(timePresetsCompiled[i].startHour);
        timePresetsCompiled[i].start.setMinutes(timePresetsCompiled[i].startMinute);
        timePresetsCompiled[i].end = new Date();
        timePresetsCompiled[i].end.setHours(timePresetsCompiled[i].endHour);
        timePresetsCompiled[i].end.setMinutes(timePresetsCompiled[i].endMinute);
      }
  
      // setUser(data);
      setTimePresets(timePresetsCompiled);
      setLoading(false)

    }).catch(e => {
      console.error("Failed to get user", e);
      setLoading(false)
    })

  }

  const saveLocalSettings = function(newFields: UserDTO) {
    let user: string = localStorage.getItem("user");
    if (user) {
      let oldUser: User = JSON.parse(user);
      localStorage.setItem("user", JSON.stringify({...oldUser, ...newFields}))
    }
  }

  const updateSettings = function(timePresetsCompiled: TimePreset[]) {

    let userDTO: UserDTO = {
      timePresets: JSON.stringify(timePresetsCompiled.filter(item => (item.name && !(item.startHour === item.endHour && item.startMinute === item.endMinute)) ).map(item => { return {
        name: item.name,
        startHour: item.startHour,
        startMinute: item.startMinute,
        endHour: item.endHour,
        endMinute: item.endMinute,
        default: item.default
      }})),
    }

    InternalTracker.trackEvent("Time Preset Updated", {
      presets: timePresetsCompiled.map(item => item.name + " (" + item.startHour + ":" + item.startMinute + " - " + item.endHour + ":" + item.endMinute + ")").join(", ")
    })

    UserAPI.update(userDTO).then(res => {
      setSuccessToast("Time presets updated");
      saveLocalSettings(userDTO)
    }).catch(e => {
      console.error(e);
      setErrorToast("Failed to update time preset");
    })

  }

  return (

    <IonPage data-page="time-presets" ref={contentRef}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Time Presets</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="time-presets-wrapper" fullscreen>
        <IonList>
          <div className="time-presets">
            <p>You can set your usual working hours to easily create new events.</p>
            <div className="list">
              { timePresets && timePresets.map((preset, i) => {
                return (
                  <div className="time-preset">
                    <div className="name">
                      <IonInput
                        placeholder="Name your preset"
                        value={preset.name} 
                        onIonBlur={() => {
                          updateSettings(timePresets);
                        }}
                        onIonInput={(e) => { 
                          let timePresetsNew = JSON.parse(JSON.stringify(timePresets));
                          timePresetsNew[i].name = Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString());
                          setTimePresets(timePresetsNew)
                        }}
                      />
                      <IonIcon className="edit-btn" icon={create} />
                      <IonIcon className="delete-btn" icon={trash} onClick={() => {
                        let timePresetsNew = JSON.parse(JSON.stringify(timePresets));
                        timePresetsNew.splice(i, 1);
                        setTimePresets(timePresetsNew)
                        updateSettings(timePresetsNew);
                      }} />
                    </div>
                    <div className="duration">
                      <div className="start">
                          <TextField
                              type="time"
                              id="datetime-local-ended"
                              value={Utilities.formatDate(preset.start, "HH:MM")}
                              onChange={(date) => { 
                                let hours = date.target.value.substr(0, 2);
                                let minutes = date.target.value.substr(3, 2);
                                if (hours.length === 2 && minutes.length === 2) {
                                  let timePresetsNew = JSON.parse(JSON.stringify(timePresets));
                                  timePresetsNew[i].startHour = parseInt(hours);
                                  timePresetsNew[i].startMinute = parseInt(minutes);
                                  timePresetsNew[i].start = new Date();
                                  timePresetsNew[i].start.setHours(timePresetsNew[i].startHour)
                                  timePresetsNew[i].start.setMinutes(timePresetsNew[i].startMinute)
                                  setTimePresets(timePresetsNew)
                                  updateSettings(timePresetsNew);
                                }
                              }}
                          />
                      </div>
                      <label>to</label>
                      <div className="end">
                          <TextField
                              type="time"
                              id="datetime-local-ended"
                              value={Utilities.formatDate(new Date(preset.end), "HH:MM")}
                              onChange={(date) => { 
                                  let hours = date.target.value.substr(0, 2);
                                  let minutes = date.target.value.substr(3, 2);
                                  if (hours.length === 2 && minutes.length === 2) {
                                    let timePresetsNew = JSON.parse(JSON.stringify(timePresets));
                                    timePresetsNew[i].endHour = parseInt(hours);
                                    timePresetsNew[i].endMinute = parseInt(minutes);
                                    timePresetsNew[i].end = new Date();
                                    timePresetsNew[i].end.setHours(timePresetsNew[i].endHour)
                                    timePresetsNew[i].end.setMinutes(timePresetsNew[i].endMinute)
                                    setTimePresets(timePresetsNew)
                                    updateSettings(timePresetsNew);
                                  }
                              }}
                          />
                      </div>
                    </div>
                    { (preset.startHour === preset.endHour && preset.startMinute === preset.endMinute) &&
                      <p className="default error">The start and end time must be different</p>
                    }
                  </div>
                )
              }) }
            </div>
          </div>
        </IonList>
        { (keyboardOpen) &&
            <div className="keyboard-hide">
                <IonIcon icon={caretDownCircle} />
            </div>
        }
        { (!keyboardOpen) &&
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton id="add-new-preset" onClick={() => {
                let timePresetsNew = JSON.parse(JSON.stringify(timePresets));
                timePresetsNew.push({
                  name: "",
                  startHour: 9,
                  startMinute: 0,
                  endHour: 17,
                  endMinute: 0,
                  end: new Date(),
                  start: new Date(),
                })
                timePresetsNew[timePresetsNew.length-1].start.setHours(timePresetsNew[timePresetsNew.length-1].startHour)
                timePresetsNew[timePresetsNew.length-1].start.setMinutes(timePresetsNew[timePresetsNew.length-1].startMinute)
                timePresetsNew[timePresetsNew.length-1].end.setHours(timePresetsNew[timePresetsNew.length-1].endHour)
                timePresetsNew[timePresetsNew.length-1].end.setMinutes(timePresetsNew[timePresetsNew.length-1].endMinute)
                setTimePresets(timePresetsNew)
              }}>
                <IonIcon icon={addCircle} />
              </IonFabButton>
          </IonFab>
        }
        <IonToast
          isOpen={successToast !== null}
          color="success"
          onDidDismiss={() => setSuccessToast(null)}
          message={successToast}
          position="top"
          duration={2500}
          buttons={[ { text: 'Hide', role: 'cancel' } ]}
        />
        <IonToast
            isOpen={errorToast !== null}
            color="danger"
            onDidDismiss={() => setErrorToast(null)}
            message={errorToast}
            position="top"
            duration={2500}
            buttons={[ { text: 'Hide', role: 'cancel' } ]}
        />
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Please wait...'}
          duration={12000}
        />
      </IonContent>
    </IonPage>
  );
};

export default TimePresetSettings;
