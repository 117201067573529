import { IonSearchbar, IonIcon, IonLoading, IonModal } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import ChatAPI from '../../../apis/Chat';
// import '../../../styles/Components/Contacts/ContactSearchModal.scss';
import { Contact } from '../../../types/Contact';
import { Channel } from '../../../types/twilio/channel';
import { Member } from '../../../types/twilio/member';
import { Message } from '../../../types/twilio/message';
import { UserDescriptor } from '../../../types/twilio/userdescriptor';
import ContactListItem from '../Contacts/ContactListItem';
import { useHistory } from "react-router-dom";
import { LoadedConversation } from '../../../pages/Chat';

interface ChannelData {
    id: string;
    contact: UserDescriptor;
    member?: Member;
    messages: Message[];
    channel: Channel;
}  

interface ContactSearchModalProps {
    onClose?: (string?) => void;
    presentingElement?: any;
    isOpen: boolean;
    threads?: LoadedConversation[]
}

const ContactSearchModal: React.FC<ContactSearchModalProps> = ({ onClose, presentingElement, isOpen, threads }) => {

    const [drawerTitle, setDrawerTitle] = useState <string> ("Select Contact");
    const [contacts, setContacts] = useState <Contact[]> (null) // List of contacts
    const [loading, setLoading] = useState <boolean> (true) // Full screen loading
    const [searchText, setSearchText] = useState <string> ("") // Search keywords
    const [currentInDirectContacts, setCurrentInDirectContacts] = useState <string[]> ([]) // Contacts that are in the same org you share with at least with
    const history = useHistory();

    useEffect( () => {
        if (isOpen) {
            reloadContacts();
            setSearchText("")
        }
    }, [isOpen])

    useEffect(() => {
        let parts = window.location.href.split("messages/")
        if (contacts && parts[1] && parts[1].indexOf("auto") !== -1) {
            onClose(contacts[0].userId)
        }
    }, [contacts])

    const reloadContacts = async function() {

        setLoading(true);
  
        ChatAPI.getContactable().then(data => {
            setLoading(false)
            // @ts-ignore
            data = data.filter((v,i,a)=>a.findIndex(t=>(t.userId === v.userId))===i)

            if (localStorage.getItem("inDirectContacts")) {
                setCurrentInDirectContacts(JSON.parse(localStorage.getItem("inDirectContacts")));
            }

            setContacts(data as Contact[]);
        }).catch(e => {
            console.log(e);
            (window as any).toast("Failed to load contacts", "error")
            setLoading(false)
        })
  
    }

    let MODAL_CONTENT_DOM = null;

    if (isOpen) {
        MODAL_CONTENT_DOM = 
        <div id="contact-selector-drawer-content" style={{ height: '100%', overflow: 'auto' }} className="swipeable-drawer-body" data-drawer="contact-selector">
            <div className="content">
                <h1>{drawerTitle}</h1>
                <IonIcon className="close-btn" onClick={() => { onClose() }} icon={close} />
                <div className="details">
                    <IonSearchbar 
                        placeholder="Search by Name" 
                        debounce={600} 
                        onKeyUp={e => {
                            setSearchText((e.target as HTMLIonSearchbarElement).value.toLowerCase())
                        }}
                    ></IonSearchbar>
                   { (contacts) && contacts.map(item => {
                       // @ts-ignore
                       if (item.fullName.toLocaleLowerCase().indexOf(searchText) === -1)
                            return null;

                       if (item.nonDirect) { // don't allow the worker to initiate thread wtih non-direct contacts
                            return null;
                       }

                       return (
                            <ContactListItem 
                                everFirst={false}
                                list="AGENCY" 
                                inviteId={item.id}
                                onEdit={() => { onClose(item.userId) }}
                                // @ts-ignore
                                contact={ { name: item.fullName, email: "Click to message", userId: item.userId, verified: item.verified } as Contact} 
                            /> 
                       )
                   }) }
                </div>
            </div>
            <IonLoading
                isOpen={loading}
                onDidDismiss={() => setLoading(false)}
                message={'Please wait...'}
                duration={12000}
            />
        </div>
    }

    return (
        <IonModal
            isOpen={isOpen}
            canDismiss={!isOpen}
            presentingElement={(presentingElement && presentingElement.current) ? presentingElement.current : null}
            onDidDismiss={() => { onClose() }}
            data-modal="entry"
            mode="ios"
        >
        { MODAL_CONTENT_DOM }
    </IonModal>
    );
    
};

export default ContactSearchModal;
