import { IonIcon, IonLoading } from '@ionic/react';
import { addCircle, alertCircle, alertCircleOutline, checkbox, checkmarkCircle, checkmarkCircleOutline, closeCircle, closeCircleOutline, ellipsisHorizontal, shield, shieldCheckmark, squareOutline, star, warning } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import NotificationAPI from '../../../apis/Notification';
import Configuration from '../../../Configuration';
import BusinessImg from '../../../images/business.png';
import PersonImg from '../../../images/person.png';
import { Contact } from '../../../types/Contact';
import ShieldVerified from '../../../icons/shield-verified.svg';
import ShieldUnverified from '../../../icons/shield-unverified.svg';
import AgencyStandaloneImg from '../../../icons/agency-standalone-min.png';
import ShareStandaloneImg from '../../../icons/share-standalone-min.png';
import { RatingRecord, getDummyRatingRating } from '../../../types/Offer';
import RatingPreview from '../Rating/RatingPreview';
import Drawer from '@material-ui/core/Drawer';
import RatingAPI from '../../../apis/Rating';
import RatingPreviewExtended from '../Rating/RatingPreviewExtended';
import UserAPI from '../../../apis/User';
import ContactAPI from '../../../apis/ContactAPI';
import SwipeToDelete from 'react-swipe-to-delete-ios'
import PublicEmailProviders from '../../../PublicEmailProviders.json';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface ContactListItemProps {
    contact: Contact;
    list: string;
    first?: boolean;
    everFirst?: boolean; // Whether we indent at all if needed
    isHirer?: boolean;
    isAgency?: boolean;
    inviteId?: string;
    mode?: string;
    status?: number;
    emailDeliveryStatusTypeId?: number;
    notificationStatusTypeId?: number;
    inviteIgnored?: boolean;
    hideOptions?: boolean;
    highlight?: boolean;
    highlightBlock?: boolean;
    highlightOnline?: boolean;
    selected?: boolean;
    selecting?: boolean;
    onlyOrgHeader?: boolean;
    orgHeadline?: string;
    orgImageOverride?: string;
    onEdit?: (string) => void;
    rate?: () => void;
    onOrgClick?: (string?) => void;
    onAcceptComplete?: (success?: string, error?: string) => void;
    onDeleteOrg?: (id: string, name: string) => void;
    onRejectComplete?: (success?: string, error?: string) => void;
    companyVerified?: boolean;
    contactRating?: RatingRecord;
    contactAvgRating?: string;
    contactTotalRatings?: number;
    organisationRating?: RatingRecord;
    dontRenderContact?: boolean;
    dontRenderOrg?: boolean;
    organisationName?: string;
    organisationId?: string;
    onClickBlockMessage?: string;
    orgAvgRating?: string;
    orgTotalRatings?: number;
    hideRatings?: boolean;
    ignoreOrgClick?: boolean;
    showNoRatings?: boolean;
    parentType?: string;
    showJustInvitedOnInvite? : boolean;
    invitedJustNow?: boolean;
    boringAvatarFallback?: boolean;
    hideOrgImg?: boolean;
    tag? : string;
    imageOverride?: string;
    hideOrgOrgOptions?: boolean;
    swipeText?: string;
    onSwipe?: () => void;
    externalOrgLogo?: string;
    orgIsRepresentingAgency?: boolean;
    sharingAvailabilityWithContact?: boolean;
    notOwn?: boolean;
}

const ContactListItem: React.FC<ContactListItemProps> = ({ organisationId, sharingAvailabilityWithContact, invitedJustNow, orgIsRepresentingAgency, externalOrgLogo, parentType, onClickBlockMessage, highlightOnline, contactTotalRatings, companyVerified, orgImageOverride, status, onlyOrgHeader, orgHeadline, onOrgClick, contact, selected, selecting, list, first, onEdit, onRejectComplete, onAcceptComplete, inviteId, mode, isAgency, isHirer, hideOptions, everFirst, highlight, contactRating, organisationRating, dontRenderContact, dontRenderOrg, contactAvgRating, organisationName, orgAvgRating, orgTotalRatings, rate, onDeleteOrg, highlightBlock, hideRatings, ignoreOrgClick, showNoRatings, showJustInvitedOnInvite, boringAvatarFallback, hideOrgImg, tag, imageOverride, hideOrgOrgOptions, swipeText, onSwipe }) => {

    const [useFallbackImage, setUseFallbackImage] = useState <boolean> (false); 
    const [showRatings, setShowRatings] = useState <boolean> (false);
    const [ratingsLoading, setRatingsLoading] = useState <boolean> (false);
    const [ratings, setRatings] = useState <RatingRecord[]> ([]);
    const [loadingMessage, setLoadingMessage] = useState <string> (null);
    let boringAvatar = false

    useEffect(() => {
        if (showRatings) {
            setRatingsLoading(true);
            getContactRatings();
        }
    }, [showRatings])

    let profilePicUrl = "";
    let companyProfilePictureUrl = "";

    
    if (externalOrgLogo && PublicEmailProviders.indexOf(externalOrgLogo) === -1) { 
        profilePicUrl = UserAPI.getProfilePicture("undefined");
    } else if (contact.profilePictureUrl) {
        profilePicUrl = contact.profilePictureUrl;
    } else if (contact.userId) {
        profilePicUrl = UserAPI.getProfilePicture(contact.userId);
    } else if (boringAvatarFallback && contact.name) {
        boringAvatar = true;
    } else {
        profilePicUrl = BusinessImg;
    }

    if (externalOrgLogo && PublicEmailProviders.indexOf(externalOrgLogo) === -1) {
        companyProfilePictureUrl = UserAPI.getExtOrgPicture(externalOrgLogo);
    } else if (contact.companyId && contact.name !== "No contact at this agency") {
        companyProfilePictureUrl = UserAPI.getOrgPicture(contact.companyId)
    } else if (orgImageOverride) {
        companyProfilePictureUrl = orgImageOverride.startsWith("http") ? orgImageOverride : ("https://" + orgImageOverride);
    }

    const getContactRatings = async () => {
        setRatings([]);
        const ratings = await RatingAPI.getContactRatings(contact.contactId);
        if (ratings) {
            // @ts-ignore
            setRatings(ratings)
            setRatingsLoading(false);
        }
    }

    const accept = async function() {
        if (list === "INCOMING") {
            setLoadingMessage("Accepting Invite");
            NotificationAPI.acceptContactInvite(inviteId).then(async data => {
                if (organisationId && isAgency) { // auto adding agency due to new explicit agency marking
                    await ContactAPI.addAgencyById(organisationId);
                }
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete("Accepted invite, the hirer can now see your availability", null);
                }
            }).catch(e => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete(null, "Failed to accept invite");
                }
            }).finally(() => {
                setLoadingMessage(null);
            })
        } else {
            setLoadingMessage("Accepting Referral");
            ContactAPI.acceptReferral(inviteId).then(data => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete("Accepted referral, this user can now see your availability", null);
                }
            }).catch(e => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete(null, "Failed to accept referral");
                }
            }).finally(() => {
                setLoadingMessage(null);
            })
        }
    }

    const reject = async function() {
        if (list === "INCOMING") {
            setLoadingMessage("Rejecting Invite");
            NotificationAPI.rejectContactInvite(inviteId).then(data => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete("Invitation Rejected", null);
                }
            }).catch(e => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete(null, "Failed to reject invitation");
                }
            }).finally(() => {
                setLoadingMessage(null);
            })
        } else {
            setLoadingMessage("Rejecting Referral");
            ContactAPI.rejectReferral(inviteId).then(data => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete("Referral Rejected", null);
                }
            }).catch(e => {
                if (typeof onAcceptComplete !== undefined) {
                    onAcceptComplete(null, "Failed to reject referral");
                }
            }).finally(() => {
                setLoadingMessage(null);
            })
        }
    }

    const existingContacts = localStorage.getItem("contactIds") ? JSON.parse(localStorage.getItem("contactIds")) : [];
    const existingContact = existingContacts && existingContacts.indexOf(contact.contactId) !== -1;

    const CONTACT_ITEM_DOM = <div 
        className="contact-item" 
        data-highlight-block={highlightBlock ? 'true' : 'false'}
        key={contact.id} 
        onClick={() => {
            if (
                (list === "AGENCY" || list === "EMPLOYER" || list === "OTHER" || list === "CHAT") &&
                (!( (list === "AGENCY" || list === "EMPLOYER" || list === "OTHER") && !hideOptions && !selecting && mode === "invite" && existingContact )) &&
                (!onClickBlockMessage) &&
                (!invitedJustNow)
            ) {
                onEdit(contact.name || contact.email || "");
            }
        }}
        data-blocked={onClickBlockMessage ? true : false}
        data-user-id={contact.contactId || contact.id}
        data-contact-id={contact.contactId}
        data-contact-name={contact.name}
        data-org-name={organisationName}
    >
        <div className="details">
            { tag && <span className="tag">{tag}</span> }
            { highlight && <span className="new-dot" /> }
            { highlightOnline && <span className="online-dot" /> }
            { (list === "INCOMING" || list === "REFERRAL" || list === "OUTGOING" || list === "REFERRALS") &&
                <div className="picture">
                    <img data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported} src={orgImageOverride ? companyProfilePictureUrl : (contact.profilePictureUrl || (boringAvatar ? ("https://source.boringavatars.com/beam/80/" + (contact.name || contact.email) + "?colors=264653,2a9d8f,e9c46a,f4a261,e76f51") : PersonImg)) } />
                    { !hideOrgImg &&
                        <img data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported} className="company-img" src={profilePicUrl} />
                    }
                </div>
            }
            { (list === "AGENCY" || list === "EMPLOYER" || list === "CHAT" || list === "OTHER") &&
                <div className="picture">
                    <img data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported} src={imageOverride ? imageOverride : orgImageOverride ? companyProfilePictureUrl : profilePicUrl} />
                </div>
            }
            <div className="contact-details">
                <h2>
                    <span data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported}>{contact.name}</span>
                    { (list === "AGENCY" || list === "EMPLOYER" || list === "CHAT" || list === "OTHER") && (contact.verified !== undefined && contact.verified !== null) &&
                        <span>
                            <img 
                                data-user-id={contact.contactId || contact.id}
                                data-report-blurred={contact.reported}
                                src={ contact.verified ? ShieldVerified : ShieldUnverified }
                                onClick={(e) => {
                                    if (!ignoreOrgClick) {
                                        e.stopPropagation();
                                        if (contact.verified) {
                                            (window as any).toast("This person is verified");
                                        } else {
                                            (window as any).toast("We haven't been able to verify this person yet, certain details may be missing");
                                        }
                                    }
                                }}
                            />
                        </span>
                    }
                    { ( ( (list === "AGENCY" || list === "EMPLOYER" || list === "OTHER") && !hideOptions && !selecting && mode === "invite" && existingContact ) || invitedJustNow) &&
                        <span className='already-sharing'>Already Invited</span>
                    }
                    { (sharingAvailabilityWithContact) &&
                        <img 
                            data-user-id={contact.contactId || contact.id}
                            data-report-blurred={contact.reported}
                            className='relationship-status' 
                            src={ShareStandaloneImg}
                            onClick={(e) => {
                                e.stopPropagation();
                                (window as any).toast("You are sharing your availability with this contact");
                            }}
                        />
                    }
                </h2>
                { contact.email && <p data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported}>{contact.email}</p> } 
                { contact.headline && <p data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported} className="headline">{contact.headline}</p> } 
                { (contact.emailDeliveryStatusTypeId === 2 || contact.emailDeliveryStatusTypeId === 2 || contact.notificationStatusTypeId === 4 || contact.inviteIgnored) &&
                    <p className="status">
                        <IonIcon 
                            style={{ color: (contact.inviteIgnored ? "#FB5B5A" : contact.notificationStatusTypeId === 4 ? "#FB5B5A" : (contact.emailDeliveryStatusTypeId === 2 || contact.emailDeliveryStatusTypeId === 3) ? "#FFA400" : "#50D890") }} 
                            icon={contact.inviteIgnored ? closeCircle : contact.notificationStatusTypeId === 4 ? closeCircle : (contact.emailDeliveryStatusTypeId === 2 || contact.emailDeliveryStatusTypeId === 3) ? alertCircle : checkmarkCircle } 
                        />
                        <span>{contact.inviteIgnored ? "This contact has rejected your invitation.  Get in contact with them to find out why." : contact.notificationStatusTypeId === 4 ? "This contact has blocked you from sending them your availability.  Get in contact with them to find out why." : (contact.emailDeliveryStatusTypeId === 2 || contact.emailDeliveryStatusTypeId === 3) ? "Incorrect email, try deleting this contact, and entering another email address" : "Recipient Accepted" }</span>
                    </p>
                }
                { (contactRating || contactAvgRating || showNoRatings) && !hideRatings && contact.contactId &&
                    <div className='tags'>
                        <span>
                            { contactRating && 
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (rate !== undefined) {
                                        rate();
                                    }
                                }}>
                                    <RatingPreview
                                        rating={contactRating}
                                        notOwn={true}
                                    />
                                </div>
                            }
                            { ((contactAvgRating || showNoRatings) && contact.contactId) &&
                                <div
                                    className='avg-rating'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowRatings(true);
                                    }}
                                >
                                    <IonIcon icon={star} />
                                    <span>{(contactAvgRating ? parseFloat(contactAvgRating) : 0)} from {(contactTotalRatings || 0)} ratings</span>
                                </div>
                            }
                        </span>
                    </div>
                }
            </div>
            { ((list === "AGENCY" || list === "EMPLOYER" || list === "OTHER") && !hideOptions && !selecting && !(mode === "invite" && existingContact)) &&
                <IonIcon className="more-btn" icon={ mode === "invite" ? ( existingContact ? checkmarkCircle : addCircle) : ellipsisHorizontal}></IonIcon>
            }
            { (selecting && selected && contact.name !== 'No contact at this agency') &&
                <IonIcon className="more-btn select" icon={checkbox} />
            }
            { (selecting && !selected && contact.name !== 'No contact at this agency') &&
                <IonIcon className="more-btn select" icon={squareOutline} />
            }
        </div>
        { (list === "INCOMING" || list === "REFERRAL" || list === "OUTGOING") &&
            <div className="options">
                { (list === "INCOMING" || list === "REFERRAL") && <button className="accept-btn" onClick={() => { accept() }}>Accept</button> }
                { (list === "INCOMING" || list === "REFERRAL") && <button className="reject-btn" onClick={() => { reject() }}>Reject</button> }
                { (list === "OUTGOING") && <button className="cancel-btn">Cancel</button> }
            </div>
        }
    </div>

    return (
        <div 
            data-indent={everFirst && !dontRenderOrg}
            data-highlight={highlight ? 'true' : 'false'}
            style={{
                position: 'relative'
            }}
            data-first={first ? 'true' : 'false'}
            data-parent-type={parentType}
        >
            { (onClickBlockMessage) &&
                <div className='contact-item-blocking-wrapper'>
                    <p>{onClickBlockMessage}</p>
                </div>
            }
            { (first && !dontRenderOrg) &&
                <div 
                    className="company-header-item" 
                    data-org-id={contact.companyId} 
                    data-org-name={contact.companyName}
                    onClick={() => {
                        if (onOrgClick && !ignoreOrgClick)
                            onOrgClick(useFallbackImage ? null : orgImageOverride)
                    }}
                >
                    <img 
                        data-user-id={contact.contactId || contact.id}
                        data-report-blurred={contact.reported}
                        src={useFallbackImage ? BusinessImg : (companyProfilePictureUrl || profilePicUrl)}
                        style={{
                            flexBasis: 32
                        }}
                        onError={() => {
                            setUseFallbackImage(true);
                        }}
                    />
                    <div
                        style={{
                            flexBasis: onOrgClick ? 'calc(100% - 72px)' : 'calc(100% - 32px)'
                        }}
                    >
                        <h2 data-user-id={contact.contactId || contact.id || inviteId} data-report-blurred={contact.reported} style={ onDeleteOrg ? { paddingLeft: 0 } : { } }>
                            {contact.companyName}
                            { (isAgency && isHirer) && " (Hirer and Agency)" }
                            { (isAgency && !isHirer) && " (Agency)" }
                            { (!isAgency && isHirer) && " (Hirer)" }
                            { (contact.verified !== undefined && contact.verified !== null) &&
                                <img 
                                    data-user-id={contact.contactId || contact.id}
                                    data-report-blurred={contact.reported}
                                    src={ ( (useFallbackImage || companyProfilePictureUrl) ? companyVerified : contact.verified) ? ShieldVerified : ShieldUnverified }
                                    className="verification-badge"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (contact.verified) {
                                            if (useFallbackImage || companyProfilePictureUrl) {
                                                (window as any).toast("This organization is verified");
                                            } else {
                                                (window as any).toast("This person is verified");
                                            }
                                        } else {
                                            if (useFallbackImage || companyProfilePictureUrl) {
                                                (window as any).toast("We haven't been able to verify this organization yet, certain details may be missing");
                                            } else {
                                                (window as any).toast("We haven't been able to verify this person yet, certain details may be missing");
                                            }
                                        }
                                    }}
                                />
                            }
                            { (orgIsRepresentingAgency) &&
                                <img 
                                    className='relationship-status' 
                                    src={AgencyStandaloneImg}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        (window as any).toast("You set this organisation as a representing agency");
                                    }}
                                />
                            }
                        </h2>
                        { ( organisationRating || (orgAvgRating && orgTotalRatings) ) && !hideRatings && 
                            <div className='tags'>
                                <span>
                                    { (organisationRating) &&
                                        <span>
                                            <RatingPreview 
                                                rating={organisationRating}
                                                notOwn={true}
                                            />
                                        </span>
                                    }
                                    { (orgAvgRating && orgTotalRatings) &&
                                        <div
                                            className='avg-rating'
                                            onClick={(e) => {
                                        
                                            }}
                                        >
                                            <IonIcon icon={star} />
                                            <span>{parseFloat(orgAvgRating)} from {orgTotalRatings} ratings</span>
                                        </div>
                                    }
                                </span>
                            </div>
                        }
                        { (orgHeadline) &&
                            <p>{orgHeadline}</p>
                        }
                    </div>
                    { (onOrgClick && !ignoreOrgClick && !hideOrgOrgOptions) &&
                        <IonIcon
                            icon={mode === "invite" ? addCircle : ellipsisHorizontal}
                            style={{
                                flexBasis: 40
                            }}
                        />
                    }
                    { (onDeleteOrg) &&
                        <IonIcon style={{ marginRight: 10, fontSize: 32 }} icon={closeCircle} onClick={() => { onDeleteOrg(contact.companyId, contact.companyName) }} />
                    }
                </div>
            }
            { (!onlyOrgHeader && !dontRenderContact && !swipeText) &&
                CONTACT_ITEM_DOM
            }
            { (!onlyOrgHeader && !dontRenderContact && swipeText) &&
                <SwipeToDelete 
                    deleteText={swipeText}
                    onDelete={(a, b) => {
                        onSwipe();
                    }}
                >
                    {CONTACT_ITEM_DOM}
                </SwipeToDelete>
            }
            <Drawer
                anchor="bottom"
                open={showRatings}
                onClose={ async () => {
                    setShowRatings(false);
                } }
                className="contact-rating-wrapper"
            >
                <div className="swipeable-drawer-body" data-visible={true}>
                    { (showRatings && ratingsLoading) &&
                        <IonLoading
                            isOpen={true}
                            onDidDismiss={() => {}}
                            message="Loading Ratings"
                            duration={12000}
                        />
                    }
                    { (showRatings && !ratingsLoading) &&
                        <div className="content">
                            <section data-section="ratings">
                                <h2>
                                    Ratings given to {contact.name.split(" ")[0]}
                                    <IonIcon onClick={() => {
                                        setShowRatings(false);
                                    }} icon={closeCircle} />
                                </h2>
                                { ratings.length === 0 &&
                                    <p>No ratings yet</p>
                                }
                                <button onClick={() => {
                                    if (rate !== undefined) {
                                        rate();
                                    }
                                    setShowRatings(false);
                                }}>Give/Edit your Rating</button>
                                { ratings.map(rating => {
                                    return (
                                    <RatingPreviewExtended
                                        hideRatee={true}
                                        preview={true}
                                        rating={rating}
                                        notOwn={true}
                                    />
                                    )
                                }) }
                            </section>
                        </div>
                    }
                </div>
            </Drawer>
            <IonLoading
                isOpen={loadingMessage !== null}
                onDidDismiss={() => setLoadingMessage(null)}
                message={loadingMessage}
                duration={12000}
            />
        </div>
    );
};

export default ContactListItem;
