import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useHistory } from "react-router-dom";

interface Props {
  
}

const Settings: React.FC<Props> = ({ }) => {
  
  const history = useHistory();

  return (
    <IonPage data-page="help-guides">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Help Guides</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="help-guides-wrapper" style={{ '--background': 'white', 'background': 'white' }} fullscreen>
        <IonList style={{ '--background': 'white', 'background': 'white' }}>
        { [
        // { label: "Notifications", slug: "notifications" },
        { label: "Recent / Favourite events", slug: "recents" },
        { label: "I'm available now event type", slug: "availability" },
        { label: "Delete events", slug: "delete" },
        { label: "Editing events", slug: "edit" },
        // { label: "Time presets", slug: "presets" },
        { label: "Multi-day events", slug: "multiday" }
        ].map(help => {
        return (
            <IonItem onClick={() => { history.push("/help/" + help.slug) }}>
            <IonLabel>{help.label}</IonLabel>
            </IonItem>
        )
        }) }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
