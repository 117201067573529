import React from 'react';

function Briefcase(props) {
    return (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 29.502 87.72" enable-background="new 0 0 29.5 87.72" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(-1, 0, 0, -1, 29.501999, 87.720001)">
            <polygon points="2.003,74.424 2.003,74.424 2.003,74.424  "/>
            <path d="M0,79.875l2.003-5.451l12.412,4.57C2.158,58.445,0.878,41.114,3.844,28.063C6.961,14.233,14.607,5.326,18.156,1.766   C19.274,0.647,19.995,0.058,20.062,0l3.669,4.504l0,0c0,0-0.005,0.006-0.065,0.056c-0.18,0.153-0.684,0.597-1.403,1.317   c-3.072,3.062-9.989,11.136-12.753,23.462c-2.601,11.662-1.694,27.237,9.968,46.793l4.587-12.157l5.437,2.05L21.311,87.72L0,79.875   z"/>
        </g>
        </svg>
    );
}

export default Briefcase;
