import React from 'react';

function Briefcase(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.502" height="18.752" viewBox="0 0 22.502 18.752">
            <g id="briefcase_1_" data-name="briefcase (1)" transform="translate(0 -2)">
                <path id="Path_227" data-name="Path 227" d="M14.563,6.219a.938.938,0,0,1-.938-.938V3.875H9.875V5.282A.938.938,0,1,1,8,5.282V3.875A1.877,1.877,0,0,1,9.875,2h3.75A1.877,1.877,0,0,1,15.5,3.875V5.282A.938.938,0,0,1,14.563,6.219Z" transform="translate(-0.499)" fill="#fb5b5a"/>
                <path id="Path_228" data-name="Path 228" d="M16.876,12.032v.863a.7.7,0,0,1-1.406,0v-.863H7.032v.863a.7.7,0,0,1-1.406,0v-.863H0v6.329a2.577,2.577,0,0,0,2.578,2.578H19.924A2.577,2.577,0,0,0,22.5,18.361V12.032ZM19.924,5H2.578A2.577,2.577,0,0,0,0,7.578v3.047H5.625V9.454a.7.7,0,0,1,1.406,0v1.172H15.47V9.454a.7.7,0,0,1,1.406,0v1.172H22.5V7.578A2.577,2.577,0,0,0,19.924,5Z" transform="translate(0 -0.187)" fill="#fb5b5a"/>
            </g>
        </svg>
    );
}

export default Briefcase;
