import React from 'react';
// import '../../../styles/Components/Calendar/AvailabilityCircle.scss';
import { AvailabilityCircleSlice } from '../../../types/AvailabilityCircleSlice';

interface AvailabilityCircleProps {
    background: AvailabilityCircleSlice[];
}

const COLOR_CODES = {
    1: '#50D890',
    2: '#B5BC0E',
    3: '#FB5B5A',
    4: '#FFA400',
    5: '#509ffe',
    6: '#777',
    7: '#333',
}

const AvailabilityCircle: React.FC<AvailabilityCircleProps> = ({ background }) => {

    if (!background || background.length === 0 || !(window as any).colors)
        return null;

    let totalMinutes = background[background.length-1].end;
    let minutesPerDegree = 360/totalMinutes
    
    let compiledBackground: string = 'conic-gradient(';

    for (let i = 0; i < background.length; i++) {
        let slice:AvailabilityCircleSlice = background[i];
        compiledBackground += ( slice.type === 0 ? "transparent" : (slice.type === 4) ? (COLOR_CODES[(window as any).colors.availability]) : (slice.type === 1) ? (COLOR_CODES[(window as any).colors.work]) : (slice.type === 2) ? (COLOR_CODES[(window as any).colors.private]) : (COLOR_CODES[(window as any).colors.private]) ) + " " + (slice.start*minutesPerDegree) + "deg " + (slice.end*minutesPerDegree) + "deg"
        if (i + 1 !== background.length) {
            compiledBackground += ","
        }
    }

    compiledBackground += ")"

    return (
        <div data-gradient={compiledBackground} style={{ background: compiledBackground }} className="availability-circle"></div>
    );
};

export default AvailabilityCircle;
