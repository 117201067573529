let Configuration = {
    local: {
        API_BASE_URI: "https://uk-test-uemobileservices.azurewebsites.net",
        API_EXTERNAL_URI: "https://uk-test-ueextapi.azurewebsites.net", // "https://localhost:4001",
        API_FUNCTIONS_URI: "http://localhost:7071/api",
        API_NEW_NODE: "http://localhost:3000",
        API_EXTERNAL_URI_MOBILE: "https://uk-test-uemobileservices.azurewebsites.net",
        API_BASE_STORAGE_URI: "uktestue.blob.core.windows.net",
        API_PROFILE_IMAGE_FOLDER_NAME: "images/profile",
        PORTAL_URL: "https://uk-test-ueportal.azurewebsites.net",
        AUTH_APP_ID: "53cdf07a-79b2-4396-8484-c7da5a35c229",
        AUTH_TENANT_NAME: "upedgedev",
        AUTH_SCOPE: "https://upedgedev.onmicrosoft.com/mobapi/user_impersonation",
        AUTH_MOBILE_SCOPE: "openid https://upedge.onmicrosoft.com/mobapi/workerapp offline_access",
        AUTH_REDIRECT_MOBILE: "com.updatedge://login",
        AUTH_END_REDIRECT_MOBILE: "com.updatedge://endredirect",
        AUTH_REDIRECT_WEB: "http://localhost:5000/redirect",
        AUTH_END_REDIRECT_WEB: "http://localhost:5000/endredirect",
        AUTH_LINK: "https://upedgedev.b2clogin.com/upedgedev.onmicrosoft.com/B2C_1A_MobileSignUpOrSignIn/v2.0",
        AUTH_POLICY_NAME: "B2C_1A_MobileSignUpOrSignIn",
        AUTH_REDIRECT_URI: "http://localhost:5000/redirect",
        AUTH_USE_PORTAL_AUTH_ON_MOBILE_PAGES: "true",
        APPINSIGHTS_KEY: "ea941a1a-f8a2-4d0e-91e7-1da9ec1a819d",
        SIGNALR_SERVICE_URL: "https://uk-test-uesignalr.azurewebsites.net",
        BUNDLE_ID: "com.updatedge",
        VERSION: "2.0",
        BUILD_NUMBER: 200,
        SIGNALR_URI: "https://uk-test-uesignalr.azurewebsites.net",
        OPEN_REPLAY_KEY: "A8fM34ShOH28qdPUH2C7",
        GROWTH_BOOK_KEY: "sdk-O8GvyHnPrb7Iny0",
        GROWTH_BOOK_URL: "https://expapi.updatedge.com",
        CDN_IMAGE_URI: "https://test-images.updatedge.com",
        POSTHOG_KEY: "phc_AyNz3hONHgT167uG4v7hCW6fRWJAAm5nS2rps7fNZnC"
    },
    dev: {
        API_BASE_URI: "https://uk-test-uemobileservices.azurewebsites.net",
        API_EXTERNAL_URI: "https://uk-test-ueextapi.azurewebsites.net",
        API_EXTERNAL_URI_: "https://localhost:4001", // "https://uk-test-ueextapi.azurewebsites.net",
        API_FUNCTIONS_URI: "https://func-test-uejs.azurewebsites.net/api", 
        API_NEW_NODE: "https://uk-test-uenodejs.azurewebsites.net",
        API_EXTERNAL_URI_MOBILE: "https://uk-test-uemobileservices.azurewebsites.net",
        API_BASE_STORAGE_URI: "uktestue.blob.core.windows.net",
        API_PROFILE_IMAGE_FOLDER_NAME: "images/profile",
        PORTAL_URL: "https://test-portal.updatedge.com",
        AUTH_APP_ID: "53cdf07a-79b2-4396-8484-c7da5a35c229",
        AUTH_TENANT_NAME: "upedgedev",
        AUTH_SCOPE: "https://upedgedev.onmicrosoft.com/mobapi/user_impersonation",
        AUTH_MOBILE_SCOPE: "openid https://upedgedev.onmicrosoft.com/mobapi/workerapp offline_access",
        AUTH_REDIRECT_MOBILE: "com.updatedge://login",
        AUTH_END_REDIRECT_MOBILE: "com.updatedge://endredirect",
        AUTH_REDIRECT_WEB: "https://worker-test.updatedge.com/redirect",
        AUTH_END_REDIRECT_WEB: "https://worker-test.updatedge.com/endredirect",
        AUTH_LINK: "https://upedgedev.b2clogin.com/upedgedev.onmicrosoft.com/B2C_1A_MobileSignUpOrSignIn/v2.0",
        AUTH_POLICY_NAME: "B2C_1A_MobileSignUpOrSignIn",
        AUTH_REDIRECT_URI: "https://test-my.updatedge.com/redirect",
        AUTH_USE_PORTAL_AUTH_ON_MOBILE_PAGES: "true",
        APPINSIGHTS_KEY: "ea941a1a-f8a2-4d0e-91e7-1da9ec1a819d",
        SIGNALR_SERVICE_URL: "https://uk-test-uesignalr.azurewebsites.net",
        BUNDLE_ID: "com.updatedge",
        VERSION: "2.0",
        BUILD_NUMBER: 200,
        SIGNALR_URI: "https://uk-test-uesignalr.azurewebsites.net",
        OPEN_REPLAY_KEY: "A8fM34ShOH28qdPUH2C7",
        GROWTH_BOOK_KEY: "sdk-O8GvyHnPrb7Iny0",
        GROWTH_BOOK_URL: "https://expapi.updatedge.com",
        CDN_IMAGE_URI: "https://test-images.updatedge.com",
        POSTHOG_KEY: "phc_AyNz3hONHgT167uG4v7hCW6fRWJAAm5nS2rps7fNZnC"
    },
    prod: {
        API_BASE_URI: "https://mobapi.updatedge.com",
        API_EXTERNAL_URI: "https://api.updatedge.com",
        API_FUNCTIONS_URI: "https://func-live-uejs.azurewebsites.net/api",
        API_NEW_NODE: "https://uk-live-uenodejs.azurewebsites.net",
        API_EXTERNAL_URI_MOBILE: "https://mobapi.updatedge.com",
        API_BASE_STORAGE_URI: "ukliveue.blob.core.windows.net",
        API_PROFILE_IMAGE_FOLDER_NAME: "images/profile",
        PORTAL_URL: "https://test-portal.updatedge.com",
        AUTH_APP_ID: "cce02dba-3a54-4734-938c-49f1a62708ea",
        AUTH_TENANT_NAME: "upedge",
        AUTH_SCOPE: "https://upedge.onmicrosoft.com/mobapi/readwrite",
        AUTH_MOBILE_SCOPE: "openid https://upedge.onmicrosoft.com/mobapi/workerapp offline_access",
        AUTH_REDIRECT_MOBILE: "com.updatedge://login",
        AUTH_END_REDIRECT_MOBILE: "com.updatedge://endredirect",
        AUTH_REDIRECT_WEB: "https://my.updatedge.com/redirect",
        AUTH_END_REDIRECT_WEB: "https://my.updatedge.com/endredirect",
        AUTH_LINK: "https://upedge.b2clogin.com/upedge.onmicrosoft.com/B2C_1A_MobileSignUpOrSignIn/v2.0",
        AUTH_POLICY_NAME: "B2C_1A_MobileSignUpOrSignIn",
        AUTH_REDIRECT_URI: "https://my.updatedge.com/redirect",
        AUTH_USE_PORTAL_AUTH_ON_MOBILE_PAGES: "true",
        APPINSIGHTS_KEY: "a9bd116c-44e2-40a3-ad28-b6dee0686e1c",
        SIGNALR_SERVICE_URL: "https://uk-live-uesignalr.azurewebsites.net",
        BUNDLE_ID: "com.updatedge",
        VERSION: "2.0",
        BUILD_NUMBER: 200,
        SIGNALR_URI: "https://uk-live-uesignalr.azurewebsites.net",
        OPEN_REPLAY_KEY: "sJwSb5rPxY8L5rTOCJXd",
        GROWTH_BOOK_KEY: "sdk-deEGLB3vs7IHR4pN",
        GROWTH_BOOK_URL: "https://expapi.updatedge.com",
        CDN_IMAGE_URI: "https://images.updatedge.com",
        POSTHOG_KEY: "phc_L77gmUWI2ZewhKPuqyLAG6OaGCSQQ5vv5GQRGPemMRU"
    }
}

export default Configuration;
