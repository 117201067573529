import { IonContent, IonHeader, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import UELogo from '../images/icon.png';
// import '../styles/VerifyEmail.scss';

interface Props { }

const EmailConfirmed: React.FC<Props> = ({  }) => {

  useEffect(() => {
      // Hide tabs
      let elements = document.getElementsByTagName("ion-tab-bar");
      if (elements && elements[0]) {
          elements[0].style.display = "none"
      }
  }, []);

  return (
    <IonPage data-page="verify-email">
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="verify-email-wrapper" fullscreen>
        <div>
          <div>
            <img src={UELogo} alt="UE Logo" style={{ borderRadius: 24, width: 100, height: 100 }} />
            <h1>You are all set</h1>
            <p style={{ fontSize: '1.1em' }}>If the app didn't open, then close this tab, and open it manually from your home screen</p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EmailConfirmed;