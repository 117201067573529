import InternalAPI from './InternalAPI';
import { SyncDTO } from '../types/SyncDTO';

class SyncAPI {

    /**
     * Confirms Availability
     * @returns status
     */
    static async confirm(token: string): Promise<Object> { return InternalAPI.request("POST", "/userprofile/confirmAvailability?token=" + token, null, false, false, true, false, true); }

    /**
     * Confirms Availability for logged-in user
     * @returns status
     */
    static async confirmAuth(): Promise<Object> { return InternalAPI.request("POST", "/userprofile/confirmAvailability?", null, false, false, true); }

    /**
     * Sends Availability in email
     * @returns status
     */
    static async send(contactIds: string[], ownUserId: string): Promise<Object> { return InternalAPI.request("POST", "/user/" + ownUserId + "/send-availability?", { contactIds: contactIds }, null, false, false, false, false, true) }

    /**
     * Gets own flattened availabiltiy
     * @returns flattened availability and token
     */
     static async getFlattened(): Promise<any> { return InternalAPI.request("POST", "/availability/getFlattenedForCurrentUser?", null, null, false, true, false, false, false) }

}

export default SyncAPI;
