import InternalAPI from './InternalAPI';

class ChatAPI {

    static async getToken(): Promise<Object> { return InternalAPI.request("GET", "/chat/token?", null, null, null, true); }

    static async notify(userId: string, message: string): Promise<Object> { return InternalAPI.request("POST", "/api/chat/notify/" + userId + "?", [message]); }

    static async getContactable(): Promise<Object> { 
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
        if (user && (user.userType === 2 || user.userType === 3)) {
            return new Promise(async (resolve) => {
                const data = await InternalAPI.request("GET", "/chat/contacts/portal?", null, null, null, true); 
                let allContactIds = [];
                let allUserIds = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        const contact = data[i];
                        contact.fullName = contact.contactName;
                        contact.userId = contact.otherUsersUserId;
                        contact.id = contact.userContactId;
                        data[i] = contact;
                        allContactIds.push(contact.otherUsersContactId)
                        allUserIds.push(contact.otherUsersUserId)
                    }
                    localStorage.setItem("allContactids", JSON.stringify(allContactIds))
                    localStorage.setItem("allUserIds", JSON.stringify(allUserIds))
                    resolve(data)
                } else {
                    resolve([])
                }
            })
        } else {
            const data = await InternalAPI.request("GET", "/chat/contacts?returnNonDirect=true", null, null, null, true); 
            if (data) {
                let allIndirectContacts = [];
                for (let i = 0; i < data.length; i++) {
                    const contact = data[i];
                    if (contact.nonDirect) {
                        allIndirectContacts.push(contact.userId)
                    }
                    localStorage.setItem("inDirectContacts", JSON.stringify(allIndirectContacts))
                }
            }
            return data;
        }
    }

    static async initiateChannel(userId: string): Promise<Object> { return InternalAPI.request("GET", "/chat/channel/" + userId + "?", null, null, null, true); }

    static async archiveThread(userId): Promise<Object> { return InternalAPI.request("PUT", "/chat/" + userId + "/status/archived?", null, null, null, true); }

    static async unarchiveThread(userId): Promise<Object> { return InternalAPI.request("PUT", "/chat/" + userId + "/status/inbox?", null, null, null, true); }


}

export default ChatAPI;
