const ResolvedEvents = (state = [], action) => {
    switch(action.type) {
        case "PUSH_RESOLVED_EVENTS":
            return state.concat(action.data);
        case "CLEAR_RESOLVED_EVENTS":
            return [];
    }
    return state;
}

export default ResolvedEvents;