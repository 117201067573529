import { GoogleMapsPlace } from '../types/GoogleMapsPlace';
import axios from "axios";

class GoogleMaps {

    /**
     * Takes a keyword and returns suggestions from Google Maps
     * @param keyword keyword to search for
     * @returns result list
     */
    static async search(keyword: string): Promise<GoogleMapsPlace[]> {
        return new Promise(function(resolve, reject) {
            axios.get("https://maps.googleapis.com/maps/api/place/findplacefromtext/json?region=GB&input=" + keyword + "&inputtype=textquery&fields=formatted_address,name,place_id&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8&language=en")
            // axios.get("https://maps.googleapis.com/maps/api/geocode/json?region=GB&address=" + keyword + "&sensor=true&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8&language=en&region=US")
            // axios.get("https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" + keyword + "&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8")
            .then(res => {
                let list: GoogleMapsPlace[] = [];
                if (res && res.data && res.data.results) {
                    for (let i = 0; i < res.data.results.length; i++) {
                        const element = res.data.results[i];
                        list.push({
                            placeId: element.place_id,
                            formattedAddress: element.formatted_address
                        } as GoogleMapsPlace)
                    }
                }
                return resolve(list);
            })
            .catch(e => { reject(e) })
        })
    }

    /**
     * Takes a keyword and returns suggestions from Google Maps
     * @param keyword keyword to search for
     * @returns result GoogleMapsPlace
     */
    static async getByPlaceId(placeId: string): Promise<GoogleMapsPlace> {
        return new Promise(function(resolve, reject) {
            axios.get("https://maps.googleapis.com/maps/api/place/details/json?placeid=" + placeId + "&key=AIzaSyB76e89fMUB7oVc1xHygDEzKw5Lt-O30M8")
            .then(res => {
                if (res && res.data && res.data.result) {
                    return resolve({
                        formattedAddress: res.data.result.formatted_address,
                        placeId: res.data.result.placeId
                    } as GoogleMapsPlace)
                }
                return resolve(null);
            })
            .catch(e => { reject(e) })
        })
    }

    static async getPostcodeByCoordinates(lat: number, lng: number): Promise<string> {
        return new Promise(function(resolve, reject) {
            axios.get("https://api.postcodes.io/postcodes?lon=" + lng + "&lat=" + lat)
            .then(res => {
                resolve((res && res.data && res.data.result && res.data.result[0] && res.data.result[0].postcode) ? res.data.result[0].postcode : null)
            })
            .catch(e => { reject(e) })
        })
    }

    static async getCoordinatesByPostcode(postcode: string): Promise<{ lat: number, lng: number }> {
        return new Promise(function(resolve, reject) {
            axios.get("https://api.postcodes.io/postcodes/" + postcode)
            .then(res => {
                resolve((res && res.data && res.data.result && res.data.result.latitude && res.data.result.longitude) ? { lat: res.data.result.latitude, lng: res.data.result.longitude } : null)
            })
            .catch(e => { reject(e) })
        })
    }
}

export default GoogleMaps;
