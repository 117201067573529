import { IonIcon } from '@ionic/react';
import Rating from '@material-ui/lab/Rating';
import { closeCircle } from 'ionicons/icons';
import React, { useState } from 'react';
import Configuration from '../../../Configuration';
import Logo from '../../../images/icon.svg';
// import '../../../styles/Components/Rating/RatingCollector.scss';
import { Event } from '../../../types/Event';
import Utilities from '../../../Utilities';
import EventTimelineItem from '../Calendar/EventTimelineItem';
import UserAPI from '../../../apis/User';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const QUESTIONS = {
    // Worker
    1: { question: "General Professionalism (communication, timeliness)?" },
    2: { question: "Quality of work" },
    // Agency
    10: { question: "Response time for confirming offer" },
    11: { question: "General attitude" },
    12: { question: "Accuracy of company brief (Was the job similar as described)" },
    // Hirer
    20: { question: "Level of health precautions taken" },
    21: { question: "General attitude of people introducing you to your class" },
    22: { question: "Accuracy of company brief" },
    // Updatedge
    50: { question: "How easy was it to use the portal?" },
    51: { question: "How easy was it to use the application / website?" },
}

interface WorkerDetails {
    name: string;
    id: string;
    questions: number[],
}

interface AgencyDetails {
    name: string;
    id: string;
    questions: number[],
}

interface HirerDetails {
    name: string;
    id: string;
    questions: number[],
}

interface UpdatedgeDetails {
    questions: number[],
    name?: string,
    id?: string,
}

interface InlineSelectProps {
    onSubmit: ([]) => void;
    onClose: () => void;
    event: Event;
    worker?: WorkerDetails;
    agency?: AgencyDetails;
    hirer?: HirerDetails;
    updatedge?: UpdatedgeDetails
}

const RatingCollector: React.FC<InlineSelectProps> = ({ onClose, onSubmit, worker, agency, hirer, event, updatedge }) => {

    const [ratings, setRatings] = useState <object> ({});  // Answers
    const [details, setDetails] = useState <object> ({});  // Details

    if (!agency && !hirer && !worker) {
        return null;
    }

    // Users to collect review about
    const entites = [];

    if (worker) entites.push({ type: "worker", data: worker });
    if (agency) entites.push({ type: "agency", data: agency });
    if (hirer) entites.push({ type: "hirer", data: hirer });
    if (updatedge) entites.push({ type: "updatedge", data: updatedge });

    return (
        <div className="rating-collector">
            { (worker || agency || hirer) &&
                <div>
                    <h1>
                        Share your experience with your latest assignment
                        <IonIcon icon={closeCircle} onClick={() => { onClose(); }} />
                    </h1>
                </div>
            } 
            <div className="timeline">
                <div className="event-timeline-item-wrapper">
                    <div className="day-header">
                        <div className="weekday">{Utilities.formatDate(new Date(event.start), "ds")}</div>
                        <div className="day">{Utilities.formatDate(new Date(event.start), "D")}</div>
                        <div className="month-year">{Utilities.formatDate(new Date(event.start), "mms YYYY")}</div>
                    </div>
                    <div className="events">
                        <EventTimelineItem event={event} />
                    </div>
                </div>
            </div>
            { entites.map(entity => {
                let type = entity.type;
                let data = (type === "worker") ? entity.data as WorkerDetails : (type === "hirer") ? entity.data as HirerDetails : (type === "updatedge") ? entity.data as UpdatedgeDetails : entity.data as AgencyDetails;
                return (
                    <section data-section={type}>
                        <div className="profile-img" style={type == "updatedge" ? { backgroundColor: "#3573E6" } : {}}>
                            { (type === "updatedge") ?
                                <img style={{ padding: 12, width: 56 }} src={Logo} />
                                :
                                <img src={type === "worker" ? UserAPI.getProfilePicture(data.id) : UserAPI.getOrgPicture(data.id)} />
                            }
                        </div>
                        <h2>What did you think about {data.name}?</h2>
                        <div className="questions">
                            { data.questions.map(question => {
                                if (!QUESTIONS[question]) {
                                    return null;
                                }
                                return (
                                    <div className="question" key={question}>
                                        <h3>{QUESTIONS[question].question}</h3>
                                        <div className="ratings-wrapper">
                                            <Rating
                                                name={question + "-rating"}
                                                value={ratings[question] || 0}
                                                onChange={(event, newValue) => {
                                                    let ratingsExisting = JSON.parse(JSON.stringify(ratings));
                                                    ratingsExisting[question] = newValue;
                                                    setRatings(ratingsExisting);
                                                }}
                                            />
                                        </div>  
                                    </div>
                                ) 
                            }) }
                        </div>
                        <div className="free-text">
                            <textarea
                                rows={1}
                                placeholder="Have more to say?"
                                value={details[data.id]}
                                onChange={(ev) => {
                                    let detailsExisting = JSON.parse(JSON.stringify(details));
                                    detailsExisting[data.id] = ev.target.value.trim();
                                    setDetails(detailsExisting);
                                }}
                            />
                        </div>
                    </section>
                )
            }) }
            <button className="submit-btn" onClick={() => {
                let ratingsArr = [];
                for (let questionId in ratings) {
                    let value = ratings[questionId];
                    ratingsArr.push({
                        rating: value, 
                        questionId: questionId, 
                        type: "rating",
                        userId: (parseInt(questionId) < 10 ? worker.id : parseInt(questionId) < 20 ? agency.id : parseInt(questionId) < 30 ? hirer.id : "updatedge"),
                    })
                }
                for (let id in details) {
                    let value = details[id];
                    if (value) {
                        ratingsArr.push({
                            details: value, 
                            type: "details",
                            userId: id,
                        })
                    }
                }
                onSubmit(ratingsArr);
            }}>
                Submit Review
            </button>
        </div>
    );
    
};

export default RatingCollector;
