// import { setSyncStatus, updateSetsOnly } from './state/actions/Calendar'
import store from './state/store';

import { getUnsyncedEvents, saveEvent, markAsSynced, saveEvents, getTotalEventsCount } from './stores/Event';
import Utilities from './Utilities';
import { SyncDTO, SyncType } from './types/SyncDTO';
import { updateSyncState } from './state/actions/Sync';
import { Event } from './types/Event';
import SyncAPI from './apis/Sync';
import InternalTracker from './InternalTracker';

class Dropbox {

    // Blocks another sync thread entering
    blockSync(source) {
        (window as any).sync.inSync = true;
    }

    // Unlbocks the sync thread
    unblockSync(source) {
        (window as any).sync.inSync = false;
    }

    // Generates a unqiue hash for events to check whether the event was updated by this device
    hash(event: Event): string {
        return (event.deletedAsNumber || "null") + "|" + (event.pinnedAsNumber || "null") + "|" + (event.title || "null") + "|" + (event.notes || "null") + "|" + (event.googlePlacesId || "null") + "|" + (event.eventType || "null") + "|" + (event.repeatType || "null") + "|" + (event.repeatForever || "null")
    }

    // Uploads new local changes, and fetched remote changes
    async sync(onSuccess?: () => void, onError?: () => void) {
        // console.log("@@@ ATTEMPT TP START SYNC");

        InternalTracker.trackEvent("Sync Attempt")

        if (window.location.href.indexOf("testing") !== -1) {
            InternalTracker.trackEvent("Sync Abort - Testing")
            return;
        }

        if (!(window as any).sync) {
            (window as any).sync = {}
        }

        if (!(window as any).sync.changedByMe) {
            (window as any).sync.changedByMe = []
        }

        if ((window as any).sync.inSync) {
            InternalTracker.trackEvent("Sync Abort - In Sync")
            return;
        }

        // console.log("@@@ DID START SYNC");

        (window as any).sync.inSync = true;

        let lastSync = localStorage.getItem("lastSync");
        
        let unsaved: Event[] = await getUnsyncedEvents();

        store.dispatch(updateSyncState({
            status: "syncing",
            details: "Syncing in progress",
            updates: false,
            newPushCount: unsaved.length
        }));


        if (unsaved.length !== 0) {

            InternalTracker.trackEvent("Sync - Pushing Changes", { count: unsaved.length })

            let changes: SyncDTO[] = [];

            for (let i = 0; i < unsaved.length; i++) {
                const event: Event = unsaved[i];
                const type: number = (event.deleted) ? SyncType.DeletedEvent : (event.isNew ? SyncType.CreatedEvent : SyncType.UpdatedEvent);
                (window as any).sync.changedByMe.push(this.hash(event))
                delete event.createdAt;
                delete event.deletedAsNumber;
                delete event.endAsDate;
                delete event.isNew;
                delete event.isSynced;
                delete event.isSyncedAsNumber;
                delete event.orderingUpdatedDate;
                delete event.pinnedAsNumber;
                delete event.startAsDate;
                delete event.updatedAtAsDate;
                delete event.version;
                delete event.userId;
                event.eventTypeId = event.eventType;
                event.repeatTypeId = event.repeatType || 0;
                event.repeatUntil = !event.repeatUntil ? null : event.repeatUntil;
                event.googlePlacesId = !event.googlePlacesId ? null : event.googlePlacesId;
                const start = new Date(event.start);
                const end = new Date(event.end);
                changes.push({ data: event, type: type });
            }
            
            let res;

            try {
                res = await SyncAPI.push(changes);
                InternalTracker.trackEvent("Sync - Pushing Changes Success");
            } catch (e) {
                InternalTracker.trackEvent("Sync - Pushing Changes Failed", { error: e })
                console.log("FAiled to upload events", e);
                store.dispatch(updateSyncState({
                    status: "error",
                    details: "Failed to upload events",
                    updates: false,
                }));
                (window as any).sync.inSync = false;
                if (onError) onError();
                return;
            }

        }

        await new Promise((resolve) => { setTimeout(() => { resolve(true) }, 1000) })

        let newChanges;

        InternalTracker.trackEvent("Sync - Pulling Changes", { lastSync: lastSync })

        try {
            newChanges = await SyncAPI.pull(new Date(lastSync).toUTCString())
        } catch (e) {
            console.error("FAiled to fetch events", e)
            InternalTracker.trackEvent("Sync - Pulling Changes Failed", { error: e })
            store.dispatch(updateSyncState({ status: "error", details: "Failed to fetch new events", updates: false }));
            (window as any).sync.inSync = false;
            if (onError) onError();
            return;
        }

        // @ts-ignore;
        let newEvents: Event[] = (newChanges && newChanges.data && newChanges.data.events) ? newChanges.data.events : [];

        InternalTracker.trackEvent("Sync - Pulling Changes Success", { count: newEvents.length })

        if (newEvents.length !== 0) {
            if (localStorage.getItem("lastSync")) {
                for (let i = 0; i < newEvents.length; i++) {
                    const event = newEvents[i];
                    newEvents[i].googlePlacesId = (newEvents[i].googlePlacesId || "")
                    await saveEvent(event, true);
                }
            } else {
                await saveEvents(newEvents);
            }
        }

        // Checking whether the local events database didn't disappear magically
        const eventCounts = await getTotalEventsCount();
        const lastLocalEventsCount = localStorage.getItem("lastLocalEventsCount") ? parseInt(localStorage.getItem("lastLocalEventsCount")) : null;
        if (eventCounts !== null && lastLocalEventsCount !== null) {
            if (lastLocalEventsCount !== 0 && eventCounts === 0) {
                (window as any).toast("Reloading Local Events")
                localStorage.removeItem("lastSync");
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000)
                return;
            }
        }
        localStorage.setItem("lastLocalEventsCount", eventCounts.toString());

        let now = new Date();
        now = Utilities.dateSub(now, "minute", 1);

        (window as any).sync.inSync = false;
        if (onSuccess) onSuccess();

        const hasRemoteUpdates = (newEvents.filter(item => {
            let event: Event = item as Event;
            let hash: string = this.hash(event);
            return ((window as any).sync.changedByMe.indexOf(hash) === -1)
        }).length !== 0);

        const hasDirectInsertionUpdates = (newEvents.filter(item => {
            let event: Event = item as Event;
            let hash: string = this.hash(event);
            return event.organisationUrl && (window as any).sync.changedByMe.indexOf(hash) === -1
        }).length !== 0);

        if (hasDirectInsertionUpdates && localStorage.getItem("lastSync") && window.location.href.indexOf("onboarding-preferences") === -1) {
            (window as any).attemptToShowReviewPrompt("We just inserted a booking into your calendar. Would you please rate the Updatedge App?");
        }

        localStorage.setItem("lastSync", Utilities.formatDate(now, "YYYY-MM-DDTHH:MM:SS"));
        InternalTracker.trackEvent("Sync Done", {
            lastSync: localStorage.getItem("lastSync"),
        })

        store.dispatch(updateSyncState({
            status: "done",
            details: "Up-to-date",
            updates: hasRemoteUpdates,
            restorenewPushCount: unsaved.length
        }));

        if (unsaved.length) {
            setTimeout( async () => {
                store.dispatch(updateSyncState({
                    status: "done",
                    details: "Up-to-date",
                    updates: hasRemoteUpdates,
                    newPushCount: 0
                }));
            }, 1800);
        }

    }

}

export default Dropbox;
