import InternalAPI from './InternalAPI';

class OfferAPI {

    /**
     * Returns a specific offer
     * @param id id of the offer
     * @returns offer
     */
    static async get(id: string): Promise<Object> { return InternalAPI.request("GET", "/offer/" + id + "/recipient?", null, null, null, true, true); }

    /**
     * Soft deletes an offer
     * @param id id of the offer
     * @returns offer
     */
    static async remove(id: string): Promise<Object> { return InternalAPI.request("DELETE", "/offers/worker/" + id + "?", null, null, null, true, true); }

    /**
     * Accepts an offer
     * @param id id of the offer
     * @param allowDirectEngagement allows the hirer to select direct engagement
     * @returns accept confirmation
     */
    static async accept(id: string, allowDirectEngagement: boolean, allowAgencyEngagement: boolean): Promise<Object> { return InternalAPI.request("GET", "/publicapi/offer/respond/" + id + "/accept/" + (allowDirectEngagement ? "true" : "false") + "/" + (allowAgencyEngagement ? "true" : "false") + "?"); }

    /**
     * Adds event(s) of an offer to the user's schedule
     * @param id id of the offer
     * @returns accept confirmation
     */
    static async addToSchedule(id: string): Promise<Object> { return InternalAPI.request("POST", "/publicapi/offer/" + id + "/events?"); }

    /**
     * Rejects an offer
     * @param id id of the offer
     * @returns reject confirmation
     */
    static async reject(id: string): Promise<Object> { return InternalAPI.request("GET", "/publicapi/offer/respond/" + id + "/reject?"); }

    /**
     * Withdraws an offer after confirmation
     * @returns withdraw confirmation
     */
    static async withdraw(id: string): Promise<Object> { return InternalAPI.request("GET", "/publicapi/offer/" + id + "/withdraw?"); }

}

export default OfferAPI;
