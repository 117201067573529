import Dexie from 'dexie';

export async function InitStorage() {

    return new Promise<void>(async function(resolve, reject) {

        const db = new Dexie("updatedge");

        db.version(7).stores({
            events: 'id,start,end,isSynced,startAsDate,endAsDate,deletedAsNumber,isSyncedAsNumber,groupId,updatedAtAsDate,pinnedAsNumber,offerEventId,pendingEventId,multipleGrouped,[deletedAsNumber+eventType],title',
            settingss: 'key,value',
            cache: 'key,value',
        });
    
        // @ts-ignore: Property doesn't exists error
        let initTest = await db.settingss.get({key: "inited"});
    
        if (!initTest) {
    
        console.log("Initing stroage")
    
            // @ts-ignore: Property doesn't exists error
            await db.settingss.bulkPut([
                {key: "inited", value: true},
            ]);
    
        }
    
        // @ts-ignore: Property doesn't exists error
        window.db = db;

        resolve();

    })
    
};

export async function DestroyStorage() {

    return new Promise<void>(async function(resolve, reject) {
        // @ts-ignore
        await window.db.delete();
        resolve();
    })
    
};