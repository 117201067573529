import { Event } from './Event'
import { UserFile } from './User';

export interface Offer {
    id?: string,
    userId?: string,
    $id?: string,
    events?: Event[],
    confirmation?: string,
    response?: string,
    addedToTimeline?: boolean,
    withdrawnAcknowledged?: boolean,
    title?: string,
    details?: string,
    createdAt?: string,
    withdrawn?: boolean,
    complete?: boolean,
    locationPlaceId?: string,
    locationPlaceName?: string,
    respondedOn?: string,
    address?: FullAddress;
    creator?: OfferCreator;
    pendingEvents?: Event[];
    parentOfferEvents?: Event[];
    parentOfferId?: string;
    cancelled?: boolean;
    autoInsert: null | false | true;
    externalHirerId?: string;
    ratings: RatingRecord[];
    locationFriendlyName?: string;
    locationFriendlyAddress?: string;
    respondByDeadline? : string;
    autoDeclined?: boolean;
    order?: number; // where it should display on the order list
    organisationUrl?: string;
    withdrawnReason?: string;
    files: UserFile[];
    metadata?: {
        createdByFullName: string;
        createdByOrganisationId: string;
        createdByOrganisationName: string;
        createdByCompanyId: string;
        createdByCompanyName: string;
        createdByIsVerified: boolean;
        createdByOrganisationIsHirer: boolean;
        createdByOrganisationIsAgency: boolean;
        onBehalfOfExternalExternalWebsiteUrl: string;
        createdAt: string;
        createdById: string;
        createdByReporeted: boolean;
    },
    financial?: {
        currencyCode: string;
        totalGrossPay: number;
    },
    totalGrossPay?: number;
    currencyCode?: string;
};

export interface RatingRecord {
    recipientExternalOrgName?: string;
    recipientExternalOrgDomain?: string;
    recipientExternalContactName? : string;
    id?: number,
    userId?: string,
    recipientContactId: string,
    ratingDirection: RatingDirection,
    stars: number,
    publicComment: string,
    privateComment: string,
    providerOrgId: string,
    providerOrgName?: string,
    recipientExternalId: string,
    recipientOrgId: string,
    recipientInternalContactId: string
    raterContactId: string,
    raterInternalContactId: string,
    raterOrgId: string,
    raterExternalId: string,
    ratingType: number,
    updatedAt: string,
    published: boolean,
    recipientExternalOrgId: string,
    raterUserId?: string,
    raterFirstName?: string,
    raterLastName?: string,
    raterOrganisationName?: string,
    recipientUserId?: string,
    recipientFirstName?: string,
    recipientLastName?: string,
    recipientOrganisationName?: string,
    raterExternalOrgName?: string,
    raterExternalOrgDomain?: string,
    recipientApproved?: boolean,
    recipientHidden?: boolean,
    recipientUserReported?: boolean;
    raterUserReported?: boolean;
}

export function getDummyRatingRating(): RatingRecord {
    return {
        recipientContactId: "",
        ratingDirection: 1,
        stars: 0,
        publicComment: "",
        privateComment: "",
        providerOrgId: "",
        recipientExternalId: "",
        recipientOrgId: "",
        recipientInternalContactId: "",
        raterContactId: "",
        raterInternalContactId: "",
        raterOrgId: "",
        raterExternalId: "",
        ratingType: 1,
        updatedAt: "",
        published: true,
        recipientExternalOrgId: ""
    } as RatingRecord
}

export enum RatingDirection {
    Worker_HirersOrganisation = 1,
    Worker_OrganisationsHirer = 2,
    Worker_Agency = 3,
    Worker_AgencysConsultant = 4,
    HirersOrganisation_Worker = 5,
    HirersOrganisation_Agency = 6,
    HirersOrganisation_AgencysConsultant = 7,
    OrganisationsHirer_Worker = 8,
    OrganisationsHirer_Agency = 9,
    OrganisationsHirer_AgencysConsultant = 10,
    Agency_HirersOrganisation = 11,
    Agency_Worker = 12,
    Agency_OrganisationsHirer = 13,
    AgencysConsultant_HirersOrganisation = 14,
    AgencysConsultant_Worker = 15,
    AgencysConsultant_OrganisationsHirer = 16
}

export interface DirectOffer extends Offer {
    pendingEvents?: DirectOfferEvent[];
}

interface DirectOfferEvent {
    addedToSchedule: boolean;
    end: string;
    id: string;
    recipientUserId: string;
    start: string;
    title: string;
}

interface OfferCreator {
    userId: string;
    contactId: string;
    firstName: string;
    lastName: string;
    organisation?: {
        id: string;
        verified: boolean;
        name: string;
        isAgency?: boolean;
    }
}

export interface FullAddress {
    address1: string;
    address2?: string;
    address3?: string;
    town: string;
    county: string;
    postcode: string;
}