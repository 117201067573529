import { IonBackButton, IonButton, IonButtons, IonChip, IonContent, IonDatetime, IonFab, IonFabButton, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import { checkmarkCircle, close, closeCircle, create, infinite, notificationsCircle, notificationsOff, searchCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import NotificationAPI from '../apis/Notification';
// import '../styles/Notifications.scss';
// import '../styles/TabHeader.scss';
import { Contact } from '../types/Contact';
import { NotificationListCombined } from '../types/NotificationList';
import ContactListItem from './Components/Contacts/ContactListItem';
import OfferListItem from './Components/Notification/OfferListItem';
import { useSelector } from 'react-redux';
import { updateOutstandingNotifications } from '../state/actions/Notifications';
import store from '../state/store';
import Utilities from '../Utilities';
import InternalTracker from '../InternalTracker';
import { CircularProgress } from '@material-ui/core';
import Configuration from '../Configuration';
import ContactAPI from '../apis/ContactAPI';
import UserApi from '../apis/User';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  notificationsT?: NotificationListCombined
}

const Notifications: React.FC<Props> = ({ notificationsT }) => {

    const [notifications, setNotifications] = useState <NotificationListCombined> (null) // List of notifications
    const [loading, setLoading] = useState <boolean> (true) // Full screen loading
    const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
    const [errorToast, setErrorToast] = useState <string> (null) // Error toast message
    // const [openOffer, setOpenOffer] = useState <Offer> (null) // Offer that is opened for editing
    const [existingNotifications, setExistingNotifications] = useState <string[]> ([]) // Notifications that has already been shown
    const [highlightNew, setHighlightNew] = useState <number> (0) // How many new notifications are highlighted
    const [noNewNotifications, setNoNewNotifications] = useState <boolean> (null) // Whether there is any new notifications since last refresh
    const [canHavePushAndNotEnabled, setCanHavePushAndNotEnabled] = useState <boolean> (false) // Whether the notificaitons are off and can enable
    const [searchModal, setSearchModal] = useState <boolean> (null) // Whether the search modal is open
    const [searchKeywords, setSearchKeywords] = useState <string> ("") // Search keywords
    const [searchTypes, setSearchTypes] = useState <string[]> ([]) // Search types
    const [searchStartDate, setSearchStartDate] = useState <string> ("") // Start Date
    const [searchEndDate, setSearchEndDate] = useState <string> ("") // End Date
    const [isSearchLoading, setIsSearchLoading] = useState <boolean> (false) // Whether offer search is pending
    const [isShowingSearchResults, setIsShowingSearchResults] = useState <boolean> (false) // Whether we are showing search results
    const [selectedPastDayPreset, setSelectedPastDayPreset] = useState <number> (30)

    const contentRef = useRef(null);
    const history = useHistory();
    // @ts-ignore
    const notificationTabCount = useSelector(state => state.notifications)
  
    useEffect(() => {

      if (notificationsT) {
        setNotifications(notificationsT)
      } else if (window.location.href.indexOf("testing=true") !== -1) {
        reloadNotifications();
      } else {
        reloadNotifications();
      }

      (window as any).isShowingSearchResults = false;
      setSearchStartDate(Utilities.formatDate(new Date(Utilities.dateSub(new Date(), "month", 1)), "YYYY-MM-DD"));
      setSearchEndDate(Utilities.formatDate(new Date(Utilities.dateAdd(new Date(), "month", 1)), "YYYY-MM-DD"));

      (window as any).reloadNotificationOnPage = function() {
        reloadNotifications();
      }.bind(this);

      history.listen(e => {
        if (e.pathname === "/notifications") {
          if (!(window as any).isShowingSearchResults)
            reloadNotifications();
        }
      })

    }, [])

    const loadCachedNotifications = async function() {
      if (localStorage.getItem("notifications")) {
        setNotifications(JSON.parse(localStorage.getItem("notifications") || ""));
      }
    }

    const openOffer = async function(id: string, direct?: boolean) {
      const randomHash = Utilities.randomStr(12);
      history.push("/" + (direct ? "directoffer" : "offer") + "/" + id + "/" + randomHash + (window.location.href.indexOf("testing=true") !== -1 ? "?testing=true" : ""))
    }

    const searchOffers = async function(keywords: string, start: string, end: string) {

      setIsSearchLoading(true);
      setNotifications({});

      const startQ = new Date(start) < new Date(end) ? start : end
      const endQ = new Date(end) < new Date(start) ? start : end

      InternalTracker.trackEvent("Offer Search", {
        keywords: keywords.toLowerCase().trim() || "",
        start: startQ,
        end: endQ,
        types: searchTypes
      })

      NotificationAPI.search(keywords.toLowerCase().trim() || "", startQ, endQ, searchTypes).then(data => {
        let notifications: NotificationListCombined = data;
        setIsSearchLoading(false);
        setNotifications(notifications);
      }).catch(e => {
        console.log(e);
        (window as any).toast("Failed to search in past notifications", "error")
        setIsSearchLoading(false);
      })

    }

    const reloadNotifications = async function(success?: string, error?: string) {
      loadCachedNotifications();

      store.dispatch(updateOutstandingNotifications([]));

      let enabledNotifs = localStorage.getItem("notificationToken") && localStorage.getItem("notificationToken") !== "skipped";
      let canHaveNotifs = (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") ));

      setCanHavePushAndNotEnabled(!enabledNotifs && canHaveNotifs);

      setLoading(true);
      setHighlightNew(0);
      setNoNewNotifications(true);

      let existingNotifications: string[] = localStorage.getItem("existingNotifications") ? JSON.parse(localStorage.getItem("existingNotifications")) : []
      setExistingNotifications(existingNotifications);

      if (success) {
        setSuccessToast(success);
      } else if (error) {
        setErrorToast(error)
      }

      Utilities.getNotifications().then(data => {
        let notifications: NotificationListCombined = data;
        setLoading(false)
        setNotifications(notifications);

        let allOutstandingNotifications = [];
        if (notifications.offersConfirmed) { for (let i = 0; i < notifications.offersConfirmed.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersConfirmed[i])) } }
        if (notifications.offersRejected) { for (let i = 0; i < notifications.offersRejected.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersRejected[i])) } }
        if (notifications.offersPending) { for (let i = 0; i < notifications.offersPending.length; i++) { notifications.offersPending = notifications.offersPending.map(item => { item.order = existingNotifications.find(sitem => typeof sitem.startsWith !== "undefined" && sitem.startsWith(item.id)) ? 2 : 1; return item; }).sort(Utilities.dynamicSort("order")); allOutstandingNotifications.push(Utilities.offerHash(notifications.offersPending[i])) } }
        if (notifications.events) { for (let i = 0; i < notifications.events.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.events[i])) } }
        if (notifications.offersWithdrawn) { for (let i = 0; i < notifications.offersWithdrawn.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersWithdrawn[i])) } }
        if (notifications.offersApplied) { for (let i = 0; i < notifications.offersApplied.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersApplied[i])) } }
        if (notifications.offerUpdates) { for (let i = 0; i < notifications.offerUpdates.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offerUpdates[i])) } }
        if (notifications.shareRequests) { for (let i = 0; i < notifications.shareRequests.length; i++) { allOutstandingNotifications.push(notifications.shareRequests[i].id) } }
        if (notifications.referrals) { for (let i = 0; i < notifications.referrals.length; i++) { allOutstandingNotifications.push(notifications.referrals[i].id) } }
        if (notifications.offersConfirmedWorkerCancelled) { for (let i = 0; i < notifications.offersConfirmedWorkerCancelled.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersConfirmedWorkerCancelled[i])) } }
        const intersect = allOutstandingNotifications.filter(value => existingNotifications.includes(value));
        localStorage.setItem("existingNotifications", JSON.stringify(allOutstandingNotifications.filter(item => item)));
        localStorage.setItem("notifications", JSON.stringify(notifications));
        
        // Outstanding notifications are same as last time
        if (allOutstandingNotifications.length === intersect.length) {
          setHighlightNew(0); // so show all notifications
          setNoNewNotifications(true) // so show no new notifications at top
        } else {
          setHighlightNew(allOutstandingNotifications.length - existingNotifications.length < 0 ? 0 : allOutstandingNotifications.length - existingNotifications.length) // so show only new notifications unless filters are off // TODO THIS IS PROBS NOT GOOD
          setNoNewNotifications(false) // so don't show no new notifications at top
        }
      }).catch(e => {
        (window as any).toast("Failed to load notifications", "error")
        setLoading(false)
      })

    }

    return (
    <IonPage data-page="notifications" ref={contentRef}>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{ (isShowingSearchResults) ? "Notifications (Search Results)" : "Notifications" }</IonTitle>
          <div className="nonblocking-loading-wrapper" style={(loading) ? { display: 'flex' } : { display: 'none' }}>
            <CircularProgress />
            Fetching Latest
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent id="notifications-wrapper" style={{ backgroundColor: 'white' }}>
        <div className="notifications-list" style={{ backgroundColor: 'white' }}>
          { (canHavePushAndNotEnabled && !isShowingSearchResults) && <div className="top-banner warning" onClick={() => { history.push("/settings") }}>
            <IonIcon icon={notificationsOff} />
            <span>Enable Notifications for new Offers & Offer Updates</span>
          </div> }
          { (!isShowingSearchResults && highlightNew) ? (
            <div className="top-banner">
              <IonIcon icon={checkmarkCircle} />
              <span>You received {highlightNew} new notifications while you were away</span>
            </div>
          ) : null }
          { (isShowingSearchResults) &&
            <div 
              className="top-banner info"
              onClick={() => {
                setSearchModal(true)
              }}
            >
              <IonIcon icon={searchCircle} />
              <span>Showing search results{ (searchKeywords) ? " matching " + searchKeywords + " " : "" } between {Utilities.formatDate(new Date(searchStartDate), "d mms, YYYY")} and {Utilities.formatDate(new Date(searchEndDate), "d mms, YYYY")}</span>
            </div>
          }
          { (!isShowingSearchResults) &&
            <div 
              className="top-banner info"
              onClick={() => {
                setSearchModal(true)
              }}
            >
              <IonIcon icon={searchCircle} />
              <span>Search notifications</span>
            </div>
          }
          { (notifications && notifications.fileRequests && notifications.fileRequests.length !== 0) &&
            <div className="group">
              <h2>File Requests</h2>
              { notifications.fileRequests.map(item => {
                return (
                  <div>
                    <div className='contact-item'>
                      <div className="details">
                          <div className="picture">
                              <img src={UserApi.getProfilePicture(item.userId)} />
                          </div>
                          <div className="contact-details">
                              <h2>{item.userName} requested access to a private file ({item.fileName})</h2>
                          </div>
                      </div>
                      <div className='options'>
                         <button className="accept-btn" onClick={() => {
                          InternalTracker.trackEvent("File Share Accept", {
                            fileId: item.fileId,
                            userId: item.userId,
                          })

                          ContactAPI
                            .shareFile(item.fileId, item.userId)
                            .then(d => {
                              (window as any).toast("File shared", "success");
                              reloadNotifications();
                            })
                            .catch(d => {
                              (window as any).toast("Unable to share file", "error");
                              reloadNotifications();
                            })
                         }}>Share File</button> 
                          <button className="reject-btn" onClick={() => {
                            InternalTracker.trackEvent("File Share Reject", {
                              fileId: item.fileId,
                              userId: item.userId,
                            })

                            ContactAPI
                            .rejectFileRequest(item.fileId, item.userId)
                            .then(d => {
                              (window as any).toast("File share rejected", "success");
                              reloadNotifications();
                            })
                            .catch(d => {
                              (window as any).toast("Unable to reject file share", "error");
                              reloadNotifications();
                            })
                          }}>Decline</button> 
                      </div>
                    </div>
                  </div>
                ) 
              }) }
            </div>
          }
          { (notifications && notifications.folderRequests && notifications.folderRequests.length !== 0) &&
            <div className="group">
              <h2>Folder Requests</h2>
              { notifications.folderRequests.map(item => {
                return (
                  <div>
                    <div className='contact-item'>
                      <div className="details">
                          <div className="picture">
                            <img src={UserApi.getProfilePicture(item.userId)} />
                          </div>
                          <div className="contact-details">
                              <h2>{item.userName} requested access to a private folder ({item.name})</h2>
                          </div>
                      </div>
                      <div className='options'>
                         <button className="accept-btn" onClick={() => {
                          InternalTracker.trackEvent("Folder Share Accept", {
                            fileId: item.folderId,
                            userId: item.userId,
                          })

                          ContactAPI
                            .shareFolder(item.folderId, item.userId)
                            .then(d => {
                              (window as any).toast("Folder shared", "success");
                              reloadNotifications();
                            })
                            .catch(d => {
                              (window as any).toast("Unable to share folder", "error");
                              reloadNotifications();
                            })
                         }}>Share Folder</button> 
                          <button className="reject-btn" onClick={() => {
                            InternalTracker.trackEvent("Folder Share Reject", {
                              fileId: item.folderId,
                              userId: item.userId,
                            })

                            ContactAPI
                            .rejectFileFolderRequest(item.folderId, item.userId)
                            .then(d => {
                              (window as any).toast("Folder share rejected", "success");
                              reloadNotifications();
                            })
                            .catch(d => {
                              (window as any).toast("Unable to reject folder share", "error");
                              reloadNotifications();
                            })
                          }}>Decline</button> 
                      </div>
                    </div>
                  </div>
                ) 
              }) }
            </div>
          }
          { (notifications && notifications.referrals && notifications.referrals.length !== 0) &&
            <div className="group">
              <h2>Incoming Referrals</h2>
              { notifications.referrals.map(item => {
                if (!item.id) return null;
                return ( <ContactListItem everFirst={false} key={item.id} list="REFERRAL" inviteId={item.id.toString()} onRejectComplete={(success, error) => { reloadNotifications(success, error) }} onAcceptComplete={(success, error) => { reloadNotifications(success, error) }} orgImageOverride={item.referredToContactEmail ? UserApi.getExtOrgPicture(item.referredToContactEmail.split("@")[1]) : null } contact={ { companyName: "", name: item.referringUserName + ' referred you to ' +  item.referredToContactFirstName, headline: "[" + Utilities.formatDate(new Date(item.createdAt), "YYYY-MM-DD") + "] " + (item.referredToContactOrgName || ""), userId: item.referringUserId, reported: item.referringUserReported || item.referredContactReported } as Contact}  /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.offerUpdates && notifications.offerUpdates.length !== 0) &&
            <div className="group">
              <h2>Altered Confirmed Offers</h2>
              { notifications.offerUpdates.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => typeof sitem.startsWith !== "undefined" && sitem.startsWith(item.id))} deletable={!isShowingSearchResults} key={item.id} type="PENDING" offer={item} onClick={() => { openOffer(item.id) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.events && notifications.events.length !== 0) &&
            <div className="group">
              <h2>Pending Direct Assignments</h2>
              { notifications.events.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => typeof sitem.startsWith !== "undefined" && sitem.startsWith(item.id))} key={item.id} type="PENDING_DIRECT" offer={item} onClick={() => { openOffer(item.id, true) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.offersPending && notifications.offersPending.length !== 0) &&
            <div className="group">
              <h2>New Offers</h2>
              { notifications.offersPending.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem showCountdown={true} showReply={true} justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => sitem === Utilities.offerHash(item))} deletable={!isShowingSearchResults} key={item.id} type="PENDING" offer={item} onClick={() => { openOffer(item.id) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.offersConfirmed && notifications.offersConfirmed.length !== 0) &&
            <div className="group">
              <h2>Offers Confirmed</h2>
              { notifications.offersConfirmed.map(item => {
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => sitem === Utilities.offerHash(item))} deletable={!isShowingSearchResults} key={item.id} type="CONFIRMED" offer={item} onClick={() => { openOffer(item.id) }} /> )
              }) }
            </div>
          }
          { (notifications && notifications.offersApplied && notifications.offersApplied.length !== 0) &&
            <div className="group">
              <h2>Offers Applied</h2>
              { notifications.offersApplied.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => typeof sitem.startsWith !== "undefined" && sitem.startsWith(item.id))} deletable={!isShowingSearchResults} key={item.id} type="APPLIED" offer={item} onClick={() => { openOffer(item.id) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.offersWithdrawn && notifications.offersWithdrawn.length !== 0) &&
            <div className="group">
              <h2>Offers Withdrawn</h2>
              { notifications.offersWithdrawn.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => sitem === Utilities.offerHash(item))} deletable={!isShowingSearchResults} key={item.id} type="WITHDRAWN" offer={item} onClick={() => { openOffer(item.id) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.offersConfirmedWorkerCancelled && notifications.offersConfirmedWorkerCancelled.length !== 0) &&
            <div className="group">
              <h2>Cancelled by You</h2>
              { notifications.offersConfirmedWorkerCancelled.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => typeof sitem.startsWith !== "undefined" && sitem.startsWith(item.id))} deletable={!isShowingSearchResults} key={item.id} type="WITHDRAWN" offer={item} onClick={() => { openOffer(item.id) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.offersRejected && notifications.offersRejected.length !== 0) &&
            <div className="group">
              <h2>Offers Declined</h2>
              { notifications.offersRejected.map(item => { 
                if (!item.id) return null;
                return ( <OfferListItem justReceived={!isShowingSearchResults && !existingNotifications.find(sitem => sitem === Utilities.offerHash(item))} deletable={!isShowingSearchResults} key={item.id} type="WITHDRAWN" offer={item} onClick={() => { openOffer(item.id) }} /> ) 
              }) }
            </div>
          }
          { (notifications && notifications.shareRequests && notifications.shareRequests.length !== 0) &&
            <div className="group">
              <h2>Share Requests</h2>
              { notifications.shareRequests.map(item => {
                if (!item.id) return null;
                return ( <ContactListItem organisationId={item.organisationId} isHirer={item.organisationIsHirer} isAgency={item.organisationIsAgency} everFirst={false} key={item.id} list="INCOMING" inviteId={item.id} onRejectComplete={(success, error) => { reloadNotifications(success, error) }} onAcceptComplete={(success, error) => { reloadNotifications(success, error) }} contact={ { companyName: "", name: item.name, headline: "from " + item.organisationName, email: "", userId: item.sendingUserId, reported: item.sendingUserReported } as Contact}  /> ) 
              }) }
            </div>
          }
          { (
            notifications && 
            notifications.offersWithdrawn && notifications.offersWithdrawn.length === 0 && 
            notifications.offersApplied && notifications.offersApplied.length === 0 && 
            notifications.offerUpdates && notifications.offerUpdates.length === 0 && 
            notifications.shareRequests && notifications.shareRequests.length === 0 && 
            notifications.offersConfirmed && notifications.offersConfirmed.length === 0 && 
            notifications.offersPending && notifications.offersPending.length === 0 && 
            notifications.offersConfirmedWorkerCancelled && notifications.offersConfirmedWorkerCancelled.length === 0 &&
            notifications.events && notifications.events.length === 0 &&
            notifications.offersRejected && notifications.offersRejected.length === 0 &&
            notifications.fileRequests && notifications.fileRequests.length === 0 &&
            notifications.folderRequests && notifications.folderRequests.length === 0 &&
            notifications.referrals && notifications.referrals.length === 0
          ) &&
            <div className="no-new-notifications">
                <div>
                    <IonIcon icon={notificationsCircle} />
                    <h2>{ (isShowingSearchResults) ? "No matching offers" : "No current notifications" }</h2>
                </div>
            </div>
          }
        </div>
        <IonToast isOpen={successToast !== null} color="success" onDidDismiss={() => setSuccessToast(null)} message={successToast} position="top" duration={2500} buttons={[ { text: 'Hide', role: 'cancel' } ]}/>
        <IonToast isOpen={errorToast !== null} color="danger" onDidDismiss={() => setErrorToast(null)} message={errorToast} position="top" duration={2500} buttons={[ { text: 'Hide', role: 'cancel' } ]} />
        { (isShowingSearchResults) &&
          <IonFab vertical="bottom" horizontal="start" slot="fixed" style={{ width: "calc(100% - 20px)", display: "flex", justifyContent: "center" }}>
            <IonFabButton 
              onClick={() => {
                (window as any).isShowingSearchResults = false
                setIsShowingSearchResults(false);
                reloadNotifications();

                InternalTracker.trackEvent("Offer Search Cleared");
              }}
              style={{
                "width": 140,
                "--border-radius": '52px',
                "--background": "#FB5B5A"
              }}
            >
              <IonIcon 
                icon={closeCircle} 
                style={{
                  marginRight: 8
                }}
              />
              Clear Filters
            </IonFabButton>
            <IonFabButton 
              onClick={() => {
                setSearchModal(true)
              }}
              style={{
                "width": 140,
                "--border-radius": '52px',
                marginLeft: 20
              }}
            >
              <IonIcon 
                icon={create} 
                style={{
                  marginRight: 8
                }}
              />
              Adjust Filters
            </IonFabButton>
          </IonFab>
        }
      </IonContent>

      <IonModal
        isOpen={searchModal !== null}
        canDismiss={searchModal === null}
        presentingElement={(contentRef && contentRef.current) ? contentRef.current : null}
        onDidDismiss={() => { 
          setSearchModal(null);
        }}
        data-modal="offer-search"
        mode="ios"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Search Notifications</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => {
                setSearchModal(null);
              }}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonInput 
                placeholder="Search"
                value={searchKeywords}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    searchOffers(searchKeywords, searchStartDate, searchEndDate);
                    setIsShowingSearchResults(true);
                    (window as any).isShowingSearchResults = true;
                    setSearchModal(null);
                  }
                }}
                onIonInput={(e) => { 
                  setSearchKeywords((e.target as HTMLIonInputElement).value.toString())
                }}
              ></IonInput>
            </IonItem>
            <div className="quick-past-date">
              <label>Past: </label>
              <div>
                { [30, 60, 90, 120].map(days => {
                  return (
                    <span 
                      data-selected={selectedPastDayPreset === days}
                      onClick={() => {
                        setSearchStartDate(Utilities.formatDate(Utilities.dateSub(new Date(), "day", days), "YYYY-MM-DD"));
                        setTimeout(() => {
                          setSelectedPastDayPreset(days);
                        }, 100)
                      }}
                    >
                      {days} days
                    </span>
                  )
                }) }
              </div>
            </div>
            <IonItem>
              <IonLabel>Start Date</IonLabel>
              <IonInput 
                value={searchStartDate}
                type="date"
                placeholder="Select Date"
                onIonChange={(e) => { 
                  setSearchStartDate(Utilities.formatDate(new Date((e.target as HTMLIonInputElement).value), "YYYY-MM-DD"))
                  setSelectedPastDayPreset(0);
                }}
              >
              </IonInput>
            </IonItem>
            <IonItem>
              <IonLabel>End Date</IonLabel>
              <IonInput 
                value={searchEndDate}
                type="date"
                placeholder="Select Date"
                onIonChange={(e) => { 
                  setSearchEndDate(Utilities.formatDate(new Date((e.target as HTMLIonInputElement).value), "YYYY-MM-DD"))
                }}
              >
              </IonInput>
            </IonItem>

            <div className="notification-types">
              { [
                {
                  label: "Show all Types",
                  id: "all",
                  color: "#333"
                },
                {
                  label: "New Offers",
                  id: "offersPending",
                  color: "#3573E6"
                },
                {
                  label: "Altered Confirmed Offers",
                  id: "offerUpdates",
                  color: "#3573E6"
                },
                {
                  label: "Offers Confirmed",
                  id: "offersConfirmed",
                  color: "#50D890"
                },
                {
                  label: "Offers Applied",
                  id: "offersApplied",
                  color: "#3573E6"
                },
                {
                  label: "Offers Withdrawn",
                  id: "offersWithdrawn",
                  color: "#FB5B5A"
                },
                {
                  label: "Cancelled by You",
                  id: "offersConfirmedWorkerCancelled",
                  color: "#FFA400"
                },
                {
                  label: "Offers Declined",
                  id: "offersRejected",
                  color: "#FFA400"
                },
              ].map(type => {
                let pos = searchTypes.indexOf(type.id);
                if (searchTypes.length === 0 && type.id === "all") {
                  pos = 1;
                }
                return (
                  <span 
                    key={type.id}
                    style={{
                      'color': pos !== -1 ? 'white' : type.color,
                      'background': pos !== -1 ? type.color : 'white',
                      border: pos !== -1 ? '1px solid transparent' : '1px solid ' +  type.color
                    }}
                    onClick={() => {
                      if (type.id === "all") {
                        if (searchTypes.length !== 0) {
                          setSearchTypes([]);
                          return;
                        }
                      }
                      let searchTypesC = JSON.parse(JSON.stringify(searchTypes));
                      let pos = searchTypesC.indexOf(type.id);
                      if (pos !== -1) {
                        searchTypesC.splice(pos, 1);
                      } else {
                        searchTypesC.push(type.id);
                      }
                      setSearchTypes(searchTypesC)
                    }}
                  >
                    {type.label}
                  </span>
                )
              }) }
            </div>
            <IonButton
              expand="block" 
              onClick={() => {
                searchOffers(searchKeywords, searchStartDate, searchEndDate)
                setIsShowingSearchResults(true);
                (window as any).isShowingSearchResults = true;
                setSearchModal(null);
              }}
              style={{
                margin: 15
              }}
              >
                Search
              </IonButton>
          </IonList>
        </IonContent>
      </IonModal>

      { (isSearchLoading && !notificationsT) &&
        <IonLoading
          isOpen={true}
          onDidDismiss={() => {}}
          message="Searching Notifications"
          duration={12000}
        />
      }

    </IonPage>
  );
};

export default Notifications;
