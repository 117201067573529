import { Event } from '../types/Event'
import { User } from '../types/User';
import Utilities from '../Utilities';

const DEFAULT_EVENT:Event = {
    "deleted": false,
    "updatedAt": "",
    "createdAt": "",
    "version": "",
    "id": "",
    "orderingUpdatedDate": "",
    "groupId": "",
    "pinned": false,
    "notes": "",
    "googlePlacesId": "",
    "repeatType": 0,
    "isSynced": false,
    "repeatForever": false,
    "repeatUntil": "",
    "end": "",
    "start": "",
    "eventType": 4,
    "title": "",
    "userId": ""
}

const DEFAULT_PINNED_EVENTS = [
    {
        id: 'Events_DefaultPinned_1',
        startHour: 8,
        endHour: 17,
        startMinute: 0,
        endMinute: 0,
        type: 1,
        title: "Working - I'm unavailable"
    },
    {
        id: 'Events_DefaultPinned_2',
        startHour: 8,
        endHour: 17,
        startMinute: 0,
        endMinute: 0,
        type: 2,
        title: "Private - I'm unavailable"
    },
    {
        id: 'Events_DefaultPinned_3',
        startHour: 8,
        endHour: 17,
        startMinute: 0,
        endMinute: 0,
        type: 3,
        title: "Private - I'm unavailable but offers considered"
    },
    {
        id: 'Events_DefaultPinned_4',
        startHour: 8,
        endHour: 17,
        startMinute: 0,
        endMinute: 0,
        type: 4,
        title: "I am available"
    },
]

export async function getInferredUnavailabilityDates() {
    // @ts-ignore
    if (!window.db) return [];
    // @ts-ignore
    let events = await window.db.events.where({
        deletedAsNumber: 0,
        eventType: 4
    }).toArray();
    let ranges = events.map(event => {
        let endDate = null;
        if (event.repeatType === 0 || !event.repeatUntil) {
            console.log(event.id + "-" + event.start + "-" + new Date(event.start))
            endDate = new Date(event.start);
        } else if (event.repeatForever) {
            endDate = new Date("9999-12-31");
        } else {
            endDate = new Date(event.repeatUntil);
        }
        return {
            start: new Date(event.createdAt),
            end: new Date(endDate),
            id: event.id,
            title: event.title
        }
    })
    console.log("unsorted ranges: ", ranges)
    ranges.sort((a, b) => a.start > b.start ? 1 : -1)
    ranges = ranges.filter(range => range.start < range.end);
    console.log("sorted and filtered ranges: ", ranges)
    return ranges;
}

export async function getAllEvents() {
    // @ts-ignore
    if (!window.db) return [];
    // @ts-ignore
    let events = await window.db.events.where("deletedAsNumber").equals(0).toArray();
    return events;
};

export async function getEventsByGroupId(groupId: string) {
    // @ts-ignore
    if (!window.db) return [];
    // @ts-ignore
    let events = await window.db.events.where({"deletedAsNumber": 0, "groupId": groupId}).toArray();
    if (events) {
        events = events.sort((a, b) => new Date(a.start) > new Date(b.start) ? 1 : -1)
    }
    return events;
};

export async function getSuggestions(keywords) {
    // @ts-ignore
    if (!window.db) return [];
    let hashes = [];
    // @ts-ignore
    let events = await window.db.events.where("title").startsWithIgnoreCase(keywords).toArray();
    for (let i = 0; i < events.length; i++) {
        const hash = events[i].title + "_" + events[i].startAsDate.getHours() + "_" + events[i].startAsDate.getHours() + "_" + events[i].startAsDate.getHours() + "_" + events[i].startAsDate.getHours();
        if (hashes.indexOf(hash) !== -1) {
            events.splice(i, 1);
            i--;
        } else {
            hashes.push(hash)
        }
    }
    return events;
}

export async function getEventById(id: string): Promise<Event> {
    // @ts-ignore
    let event = await window.db.events.get(id);
    if (!event) {
        // Loading default pinned event
        event = DEFAULT_PINNED_EVENTS.find(item => item.id === id);
        if (event) {
            event.eventType = event.type;
            let now = new Date();
            event.start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), event.startHour, event.startMinute).toISOString();
            event.end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), event.endHour, event.endMinute).toISOString();
            event.notes = "";
        }
    }
    return event;
};

export async function getLatestAvailableEventDate(): Promise<Date> {
    // @ts-ignore
    let events = window.db ? await window.db.events.where({"deletedAsNumber": 0, "eventType": 4}).toArray() : [];
    let lastAvailableDate = null;
    let lastAvailableId = null
    const limit: Date = Utilities.dateAdd(new Date(), "year", 5);
    for (let i = events.length -1; i > -1; i--) {
        if (events[i].repeatForever) {
            return limit
        }
    }
    for (let i = events.length -1; i > -1; i--) {
        let dateStart: Date = new Date(events[i].start);
        if (lastAvailableDate === null || lastAvailableDate < dateStart) {
            lastAvailableDate = dateStart;
            lastAvailableId = events[i].id;
        }
        if (events[i].repeatType) {
            let repeatUntil =  (events[i].repeatUntil) ? Utilities.dateAdd(new Date(events[i].repeatUntil), "day", 1) : null;
            while (dateStart < limit && (!events[i].repeatUntil || (events[i].repeatUntil && dateStart < repeatUntil))) {
                console.log(dateStart + " < " + repeatUntil)
                switch (events[i].repeatType) {
                    case 1:
                    case 2:
                        dateStart = Utilities.dateAdd(dateStart, "day", 1);
                        break;
                    case 3:
                        dateStart = Utilities.dateAdd(dateStart, "week", 1);
                        break;
                    case 4:
                        dateStart = Utilities.dateAdd(dateStart, "month", 1);
                        break;
                }
                if (lastAvailableDate === null || lastAvailableDate < dateStart && dateStart < repeatUntil) {
                    lastAvailableDate = dateStart;
                    lastAvailableId = events[i].id;
                }
            }
        }
    }
    console.log("LAST AVAILABLE: ", lastAvailableDate, lastAvailableId);
    return lastAvailableDate;
};

export async function hasUndeletedEvents(): Promise<boolean> { 
    // @ts-ignore
    let events = window.db ? await window.db.events.where({"deletedAsNumber": 0}).limit(1).toArray() : [];
    return (!events || events.length === 0) ? false : true;
};

export async function getLastEventDate(): Promise<Date> { 
    if (!localStorage.getItem("lastEventStartDate")) {
        // @ts-ignore
        let events = window.db ? await window.db.events.orderBy("startAsDate").limit(100000).toArray() : [];
        for (let i = events.length -1; i > -1; i--) {
            if (!events[i].deleted) {
                localStorage.setItem("lastEventStartDate", events[i].startAsDate.toISOString());
                return events[i].startAsDate;
            }
        }
        return null;
    } else {
        return new Date(localStorage.getItem("lastEventStartDate"));
    }
};

export async function getLastSharedDate(): Promise<Date> { 
    // @ts-ignore
    let events = window.db ? await window.db.events.orderBy("updatedAtAsDate").reverse().limit(1).toArray() : [];
    return events.length !== 0 ? events[0].updatedAtAsDate : null
};

export async function getPinnedEvents(): Promise<Event[]> {

    let user: User = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    let hashes = [];

    // @ts-ignore
    let events = await window.db.events.orderBy("updatedAtAsDate").toArray();
    for (let i = 0; i < events.length; i++) {
        const event: Event = events[i];
        if (event.pinned) {
            const hash: string = 
                event.startAsDate.getHours() + " " + 
                event.startAsDate.getMinutes() + " " + 
                event.endAsDate.getMinutes() + " " + 
                event.endAsDate.getMinutes() + " " + 
                event.eventType + " " + 
                event.title + " " + 
                event.googlePlacesId + " ";
            if (hashes.indexOf(hash) === -1) {
                hashes.push(hash);
                events[i].repeatType = 0;
                events[i].repeatUntil = null;
                events[i].repeatForever = null;
            } else {
                events.splice(i, 1);
                i--;
            }
        } else {
            events.splice(i, 1);
            i--;
        }
    }

    for (let i = 0; i < DEFAULT_PINNED_EVENTS.length; i++) {
        const defaultEvent = DEFAULT_PINNED_EVENTS[i];
        let event: Event = JSON.parse(JSON.stringify(DEFAULT_EVENT));
        if (user && defaultEvent.id && !user[(defaultEvent.id.replace("Events", "events") + "_Dismissed")]) {
            let now = new Date();
            event.start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), defaultEvent.startHour, defaultEvent.startMinute).toISOString();
            event.end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), defaultEvent.endHour, defaultEvent.endMinute).toISOString();
            event.eventType = defaultEvent.type;
            event.title = defaultEvent.title;
            event.id = defaultEvent.id;
            events.push(event)
        }
    }

    return events.reverse();
    
};

export async function getRecentUniqueEvents(): Promise<Event[]> {
    let hashes = [];
    // @ts-ignore
    let events = await window.db.events.orderBy("updatedAtAsDate").toArray();
    for (let i = 0; i < events.length; i++) {
        const event: Event = events[i];
        if (event.title !== "Working - I'm unavailable" && event.title !== "Private - I'm unavailable" && event.title !== "Private - I'm unavailable but offers considered" && event.title !== "I am available") {
            const hash: string = 
                event.startAsDate.getHours() + " " + 
                event.startAsDate.getMinutes() + " " + 
                event.endAsDate.getMinutes() + " " + 
                event.endAsDate.getMinutes() + " " + 
                event.eventType + " " + 
                event.title + " " + 
                event.googlePlacesId + " ";
            if (hashes.indexOf(hash) === -1) {
                hashes.push(hash);
                events[i].repeatType = 0;
                events[i].repeatUntil = null;
                events[i].repeatForever = null;
            } else {
                events.splice(i, 1);
                i--;
            }
        } else {
            events.splice(i, 1);
            i--;
        }
    }
    return events.reverse();
};

export async function getEventByBetween(start: Date, end: Date): Promise<Event[]> {
    // @ts-ignore
    let events = await window.db.events.where("startAsDate").between(start, end, true, true).or("endAsDate").between(start, end, true, true).toArray();
    return events;
};

export async function getUnsyncedEvents() {
    // @ts-ignore
    if (!window.db) return [];
    // @ts-ignore
    let events = await window.db.events.where("isSyncedAsNumber").equals(0).toArray();
    return events;
}

export async function getEventsByOfferId(id: string) {
    // @ts-ignore
    if (!window.db) return [];
    // @ts-ignore
    let events = await window.db.events.where("groupId").equals(id).toArray();
    return events.filter(item => !item.deleted);
}

export async function markAsSynced(eventId: string): Promise<void> {

    // @ts-ignore
    let existingEvent = await window.db.events.get(eventId);

    // Make dates queryable for clash detection

    if (existingEvent) {
        existingEvent.isSynced = true;
        existingEvent.isSyncedAsNumber = 1;
        existingEvent.isNew = false;
        // @ts-ignore
        window.db.events.update(eventId, existingEvent)
    }

};

export async function saveEvents(events) {
    events = events.map(event => {
        event.startAsDate = new Date(event.start);
        event.endAsDate = new Date(event.end);
        event.deletedAsNumber = event.deleted ? 1 : 0
        event.updatedAtAsDate = new Date(event.updatedAt)
        event.pinnedAsNumber = event.pinned ? 1 : 0;
        event.isSynced = false;
        event.isSyncedAsNumber = 0;
        event.isSynced = true;
        event.isSyncedAsNumber = 1;
        event.isNew = false;
        event.repeatType = event.repeatTypeId;
        event.eventType = event.eventTypeId;
        event.googlePlacesId = event.googlePlacesId || "";
        return event;
    })
    // @ts-ignore
    await window.db.events.bulkPut(events);
}

export async function saveEvent(event: Event, fromSync?: boolean) {

    console.log("@@@@@@@", JSON.parse(JSON.stringify(event)));

    // @ts-ignore
    let existingEvent = await window.db.events.get(event.id);

    // Make dates queryable for clash detection
    event.startAsDate = new Date(event.start);
    event.endAsDate = new Date(event.end);
    event.deletedAsNumber = event.deleted ? 1 : 0
    event.updatedAtAsDate = new Date(event.updatedAt)
    event.pinnedAsNumber = event.pinned ? 1 : 0;
    event.isSynced = false;
    event.isSyncedAsNumber = 0;
    // event.hasCollisions = true;

    if (fromSync) {
        event.isSynced = true;
        event.isSyncedAsNumber = 1;
        event.isNew = false;
        // if (typeof event.eventType === "string") ? 
        event.repeatType = event.repeatTypeId;
        event.eventType = event.eventTypeId;
    }

    if (!event.createdAt) {
        event.createdAt = new Date().toISOString();
    }

    let id = null;

    if (existingEvent) {
        if (existingEvent.isNew && !fromSync) {
            event.isNew = true;
        }
        console.log("SAVING ", event)
        // @ts-ignore
        id = await window.db.events.update(event.id, event)
    } else {
        if (!fromSync) {
            event.isNew = true;
        }
        console.log("SAVING ", event)
        // @ts-ignore
        id = await db.events.put(event);
    }

    if (localStorage.getItem("lastEventStartDate")) {
        if (event.deleted) {
            localStorage.removeItem("lastEventStartDate");
        } else if (new Date(localStorage.getItem("lastEventStartDate")) < event.startAsDate) {
            localStorage.setItem("lastEventStartDate", event.startAsDate.toISOString());
        }
    }

    return id;

};

export async function getTotalEventsCount() {
    // @ts-ignore
    return window.db ? await window.db.events.count() : null;
}