import { RatingRecord } from "./Offer";

export interface User {
    organisationName?: string;
    organisationId?: string;
    id?: string,
    firstName?: string,
    lastName?: string,
    sectorId?: number,
    profileUpdateAt?: string,
    profileImageUpdateAt?: string,
    email?: string
    postCode?: string,
    canSync?: boolean,
    sectors?: string,
    rating?: number,
    postalCode?: string,
    timePresets?: string[],
    maxDistance?: number,
    maxDistanceSet?: boolean,
    allowRecording?: boolean,
    verified?: boolean,
    allowDirectEngagement?: boolean,
    allowAgencyEngagement?: boolean,
    eventColors?: string;
    userSetProfileImage?: boolean;
    industries?: Sector[];
    headline?: string;
    lastVersion?: string;
    settings?: UserSetting[];
    createdAt?: string;
    availabilityLastConfirmed?: string,
    lastTimelineUpdateAt?: string
    events_DefaultPinned_1_Dismissed?: string;
    events_DefaultPinned_2_Dismissed?: string;
    events_DefaultPinned_3_Dismissed?: string;
    events_DefaultPinned_4_Dismissed?: string;
    qualifications?: UserQualification[];
    ratings?: RatingRecord[];
    avgRating?: number;
    averageOfferResponseSeconds?: number;
    raterDetails?: RaterDetail[];
    appVersion?: string;
    phoneNumberVerified?: boolean;
    phoneNumber?: string;
    smsNotifications?: boolean;
    files?: UserFile[];
    folders?: UserFolder[];
    config?: ServerConfig;
    userType?: number;
    latitude?: number;
    longitude?: number;
    workerAttributes? : Attribute[];
    verifiedByAgency?: boolean;
    verifications?: Verification[];
};

export enum VerificationType {
    Rejected = 0,
    IdentityVerified = 1,
    Representing = 2,
    Vetted = 3,
}

export interface Verification {
    contactName: string;
    contactUserId: string;
    start: string
    typeId: VerificationType,
    verifyingOrgId: string,
    verifyingOrgName: string
}

export interface Attribute {
    sectorId: number;
    subSectorId: number;
    skillId: number;
    sectorName: string;
    subSectorName: string;
    skillName: string;
    sectorIcon?: string;
    skillCount?: number;
}

export interface AttributeBreakdown {
    skillName: string;
    skillCountTotal?: number;
    entities: AttributeBreakdownEntity[];
}

export interface AttributeBreakdownEntity
{
    sectorIcon: string;
    skillId: number;
    skillCount: number;
    sectorId: number;
    subSectorId: number;
    sectorName: string;
    subSectorName: string;
}

export interface ServerConfig {
    allowedFileTypesToUpload: string[];
    fileCategorySuggestions: { id: number, name: string }[];
}

export interface UserFile {
    id?: number;
    userId?: string;
    fileName?: string;
    fileFormat?: string;
    fileType?: FileType;
    fileCategory?: number;
    fileCategoryName?: string;
    description?: string;
    access: FileAccess;
    createdAt?: string;
    accessLink?: string;
    accessLinkExpiry?: number;
    previewAccessLink?: string;
    previewAccessLinkExpiry?: string;
    displayOrder?: number;
    thumbnailImg?: File;
    link?: string;
    folderId?: number;
    virusScanned?: boolean;
    moderatorApproved?: boolean;
}

export interface UserFileWitUserDetails extends UserFile {
    userReported?: boolean;
    userName?: string;
    organisationName?: string;
    organisationId?: string;
}

// MS Office = xls, xlsx, doc, docx, ppt, pptx
// Graphics = jpg, jpeg, png
// Video = mp4, mov
// OpenOffice = odt, ods, opt
export enum FileType {
    Document = 0,
    TextDocument = 1,
    Image = 2,
    Video = 3,
    Link = 4,
    VideoLink = 5
}

export enum FileAccess {
    Public = 0,
    PublicToContacts = 1,
    OnRequest = 2,
    Private = 3
}

export interface RaterDetail {
    id: string;
    contactId: string;
    type: string;
    name: string;
}

interface UserQualification {
    id: number;
    school: string;
    degree: string;
    field: string;
    description: string;
    grade: string;
    startDate: string;
    startYear?: string;
    startMonth?: string;
    endYear?: string;
    endMonth?: string;
    endDate: string;
    startDateTimestamp: number;
    endDateTimestamp: number;
}

interface UserSetting {
    settingId: number;
    settingName: string;
    value: string;
}

export interface UserDTO {
    firstName?: string;
    lastName?: string;
    postalCode?: string;
    sectors?: string;
    timePresets?: string;
    maxDistance?: number;
    allowRecording?: boolean;
    eventColors?: string;
    appVersion?: string;
    industries?: Sector[];
    headline?: string;
    allowDirectEngagement?:boolean;
    allowAgencyEngagement?: boolean;
    settings?: UserSetting[];
    availabilityLastConfirmed?: string;
    workerAttributes?: Attribute[];
    latitude?: number;
    longitude?: number;
};

export interface Sector {
    name?: string;
    text?: string;
    value?: string;
    id: number;
    subSectors?: SubSector[];
    selected?: boolean;
    icon?: string;
    color?: string;
}

export interface SubSector {
    name?: string;
    value?: string;
    id: number;
    suggested?: boolean;
}

export interface UserFolder {
    id: number;
    name?: string;
    description?: string;
    access: FileAccess;
    userId?: string;
}

export interface UserFolderWithFilterType extends UserFolder {
    accessType?: string;
}

export interface FileUserDetail {
    reported: boolean;
    name: string;
    userId: string;
    organisationName: string;
    organisationId: string;
}

export interface AllFilesResponse {
    userDetails: FileUserDetail[],
    ownFiles: UserFileWithFilterType[],
    ownFolders: UserFolderWithFilterType[],
    receivedFiles: UserFileWithFilterType[],
    receivedFolders: UserFolderWithFilterType[],
    explicitelySharedFiles: ExplicitelySharedFile[],
    explicitelySharedFolders: ExplicitelySharedFolder[]
}

export interface ExplicitelySharedFile {
    fileId: number,
    userId: string,
    folderId?: number
}

export interface ExplicitelySharedFolder {
    folderId: number,
    userId: string,
    fileId?: number
}

export interface UserFileWithFilterType extends UserFile {
    accessType: string;
}

export interface DeviceDetails {
    platform: string;
    version: string;
    build: number;
    notificationStatus: string;
    deviceId: string;
}