import React, { useEffect, useState } from 'react';
import EventRepeatImgOne from '../../images/help/event-repeat-1-min.png';
import EventRepeatImgTwo from '../../images/help/event-repeat-2-min.png';
import UserAPI from '../../apis/User';

import PromoPictureA from "../../images/help/onboarding_promo_picture.png";
import PromoFilesA from "../../images/help/onboarding_promo_files.png";
import PromoHeadlineA from "../../images/help/onboarding_promo_headline.png";
import PromoLocationA from "../../images/help/onboarding_promo_location.png";
import PromoQualificationsA from "../../images/help/onboarding_promo_qualifications.png";
import PromoSkillsA from "../../images/help/onboarding_promo_skills.png";
import PromoSMSA from "../../images/help/onboarding_promo_sms.png";
import YourImagePlaceholder from "../../images/your-image-placeholder.png";
import AvailabilityOverWorkImg from "../../images/help/availability-over-work.png";
import RepeatForeverCrashImg from "../../images/help/repeat-forever-clash.png";

import Configuration from '../../Configuration';
import InternalTracker from '../../InternalTracker';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

const VARIATIONS = {
    'image': [
        PromoPictureA
    ],
    'files': [
        PromoFilesA
    ],
    'headline': [
        PromoHeadlineA
    ],
    'postalcode': [
        PromoLocationA
    ],
    'qualifications': [
        PromoQualificationsA
    ],
    'sectorssubsectors': [
        PromoSkillsA
    ],
    'smsnotifications': [
        PromoSMSA
    ]
}

interface CalendarProps {
    onClose: (actioned?: boolean) => void;
    section: string;
    hide?: boolean;
    sectionContents?: any;
}

const SECTION_SETTING_IDS = {
    "events-repeat": 30,   
}

const TipsGuidePrompt: React.FC<CalendarProps> = ({ section, hide, onClose, sectionContents }) => {

    const [mode, setMode] = useState <string> ("hidden"); // View mode
    const [hasProfileImage, setHasProfileImage] = useState <boolean> (false); // Whether user has profile image
    const localStorageKey = section + "TipsGuidePassed";
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    
    const reload = async function() {
        const dismissed = localStorage.getItem(localStorageKey) || (user && user.settings.find(setting => setting.settingId === SECTION_SETTING_IDS[section] && setting.value === "true"))
        if (!dismissed) {
            setMode("prompt");
        }
        if (user && user.userSetProfileImage) {
            setHasProfileImage(true);
        }
    }

    useEffect(() => { reload(); }, [])
    useEffect(() => { reload(); }, [section])

    if (hide) {
        return null;
    }

    // setMode("hidden");
    // if (nextStep === 20) {
    //     localStorage.setItem("contactsRatingScreenPassed", "true")
    //     UserAPI.updateSetting("29", "true");
    
    const profileImg = hasProfileImage ? (UserAPI.getProfilePicture(user.id)) : YourImagePlaceholder;

    if (mode === "prompt") {
        return (
            <React.Fragment>
                <div 
                    className="onboarding-guide-prompt-backdrop"
                    onClick={() => {

                    }}
                >
                </div>
                <div className="onboarding-guide-prompt tips-guide-prompt">
                    { (section === "proposed-resolutions") &&
                        <section data-section="proposed-resolutions">
                            <div className="details">
                                <h2>Confirm Proposed Changes</h2>
                                <p>These changes are irreversible</p>
                                <div>
                                    { sectionContents.numberOfAdds ? <p style={{ color: '#1bb161' }} >Events to be added: <span>{sectionContents.numberOfAdds}</span></p> : null }
                                    { sectionContents.numberOfAmends ? <p style={{ color: '#FFA400' }}>Events to be amended: <span>{sectionContents.numberOfAmends}</span></p> : null }
                                    { sectionContents.numberOfDeletes ? <p style={{ color: '#FB5B5A' }}>Events to be deleted: <span>{sectionContents.numberOfDeletes}</span></p> : null }
                                    { sectionContents.numberOfConfirmedOffers ? <p style={{ color: '#FB5B5A' }}>Confirmed events to be cancelled: <span>{sectionContents.numberOfConfirmedOffers}</span></p> : null }
                                    <p></p>
                                </div>
                                <div className='buttons'>
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("Proposed Resolution Accepted");
                                        setMode("hidden");
                                        onClose(true);
                                    }} className='main'>Proceed and Merge with work events</button>
                                    <button style={{
                                        padding: 8
                                    }} onClick={() => {
                                        InternalTracker.trackEvent("Proposed Resolution Rejected");
                                        setMode("hidden");
                                        onClose();
                                    }}>Back to Edit</button>
                                </div>
                            </div>
                        </section>
                    }
                    { (section === "availability-over-work") &&
                        <section data-section="availability-over-work">
                            <div className="details">
                                <h2>You are attempting to add availability over work events</h2>
                                <p>Availability will NOT override work events, only wraps around them. To make yourself available delete or adjust the work events instead.</p>
                                <div className="marginless-image">
                                    <img src={AvailabilityOverWorkImg} />
                                </div>
                                <div className='buttons'>
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("Availability over Work Accepted");
                                        setMode("hidden");
                                        onClose();
                                    }}>Go back and edit</button>
                                    <button onClick={() => {
                                        InternalTracker.trackEvent("Availability over Work Rejected");
                                        setMode("hidden");
                                        onClose(true);
                                    }} className='main'>Proceed and Merge with work events</button>
                                </div>
                            </div>
                        </section>
                    }
                    { (section === "repeating-forever-clash") &&
                        <section data-section="availability-over-work">
                            <div className="details">
                                <h2>This repeat forever is clashing with another repeat forever event</h2>
                                <p>Please go back and edit the details of this event, or amend the existing repeat forever event that this is clashing with</p>
                                <div className="marginless-image">
                                    <img src={RepeatForeverCrashImg} />
                                </div>
                                <div className='buttons'>
                                    <button onClick={() => {
                                        setMode("hidden");
                                        onClose();
                                    }} className='main'>Go Back</button>
                                </div>
                            </div>
                        </section>
                    }
                    { (section === "events-repeat") &&
                        <section data-section="events-repeat">
                            <div className="details">
                                <h2>Did you know, that you can add similar events faster in two ways?</h2>
                                <div className='step'>
                                    <div className="step-number">1</div>
                                    <div className="step-description">
                                        Select multiple days – then tap "Add to … days"
                                    </div>
                                </div>
                                <div className="step-img">
                                    <img src={EventRepeatImgOne} />
                                </div>
                                <div className='or'>
                                    <div className="or-line"></div>
                                    <div className="or-text">or</div>
                                    <div className="or-line"></div>
                                </div>
                                <div className='step'>
                                    <div className="step-number">2</div>
                                    <div className="step-description">
                                        Repeat - Select the first day, add event details, tap 'Repeat'
                                    </div>
                                </div>
                                <div className="step-img">
                                    <img src={EventRepeatImgTwo} />
                                </div>
                                <div className='buttons'>
                                    <button onClick={() => {
                                        setMode("hidden");
                                        onClose();
                                    }}>Remind me Later</button>
                                    <button onClick={() => {
                                        localStorage.setItem(localStorageKey, "true");
                                        setMode("hidden");
                                        UserAPI.updateSetting("30", "true");
                                    }} className='main'>Got it</button>
                                </div>
                            </div>
                        </section>
                    }
                    { (["image", "headline", "postalcode", "sectorssubsectors", "smsnotifications", "qualifications", "files"].indexOf(section) !== -1 && sectionContents && sectionContents.header) &&
                        <section data-section="events-repeat">
                            <div className="details">
                                <h2>{sectionContents.header}</h2>
                                { sectionContents.body &&
                                    <p>{sectionContents.body}</p>
                                }
                                <div className='marginless-image'>
                                    <img src={VARIATIONS[section][sectionContents.img] || VARIATIONS[section][0]} />
                                    { (["headline", "postalcode", "sectorssubsectors", "smsnotifications", "qualifications", "files"].indexOf(section) !== -1) &&
                                        <img 
                                            className='own-profile-image' 
                                            src={profileImg} 
                                            style={{
                                                width: section === "headline" ? 120 : section === "postalcode" ? 52 : section === "sectorssubsectors" ? 54 : section === "smsnotifications" ? 50 : section === "qualifications" ? 68 : section === "files" ? 26 : "",
                                                height: section === "headline" ? 120 : section === "postalcode" ? 52 : section === "sectorssubsectors" ? 54 : section === "smsnotifications" ? 50 : section === "qualifications" ? 68 : section === "files" ? 26 : "",
                                                top: section === "headline" ? 49 : section === "postalcode" ? 85 : section === "sectorssubsectors" ? 0 : section === "smsnotifications" ? 88 : section === "qualifications" ? 0 : section === "files" ? 5 : "",
                                                left: section === "headline" ? 106 : section === "postalcode" ? 67 : section === "sectorssubsectors" ? 5 : section === "smsnotifications" ? 16 : section === "qualifications" ? 132 : section === "files" ? 38 : "",
                                                zIndex: section === "headline" ? 1 : section === "postalcode" ? 3 : section === "sectorssubsectors" ? 3 : section === "smsnotifications" ? 3 : section === "qualifications" ? 3 : section === "files" ? 3 : 3
                                            }}
                                        />
                                    }
                                    { (section === "files") && <img className='own-profile-image' src={profileImg} style={{ width: 42, height: 42, top: 64, left: 216 }} /> }
                                    { (section === "files") && <img className='own-profile-image' src={profileImg} style={{ width: 25, height: 25, top: 150, left: 178 }} /> }
                                </div>
                                <div className='buttons'>
                                    <button onClick={() => {
                                        setMode("hidden");
                                        onClose();
                                        InternalTracker.trackEvent("Tip Prompt Remind Later - " + section)
                                    }}>Remind me Later</button>
                                    <button onClick={() => {
                                        setMode("hidden");
                                        onClose(true);
                                        InternalTracker.trackEvent("Tip Prompt Proceeded - " + section)
                                    }} className='main'>Add Now</button>
                                </div>
                            </div>
                        </section>
                    }
                </div>
                { (["image", "headline", "postalcode", "sectorssubsectors", "smsnotifications", "qualifications", "files"].indexOf(section) !== -1) &&
                    <button 
                        className='hide-forever-btn'
                        onClick={() => {
                            localStorage.setItem(localStorageKey, "true");
                            setMode("hidden");
                            onClose();
                            InternalTracker.trackEvent("Hidden Tip Prompt Forever - " + section)
                            UserAPI.updateSetting(
                                section === "image" ? "32" :
                                section === "headline" ? "33" :
                                section === "postalcode" ? "34" :
                                section === "files" ? "35" :
                                section === "sectorssubsectors" ? "36" :
                                section === "smsnotifications" ? "37" :
                                section === "qualifications" ? "38" : "32"
                            , "true");
                        }}
                    >
                        Don't show this tip again
                    </button>
                }
            </React.Fragment>
        )
    }

    return null;
    
};

export default TipsGuidePrompt;
